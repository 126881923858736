import React, {Component, useState} from 'react'
import { withStyles, Table, TableBody, TableRow, TableCell, Grid, Avatar, IconButton, Icon, Fab, Typography } from '@material-ui/core';
import Caption from '../../../../../../../components/Texts/Caption';
import { fade, makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import PopperWrapper from '../../../../../../../components/Poppers/PopperWrapper';
import SimpleTable from './components/SimpleTable';
import CardWrapper from '../../../../../../../components/Cards/CardWrapper/CardWrapper';
import { Subtitle } from '../../../../../../../components/DisplayData/DisplayData';

const ContactInfoList = props => {
  
  const classes = styles()
  const {title, data, total, onAdd, onEdit, onDelete, loading} = props 

  return(
    <CardWrapper>
      <div style={{padding:'32px'}}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                <Grid item xs><Subtitle data={title}/></Grid>
                <Grid item><IconButton className={classes.addbutton} color='primary' onClick={onAdd}><Icon className={classes.icon}>add</Icon></IconButton> </Grid>
                
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <SimpleTable 
              data={data}
              total={total} 
              loading={loading}
              onEditItem={onEdit}
              onDeleteItem={onDelete}
            />
          </Grid>
        </Grid>
      </div>
    </CardWrapper>
  )
  
}

const styles = makeStyles(theme => ({
  addbutton:{
    width:'30px',
    height:'30px',
    background:theme.palette.secondary.main,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    color:'white',
    '&:hover':{
      background:theme.palette.secondary.dark,
    },
  },
  icon:{
    position:'absolute'
  },
  root:{
    width:'inherit',
    marginTop:16,
    paddingTop:16,
    borderTop:`1px dashed ${grey[300]}`,
    paddingBottom:16,

  },
  row:{
    margin:0,
    padding:'0px !important'
  },
  cell:{
    border:0,
    margin:0,
  },
  cellWrapper:{
    padding:16,
    paddingTop:12,
    paddingBottom:12,
    borderRadius:8,
    background: fade(theme.palette.secondary.main,0.05)
  },
  avatarCell:{
    '&:hover':{
      cursor:'pointer'
    }
  },
  table:{
    paddingBottom:'30px',
    paddingLeft:0
  }
}))

export default ContactInfoList


const AvatarData = (props) => {

  const {data} = props

  return(
    <div>
      <Grid container spacing={1}>
        <Grid item>
          <Avatar src={data.image} alt='Dr' />
        </Grid>
        <Grid item>
          <Grid container direction='column'>
            <Grid item>
              <Caption text={data.name} variant='bold'/>
            </Grid>
            <Grid item>
              <Caption text={data.speciality}/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const InfoButton = ({icon, data}) => {

  const classes = button_styles()
  const [open, setOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)

  return(
    <div>
      <PopperWrapper
        open={open}
        anchorEl={anchorEl}
        onClickAway={onCloseMenu}
      >
        <div style={{padding:4, paddingLeft:8, paddingRight:8, maxWidth:200}}>
          <Grid container>
            <Grid item xs={12}>
              <Caption text={data.label} variant='light'/>
            </Grid>
            <Grid item xs={12}>
              <Caption text={data.data} variant='bold' medium/>
            </Grid>
          </Grid>
        </div>
      </PopperWrapper>
      <Fab size='small' className={classes.button} onClick={onOpenMenu}>
        <Icon fontSize='small' className={classes.icon}>
          {icon}
        </Icon>
      </Fab>
    </div>
  )

  const onCloseMenu = () => {
    setOpen(false)
    setAnchorEl(null)
  }
 

  const onOpenMenu = (event) => {
    const { currentTarget } = event;
    setAnchorEl(currentTarget)
    setOpen(true)
  }
}

const button_styles =  makeStyles(theme => ({
  root:{},
  button:{
    background:'white',
    boxShadow:'0 5px 24px 0 rgba(82, 91, 115, .12)',
    '&:hover':{
      background:'white',
    }
  },
  icon:{
    color:theme.palette.primary.main
  }
}))