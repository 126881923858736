export const contentData = {
    spanish:{
        layout:{
            title:'Inscripción al SAT Persona Física',
            navigation:[
                {name:'Clientes',path:'/clients'},
                {name:'Detalles',path:'/clients/details/:id'},
                {name:'Negocios',path:'/clients/details/:id/businesses'},
                {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
                {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
            ],
            menu:[
                {id:0, label:'General'},
                {id:1, label:'Logs'}
            ]
        },
        modals:{
            warning_delete_file:{
                title:'¿Estás seguro?',
                message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
                cancelButton:'Cancelar',
                deleteButton:'Eliminar'
            },
            edit_service:{
                title:'Editar servicio',
                button:'Guardar',
                cancelButton:'Cancelar'
            }
        },
        basicform:{
            title:'Información Básica',
            button:'Editar',
            nodata:'Sin Registro',
            data:null,
            content:null,
            documents:{
                title:'Documentos',
                header:{title:'Archivos', actions:'Acciones'},
                files:[
                    {key:'oficial_id',name:'IFE - INE',path:'oficialid', status:false},
                    {key:'address_proof',name:'Comprobante de Domicilio',path:'addressproof', status:false},
                    {key:'curp',name:'CURP',path:'curp', status:false},
                    {key:'sat_enrollment_format',name:'Formato "Inscripción SAT"',path:'satenrollmentformat', status:false}
                ]
            }
        }
    }
}
