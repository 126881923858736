export const contentData = {
    spanish: {
        title: 'Estadísticas',
        button: null,
        nodata: 'Sin Registro',
        statistics: [
            { 
                id: 1, 
                icon: 'pending_actions',
                label: 'Time Reports',
                notifications: false, 
                url: 'statistics/timereports/'
            },
            { 
                id: 2, 
                icon: 'assignment',
                label: 'Evaluaciones',
                notifications: false, 
                url: 'statistics/evaluations/'
            },
        ]
    }
}
