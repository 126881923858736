import { Avatar, Grid, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

const WelcomeMessage = () => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container justify='center' spacing={2}>
                <Grid item>
                    <img src="/assets/images/chatbot.png" width={100} />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h6'>
                    ¡Hola! Soy ASiBot, tu aliado laboral.
                    <br/>
                    Estoy aquí para brindarte apoyo en los retos de tu trabajo dentro de ASCG Group.
                    </Typography>
                    

                </Grid>
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        height:'100%',
        display:'flex',
        flexDirection:'column',
        alignItems:'center',
        justifyContent:'center',
        padding:100,
        boxSizing:'border-box',
        [theme.breakpoints.down('sm')]:{
            padding:16
        }
    }
}))
 
export default WelcomeMessage;