import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField, Icon } from '@material-ui/core';
import { ruleValidation } from './customFunctions.jsx';
import { blueGrey, grey } from '@material-ui/core/colors';
import DisplayText from '../Texts/DisplayText.jsx';

const CssTextField = withStyles(theme => ({
  root: {
    paddingTop: 6,
    '& input': {
      color: grey[900],
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `16px`,
      },
    },
  }
}))(TextField);

const InputText = props => {

  const { color, onChange, data, onKeyPress, log, disabled, className, endAdornment } = props
  const { value, isVisited, isValid, isRequired } = props.data
  const { id, type, label, placeholder, fullWidth, icon, multiline, rows, helper, minDate, maxDate, step, onKeyDown } = props.data.config

  const onInnerChange = event => {
    let response = event.target.value;
    let temp = { ...data };
    /*if(temp.config.type === 'number'){
      response = temp.config.float ? Number(response).toString() : parseInt(response).toString()
    }*/
    temp.value = response;
    temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
    temp.isVisited = true;
    onChange(temp);
  }

  const error = isVisited && !isValid;

  let inputaddornment = null;
  if (icon) {
    //console.log(icon)
    inputaddornment =
      <InputAdornment position='start'>

        <Icon style={{ color: blueGrey[500] }}>
          {icon.name}
        </Icon>
      </InputAdornment>
  }


  if (log) {
    console.log(`ID: ${id}`)
    console.log(`disabled: ${data.disabled || disabled}`)

  }

  return (
    <div>
      <DisplayText variant='subtitle2' color={error ? 'error' : color ? color : 'inherit'}>{label}</DisplayText>
      <CssTextField
        variant='outlined'
        placeholder={placeholder}
        fullWidth={fullWidth ? fullWidth : true}
        disabled={data.disabled || disabled}
        error={error}
        value={value !== undefined && value !== null ? value : ''}
        onChange={onInnerChange}
        multiline={multiline}
        rows={rows}
        type={type}
        helperText={error ? helper : null}
        InputProps={{
          startAdornment: inputaddornment,
          endAdornment: endAdornment,
          disabled: data.disabled || disabled
        }}
        InputLabelProps={{
          shrink: true
        }}
        onKeyPress={onKeyPress}
        inputProps={{
          spellCheck: "false",
          autoComplete: id === 'password' ? 'new-password' : "off",
          name: "hidden",
          disabled: data.disabled || disabled,
          min: minDate,
          max: maxDate,
          step:step
        }}
      />
    </div>

  )
}

export default InputText