import React from 'react'
import { Typography } from '@material-ui/core'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './htmlStyles.css'


const HtmlDisplayer = props => {

    const {data, maxHeight} = props

    return(
        <div>
            <div style={{height:maxHeight ? maxHeight : '400px', overflowY:'auto', marginTop:'4px'}}>
                {data !== null ? <CKEditor
                    id={`rt-text`}
                    className='ck-editor' 
                    editor={ClassicEditor}
                    onError={() => console.log('Hubo un error en ckeditr')}
                    onReady={() => console.log('Estuvo listo')}
                    data={data}
                    disabled={true}
                    config={{toolbar:[]}}                                              
                /> : null}
            </div>
        </div>
    )
}

export default HtmlDisplayer