export const contentData = {
  spanish:{
    nodata:'-',
    notable:'No existen registros patronales',
    tableTitle:'Registro Patronal',
    header:['ID','Estado','Registro Patronal', 'Usuario', 'Contraseña','Fecha límite de pago','Tarjeta Patronal', ],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      cerl:{
        name:'Certificado eFirma',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
  },
  english:{

  }
}