export const formData = {
    rfc:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
          id:'rfc',
          type:'text',
          fullWidth: true,
        },
        rules:{
        type:'rfc',
        }
    },
    sat_password:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false, 
        config:{
            id:'sat_password',
            type:'text',
            fullWidth: true,
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    taxpayer_type_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
          id:'taxpayer_type_id',
          type:'simple_select',
          fullWidth: true,
        },
        rules:{
          type:'none',
        }
    },
    tax_payment_limit_date:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'tax_payment_limit_date',
            type:'date',
            fullWidth: true
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    }
};
