export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'real_staff',
            responsible_id: 'real_staff_id',
            completed_date: 'real_staff_completed_date',
            ctrlpath:true
        },
        accordions: [
            {
                id: 'payroll_social_security_taxes',
                title: 'Registro de Nómina, Seguro Social, Impuestos Sobre Nómina',
            },
            {
                id: 'professional_services_payment',
                title: 'Registro Pago de Honorarios y Servicios Profesionales'
            },
            {
                id: 'payment_service_invoice',
                title: 'Pago y Factura del Servicios - AS Consulting Group'
            },
            {
                id: 'intercompany_operation',
                title: 'Registro Operaciones Intercompañia'
            },
            {
                id: 'non_tax_provisions',
                title: 'Registro de Provisiones contables no fiscales'
            },
            {
                id: 'interest_loans_payments',
                title: 'Registro de Pago de Intereses y/o Préstamos'
            },
            {
                id:'inventories',
                title:'Registro de Inventarios'
            },
            {
                id: 'amortizations_deprecations',
                title: 'Registro de Amortizaciones y Depreciaciones'
            },
            {
                id: 'tax_payment_record',
                title: 'Registro de Pago de Impuestos'
            },
            {
                id: 'insurance_policy',
                title: 'Pólizas'
            },
            {
                id: 'bank_reconciliation',
                title: 'Conciliación Bancaria'
            },
            {
                id: 'tax_calculation',
                title: 'Determinación de Impuestos'
            },
        ],
    }
}
