import React from 'react'
import { Button, CircularProgress, Fab, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import { onFormatNumber, onGetDateFormat, onGetFullname, onGetTimeDuration } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import empty from '../../../../assets/empty.png'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import TimeReportState from '../../../../components_v1/Structure/DisplayData/TimeReportState'
import { catalogs } from '../../../../texts/esp/catalogs'


const statusCatalog = [{id:-1, value:-1, label:'Todos'}, {id:1, value:1, label:'Activo'},{id:0, value:0, label:'Inactivo'}]
const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const header = [
    {id:1, label:'ID'},
    {id:2, label:'Usuario'},
    {id:3, label:'Negocio'},
    {id:4, label:'Servicio'},
    {id:4, label:'Tipo de tarea'},
    {id:5, label:'Fecha'},
    {id:6, label:'Duración'},
    {id:7, label:'Estatus'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const ApprovalSimpleTable = props => {

    const classes = useStyles()
    const {data, onAddRegister, task_type, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading} = props

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container justify='flex-start' spacing={2} alignItems='flex-end' > 
                    <Grid item>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-start'>
                        <Grid item><FilterButton label='Tipo de tarea' options={[...catalogs.timereport_tasks, {id:-1, value:-1, label:'Todos'}]} selected={task_type} onChange={(val) => onChangeFilter(val,'task_type')}/></Grid>

                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {id_time_report, task_type, service, user, business,date, duration, state_id} = item

                                    const report_date = onGetDateFormat(date, 'DD MMM YY')
                                    const timdata = onGetTimeDuration(duration)

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} onClick={() => onSelectedItem(item)}>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{id_time_report}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{user}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{business}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{service}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{task_type}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{report_date}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{timdata}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><TimeReportState id={state_id} variant='body2'/></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={140}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default ApprovalSimpleTable