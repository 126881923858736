export const contentData = {
  spanish:{
    layout:{
      title:'Editar prospecto',
      navigation:[
        {name:'Prospectos',path:'/prospects'},
        {name:'Editar',path:'/prospects/edit'},   
      ],
    },
  }
}