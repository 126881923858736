export const contentData = {
  spanish:{
    title:'Editar información de contacto',
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    form:{
      phone_number:{
        label:'Teléfono',
        placeholder:'Teléfono',
      },
      work_email:{
        label:'Correo electrónico',
        placeholder:'Correo electrónico',
      },
      personal_email:{
        label:'Correo personal',
        placeholder:'Correo personal',
      },
      gmail:{
        label:'Usuario Microsoft',
        placeholder:'Usuario Microsoft',
      },
      dropbox:{
        label:'Usuario AS iViewer',
        placeholder:'Usuario AS iViewer',
      }
    },
  },
}