import React, {Component} from 'react'
import {
  TableRow,
  TableCell,
  Icon,
  IconButton,
  Grid,
  Switch
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import {TableItem, FileItemWrapper, FileItemWrapperDownload} from '../../../../components/DisplayData/DisplayData'
import cx from 'classnames'
import InputSimpleSelect from '../../../Inputs/InputSimpleSelect/InputSimpleSelect'
import AvatarSelectInput from '../../../Inputs/AvatarSelectInput/AvatarSelectInput'
import InputText from '../../../Inputs/InputText/InputText'
import moment from 'moment'
import { sleep } from './customFunctions'

const styles = (theme) => ({
  root:{},
  tablecell:{
    border:0,
    /*'&:hover':{
      cursor:'pointer'
    }*/
  },
  editcell:{
    border:0,
    boxSizing: 'border-box',
    padding:'0px',
    paddingRight:'10px',
    minWidth:'200px'
  },
  tablerow:{
    height:'76px',
  },
  hideCell:{
    display:'none'
  },
  statusGreen:{
    width:'10px',
    height:'10px',
    borderRadius: '10px',
    backgroundColor:'green'
  },
  statusYellow:{
      width:'10px',
      height:'10px',
      borderRadius: '10px',
      backgroundColor:'yellow'
  },
  statusRed:{
      width:'10px',
      height:'10px',
      borderRadius: '10px',
      backgroundColor:'red'
  }
})

const prepareData = (users) => {
  let usersData = [];
 
  if(users){
    usersData = users.map((item) => {
      let firstname = item.first_name ? item.first_name : '';
      let lastname = item.last_name ? item.last_name : ''; 
      return{
        value: item.id_user,
        label: firstname + ' ' + lastname,
        image: item.image
      }
    })
  }

  return usersData;
}

class ControlTableRow extends Component{
  
  state = {
    editMode : false,
    completed: false,
    formData: {},
    isValid:false,
  }

  componentDidMount(){
    const {edit, data, catalogs, catalog_matrix, content, formData} = this.props

    if(edit){
      let temp = JSON.parse(JSON.stringify(formData))
      if(catalog_matrix){
        catalog_matrix.forEach(item => {
          if(item.catalog_key !== 'none'){
            temp[item.form_key].options = catalogs[item.catalog_key]
          }else{
            const aux_catalog = prepareData(this.props[item.propname])
            temp[item.form_key].options = aux_catalog
          }
          
        })
      }
      Object.keys(formData).forEach(item => {
        temp[item].config = {...temp[item].config,...content.form[item]} // Init labels, placeholders, errors, helpers
        if(data.origin_data){
          let value = data.origin_data[item]
          if(temp[item].config.type === 'date'){
            value = value ? moment(value).format('YYYY-MM-DD') : null
          }
          if(temp[item].config.type === 'time'){
            value = value ? value : null
            value = value === 'Invalid date' ? null : value
          }
          temp[item].value = value ? value : ''
          temp[item].isValid = value ? true : false
        }else{ // When initialization is not necessary
          temp[item].value = temp[item].value ? temp[item].value : '' 
          temp[item].isValid = false
        }
      });

      const isValidForm = this.validationForm(temp);
      this.setState({formData:{...temp}, isValid: isValidForm, completed: data.completed})
    }
  }

  onSelected = () => {
    this.setState({editMode : true})
  }

  onSubmit = (id) => {
    const {onEdit, origin_data} = this.props;
    const {formData} = this.state;
    let data2Send = {};

    if(origin_data){
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
          if(formData[item].value !== origin_data[item]){
            data2Send = {...data2Send, [item]:formData[item].value}
          }
        }
      })
    }else{
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
    }
    
    this.setState({editMode : false})
    onEdit(id, data2Send)
  }


  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  handleSwitch = async(event) =>{
    const {onEdit, data} = this.props
    const completed = event.target.checked ? 1 : 0
    this.setState({completed: event.target.checked})
    onEdit(data.id, {completed:completed});
    await sleep(600)
    this.setState({completed: !this.state.completed})
  }

  render(){

    const {classes, data, onFileAction, edit, wrap} = this.props;
    const {editMode, formData, completed} = this.state

    let editButton = null; 
    const normalRow = (
      <TableRow
        key={data.id.toString()}
        className={classes.tablerow}>
        {data.data.map((label,key) => {
            if(Array.isArray(label)){
              return (<TableCell
                className={cx({
                  [classes.tablecell]:true,
                  [classes.hideCell]:(key == 0 ? false :wrap)
                })}
              >
                <Grid container justify="center" alignItems="center" spacing={1}>
                  <Grid item className={cx({
                    [classes.statusGreen]: label[0].color === 'green',
                    [classes.statusYellow]: label[0].color === 'yellow',
                    [classes.statusRed]: label[0].color === 'red'
                  })}></Grid>
                  <Grid item>
                    <TableItem
                          data={label[0].label}
                      />
                  </Grid>
                </Grid>
              </TableCell>)
            }
            else if(typeof label === 'string'){
              return (
                  <TableCell
                    className={cx({
                      [classes.tablecell]:true,
                      [classes.hideCell]:(key == 0 ? false :wrap)
                    })}
                  >
                    <TableItem
                        data={label}
                    />
                  </TableCell>
              )
            }
            else if(typeof label === 'object'){
              let file = data.files.filter(e => e.key == label.key)
              
              return (
                  <TableCell
                      className={cx({
                        [classes.tablecell]:true,
                        [classes.hideCell]:wrap
                      })}
                      padding='checkbox'>
                      {edit ? 
                        <FileItemWrapper
                            data={file[0]}
                            onFileAction={onFileAction}
                        />
                      :
                        <FileItemWrapperDownload
                          data={file[0]}
                          onFileAction={onFileAction}
                        />}
                  </TableCell>
              )
            }
        })}
      {edit ?
          <Grid container justify="center" alignItems="center" spacing={4}>
            <Grid item>
              <TableCell>
                <Switch
                    checked={completed}
                    onChange={this.handleSwitch}
                    color={classes.completedSwitch}
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                />
              </TableCell>
            </Grid>
            <Grid item>
              <TableCell
                  className={classes.tablecell}
                  padding='checkbox' align='center'
                  onClick={() => this.onSelected()}>
                <IconButton style={{padding:8}}>
                  <Icon fontSize='small'>edit</Icon>
                </IconButton>
              </TableCell>
            </Grid>
          </Grid>: null}
      </TableRow>
    )
    
    let content = null;
    
    if(formData)
      content = Object.keys(formData).map((item,key)=> {
        let inputContent = null
        console.log(formData[item])
        switch (formData[item].config.type) {
          
          case 'simple_select':
            inputContent = (
              <InputSimpleSelect
                wrap='vertical'
                data={formData[item]}
                onChange={this.onInputChange}
              />
            )
            break;
          case 'avatar_select':
            inputContent = (
              <AvatarSelectInput
                wrap='vertical'
                data={formData[item]}
                onChange={this.onInputChange}
              />
            )
            break
          case 'file':
            let file = data.files.filter(e => e.key == formData[item].key)
            inputContent = (
              <FileItemWrapper
                data={file[0]}
                onFileAction={onFileAction}
              />
            )
            break
          default:
            inputContent = (
              <InputText
                data={formData[item]}
                onChange={this.onInputChange}
              />
            )
            break;
        }
        return(
          <TableCell
            className={cx({
              [classes.editcell]:true,
              [classes.hideCell]:(key == 0 ? false :wrap)
            })}
          >
            {inputContent}
          </TableCell>
        )
    })

    const editRow = (
      <TableRow
        key={data.id.toString()}
        className={classes.tablerow}>
        {content}
      <TableCell
            className={classes.tablecell}
            padding='checkbox' align='center'
            onClick={() => this.onSubmit(data.id)}>
          <IconButton style={{padding:8}}>
            <Icon fontSize='small'>save</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    )


    return( editMode ? editRow : normalRow )
  }
}

export default withStyles(styles)(ControlTableRow)
