import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import AddUserCard from './AddUserCard/AddUserCard'

import {sleep, request_add_user} from './requests'

class AddUser extends Component{

  state = {
    isloading:false,
    issending:false,
    error:null,
    user:null,
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id];
    temp.open = false;
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state;
    let success_temp = {...success};
    success_temp.open = true;
    this.setState({success:{...success_temp}})
  }

  initModule = async() => {
    this.setState({isloading:true})

    this.setState({isloading:false})
  }

  onCreateUser = async(data) => {
    try {
      this.setState({issending:true})
      let userID = await request_add_user(data)
      console.log(userID)
      this.setState({issending:false, error:null})
      this.onActivateSuccessSnack()
      await sleep(2000)
      this.props.history.push("/users/details/"+userID)
    } catch (error) {
      this.setState({issending:false})
      if(error.response){
        console.log(error.response)
        this.setState({error:error.response.data.error.message})
      }
      
    }
  }

  onReturn = () => {
    this.props.history.push('/users')
  }

  render(){

    const {history, language, catalogs} = this.props;
    const {isloading, issending, error, success} = this.state
    const content = contentData[language]

    let mainContent = <div>Loading...</div>

    if(!isloading){
      mainContent = (
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <AddUserCard 
              language={language}
              catalogs={catalogs}
              issending={issending}
              error={error}
              onSubmit={this.onCreateUser}
              />
          </Grid>
        </Grid>
      )
    }
    
    return(
      <BasicView
        history={history}
        selected={0}
        onChange={this.onChangeView}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {mainContent}
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(AddUser)