import React, { useState, useEffect } from 'react';
import TimeReportTable from '../../../../../../components_v1/Structure/Tables/TimeReportTable/TimeReportTable';
import queryString from 'query-string'
import { onGetMonthsCatalog } from '../../../../../../shared/utility';
import moment from 'moment'
import { request_timereports, request_timereport_statistic } from '../../requests';
import { makeStyles } from '@material-ui/core';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';

const useStyles = makeStyles(theme => ({
    root:{
        padding:'32px 0px'
    }
}))

const BusinessTimeReport = ({history, match}) => {

    const classes = useStyles()
    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [statistics, setStatistics] = useState(null)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [month, setMonth] = useState(moment().month()+1)
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const clientID = match.params.id
    const businessID = match.params.id

    useEffect(() => {
        const initModule = async() => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const {limit, offset} = tableFilter
                const rangeDate = month_options.find(el => el.value === month)
                let filterArgs = [
                    {"field":"business_id","operator":"LIKE","value":businessID},
                    {"field":"date","operator":">=","value":rangeDate.start_date},
                    {"field":"date","operator":"<=","value":rangeDate.end_date}
                ]
                const param2send = {language_id:2, limit:limit, offset:offset*limit, order_by:'id_time_report', order:1, start_date:rangeDate.start_date, end_date:rangeDate.end_date}
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _timereports = await request_timereports(params)
                const statistics_params = {
                    language_id:2,
                    start_date: rangeDate.start_date,
                    end_date:rangeDate.end_date
                }
                const _statistics = await request_timereport_statistic(businessID, statistics_params)
                setTotal(_timereports.count)
                setTimereports(_timereports.time_reports)
                setStatistics(_statistics && _statistics.length ? _statistics[0] : null)

            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        initModule()
    }, [filter, tableFilter, month])

    const onChangeFilter = (value, type) => {
        if(type === 'month') setMonth(value)
        if(type === 'year'){
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setMonth(1)
        }

    }

    const onSelectedItem = (item) => window.open(`/clients/details/${clientID}/businesses/${businessID}/timereport/${item.id_time_report}`,'_blank')

    return ( 
        <div className={classes.root}>
            <LoadingContainer>
                <TimeReportTable type='business' statistics={statistics ? statistics : {}}
                    month={month} year={year} monthCatalog={month_options}
                    data={timereports} filter={filter} tableFilter={tableFilter}
                    total={total} loading={loading}
                    onSelectedItem={onSelectedItem}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onChangeFilter={onChangeFilter} onSelectedItem={onSelectedItem}/>
            </LoadingContainer>
        </div>
        
     );
}
 
export default BusinessTimeReport;