import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import { onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import { contentData } from './content';
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import ButtonsSelect from '../../../../../../components_v1/Forms/ButtonsSelect';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32,
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32,
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const SectionB = ({ id, ctrlID, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 2
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [accordion, setAccordion] = useState(true)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const _process = await request_process(id, `?step_id=${step}`)
                setProcess(_process)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, _process, step)
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner)
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }
            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        console.log(data)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='body2' className={classes.title}>{content.title}</DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    <Accordion2 expanded={accordion} onChange={() => setAccordion(prevState => !prevState)}>
                                        <AccordionSummary2
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="general_info"
                                            id="top_banner"
                                        >
                                            <DisplayText variant='body2' align='center'>{content.accordion_title}</DisplayText>
                                        </AccordionSummary2>
                                        <AccordionDetails>
                                            <ButtonsSelect
                                                data={form.accounting_revision}
                                                onChange={onChange}
                                                disabled={!permission}
                                            />
                                        </AccordionDetails>
                                    </Accordion2>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container justify='center' spacing={8}>
                                <Grid item xs={12}>
                                    <ShadedInputText
                                        className={classes.selectStatus}
                                        color='primary'
                                        data={form.doubts_clarifications}
                                        onChange={onChange}
                                        disabled={!permission}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DocumentInput
                                        data={files[0]}
                                        onUploadDocument={() => onUploadDocument(filesData[0])}
                                        onDownloadDocument={() => onDownloadDocument(filesData[0])}
                                        disabled={!permission}
                                        downloadEnabled
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(step - 1, true)}>
                            {content.back}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer>
    )
}

const filesData = [
    { path: 'evidence', name: 'Evidencia', status: false },
]

const formData = {
    accounting_revision: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'accounting_revision',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Introduce un texto',
        },
        rules: {
            type: 'none',
        }
    },
    doubts_clarifications: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'doubts_clarifications',
            type: 'text',
            fullWidth: true,
            label: 'Dudas o aclaraciones',
            helper: 'Introduce un texto',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
}

export default SectionB