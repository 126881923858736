import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_accounting,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.sub_activity ? currentData.sub_activity : noData,
        currentData.economic_activity_sector ? currentData.economic_activity_sector : noData,
        currentData.payday_limit ? currentData.payday_limit : noData,
        currentData.as_responsible ? currentData.as_responsible : noData,
        currentData.coi ? currentData.coi : noData
    ];

    let varData = [
        {key:'sat_table'},
        {key:'client_docs'},
        {key:'tax_work_sheet'},
        currentData.responsible ? currentData.responsible : noData,
        currentData.revision_date ? moment(currentData.revision_date).format('DD-MM-YYYY') : noData,
        currentData.revisor ? currentData.revisor : noData,
        {key:'client_authorization'},
        currentData.control_client_status ? currentData.control_client_status : noData,
        currentData.process_status ? currentData.process_status : noData,
        currentData.sent_date ? moment(currentData.sent_date).format('DD-MM-YYYY') : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        responsible_id: currentData.responsible_id,
        revision_date : currentData.revision_date,
        revised_by : currentData.revised_by,
        control_client_status_id : currentData.control_client_status_id,
        process_status_id : currentData.process_status_id,
        sent_date : currentData.sent_date,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_accounting,key:'sat_table',name:'Tabla SAT',path:'sattable',
            status: checkStatus(currentData, 'sat_table')},
            {id:currentData.id_ctrl_accounting,key:'client_docs',name:'Docs Cliente',path:'clientdocs',
            status: checkStatus(currentData, 'client_docs')},
            {id:currentData.id_ctrl_accounting,key:'tax_work_sheet',name:'Hoja Trabajo Impuestos',path:'taxworksheet',
            status: checkStatus(currentData, 'tax_work_sheet')},
            {id:currentData.id_ctrl_accounting,key:'client_authorization',name:'Autorización Cliente',path:'clientauthorization',
            status: checkStatus(currentData, 'client_authorization')},
            {id:currentData.id_ctrl_accounting,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
            {id:currentData.id_ctrl_accounting,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
