import React, { Component } from 'react';
import styles from './styles.jsx';
import cx from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  Grid,
  Icon,
  IconButton
} from '@material-ui/core';
import UserButton from '../../components/Buttons/UserButton/UserButton.jsx';
import UserPopper from '../Poppers/UserPopper/UserPopper.jsx';
import { contentData } from './content.jsx';
import asiviewer from '../../assets/platforms/asiviewer.png'
import asnews from '../../assets/platforms/asnews.png'
import asxpert from '../../assets/platforms/asxpert.png'
import sicofi from '../../assets/platforms/sicofi.png'
import teams from '../../assets/platforms/teams.jpg'
import ascg from '../../assets/platforms/ascg.png'
import ascgBanner from '../../assets/company/30years.png'



class Topbar extends Component {

  state = {
    //open: true,
    user_anchorEl: null,
    user_open: false,
  }

  handleClick = () => {
    this.setState({
      user_open: false
    });
  };


  handleOpenUserPopper = event => {
    const { currentTarget } = event;
    this.setState({
      user_anchorEl: currentTarget,
      user_open: true
    });
  }
  handleLogout = () => {
    localStorage.clear();
    this.props.history.push("/signin");
    console.log("sali")
  }

  onMenuItemSelected = (path) => {
    const { onActionSelected } = this.props
    this.setState({
      user_anchorEl: null,
      user_open: false
    })

    onActionSelected(path)
  }

  render() {
    const { classes, toggleDrawer, open, closet, user, notifications, language } = this.props;
    const { user_anchorEl, user_open } = this.state;

    const content = contentData[language]

    return (

      <div className={classes.root}>

        <UserPopper
          menu={content.menu}
          open={user_open}
          anchorEl={user_anchorEl}
          onClickLogout={closet}
          onItemSelected={this.onMenuItemSelected}
          onClickAway={this.handleClick}
          onMouseOut={this.handleClick}

        />

        <AppBar
          position="fixed"
          className={cx(classes.appBar, {
            [classes.appBarSecond]: open
          })}
        >
          <Toolbar>
            <Grid
              className={classes.menuButton}
              container
              direction='row'
              justify='flex-start'
              spacing={4}
            >
              <Grid item>
                <IconButton
                  onClick={toggleDrawer}
                >
                  <Icon className={classes.menu}>menu</Icon>
                </IconButton>
              </Grid>

            </Grid>
            <Grid container direction='row' justify="flex-end" alignItems='center' spacing={2} wrap='nowrap'>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://ascg.uberprototech.com/login')}>
                  <img src={asiviewer} alt='' width={120} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://cfd.sicofi.com.mx/Sicofi/Main')}>
                  <img src={sicofi} alt='' width={80} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://teams.microsoft.com')}>
                  <img src={teams} alt='' width={120} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://asnews.mx/')}>
                  <img src={asnews} alt='' width={120} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://www.asxpert.com.mx')}>
                  <img src={asxpert} alt='' width={120} />
                </div>
              </Grid>
              <Grid item>
                <div className={classes.platformBtn}
                  onClick={() => this.onGo2Page('https://ascg.mx/')}>
                  <img src={ascg} alt='' width={120} />
                </div>
              </Grid>
              <Grid item>
                <div onMouseOver={this.handleOpenUserPopper} >
                  <UserButton user={user} notifications={notifications} closet={closet} />
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }

  onGo2Page = (url) => {
    window.open(url, '_blank')
  }
}


export default withStyles(styles)(Topbar);
