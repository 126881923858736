export const contentData = {
  spanish: {
    title: 'Permisos',
    nodata: 'Sin Registro',
    error: 'Sin registro',
    save_button: 'Guardar',
    image_button: 'Cambiar',
    header: ['General', 'Servicios', 'Tablas de Control'],
    table: {
      header: ['Sin Acceso', 'Lectura', 'Edición', 'Creación', 'Acceso Completo']
    },
    permissions: {
      general: [{ id_permission_module: 1, name: 'Usuarios', id_permission_type: 1 },
      { id_permission_module: 73, name: 'Información laboral', id_permission_type: 1 },
      { id_permission_module: 2, name: 'Clientes', id_permission_type: 1 },
      { id_permission_module: 3, name: 'Negocios', id_permission_type: 1 },
      { id_permission_module: 4, name: 'Servicios', id_permission_type: 1 },
      { id_permission_module: 5, name: 'Tablas de Control', id_permission_type: 1 },
      { id_permission_module: 70, name: 'Time Report General', id_permission_type: 1 },
      { id_permission_module: 71, name: 'Estadísticas', id_permission_type: 1 },
      { id_permission_module: 72, name: 'Contratos', id_permission_type: 1 },
      { id_permission_module: 74, name: 'Bonos', id_permission_type: 1 },
      { id_permission_module: 75, name: 'Banners', id_permission_type: 1 },
      { id_permission_module: 79, name: 'Nómina interna', id_permission_type: 1 },
      { id_permission_module: 80, name: 'ASiBot', id_permission_type: 1 },
    ],
      service: [{ id_permission_module: 6, name: 'Impuesto local sobre nómina', id_permission_type: 1 },
      { id_permission_module: 7, name: 'Facturación', id_permission_type: 1 },
      { id_permission_module: 8, name: 'Seguro social mensual', id_permission_type: 1 },
      { id_permission_module: 9, name: 'Timbrado y cálculo de nómina semanal, quincenal o mensual (Real, HAS o mixta)', id_permission_type: 1 },
      { id_permission_module: 10, name: 'Contabilidad Mensual', id_permission_type: 1 },
      { id_permission_module: 11, name: 'Administración de la tesorería', id_permission_type: 1 },
      { id_permission_module: 12, name: 'Prevención en Lavado de Dinero', id_permission_type: 1 },
      { id_permission_module: 13, name: 'Publicidad', id_permission_type: 1 },
      { id_permission_module: 14, name: 'Outsourcing', id_permission_type: 1 },
      { id_permission_module: 15, name: 'Desarrollo tecnológico', id_permission_type: 1 },
      { id_permission_module: 16, name: 'Servicios legales', id_permission_type: 1 },
      { id_permission_module: 17, name: 'Auditoría', id_permission_type: 1 },
      { id_permission_module: 18, name: 'Constitución de Empresas Socios Nacionales', id_permission_type: 1 },
      { id_permission_module: 19, name: 'Llave en Mano', id_permission_type: 1 },
      { id_permission_module: 20, name: 'Declaraciones mensuales (impuestos e informativas)', id_permission_type: 1 },
      { id_permission_module: 21, name: 'SATIC o SIROC', id_permission_type: 1 },
      { id_permission_module: 22, name: 'Actualización Fiscal y Contable', id_permission_type: 1 },
      { id_permission_module: 23, name: 'Declaración anual físicas', id_permission_type: 1 },
      { id_permission_module: 24, name: 'Declaración anual Riesgo de Trabajo', id_permission_type: 1 },
      { id_permission_module: 25, name: 'Declaración anual A.C.', id_permission_type: 1 },
      { id_permission_module: 26, name: 'Declaración anual moral', id_permission_type: 1 },
      { id_permission_module: 27, name: 'AS iViewer', id_permission_type: 1 },
      { id_permission_module: 28, name: 'Apertura de Cuenta Bancaria', id_permission_type: 1 },
      { id_permission_module: 29, name: 'Inscripción al IMSS', id_permission_type: 1 },
      { id_permission_module: 30, name: 'Inscripción al SAT Persona Moral', id_permission_type: 1 },
      { id_permission_module: 31, name: 'Inscripción al SAT Persona Física', id_permission_type: 1 },
      { id_permission_module: 32, name: 'Inscripción registro estatal de contribuyentes', id_permission_type: 1 },
      { id_permission_module: 33, name: 'Trámite o Renovación E.firma', id_permission_type: 1 },
      { id_permission_module: 34, name: 'Diagnóstico Fiscal', id_permission_type: 1 },
      { id_permission_module: 35, name: 'Infonavit', id_permission_type: 1 },
      { id_permission_module: 36, name: 'Registro en inversión Extranjera', id_permission_type: 1 },
      { id_permission_module: 37, name: 'Consultoría', id_permission_type: 1 },
      { id_permission_module: 77, name: 'Certificado Digital para Facturar', id_permission_type: 1 },
      { id_permission_module: 78, name: 'Nómina', id_permission_type: 1 }],
      ctrl_tables: [{ id_permission_module: 38, name: 'Tabla de Control de Impuesto local sobre nómina', id_permission_type: 1 },
      { id_permission_module: 39, name: 'Tabla de Control de Facturación', id_permission_type: 1 },
      { id_permission_module: 40, name: 'Tabla de Control de Seguro social mensual', id_permission_type: 1 },
      { id_permission_module: 41, name: 'Tabla de Control de Timbrado y cálculo de nómina semanal, quincenal o mensual (Real, HAS o mixta)', id_permission_type: 1 },
      { id_permission_module: 42, name: 'Tabla de Control de Contabilidad Mensual', id_permission_type: 1 },
      { id_permission_module: 43, name: 'Tabla de Control de Administración de la tesorería', id_permission_type: 1 },
      { id_permission_module: 44, name: 'Tabla de Control de Prevención en Lavado de Dinero', id_permission_type: 1 },
      { id_permission_module: 45, name: 'Tabla de Control de Publicidad', id_permission_type: 1 },
      { id_permission_module: 46, name: 'Tabla de Control de Outsourcing', id_permission_type: 1 },
      { id_permission_module: 47, name: 'Tabla de Control de Desarrollo tecnológico', id_permission_type: 1 },
      { id_permission_module: 48, name: 'Tabla de Control de Servicios legales', id_permission_type: 1 },
      { id_permission_module: 49, name: 'Tabla de Control de Auditoría', id_permission_type: 1 },
      { id_permission_module: 50, name: 'Tabla de Control de Constitución de Empresas Socios Nacionales', id_permission_type: 1 },
      { id_permission_module: 51, name: 'Tabla de Control de Llave en Mano', id_permission_type: 1 },
      { id_permission_module: 52, name: 'Tabla de Control de Declaraciones mensuales (impuestos e informativas)', id_permission_type: 1 },
      { id_permission_module: 53, name: 'Tabla de Control de SATIC o SIROC', id_permission_type: 1 },
      { id_permission_module: 54, name: 'Tabla de Control de Actualización Fiscal y Contable', id_permission_type: 1 },
      { id_permission_module: 55, name: 'Tabla de Control de Declaración anual físicas', id_permission_type: 1 },
      { id_permission_module: 56, name: 'Tabla de Control de Declaración anual Riesgo de Trabajo', id_permission_type: 1 },
      { id_permission_module: 57, name: 'Tabla de Control de Declaración anual A.C.', id_permission_type: 1 },
      { id_permission_module: 58, name: 'Tabla de Control de Declaración anual moral', id_permission_type: 1 },
      { id_permission_module: 59, name: 'Tabla de Control de AS iViewer', id_permission_type: 1 },
      { id_permission_module: 60, name: 'Tabla de Control de Apertura de Cuenta Bancaria', id_permission_type: 1 },
      { id_permission_module: 61, name: 'Tabla de Control de Inscripción al IMSS', id_permission_type: 1 },
      { id_permission_module: 62, name: 'Tabla de Control de Inscripción al SAT Persona Moral', id_permission_type: 1 },
      { id_permission_module: 63, name: 'Tabla de Control de Inscripción al SAT Persona Física', id_permission_type: 1 },
      { id_permission_module: 64, name: 'Tabla de Control de Inscripción registro estatal de contribuyentes', id_permission_type: 1 },
      { id_permission_module: 65, name: 'Tabla de Control de Trámite o Renovación E.firma', id_permission_type: 1 },
      { id_permission_module: 66, name: 'Tabla de Control de Diagnóstico Fiscal', id_permission_type: 1 },
      { id_permission_module: 67, name: 'Tabla de Control de Infonavit', id_permission_type: 1 },
      { id_permission_module: 68, name: 'Tabla de Control de Registro en inversión Extranjera', id_permission_type: 1 },
      { id_permission_module: 69, name: 'Tabla de Control de Consultoría', id_permission_type: 1 },
      {id_permission_module:76,name:'Tabla de Control de Certificado',id_permission_type: 1},
      ]
    }
  },
}