import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    presentation_limit_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'presentation_limit_date',type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    process_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_process_statuses], 
        formType:'select',
        config:{id:'process_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    class_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_classes], 
        formType:'select',
        config:{id:'class_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },    
    client_comment:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'client_comment',  type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    presentation_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'presentation_date',type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    extra_hours:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'extra_hours',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    extra_charges:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'extra_charges',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    collections_charge:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'collections_charge',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    delivery_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'delivery_date',type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    responsible_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'responsible_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}