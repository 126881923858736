import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import ButtonsSelect from '../../../../../../components_v1/Forms/ButtonsSelect';
import InputText from '../../../../../../components_v1/Forms/InputText';
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const SectionE = ({ id, ctrlID, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 5
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [accordion, setAccordion] = useState(true)
    const [accordions, setAccordions] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                const _process = await request_process(id, `?step_id=${step}`)
                setProcess(_process)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, _process, step)
                _form.monthlyvatinfavorcancellationifcreditablevatpaidishigher.value = _process[`${step}monthlyvatinfavorcancellationifcreditablevatpaidishigher`]
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
                let _accordions = content.accordions.map(item => true)
                setAccordions(_accordions)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep) {
                let temp = { ...form }
                delete temp.monthlyvatinfavorcancellationifcreditablevatpaidishigher
                let monthly_vat_in_favor_cancellation_if_creditable_vat_paid_is_higher = { ...form.monthlyvatinfavorcancellationifcreditablevatpaidishigher }
                monthly_vat_in_favor_cancellation_if_creditable_vat_paid_is_higher.config.id = 'monthly_vat_in_favor_cancellation_if_creditable_vat_paid_is_higher'
                temp = { ...temp, monthly_vat_in_favor_cancellation_if_creditable_vat_paid_is_higher }

                await onUpdate(temp, true, newStep, content.responsible_banner)
            }
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }
            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleAccordions = (id) => {
        let _accordions = [...accordions]
        _accordions[id] = !accordions[id]
        setAccordions(_accordions)
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner2}>
                            <Grid container justify='flex-start'>
                                <Grid item xs={12} sm={4}>
                                    <InputSelectStatus
                                        className={classes.selectStatus}
                                        color='primary'
                                        data={form.supervisor_status_id}
                                        onChange={onChange}
                                        disabled={!permission}
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='body2' className={classes.title}>{content.title}</DisplayText>
                                </Grid>
                                {accordions && content.accordions.map((accordionData, key) => (
                                    <Grid item xs={12}>
                                        <Accordion2 expanded={accordions[key]} onChange={() => handleAccordions(key)}>
                                            <AccordionSummary2
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`${accordionData.id}_controls`}
                                                id={accordionData}
                                                classes={{ root: classes.subAccordion }}
                                            >
                                                <DisplayText variant='body2' align='center'>{accordionData.title}</DisplayText>
                                            </AccordionSummary2>
                                            <AccordionDetails>
                                                <Grid container justify='center' spacing={4}>
                                                    <Grid item xs={12}>
                                                        <ButtonsSelect
                                                            data={form[accordionData.id]}
                                                            onChange={onChange}
                                                            disabled={!permission}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AccordionDetails>
                                        </Accordion2>
                                    </Grid>
                                ))}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Hidden smDown>
                                <Grid container justify='center' alignItems='center' spacing={2}>
                                    <Grid item>
                                        <DisplayText variant='subtitle2' color='primary' align='right'>
                                            Importe a pagar
                                        </DisplayText>
                                    </Grid>
                                    <Grid item>
                                        <ShadedInputText
                                            className={classes.selectStatus}
                                            color='primary'
                                            data={form.payment_amount}
                                            onChange={onChange}
                                            disabled={!permission}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>
                            <Hidden mdUp>
                                <Grid container alignItems='center'>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2' color='primary' align='left'>
                                            Importe a pagar
                                        </DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <ShadedInputText
                                            className={classes.selectStatus}
                                            color='primary'
                                            data={form.payment_amount}
                                            onChange={onChange}
                                            disabled={!permission}
                                        />
                                    </Grid>
                                </Grid>
                            </Hidden>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={8}>
                                <Grid item xs={12}>
                                    <ShadedInputText
                                        className={classes.selectStatus}
                                        color='primary'
                                        data={form.supervisor_comment}
                                        onChange={onChange}
                                        disabled={!permission}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <DocumentInput
                                        data={files[0]}
                                        onUploadDocument={() => onUploadDocument(files[0])}
                                        onDownloadDocument={() => onDownloadDocument(files[0])}
                                        disabled={!permission}
                                        downloadEnabled
                                    />
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(step - 1, true)}>
                            {content.back}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer >
    )
}

const filesData = [
    { path: 'taxes_calculations', name: 'Cálculo de impuestos', status: false, ctrlpath: true },
]

const formData = {
    supervisor_status_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.supervisor_statuses],
        config: {
            id: 'supervisor_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Estatus supervisor:',
            helper: 'Selecciona una opción'
        },
        rules: {
            type: 'none',
        }
    },
    payment_amount: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'payment_amount',
            type: 'number',
            fullWidth: true,
            label: null,
            helper: 'Introduce el número de operaciones',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    observations: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'observations',
            type: 'text',
            fullWidth: true,
            label: 'Observaciones',
            helper: 'Introduce tus observaciones',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    supervisor_comment: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'supervisor_comment',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce tus comentarios',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    not_negative_bank_account_final_balance: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'not_negative_bank_account_final_balance',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    recorded_bank_movements_every_month: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'recorded_bank_movements_every_month',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    bank_account_final_balance_correspons_to_statements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'bank_account_final_balance_correspons_to_statements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    client_account_registered_in_sales_vat_payable: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'client_account_registered_in_sales_vat_payable',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    client_account_does_not_have_movements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'client_account_does_not_have_movements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    client_collection_bank_deposits: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'client_collection_bank_deposits',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    not_negative_customers_balance: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'not_negative_customers_balance',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    monthlyvatinfavorcancellationifcreditablevatpaidishigher: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'monthlyvatinfavorcancellationifcreditablevatpaidishigher',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    vat_in_favor_application_of_balance_following_months: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'vat_in_favor_application_of_balance_following_months',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    inventories_record_every_month_pass_through_at_cost: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'inventories_record_every_month_pass_through_at_cost',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    global_proof_veritifacion_creditable_vat_paid: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'global_proof_veritifacion_creditable_vat_paid',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    advance_payment_movements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'advance_payment_movements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    advance_payment_supplier: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'advance_payment_supplier',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    machinery_equipment_balance: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'machinery_equipment_balance',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    suppliers_accounts_no_movements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'suppliers_accounts_no_movements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    suppliers_payment_bank_transfers: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'suppliers_payment_bank_transfers',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    suppliers_balance_no_positive: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'suppliers_balance_no_positive',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    creditos_veritfy_bank_transfers: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'creditos_veritfy_bank_transfers',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    creditors_balance_no_movements: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'creditors_balance_no_movements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    transferred_vat_collected_register_bank_deposits: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'transferred_vat_collected_register_bank_deposits',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    advance_application_transfer_clients: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'advance_application_transfer_clients',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    social_capital_check_variations: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'social_capital_check_variations',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    sales_return_balance_verify_clients: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'sales_return_balance_verify_clients',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    sales_return_balance_non_positive: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'sales_return_balance_non_positive',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    costs_vertify_warehouse_transfer_monthly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'costs_vertify_warehouse_transfer_monthly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    expenses_salaries_monthly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'expenses_salaries_monthly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    honorary_expenses_similar_to_salaries_monthly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'honorary_expenses_similar_to_salaries_monthly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    expenses_imss_rcv_infonavit: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'expenses_imss_rcv_infonavit',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    state_tax_expenses_monthly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'state_tax_expenses_monthly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    payment_service_ascg_monthly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'payment_service_ascg_monthly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    expenses_payment_federal_state_taxes_monhtly: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'expenses_payment_federal_state_taxes_monhtly',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    leases_expenses_monthly_record: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'leases_expenses_monthly_record',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    other_products_financial_movements_creditors: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'other_products_financial_movements_creditors',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    other_expenses_financial_movements_debtors: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_accounting_documents_statuses],
        config: {
            id: 'other_expenses_financial_movements_debtors',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
}

export default SectionE