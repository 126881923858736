import React from 'react';
import { Grid, Hidden, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { onGetDateFormat } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import moment from 'moment';
import cx from 'classnames'
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import HtmlDisplayer from '../../../../components_v1/Structure/DisplayData/HtmlDisplayer';


const TaskInfo = ({ content, task, onEdit, onUploadDocument, onDownloadDocument, onDeleteDocument}) => {
	const classes = useStyles();
	const { title, description, task_type_id, task_priority_id, task_status_id, document, expiration_date, finish_date } = task;
    const expiringDate = finish_date ? moment(expiration_date).diff(moment(finish_date), 'hours') < 24 : moment(expiration_date).diff(moment(), 'hours') < 24

	return (
		<Grid container alignItems='center' style={{ borderRight: '1px solid #DDDDDD', paddingRight: 30 }}>
			<Grid item container xs={12} alignItems='center'>
				<Grid item xs={11}>
					<Typography className={classes.section_title}>{content.title}</Typography>
					<Typography className={classes.task_title}>{title}</Typography>
				</Grid>
				<Grid item container justify='flex-end' xs={1}>
					<IconButton fontSize='small' className={classes.edit_button} onClick={onEdit}>
						<Icon>edit</Icon>
					</IconButton>
				</Grid>
			</Grid>
			<Grid item xs={12} style={{ minHeight: 260 }}>
				{/* <Typography className={classes.task_description}>{description}</Typography> */}
				<HtmlDisplayer data={description} maxHeight='500px' />
			</Grid>
			<Grid item container xs={12} md={7} spacing={3}>
				<Grid item xs={4}>
					<CatIndicator catalog={catalogs.task_priority} id={task_priority_id} variant={'priority'} content={content} />
				</Grid>
				<Grid item xs={4}>
					<CatIndicator catalog={catalogs.task_status} id={task_status_id} variant={'status'} content={content} />
				</Grid>
				<Grid item xs={4}>
					<CatIndicator catalog={catalogs.task_type} id={task_type_id} variant={'type'} content={content} />
				</Grid>
			</Grid>
			<Grid item xs />
			<Grid item container xs={6} md={2} justify='center' className={classes.mt_sm}>
				<Grid item xs={12}>
					<Typography align='center' className={classes.section_title}>
						{content.document}
					</Typography>
				</Grid>
				<Grid item>
					<IconButton color='secondary' onClick={onUploadDocument}>
						<Icon fontSize='small'>cloud_upload</Icon>
					</IconButton>
				</Grid>
				<Grid item>
					<IconButton disabled={document === '0' || !document} color='primary' onClick={onDownloadDocument}>
						<Icon fontSize='small'>cloud_download</Icon>
					</IconButton>
				</Grid>
				<Grid item>
					<IconButton onClick={onDeleteDocument}>
						<Icon style={{ color: 'red' }} fontSize='small'>
							close
						</Icon>
					</IconButton>
				</Grid>
			</Grid>
			<Grid item container xs={6} md={3}  className={classes.mt_sm}>
				<Grid item xs={12}>
					<Typography className={classes.section_title} style={{marginBottom: 5}}>{content.expiration_date}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography
						align='center'
						className={cx({
							[classes.expiration_date]: true,
							[classes.expiration_date_red]: expiringDate,
						})}
					>
						{onGetDateFormat(expiration_date, 'DD/ MMMM / YYYY')}
					</Typography>
				</Grid>
			</Grid>
		</Grid>
	);
};

const CatIndicator = ({ catalog, id, variant, content }) => {
	const elem = catalog.find((item) => item.id === id);
	const classes = useStyles();

	let icon = null;
	let title = null;

	switch (variant) {
		case 'priority':
			title = content.priority;
			icon = 'notifications_active';
			break;
		case 'type':
			title = content.type;
			icon = 'format_list_bulleted';
			break;
		case 'status':
			title = content.status;
			icon = 'adjust';
			break;
		default:
			break;
	}

	return (
		<Grid container alignItems='center' className={classes.cat_container}>
			<Hidden smDown>
				<Grid item>
					<div className={classes.cat_icon}>
						<Icon fontSize='small' style={elem && elem.color ? { color: elem.color } : {}}>
							{icon}
						</Icon>
					</div>
				</Grid>
			</Hidden>
			<Grid item style={{ paddingLeft: 15 }}>
				<Typography className={classes.section_title}>{title}</Typography>
				<Typography className={classes.cat_label} style = {elem && elem.color ? { color: elem.color } : {}}>
					{elem && elem.label ? elem.label : ''}
				</Typography>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles((theme) => ({
	section_title: {
		fontWeight: 600,
		fontSize: '0.75rem',
		color: '#9AA6B4',
	},
	task_title: {
		fontWeight: 500,
		fontSize: '1.375rem',
		color: theme.palette.primary.main,
	},
	edit_button: {
		width: 35,
		height: 35,
		background: theme.palette.secondary.light,
		'&:hover': {
			background: theme.palette.secondary.main,
		},
		'& .MuiIcon-root': {
			color: 'white',
		},
	},
	task_description: {
		fontWeight: 400,
		fontSize: '0.875rem',
		marginTop: 18,
	},
	cat_icon: {
		width: 35,
		height: 35,
		border: '1px solid #D8E1F0',
		borderRadius: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	cat_label: {
		fontWeight: 500,
		fontSize: '0.875rem',
	},
	cat_container: {
		borderRight: '1px solid #DDDDDD',
		paddingRight: 20,
		[theme.breakpoints.down('sm')]: {
			borderRight: '0px solid #DDDDDD',
			paddingRight: 0,
		},
	},
    expiration_date:{
        color: theme.palette.primary.main,
        background: '#E0E6EF',
        padding: 8,
        fontSize: '1rem',
        fontWeight: 600
    },
    expiration_date_red:{
        color: '#EA1313',
        background: '#FFDBDB',
    },
	mt_sm: {
        [theme.breakpoints.down('sm')]: {
			marginTop: 20
		},
    }
}));

export default TaskInfo;
