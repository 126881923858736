export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      title:'Servicios',
      header:{title:'Nombre'},
      ctrltableData:[
        {name:'Impuesto local sobre nómina', id:1, status:false},
        {name:'Facturación', id:2, status:false},
        {name:'Seguro Social', id:3, status:false},
        {name:'Nóminas', id:4, status:false},
        {name:'Contabilidad', id:5, status:false},
        {name:'Prevención', id:7, status:false},
        {name:'Publicidad', id:8, status:false},
        {name:'Outsourcing', id:9, status:false},
        {name:'Tecnología', id:10, status:false},
        {name:'Legal', id:11, status:false},
        {name:'Auditoría', id:12, status:false},
        {name:'Constitución', id:13, status:false},
        {name:'Llave en Mano', id:14, status:false},
        {name:'Declaración Mensual', id:15, status:false},
        {name:'SATIC o SIROC', id:16, status:false},
        {name:'Actualización', id:17, status:false},
        {name:'Declaración Anual Física', id:18, status:false},
        {name:'Declaración Anual de Riesgo', id:19, status:false},
        {name:'Declaración Anual AC', id:20, status:false},
        {name:'Declaración Anual Moral', id:21, status:false},
        {name:'AS iViewer', id:22, status:false},
        {name:'Apertura de Cuenta Bancaria', id:23, status:false},
        {name:'Inscripción IMSS', id:24, status:false},
        {name:'Inscripción Moral SAT', id:25, status:false},
        {name:'Inscripción Física SAT', id:26, status:false},
        {name:'Inscripción de Registro Estatal', id:27, status:false},
        {name:'Efirma', id:28, status:false},
        {name:'Diagnóstico Fiscal', id:29, status:false},
        {name:'Infonavit', id:30, status:false},
        {name:'Registro en Inversión Extranjera', id:31, status:false},
        {name:'Consultoría', id:32, status:false},
        {name:'AS Advisory', id:33, status:false},        
      ]
    },
    
  }