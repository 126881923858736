import React, { useEffect, useState } from 'react'
import { Grid, Card, makeStyles, Hidden } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText'
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetDateFormat, onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { green, grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: '0px 64px 64px 64px',
        [theme.breakpoints.down('sm')]: {
            padding: '0px 32px 32px 32px',
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
    lineInput: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        height: '100%',
    },
    greenDiv: {
        backgroundColor: green[700],
        border: 0,
        borderRadius: '5px 0px 0px 5px',
        width: 150,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    greyLabel: {
        backgroundColor: '#F3F5F8',
        border: 0,
        borderRadius: '0px 5px 5px 0px',
        width: 150,
        minHeight: 20,
        padding: 5.5,
        [theme.breakpoints.only('xs')]: {
            width: '100%',
            borderRadius: 0
        }
    },
    declarations: {
        backgroundColor: '#F8FBFF',
        padding: 24
    },
    declarationsRow: {
        borderRadius: 24,
        display: 'flex',
        paddingTop: 24
    },
    declarationsTH: {
        width: '50%',
        backgroundColor: '#6D7CA4',
        borderRadius: '5px 0px 0px 5px',
        height: 'fit-content',
        color: 'white',
        padding: 8,
    },
    declarationsTD: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.dark,
        height: 'fit-content',
        textAlign: 'center',
        padding: 8
    },
    declarationsTDEnd: {
        width: '25%',
        backgroundColor: '#EDF2F8',
        color: theme.palette.primary.main,
        borderRadius: '0px 5px 5px 0px',
        height: 'fit-content',
        padding: 8,
        paddingRight: 64
    },
    declarationsTOTAL: {
        backgroundColor: theme.palette.primary.dark,
    },
}))

const SectionC = ({ id, ctrlID, process, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 3
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [accordions, setAccordions] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, process, step)
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, process, step)
                setFiles(_files)
                let _accordions = content.accordions.map(item => true)
                setAccordions(_accordions)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner)
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }

            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleAccordions = (id) => {
        let _accordions = [...accordions]
        _accordions[id] = !accordions[id]
        setAccordions(_accordions)
    }


    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' alignItems='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <Grid container spacing={2}>
                                {accordions && content.accordions.map((accordionData, key) => {
                                    const file = files.find(f => f.path === accordionData.file)

                                    return (
                                        <Grid item xs={12}>
                                            <Accordion2 expanded={accordions[key]} onChange={() => handleAccordions(key)}>
                                                <AccordionSummary2
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls={`${accordionData.file}_controls`}
                                                    id={accordionData}
                                                    classes={{ root: classes.subAccordion }}
                                                >
                                                    <DisplayText variant='body2' align='center'>{accordionData.title}</DisplayText>
                                                </AccordionSummary2>
                                                <AccordionDetails>
                                                    <Grid container justify='center' spacing={4}>
                                                        <Grid item xs={12} sm={3}>
                                                            <InputSelectStatus
                                                                className={classes.selectStatus}
                                                                color='primary'
                                                                data={form[accordionData.status]}
                                                                onChange={onChange}
                                                                disabled={!permission}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={2}>
                                                            <DocumentInput
                                                                data={file}
                                                                onUploadDocument={() => onUploadDocument(file)}
                                                                onDownloadDocument={() => onDownloadDocument(file)}
                                                                disabled={!permission}
                                                                downloadEnabled
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <ShadedInputText
                                                                className={classes.selectStatus}
                                                                color='primary'
                                                                data={form[`${accordionData.comment}`]}
                                                                onChange={onChange}
                                                                disabled={!permission}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion2>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='blueGrey' onClick={() => onUpdateStep(step - 1, true)}>
                            {content.back}
                        </RoundedButton>
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer >
    )
}

const filesData = [
    { path: 'compliance_opinion', name: 'Archivo', status: false, ctrlpath: true },
    // { path: 'tax_mailbox', name: 'Archivo', status: false, ctrlpath: true },
]

const formData = {
    compliance_opinion_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.ctrl_compliance_opinion_statuses],
        config: {
            id: 'compliance_opinion_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Opinión',
            helper: 'Opinión'
        },
        rules: {
            type: 'none',
        }
    },
    compliance_opinion_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'compliance_opinion_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    tax_mailbox_status: {//tax_mailbox_status_id
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.ctrl_compliance_opinion_statuses],
        config: {
            id: 'tax_mailbox_status',//tax_mailbox_status_id
            type: 'simple_select',
            fullWidth: true,
            label: 'Opinión',
            helper: 'Opinión'
        },
        rules: {
            type: 'none',
        }
    },
    mailbox_comment: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'mailbox_comment',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
}

export default SectionC