import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { grey, } from '@material-ui/core/colors'
import {
    Grid, OutlinedInput, Typography, InputAdornment, Icon, IconButton,
    Table, TableBody, TableCell, TableFooter, TableHead, TableRow, TablePagination
} from '@material-ui/core'
import ContentText from '../Texts/ContentText';
import cx from "classnames";


const styles_input = (theme) => ({
    root: {
        position: 'relative',
        color: grey[700],
        paddingTop: 5,
        paddingBottom: 5,
        '& $notchedOutline': {
            borderRadius: '40px',
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
                borderColor: 'blue',
            },
        },
        '&$focused $notchedOutline': {
            borderWidth: 2,
        },
        '&$error $notchedOutline': {
            borderColor: theme.palette.error.main,
        },
        '&$disabled $notchedOutline': {
            borderColor: theme.palette.action.disabled,
        },
    },
    /* Styles applied to the root element if the component is focused. */
    focused: {},
    /* Styles applied to the root element if `disabled={true}`. */
    disabled: {},
    /* Styles applied to the root element if `error={true}`. */
    error: {},
    /* Styles applied to the `NotchedOutline` element. */
    notchedOutline: {},
})

class SearchInputW extends Component {

    onChange = (event) => {
        const { onSearch } = this.props
        onSearch(event.currentTarget.value)
    }

    render() {
        const { classes } = this.props

        return (
            <OutlinedInput
                margin='dense'
                placeholder='Search'
                classes={classes}
                onChange={this.onChange}
                startAdornment={<InputAdornment>
                    <Icon style={{ color: grey[500], paddingRight: '4px' }}>search</Icon>
                </InputAdornment>}
            />
        )
    }
}

const SearchInput = withStyles(styles_input)(SearchInputW);

const styles_toolbar = (theme) => ({
    addbutton: {
        background: theme.palette.secondary.main,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    }
})

class TableToolbarW extends Component {
    render() {
        const { classes, onSearch, onAddItem, noSearch, addItem } = this.props

        let addButton = null
        if (addItem) {
            addButton = <Grid item>
                <IconButton onClick={onAddItem} className={classes.addbutton}>
                    <Icon>add</Icon>
                </IconButton>
            </Grid>
        }

        let searchButton = null
        if (!noSearch) {
            searchButton = <SearchInput onSearch={onSearch} />
        }

        return (
            <Grid container justify='flex-end' spacing={2}>
                <Grid item>
                    {searchButton}
                </Grid>
                {addButton}
            </Grid>
        )
    }
}

const TableToolbar = withStyles(styles_toolbar)(TableToolbarW)



const styles = (theme) => ({
    container: {
        boxSizing: 'border-box',
        background: 'white'
    },
    title: {
        color: theme.palette.primary.main
    },
    headerCell: {
        color: theme.palette.primary.main,
        border: 0,
        whiteSpace: 'nowrap',
        minWidth: '200px'
    },
    headerText: {
        fontWeight: '500'
    },
    addbutton: {
        background: theme.palette.secondary.main,
        color: 'white',
        '&:hover': {
            background: theme.palette.secondary.dark,
        }
    },
    hideCell: {
        display: 'none'
    }

})

class ControlTableTable extends Component {

    state = {
        rowsPerPage: 5,
        rows: 50,
        page: 0,
        setPage: 0
    }

    handleChangeRowsPerPage = (event) => {
        const value = parseInt(event.target.value, 10)
        this.setState({ rowsPerPage: value })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage })
    }



    render() {

        const { classes, header, title, onSearch, rowsPerPage,
            rows, page, onChangePage, onChangeRowsPerPage, addItem, noSearch,
            onAddItem, onCloseCard, nodata, backgroundColor, wrap,
            pagination } = this.props

        let closeButton = null
        if (onCloseCard) {
            closeButton = <Grid item>
                <IconButton size='small' onClick={onCloseCard}>
                    <Icon >close</Icon>
                </IconButton>
            </Grid>
        }

        let tableContent = null


        if (this.props.children.length) {
            tableContent = (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <TableToolbar
                            onSearch={onSearch}
                            addItem={addItem}
                            onAddItem={onAddItem}
                            noSearch={noSearch}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <div>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {header.map((item, key) => {
                                            let name = ''
                                            let align = 'left'
                                            if (typeof (item) === 'string')
                                                name = item
                                            else if (typeof (item === 'object')) {
                                                name = item.text
                                                align = item.align
                                            }

                                            return (
                                                <TableCell align={align}
                                                    className={cx({
                                                        [classes.headerCell]: true,
                                                        [classes.hideCell]: (key == 0 ? false : wrap)
                                                    })}
                                                    key={key.toString()}>
                                                    <Typography variant='body2' className={classes.headerText}>
                                                        {name}
                                                    </Typography>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.props.children}
                                </TableBody>
                                {pagination ?
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                style={{ border: 0 }}
                                                rowsPerPageOptions={[5, 10, 25]}
                                                count={rows}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: { 'aria-label': 'Rows per page' },
                                                    native: true,
                                                }}
                                                onChangePage={onChangePage}
                                                onChangeRowsPerPage={onChangeRowsPerPage}
                                            />
                                        </TableRow>
                                    </TableFooter> : null}
                            </Table>
                        </div>
                    </Grid>
                </Grid>
            )
        } else {
            tableContent = <Grid container justify='center' wrap='nowrap' alignItems='center' spacing={1}>
                <Grid item>
                    <div>
                        <ContentText text={nodata} medium align='center' />
                    </div>
                </Grid>
                <Grid item>
                    <IconButton size='small' className={classes.addbutton} onClick={onAddItem}><Icon>add</Icon></IconButton>
                </Grid>
            </Grid>
        }

        return (
            <div className={classes.root}>
                <div className={classes.container}>
                    <Grid container
                        spacing={2}
                        className={{ backgroundColor: backgroundColor }}
                    >
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs>
                                    <Typography className={classes.title} variant='h6'>{title}</Typography>
                                </Grid>
                                {closeButton}
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            {tableContent}
                        </Grid>
                    </Grid>
                </div>
            </div>
        )
    }
}

export default withStyles(styles)(ControlTableTable)
