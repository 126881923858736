import { onGetDateFormat, monthsName, onGetDateBimesterFormat } from "../../../../../../shared/utility"
import moment from "moment"


export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {

    const image = findImageResponsible(item.evaluator_id, users)

    /* let e_date
    if(item.monthly === 1) {
      e_date = item.auto_date ? onGetDateFormat(item.auto_date, 'MMM, YYYY') : nodata,
    }
    else{
      e_date = item.auto_date ? onGetDateFormat(item.auto_date, 'MMM') + ' ' + onGetDateFormat(item.auto_date, 'MMM, YYYY') : nodata
    } */


    let data = {
      id_employee_evaluation: item.id_employee_evaluation,
      //date: item.auto_date ? onGetDateFormat(item.auto_date, 'MMM, YYYY') : nodata,
      date: item.auto_date && item.monthly === 1 ? onGetDateFormat(item.auto_date, 'MMM, YYYY') : item.auto_date ? onGetDateFormat(item.auto_date, 'MMM') + ' - ' + onGetDateBimesterFormat(item.auto_date, 'MMM, YYYY') : nodata,
      evaluator: item.evaluator ? item.evaluator : nodata,
      total: item.total ? item.total : '-',
      evaluation_date: item.evaluation_date ? onGetDateFormat(item.evaluation_date, 'DD MMM YY') : nodata,
      evaluation_step_id: item.evaluation_step_id,
      image: image,
      monthly: item.monthly
    }

    return data
  })

  return table
}

export const cleanData2Chart = (data, content, start, end) => {
  let _data = {}

  const startMonth = moment(start).month()
  const endMonth = moment(end).month()
  const startYear = moment(start).year()
  const endYear = moment(end).year()

  //const catalog = onGetMonthsCatalog(moment(start).year())

  if (data) {
    if ((endYear - startYear) > 0) {
      for (let y = startYear; y <= endYear; y++) {
        let _startMonth = y === startYear ? startMonth : 0
        let _endMonth = y === endYear ? endMonth : 11

        for (let m = _startMonth; m <= _endMonth; m++) {
          Object.keys(content).map(evaluation_type => {
            const chart = content[evaluation_type]
            let _chart = []
            let evaluations = data.filter(e => moment(e.evaluation_date).month() == m)
            let currentMonthTotal = evaluations ? evaluations.reduce((acc, currentValue) => acc + currentValue[chart.id], 0) : 0
            _chart.push({
              month: monthsName[m],
              year: y,
              score: currentMonthTotal
            })
            _data[evaluation_type] = _data[evaluation_type] ? [..._data[evaluation_type], ..._chart] : [..._chart]
          })
        }
      }
    } else {
      for (let m = startMonth; m <= endMonth; m++) {
        Object.keys(content).map(evaluation_type => {
          const chart = content[evaluation_type]
          let _chart = []
          let evaluations = data.filter(e => moment(e.evaluation_date).month() == m)
          let currentMonthTotal = evaluations ? evaluations.reduce((acc, currentValue) => acc + currentValue[chart.id], 0) : 0
          _chart.push({
            month: monthsName[m],
            year: startYear,
            score: currentMonthTotal
          })
          _data[evaluation_type] = _data[evaluation_type] ? [..._data[evaluation_type], ..._chart] : [..._chart]
        })
      }
    }

  }

  return _data
}


const findImageResponsible = (id, users) => {
  let temp = null

  if (id !== null && id !== undefined) {
    if (users.length > 0) {
      temp = users.filter(el => el.id_user === id)
    }
  }

  return temp && temp[0] ? temp[0].image : null
}