import React from 'react';
import { Grid, Hidden, Icon, IconButton, makeStyles, Typography } from '@material-ui/core';
import { amber, grey, red } from '@material-ui/core/colors';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';



const DocumentElement = ({onUpload, onDownload, onDelete, data, has_full_access, can_edit}) => {

    const classes = useStyles()

    const disabled = data ? !data.status : false
    const delete_color = disabled ? grey[400] : red[700]
  
    return(
        <div className={classes.container}>
            <Grid container wrap='nowrap' alignItems='center' spacing={1}>
              <Grid item>
                <Icon className={classes.folder}>folder</Icon>
              </Grid>
              <Grid item xs>
                <DisplayText variant='body2' className={classes.name}>{data.name}</DisplayText>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item>
                    <IconButton disabled={!can_edit} color='primary' onClick={() => onUpload(data)}>
                      <Icon fontSize='small'>cloud_upload</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton color='primary' disabled={disabled} onClick={() => onDownload(data)}>
                      <Icon fontSize='small'>cloud_download</Icon>
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton disabled={disabled || !has_full_access} onClick={() => onDelete(data)}>
                      <Icon fontSize='small' style={{color: has_full_access ? delete_color : grey[400]}}>close</Icon>
                    </IconButton>
                  </Grid> 
                </Grid>
              </Grid>
              
            </Grid>
        </div>
    )
}

const useStyles = makeStyles(theme => ({
  card:{borderRadius:'16px'},
  container:{padding:'8px', paddingLeft:'8px', borderTop:`1px solid ${grey[200]}`},
  folder:{color:amber[500]},
  name:{}
}))


export default DocumentElement