import React, {useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import {contentData} from './content'
import ContactInfoList from './components/ContactInfoList/ContactInfoList'
import { Redirect } from 'react-router'
import { PERMISSION_MODULES } from '../../../../../variables/config'
import { connect } from 'react-redux'
import {
  request_client,
  request_employees,
  request_update_client,
  request_contacts_info,
  request_downloadFile, 
  request_uploadFile, 
  request_deleteFile,
  request_add_contact_info,
  request_delete_contact_info,
  request_update_contact_info
} from './requests'
import PictureModal from './modals/PictureModal/PictureModal'
import EditClientModal from './modals/EditClient/EditClientModal'
import EditRegisterInfoModal from './modals/EditRegisterInfoModal/EditRegisterInfoModal'
import ClientInfoForm from './components/ClientInfoForm'
import DocumentsCards from './components/DocumentsCards'
import DocumentModal from './modals/DocumentModal'
import RegisterInfoForm from './components/RegisterInfoForm'
import AddContactModal from './modals/AddContact/AddContactModal'
import EditContactModal from './modals/EditContact/EditContactModal'
import WarningModal from '../../../../../components_v1/Structure/Modals/WarningModal'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import {catalogs} from '../../../../../variables/catalogs'
import ClientStepper from './components/ClientStepper'
import RoundedButton from '../../../../../components_v1/Actions/RoundedButton'
import ContractCard from './components/ContractCard'
import EditContractModal from './modals/EditContractModal'

const styles = (theme) => ({
  root:{padding:'32px'},
  subtitle:{color:theme.palette.primary.main, fontWeight:'500'},
  nospeciality:{color:grey[700], fontWeight:'500'}
})

const number_values = [
  'anual_increment', 
  'outsourcing_comission',
  'contract_value',
  'iva',
  'total_value'
]

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})

const GeneralView = ({history, permissions, permission, permissionsType, catalogs,
  business_permission, module_permission, language, clientID, contract_permission}) =>{

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(null)
  const [selected_document, setSelectedDocument] = useState(null)
  const [contactInfo, setContactInfo] = useState([])
  const [client, setClient] = useState(null)  
  const [contact, setContact] = useState({data:null, type:null})
  const content = contentData[language]

  ///////// MODAL'S STATES //////////////////////////////////
  const [additemmodal, setAddItemModal] = useState(false)
  const [edititemmodal, setEditItemModal] = useState(false)
  const [profilemodal, setProfileModal] = useState(false)
  const [editregisteritemmodal, setEditRegisterItemModal] = useState(false)
  const [documentmodal, setDocumentModal] = useState(false)
  const [deletefilemodal, setDeleteFileModal] = useState(false)
  const [addcontactmodal, setAddContactModal] = useState(false)
  const [editcontactmodal, setEditContactModal] = useState(false)
  const [deletecontactmodal, setDeleteContactModal] = useState(false)
  const [editcontractmodal, setEditContractModal] = useState(false)

  const [stepper, setStepper] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const initModule = async() => {
      try{
        setLoading(true)
        let response = await request_client(clientID)
        setClient({...response})
        response = await request_contacts_info(clientID)
        setContactInfo([...response.client_contacts])
      }catch(error){
        console.log(error)
      }
      setLoading(false)
    }
    initModule()
  },[clientID])

  useEffect(() => {
    const {type} = contact
    if(type && type === 'edit')
      setEditContactModal(true)
    if(type && type === 'delete')
      setDeleteContactModal(true)
  },[contact])

  const onUpdateCompleted = async() => {
    try {
      //setState({stepper:{issending:true}})
      setSending(true)
      let response = await request_client(clientID)
      setClient({...response})
      //setState({stepper:{issending:false, error:null}})
      setSending(false)
      setProfileModal(false)
      setDocumentModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      //setState({stepper:{issending:false}})
      /*if(error.response){
        setState({stepper:{issending:false, error:error.response.data.error.message}})
      }*/
    }
    setSending(false)
  }

  const onChange = (stepID) => {
    onUpdateClient({step_id: stepID})
  }

  const onUpdateClient = async(data) => {
    try {
      setSending(true)
      let response = await request_update_client(clientID, data)
      setClient({...response})
      setSending(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)      
    }
    setSending(false)
  }

  const onSubmitFile = async(filepath, file) => {
    try{      
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      const aux = file[0].name.split('.')
      const ext = {ext:aux[aux.length-1]}
      let response = await request_uploadFile(userID, filepath, file[0], ext);
      setClient({...response})
      setSending(false)
      setDocumentModal(false)
      setSuccess(true)
    }catch(e){
      console.log(e)
      setError('Hubo un error al intentar guardar el documento')
    }
    setSending(false)
  }

  const onUploadDocument = (data) => {
      setSelectedDocument(data)
      setDocumentModal(true)
  }

  const onDownloadDocument = async (filedata) => {
    try{
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      await request_downloadFile(userID, filedata.path)
    }catch(e){
      console.log(e)
      setError('No fue posible descargar el documento.')
    }
    setSending(false)
  }

  const onDeleteDocument = async(filedata) => {
    try{
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/')+1)
      let response = await request_deleteFile(userID, filedata.path)
      setClient({...response})
      setSending(false)
      setDeleteFileModal(false)
      setSuccess(true)
    }catch(e){
      console.log(e)
      setError('No fue posible eliminar el documento')
    }
    setSending(false)
  }

  // ------------------- CONTACT -------------------- //
  const onAddContact = async(data) => {
    try{
      setSending(true)
      const addedData={...data,client_id:clientID,gender_id:1,
          country_id:1,area_id:1,client_language_id:1,position_id:1}
      let response = await request_add_contact_info(clientID, addedData)
      setContactInfo([...response])
      setSending(false)
      setAddContactModal(false)
      setSuccess(true)
    }catch(e){
      console.log(e)
      setError('No fue posible agregar el contacto')
    }
    setSending(false)
  }

  const onPreUpdate = (type, data) => {
    setContact({data:data, type:type})
  }
  
  const onEditContact = async(data) => {
    try{
      setSending(true)
      const {id_user} = contact.data
      let response = await request_update_contact_info(clientID,id_user,data);
      setContactInfo([...response])
      setSending(false)
      setEditContactModal(false)
      setContact({data:null, type:null})
      setSuccess(true)
    }catch(e){
      console.log(e)
      setError('Ocurrió un error en la actualización del contacto')
    }
    setSending(false)
  }

  const onDeleteContact = async() => {
    try{
      setSending(true)      
      let response = await request_delete_contact_info(clientID, contact.data)
      setContactInfo([...response])
      setSending(false)
      setDeleteContactModal(false)
      setContact({data:null, type:null})
      setSuccess(true)
    }catch(e){
      console.log(e)
      setError('Ocurrió un error al intentar eliminar el contacto')
    }
    setSending(false)
  }

  const onSend2Businesses = () => {
    history.push('/clients/details/'+clientID+'/businesses')
  }
  const editButton = permission >= permissionsType.EDIT
  let restricted
  if(client) restricted = client.restricted ? 'Negocio restringido' : null
  /// Find module permission
  const _permission = permissions ? permissions.find(el => el.permission_module_id === PERMISSION_MODULES.CLIENTS) : null
  console.log(contract_permission)
  return(
    <LoadingContainer         
      loading={loading} 
      sending={sending} 
      success={success} 
      error={error}         
      onCloseError={() => setError(null)} 
      onCloseSuccess={() => setSuccess(false)}
    >
      <div>
        {permission === 1 ? (
            <Redirect to='/profile'/>
          ) : null}
        {
        business_permission >= permissionsType.READ ?
          <Grid container justify='flex-end' spacing={2}>
            <Grid item>
              <RoundedButton size='small' color='primary' onClick={onSend2Businesses}>
                {content.businesses_button}
              </RoundedButton>
            </Grid>
          </Grid>
        : null}
        <WarningModal open={deletecontactmodal} message={content.modals.warning_delete_contact} onClose={() => setDeleteContactModal(false)} onDelete={onDeleteContact}/>
        <EditClientModal open={edititemmodal} origin_data={client} language={language} onSubmit={onUpdateClient} onClose={() => setEditItemModal(false)}/>
        <PictureModal open={profilemodal} id={clientID} onUpdateCompleted={onUpdateCompleted} onClose={() => setProfileModal(false)}/>
        <EditRegisterInfoModal open={editregisteritemmodal} origin_data={client} language={language} onSubmit={onUpdateClient} onClose={() => setEditRegisterItemModal(false)}/>
        <EditContractModal id={clientID} open={editcontractmodal} origin_data={client} language={language} onUpdateCompleted={(data) => setClient(data)} onClose={() => setEditContractModal(false)}/>
        <DocumentModal id={clientID} open={documentmodal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted}/>
        <AddContactModal open={addcontactmodal} language={language} onSubmit={onAddContact} onClose={() => setAddContactModal(false)}/>
        <EditContactModal open={editcontactmodal} origin_data={contact.data} language={language} onSubmit={onEditContact} onClose={() => setEditContactModal(false)}/>
        <Grid container spacing={3}>
          <Grid item xs={12}>
              <ClientInfoForm permission={module_permission}  data={client} onEdit={() => setEditItemModal(true)} onChangePicture={() => setProfileModal(true)}/>
          </Grid>
          
          
          
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                  <RegisterInfoForm title={content.title.sectionB} permission={module_permission}  data={client} onEdit={() => setEditRegisterItemModal(true)}/>
              </Grid>
              <Grid item xs={12}>
                <ContactInfoList 
                  title={content.title.sectionC} 
                  data={contactInfo} 
                  loading={loading}
                  button={content.add} 
                  onAdd={() => setAddContactModal(true)}
                  onEdit={(data) => onPreUpdate('edit', data)}
                  onDelete={(data) => onPreUpdate('delete', data)}
                />
              </Grid>
              <Grid item xs={12}>
                  <ContractCard permission={contract_permission}  data={client} onEdit={() => setEditContractModal(true)} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={3}>
              <Grid item xs={12} >
                  <DocumentsCards title={content.title.sectionD} permission={module_permission} files={client} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument}/>
              </Grid>
              <Grid item xs={12}>
                <ClientStepper 
                  title={content.title.sectionF}
                  language={language}
                  step={client ? client.step_id : 0}
                  steps={catalogs.steps}
                  onChange={onChange}
                  permission={permission}
                  permissionsType={permissionsType}
                />
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </div>
    </LoadingContainer>
  )
}

const mapStateToProps = state => {
  return{
      permissions:state.permissions
  }
}

export default withStyles(styles)(connect(mapStateToProps)(GeneralView))