import React from 'react'
import { CircularProgress, Grid, makeStyles } from '@material-ui/core'
import CardContentList from '../../Cards/CardContentList';
import EvaluatorProfile from './EvaluatorProfile'
import Banner from './Banner'
import DisplayText from '../../../Texts/DisplayText';
import { catalogs } from '../../../../texts/esp/catalogs';

const styles = makeStyles(theme => ({
    nodata: {
        marginTop: 40
    }
}))

const DataView = props => {

    const classes = styles
    const { data, content, onEditEvaluator, path} = props

    let kpiContent = { title: content.kpi.title, content: [] }
    let targetContent = { title: content.department_target.title, content: [] }

    if (data && data.division) {
        const type = catalogs.kpiTypes.find(kpi => kpi.key === data.division.data)
        console.log(type)
        if (type) {
            kpiContent.content = [...type.content]
            targetContent.content = [...type.target]
        }
    }

    return (
        <div>
            {!data ?
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32} /></Grid>
                </Grid>
                :
                (data !== {} ?
                    <Grid container justify='center' spacing={4}>
                        <Grid item xs={12}>
                            <Banner {...data} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <EvaluatorProfile {...data} onEditEvaluator={onEditEvaluator} path={path} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <CardContentList {...content.global_objective} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <CardContentList {...kpiContent} />
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <CardContentList {...targetContent} />
                        </Grid>
                    </Grid>
                    :
                    <div className={classes.nodata}>
                        <Grid container justify='center' alignItems='center' direction='column'>
                            <Grid item>
                                <DisplayText>No se encontraron registros</DisplayText>
                            </Grid>
                        </Grid>
                    </div>
                )
            }
        </div>
    )
}

export default DataView