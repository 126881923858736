export const formData = {
  rfc:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'rfc',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'rfc',
    }
  }
}