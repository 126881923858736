import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import FilterButton from '../../../Structure/Tables/TimeReportTable/components/FilterButton';
import SearchBar from './SearchBar';

const EmployeePayrollActionBar = ({
    month,
    months,
    year,
    years,
    onChangeFilter,
    onRequestSearch,
}) => {

    return ( 
        <div style={{marginBottom:16}}>
            <Grid container spacing={2} alignItems='center' > 
                <Grid item>
                    <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                        <Grid item><FilterButton label='Mes' options={months} selected={month} onChange={(val) => onChangeFilter(val,'month')}/></Grid>
                        <Grid item><FilterButton label='Año' options={years} selected={year} onChange={(val) => onChangeFilter(val,'year')}/></Grid>
                    </Grid>
                </Grid>
                <Grid item xs/>
                <Grid item>
                    <SearchBar 
                        onRequestSearch={onRequestSearch}
                    />
                </Grid>
            </Grid>
        </div>
     );
}
 
export default EmployeePayrollActionBar;