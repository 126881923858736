import React from 'react'
import { Grid } from '@material-ui/core'
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton'
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import BenefitItem from '../../../../../../components_v1/Structure/DisplayData/BenefitItem'

const BenefitContent = ({title, benefits}) => {
    
    let contentView = null 
    
    if(benefits)
        contentView = benefits.map((item,key)=> {
            return(
              <Grid item xs={12} key={key.toString()}>
                <BenefitItem data={item.benefit} status={item.status}/>
              </Grid>
            )
        })

    return(
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {contentView}
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default BenefitContent