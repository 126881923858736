import React from 'react'
import {Grid, Typography, Checkbox } from '@material-ui/core'
import {grey} from '@material-ui/core/colors'

const styles = {
  text:{
    fontWeight:'500',
    color:grey[700]
  }
}

const ItemSelection = (props) => {

  const {label, status, id, onChange, disabled} = props

  return(
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Checkbox 
          disabled={disabled}
          checked={status}
          onChange={(event, status) => onChange(id,status)}
        />
      </Grid>
      <Grid item xs>
        <Typography variant='body2' style={styles.text}>
          {label}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ItemSelection
