import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import SimpleTable from '../components/SimpleTable';
import { request_statistics, request_statistics_by_businses, request_timereport_statistic } from '../requests';
import queryString from 'query-string'
import { onGetMonthsCatalog, onGetUserID } from '../../../../shared/utility';
import moment from 'moment'
import DeleteTimeReportModal from '../modals/DeleteTimeReportModal';
import BusinessSimpleTable from '../components/BusinessSimpleTable';

const BusinessStatisticsView = ({history}) => {

    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [statistics, setStatistics] = useState([])
    const [search, setSearch] = useState(null)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0})
    const [filter, setFilter] = useState({query:''})
    const [month, setMonth] = useState(moment().month()+1)
    const [year, setYear] = useState(moment().year())
    const [status, setStatus] = useState(-1)
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [startDate, setStartDate] = useState(moment().startOf('month').format('YYYY-MM-DD'))
    const [endDate, setEndDate] = useState(moment().endOf('month').format('YYYY-MM-DD'))


    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async() => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const {limit, offset} = tableFilter
                let filterArgs = []
                if(status !== -1) filterArgs.push({"field":"time_report_status_id","operator":"LIKE","value":status})
                if(search !== null && search !== '') filterArgs.push({"field":"business","operator":"LIKE","value":`%${search}%`})
                const param2send = {language_id:2, limit:limit, offset:offset*limit, order_by:'id_time_report', order:1, start_date:startDate, end_date:endDate}
                let params = `?${queryString.stringify(param2send)}` //&filter=${filterArgs}
                if(filterArgs.length > 0){
                    filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                    params = `${params}&filter=${filterArgs}`
                }
                const _statistics = await request_statistics_by_businses(params)
                setTotal(_statistics.count)
                setStatistics(_statistics.statistics)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        initModule()
    }, [filter, tableFilter, status, search, startDate, endDate])

    

    const onChangeFilter = (value, type) => {
        setTableFilter({ limit: 10, offset: 0 })
        if(type === 'month') setMonth(value)
        if(type === 'year'){
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setYear(value)
            //setMonth(1)
        }
        if(type === 'status') setStatus(value)

    }

    const onChangeSearch = (data) => {
        setTableFilter({ limit: 10, offset: 0 })
        setSearch(data)
    }

    const onChangeDate = (value, id) => {
        setTableFilter({ limit: 10, offset: 0 })
        let aux
        if(id === 'start_date'){
            aux = moment(value)
            console.log()
            if(moment(endDate).diff(aux) > 0) setStartDate(value)
        }
        if(id === 'end_date'){
            aux = moment(value)
            if(aux.diff(moment(startDate)) > 0) setEndDate(value)
        }
    }


    return ( 
        <LoadingContainer>
            <>
                <BusinessSimpleTable search={search} onChangeSearch={onChangeSearch}
                    month={month} year={year} status={status} monthCatalog={month_options}
                    data={statistics} 
                    filter={filter}
                    tableFilter={tableFilter} onChangeDate={onChangeDate}
                    total={total}  startDate={startDate} endDate={endDate}
                    loading={loading}
                    onSelectedItem={(data) => history.push(`/timereports/${data.id_time_report}`)}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onChangeFilter={onChangeFilter} />
            </>
        </LoadingContainer>
     );
}
 
export default BusinessStatisticsView;