import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Fade} from '@material-ui/core'
import { grey} from '@material-ui/core/colors'

import NavigationTitle from '../../Navigation/NavigationTitle'
import SmallNavigation from '../../Navigation/SmallNavigation'
import CustomSnackbar from '../../Snackbars/CustomSnackbar'
import SimpleLoader from '../../Loaders/SimpleLoader'

const styles = (theme) => ({
  root:{
    padding:'32px',
  },
  loader:{
    color:grey[700],
    fontWeight:'400'
  },
  loaderContainer:{
    padding:'40px'
  }
})

class BasicView extends Component{

  componentDidMount(){

  }

  render(){

    const {classes, history, selected, onChange, content, success, 
      onCloseSnackbar, onReturn, returnBtn, isloading} = this.props;

    let menuContent = null
    if(content.menu){
      menuContent = (
        <Grid item xs={12}>
          <Grid container justify='flex-start'>
            <Grid item>
              <SmallNavigation 
                selected={selected}
                data={content.menu}
                onChange={onChange}
              />
            </Grid>
          </Grid>      
        </Grid>
      )
    }

    let snackbarContent = null
    if(success){
      snackbarContent = (
        <CustomSnackbar 
          open={success.open} 
          autoHideDuration={success.timeout} 
          onClose={() => onCloseSnackbar(success.type)} 
          message={success.message} 
          variant={success.type}
        /> 
      )
    }
    
    let contentData = null

    if(isloading){
      contentData = (
        <div className={classes.loaderContainer}>
          <Grid container alignItems='center' direction='column' spacing={1}>
            <Grid item>
              <SimpleLoader />
            </Grid>
            <Grid item>
              <Typography variant='body2' className={classes.loader}>Loading...</Typography>
            </Grid>
          </Grid>
        </div>
        
      )
    }else{
      contentData = (
        <Fade in timeout={1000}>
          <div>
            {this.props.children}
          </div>
        </Fade>
        
      )
    }
    
    return(
      <div className={classes.root}>
        {snackbarContent}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <NavigationTitle 
              history={history}
              title={content.title}
              navigation={content.navigation}
              returnBtn={returnBtn}
              onReturn={onReturn}
            />
          </Grid>        
          <Grid item xs={12}>
            <Grid container spacing={3}>
              {menuContent}
              <Grid item xs={12}>
                {contentData}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(BasicView)