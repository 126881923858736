export const cleanData2Table = (data, nodata, catalogs) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_employer_registration, 
      used_id: item.used,
      files:[
        {id:item.id_employer_registration,key:'employer_card',name:'Registro Patronal',path:'employercard', status: checkStatus(item, 'employer_card')},
      ],
      data:[]
    }
    
    let state = catalogs.business_documents.mexico_states.filter(el => el.id === item.state_id);
    state = state.length > 0 ? state[0].label : nodata

    let array = [
      item.id_employer_registration, 
      state,
      item.employer_enrollment ? item.employer_enrollment: nodata,
      item.user ?  item.user : nodata,
      item.password ?  item.password : nodata,
      item.tax_payment_limit_date ? item.tax_payment_limit_date : nodata
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}