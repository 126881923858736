import React, { useState } from 'react';
import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { amber, grey } from '@material-ui/core/colors';
import { isValidDocument } from '../../../../shared/utility';

const useStyles = makeStyles(theme => ({
    file:{color:amber[700], fontSize:36}
}))

const DocumentForm = ({file,onUploadDocument,onDownloadDocument, onDeleteDocument}) => {
    const classes = useStyles()

    const valid = isValidDocument(file)

    return ( 
        <SimpleCard>
            <Grid container wrap='nowrap' alignItems='center'>
                <Grid item xs>
                    <Grid container>
                        <Grid item><Icon className={classes.file}>insert_drive_file</Icon></Grid>
                        <Grid item xs><DisplayText variant='subtitle1'>Documento de evidencia</DisplayText></Grid>
                    </Grid>
                </Grid>
                <Grid item><IconButton color='primary' onClick={onUploadDocument}><Icon>cloud_upload</Icon></IconButton></Grid>
                <Grid item><IconButton color='primary' disabled={!valid} onClick={onDownloadDocument}><Icon>cloud_download</Icon></IconButton></Grid>
                <Grid item><IconButton disabled={!valid} onClick={onDeleteDocument} style={{color:valid ? 'red' : grey[400]}}><Icon>close</Icon></IconButton></Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default DocumentForm;