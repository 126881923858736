import { Grid, Icon, IconButton, makeStyles, TableHead, TableRow } from '@material-ui/core';
import React from 'react';
import TCell from './TCell';

const useStyles = makeStyles(theme => ({
    root:{position:'relative'}
}))

const THeader = ({data, log, onVisibleClick, order, orderby, onChangeOrder}) => {

    const classes = useStyles()

    const onInnerChangeOrder = (orderby) => {
        onChangeOrder(orderby)
    }


    if(!data) return null

    //console.log(data)

    let icon = 'unfold_more'
    if(order !== -1){
        if(order === 0) icon = 'expand_less'
        if(order === 1) icon = 'expand_more'
    }

    return ( 
        <TableHead>
            <TableRow>
            {data.map((item,pos) => {
                    const {id,width, label, fixed, hidden, add, position, padding, key} = item
                    if(hidden) return null

                    let orderButton = null
                    if(key){
                        orderButton = (
                            <Grid item>
                                <IconButton size='small' onClick={() => onInnerChangeOrder(key)}>
                                    <Icon>{orderby === key ? icon : 'unfold_more'}</Icon>
                                </IconButton>
                                
                            </Grid>
                        )
                    }

                    return(
                        <TCell type='header' log={log} key={pos.toString()} width={width} fixed={fixed} onVisibleClick={() => onVisibleClick(id)}
                        add={add} position={position} padding={padding}>
                            <Grid container alignItems='center'>
                                <Grid item xs>
                                    {label}
                                </Grid>
                                {orderButton}
                            </Grid>
                            
                        </TCell>
                    )
                })}
            </TableRow>
        </TableHead>
    );
}
 
export default React.memo(THeader);