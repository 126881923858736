import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc',label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status_id',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        //Fixed other data
        {id:6,key:'fixed_billing_account_account',label:'Acceso a la cuenta',hidden:false, add:{icon:'add'}, width:300},
        {id:7,key:'fixed_billing_account_type',label:'Tipo de Cuenta',hidden:false},
        {id:8,key:'fixed_billing_account_status',label:'Estatus',hidden:false},
        {id:9,key:'fixed_billing_account_password',label:'Contraseña',hidden:false},
        {id:10,key:'fixed_billing_account_stamps_expiration',label:'Vencimiento de sellos',hidden:false},
        {id:11,key:'fixed_billing_account_responsible',label:'Responsable',hidden:false},
        {id:12,key:'fixed_billing_stamps_limits',label:'Folios mensuales',hidden:false},

        /// New data
        {id:27,key:'notes',label:'Notas',hidden:false},
        {id:28,key:'completition_date',label:'Fecha de Completado',hidden:false},
        {id:29,label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'fixed_rfc',type:'text'},
        {id:'fixed_client_classification', type:'text'},
        {id:'fixed_client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fixed_billing_account_account',type:'text', },
        {id:'fixed_billing_account_type',type:'text'},
        {id:'fixed_billing_account_status',type:'text'},
        {id:'fixed_billing_account_password',type:'text'},
        {id:'fixed_billing_account_stamps_expiration',type:'date'},
        {id:'fixed_billing_account_responsible',type:'text'},
        {id:'fixed_billing_stamps_limits',type:'text'},
        // Real data
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}