import React from 'react'

import SectionA from './StepsViews/SectionA/SectionA';
import SectionB from './StepsViews/SectionB/SectionB';
import SectionC from './StepsViews/SectionC/SectionC';
import SectionD from './StepsViews/SectionD/SectionD';
import SectionE from './StepsViews/SectionE/SectionE';
import SectionF from './StepsViews/SectionF/SectionF';
import SectionG from './StepsViews/SectionG/SectionG';
import SectionH from './StepsViews/SectionH/SectionH';

export const contentData = {
    spanish: {
        modals: {
            sign_modal: {
                title: 'Firma',
                button: 'Aprobar'
            },
            second_sign_modal: {
                title: 'Firma',
                button: 'Completar evaluación'
            }
        },
        url:'physical_person',
        fixed_id:'physical_person_step_id',
        monthly_steps:[4,6,7],
        paths: {
            primary: {
                id: 'ctrl_accounting_id',
                path: 'ctrlaccounting'
            },
            secondary: {
                id: 'ctrl_monthly_statement_id',
                path: 'ctrlmonthlystatements'
            }
        },
        sections: {
            general_info: {
                banner: {
                    content: {
                        business: 'Razón social',
                        fixed_rfc: 'RFC',
                        fixed_importance:'Importancia',
                        fixed_client_classification: 'Clasificación',
                        fixed_client_status: 'Estatus',
                        fixed_client_responsible: 'Responsable',
                        fixed_client_associate:'Socio'
                    }
                },
                fiscal: {
                    title: 'Fiscal',
                    content: {
                        fixed_fiscal_regime: 'Regimen Fiscal',
                        fixed_activity: 'Actividad económica',
                        fixed_fiscal_situation: 'Situación Fiscal',
                        fixed_payday_limit: 'Límite envío Impuestos',
                        fixed_responsible: 'Responsable de Impuestos',
                        fixed_business_advisor: 'Asesor',
                    }
                },
                taxes: null,
                responsibles: null
            },
            stepper_container: {
                top_banner: {
                    advisory: 1,
                    percent: '% Completado',
                },
                progress: 'Progreso',
                priority: 'Prioridad',
                steps: [
                    {
                        title: 'Solicitud de información',
                        component: <SectionA/>,
                    },
                    {
                        title: 'Cálculo de Impuestos',
                        component: <SectionB/>,
                    },
                    {
                        title: 'Revisión, Envío y Autorización de Cálculo de Impuestos',
                        component: <SectionC/>,
                    },
                    {
                        title: 'Presentación de Declaración',
                        component: <SectionD/>,
                    },
                    {
                        title: 'Envío Línea de Captura y Pago',
                        component: <SectionE/>,
                    },
                    {
                        title: 'Envío de la DIOT',
                        component: <SectionF/>,
                    },
                    {
                        title: 'Consulta Opinión del Cumplimiento y Validación del Buzón Tributario',
                        component: <SectionG/>,
                    },
                    {
                        title: 'Revisión Final',
                        component: <SectionH/>,
                    },
                ],
            }
        }
    }
}
