import { Grid, LinearProgress, makeStyles } from '@material-ui/core'
import React, {useState, useEffect} from 'react'
import ActionModalBar from '../../../../../components_v1/Actions/ActionModalBar'
import InputText from '../../../../../components_v1/Forms/InputText'
import SimpleCard from '../../../../../components_v1/Structure/Cards/SimpleCard'
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { request_business_lr, request_business_documents, request_update_business_lr, request_download_document, request_update_businessdocs_lr } from './requests'
import DocumentsCard from './components/DocumentsCards'
import { onGetErrorMessage, onInitForm, isFormValid, onSetErrorsToForm, onGetSelectedFormData, onGetExtensionFromURL } from '../../../../../shared/utility'
import DeleteDocumentModal from './modals/DeleteDocumentModal'
import DocumentModal from './modals/DocumentModal'
import empty from '../../../../../assets/empty.png'
import SnackbarSuccess from '../../../../../components_v1/Actions/SnakbarSuccess'
//import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    },
    nodata:{
        marginTop:40
    }
}))

const LegalRepresentativeView = props => {

    const classes = useStyles()
    const { businessID, permission } = props
    const [business_documents, setBusinessDocuments] = useState(null)
    const [rfc, setRfc] = useState(null)
    const [lr, setLr] = useState(null)
    const [lrID, setLrID] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState('')
    const [success, setSuccess] = useState(false)
    const [err, setErr] = useState(false)

    const [selected_document, setSelectedDocument] = useState(null)
    const [document_modal, setDocumentModal] = useState(false)
    const [delete_document_modal, setDeleteDocumentModal] = useState(false)

    const serviceID =  null

    useEffect(() => {
      const initModule = async() => {
        try {
          await onRequestModelInformation()
        } catch (error) {
          console.log(error)
          setError(onGetErrorMessage(error))
        }
      }
      initModule()
    }, [])

    const onRequestModelInformation = async() => {
        setLoading(true)
        const _business_documents = await request_business_documents(businessID)
        if (_business_documents && _business_documents.legal_representative_id !== null){
            setLrID(_business_documents.legal_representative_id)
            const _business_docs_lr = await request_business_documents(_business_documents.legal_representative_id)
            setBusinessDocuments(_business_docs_lr)
            const _rfc = _business_docs_lr.rfc
            console.log(_business_docs_lr)
            const _lr = await request_business_lr(_business_documents.legal_representative_id)
            //console.log(_lr)
            setLr(_lr)
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, _lr)
            _form.rfc.value = _rfc
            _form.rfc.isFormValid = true
            _form.rfc.isVisited = true
            setForm(_form)
        }
        setLoading(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        if (id === 'rfc') setRfc(1)
        setForm(temp)
    }

    const onUploadDocument = (data) => {
      setSelectedDocument(data)
      setDocumentModal(true)
    }

    const onDeleteDocument = (data) => {
        setSelectedDocument(data)
        setDeleteDocumentModal(true)
    }

    const onSubmit = async () => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Llene los campos faltantes')
            return
        }

        //Extract data
        let data2sendRfc = null
        let data2send = onGetSelectedFormData(form, lr)
        if(data2send.rfc && rfc === 1){
            data2sendRfc = {rfc: data2send.rfc}
        }
        delete data2send.rfc
    
        //Send data
        setSending(true)
        try {
            //console.log(data2send)
            await request_update_business_lr(lrID, data2send)
            if(data2sendRfc !== null){
                //console.log(data2sendRfc)
                await request_update_businessdocs_lr(lrID, data2sendRfc)
            }
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setSending(false)
        }
        setSending(false)

    }

    const onInnerUpdateCompleted = async() => {
      try {
        await onRequestModelInformation()
        setDocumentModal(false)
        setDeleteDocumentModal(false)
      } catch (error) {
        setError(onGetErrorMessage(error))
      }
    }

    const onDownloadDocument = async(data) => {
        try {
        if(data.businessDocument){
            console.log('Download from business documents')
            const local_path = business_documents[data.key]
            const filename = `${data.key}.${onGetExtensionFromURL(local_path)}`
            //console.log(filename)
            //console.log(data.path)
            //console.log(lrID)
            await request_download_document(lrID, data.path, filename, 'BUSINESS')
        }
        /* else{
            console.log('Upload to service documents')
            const local_path = efirma[data.key]
            const filename = `${data.key}.${onGetExtensionFromURL(local_path)}`
            await request_download_document(serviceID, data.path, filename, 'SERVICE')
        } */
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
    }

    return(
      
        <div style={{marginTop:16}}>
            {/* <LoadingContainer sending={loading} success={success} error={err}> */}
            <SnackbarSuccess open={success} onClose={()=>setSuccess(false)}/>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <SimpleCard >
                <div>
                    <DeleteDocumentModal businessID={lrID} serviceID={serviceID} open={delete_document_modal} document={selected_document} onClose={() => setDeleteDocumentModal(false)} 
                    onUpdateCompleted={onInnerUpdateCompleted}/>
                    <DocumentModal businessID={lrID} serviceID={serviceID}  open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)}
                    onUpdateCompleted={onInnerUpdateCompleted}/>
                    <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Información básica</DisplayText>
                    <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={6}>
                                <InputText data={form.name} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText data={form.rfc} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputText data={form.antiquity} onChange={onChange}/>
                            </Grid>
                            <Grid item xs={12}>
                                <ActionModalBar btnLabel='Guardar' loading={sending} error={error} onSubmit={onSubmit}/>
                            </Grid>
                        </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <DocumentsCard permission={permission}  files={business_documents} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument}/>
                        </Grid>                   
                    </Grid>                   
                    </div>
                </div>
            
            </SimpleCard>
            {lr === null && !loading ? (
            <div className={classes.nodata}>
                <Grid container justify='center' alignItems='center' direction='column'>
                    <Grid item>
                        <img src={empty} alt='' width={180}/>
                    </Grid>
                    <Grid item>
                        <DisplayText>No se encontró Respresentante Legal</DisplayText>
                    </Grid>
                </Grid>
            </div>) : null}
            {/* </LoadingContainer> */}
        </div>
    )
}

export default LegalRepresentativeView

const formData = {
    name:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
          label:'Nombre',
          placeholder:'Nombre',
          id:'name',
          type:'text',
          fullWidth: true
      },
      rules:{
          type:'distance',
          min:1,
          max:250
      }
    },  
    rfc:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
          id:'rfc',
          type:'text',
          fullWidth: true,
          label:'RFC',
          placeholder:'RFC'
      },
      rules:{
          type:'distance',
          min:1,
          max:100
      }
  },
  antiquity:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
          id:'antiquity',
          type:'date',
          fullWidth: true,
          label:'Antiguedad',
          placeholder:'Antiguedad'
      },
      rules:{
          type:'distance',
          min:1,
          max:100
      }
  },
}