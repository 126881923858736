import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'rfc',label:'RFC',hidden:false},
        {id:3,key:'client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'client_status_id',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        //Fixed other data
        {id:6,key:'fiscal_regime',label:'Regimen Fiscal',hidden:false, add:{icon:'add'}},
        {id:7,key:'sub_activity',label:'Sub-Actividad',hidden:false},
        /// New data
        {id:20,key:'process_status_id',label:'Estatus',hidden:false},
        {id:21,key:'declaration_period',label:'Periodo',hidden:false},
        {id:22,key:'declaration_type',label:'Tipo',hidden:false},
        {id:23,key:'income',label:'Ingresos',hidden:false},
        {id:24,key:'expenses',label:'Egresos',hidden:false},
        {id:25,key:'fiscal_utility',label:'Unidad Fiscal / Pérdida',hidden:false},
        {id:26,key:'isr_to_pay',label:'ISR a Pagar',hidden:false},
        {id:27,key:'total_to_pay',label:'Total a Pagar',hidden:false},
        {id:28,key:'operation_number',label:'Número de Operación',hidden:false},
        {id:29,key:'presentation_date',label:'Fecha de Presentación',hidden:false},
        {id:30,label:'Hoja de Trabajo',hidden:false},
        {id:31,label:'Acuse Anual',hidden:false},
        {id:32,label:'Detalle Doc',hidden:false},
        
        {id:33,key:'extra_hours',label:'Horas Extra',hidden:false},
        {id:34,label:'Comprobante Horas',hidden:false},
        {id:35,key:'extra_charges',label:'Cargos Extra',hidden:false},
        {id:36,label:'Comprobante Cargo',hidden:false},
        {id:37,key:'collections_charge',label:'Cargo a Cobranza',hidden:false},
        {id:38,key:'delivery_date',label:'Fecha de Entrega',hidden:false},
        {id:39,key:'responsible',label:'Responsable',hidden:false},
        {id:40,key:'notes',label:'Notas',hidden:false},
        {id:41,key:'completition_date',label:'Fecha de Completado',hidden:false},
        {id:42,label:'Completado',hidden:false},

    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'rfc',type:'text'},
        {id:'client_classification', type:'text'},
        {id:'client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        ///////// second fixed data
        {id:'fiscal_regime',type:'text'},
        {id:'sub_activity',type:'text'},
        // Real data
        {id:'process_status_id', type:'status', options: [...catalogs.ctrl_process_statuses], editable:true},
        {id:'declaration_period',formId:'declaration_period_id', type:'text',editable:true},
        {id:'declaration_type',formId:'declaration_type_id', type:'text',editable:true},
        {id:'income',type:'cash', editable:true},
        {id:'expenses',type:'cash', editable:true},
        {id:'fiscal_utility',type:'cash', editable:true},
        {id:'isr_to_pay',type:'cash', editable:true},
        {id:'total_to_pay',type:'cash', editable:true},
        {id:'operation_number',type:'text',editable:true},
        {id:'presentation_date',type:'date',variant:'date',editable:true},
        {id:'worksheet',type:'document'},
        {id:'annual_receipt',type:'document'},
        {id:'details_document',type:'document'},

        {id:'extra_hours',type:'text',editable:true},
        {id:'hours_proof',type:'document'},
        {id:'extra_charges',type:'cash',editable:true},
        {id:'charge_proof',type:'document'},
        {id:'collections_charge',type:'cash',editable:true},
        {id:'delivery_date',type:'date',variant:'date',editable:true},
        {id:'responsible',formId:'responsible_id', type:'text', editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}