import React from 'react'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
  } from '@material-ui/pickers';
import { grey } from '@material-ui/core/colors';
import { withStyles } from '@material-ui/core';
import moment from 'moment'
import {ruleValidation} from './customFunctions.jsx';


  const CSSKeyboardDatePicker = withStyles(theme => ({
    root: {
      marginTop:'6px !important',
      '& input':{
        color:grey[900],
      },
      '& .MuiFormControl-root': {
          marginTop:0
      },
      '& .MuiInput-underline': {
        borderBottomColor: grey[300],
        borderBottomWidth:2,
        '&:hover:not($disabled):after': {
          //its when its hover and input is focused 
        },
        '&:hover:not($disabled):before': {
          borderBottomWidth:2,
          borderBottomColor: theme.palette.secondary.light,//its when you hover and input is not foucused 
        },
        '&:after': {
          borderBottomColor: theme.palette.secondary.main,//when input is focused, Its just for example. Its better to set this one using primary color
        },
        '&:before': {
          borderWidth:1,
          borderBottomColor: grey[300],// when input is not touched
        },
      }
    }
  }))(DatePicker);


const LineInputDate = props => {    

    const {onChange, data, disabled} = props
    const {value, isVisited, isValid} = props.data
    const {label,placeholder, helper} = props.data.config


    const handleDateChange = (date) => {
      //console.log('Cambio')
      //console.log(date)
      //console.log(date.format('YYYY-MM-DD'))
        const response =date.format('YYYY-MM-DD');
        let temp = {...data};
        temp.value = response;
        temp.isValid = ruleValidation(temp.value, temp.rules, temp.value2);
        temp.isVisited = true;
        onChange(temp);
    }


    const error = isVisited && !isValid;
    //console.log(value)


    return(
        <div>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <CSSKeyboardDatePicker
                    disabled={disabled}
                    value={value}
                    placeholder={placeholder}
                    error={error}
                    fullWidth
                    variant="inline"
                    format="DD/MM/YYYY"
                    label={label}
                    helperText={error ? helper : null}
                    onChange={handleDateChange}
                    clearable
                    autoOk
                    
                />
            </MuiPickersUtilsProvider>
            
        </div>
    )
}

export default LineInputDate