import React, {Component} from 'react'
import { Redirect } from 'react-router'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import WarningModal from  '../../../../components/Modals/WarningModal'
import ServiceForm from '../../../../components/Forms/ServiceForm/ServiceForm'
import FileManagerModal from '../../../../components/Modals/FileManagerModal/FileManagerModal'
import FormAModal from '../../../../components/Modals/FormAModal'

/////// IMPORT MODULES //////////
import BillingStamps from '../Modules/BillingStamps/BillingStamps'

import {request_service, 
  request_updateService,
  request_deleteFile,
  request_downloadFile,
  request_uploadFile,
} from './requests'

import {formData as servicedata} from './servicedata/data' 
import {contentData as servicecontent} from './servicedata/content' 
import {catalog_matrix as servicematrix} from './servicedata/catalog_matrix'
import BillingAccounts from '../Modules/BillingAccounts/BillingAccounts';
import Logs from '../Modules/Logs/Logs'

class Billing extends Component {

  state = {
    isloading:false,
    pageSelected:0,
    service:null,
    modals:{
      filemodal:{open:false,issending:false,error:null,extra:null},
      edit_service:{open:false,issending:false,error:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  initModule = async() => {
    try{
      const {businessID} = this.props
      this.setState({isloading:true})
      let response = await request_service(businessID)
      this.setState({service:{...response}})
      this.setState({isloading:false})

    }catch(e){
      console.log(e)
    }
  }

  onEditService = async(data, type) => {
    try {
      const {businessID} = this.props
      this.onEditModal(type,{issending:true})
      let response = await request_updateService(businessID, data)
      this.setState({service:{...response}})
      this.onEditModal(type,{open:false, issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      this.onEditModal(type,{issending:false})
      if(error.response){
        this.onEditModal(type,{error:error.response.data.error.message})
      }
    }
  }

  onSubmitFile = async(filepath, file) => {
    try{
      const {businessID} = this.props
      this.onEditModal('filemodal',{issending:true});
      const aux = file[0].name.split('.')
      const ext = {ext:aux[aux.length-1]}
      let response = await request_uploadFile(businessID, filepath, file[0], ext);
      this.setState({service:{...response}})
      this.onCloseModal('filemodal')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('filemodal',{issending:false})
      if(e.response){
        this.onEditModal('filemodal',{error:e.response.data.error.message})
      }
    }
  }

  onDownloadFile = async (filedata) => {
    try{
      const {businessID} = this.props
      await request_downloadFile(businessID, filedata.path)
    }catch(e){
      console.log(e)
    }
  }

  onDeleteFile = async (filedata) => {
    try{
      this.onEditModal('warning_delete_file',{issending:true});
      const {businessID} = this.props
      let response = await request_deleteFile(businessID, filedata.path)
      this.setState({service:{...response}})
      this.onCloseModal('warning_delete_file')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('warning_delete_file',{issending:false});
      if(e.response){
        this.onEditModal('warning_delete_file',{error:e.response.data.error.message})
      }
    }
    
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata} 
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  onChangeView = (id) => {
    this.setState({pageSelected:id})
  }

  

  render(){

    const {history, language, catalogs, businessID, 
          permission, permissionsType} = this.props
    const {isloading, success, pageSelected, service, modals, users} = this.state

    const content = contentData[language]

    let modalcontent = null
    if(modals.warning_delete_file){
      modalcontent=(
        <WarningModal 
          data={modals.warning_delete_file}
          content={content.modals.warning_delete_file}
          onClose={() => this.onCloseModal('warning_delete_file')}
          onDelete={this.onDeleteFile}
        />
      )
    }

    if(modals.filemodal.open){
      modalcontent = (
        <FileManagerModal
          data={modals.filemodal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('filemodal')}
          onSubmit={this.onSubmitFile}
          onDelete={this.onDeleteFile}
        />
      )
    }

    if(modals.edit_service.open){
      modalcontent = (
        <FormAModal 
          origin_data={service}
          catalogs={catalogs.business_documents}
          data={modals.edit_service}
          catalog_matrix={servicematrix}
          content={servicecontent[language]}
          form_data={servicedata}
          onClose={() => this.onCloseModal('edit_service')}
          onSubmit={(data) => this.onEditService(data, 'edit_service')}
          addSpace={true}
        />
      )
    }

    let contentView = null

    switch(pageSelected){
      case 0:
        contentView = (
          <ServiceForm 
            staticview={content.basicform}
            service={service}
            onEditData={() => this.onOpenFileModal('edit_service')}
            onUploadFile={(filedata) => this.onOpenFileModal('filemodal', filedata)}
            onDownloadFile={this.onDownloadFile}
            onDeleteFile={(filedata) => this.onOpenFileModal('warning_delete_file', filedata)}
            permission={permission}
            permissionsType={permissionsType}
          />
        )
        break
      case 1:
        contentView = (
          <BillingAccounts 
            history={history}
            language={language}
            catalogs={catalogs}
            businessID={businessID}
            permission={permission}
            permissionsType={permissionsType}
          />
        )
        break
      case 2:
          contentView = (
            <BillingStamps 
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              permission={permission}
              permissionsType={permissionsType}
            />
          )
          break
      case 3:
        contentView = (
            <Logs
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              service={'ctrlbillings'}
              ctrlTableIdLabel={'id_ctrl_billing'}
            />
        )
        break
      default:
        break
    }

    return(
      <BasicView
        history={history}
        selected={pageSelected}
        content={content.layout}
        onChange={this.onChangeView}
        isloading={isloading}
        returnBtn={true}
        onReturn={this.onReturn}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
      >
        {modalcontent}
        {permission === 1 ? (
          <Redirect to='/profile'/>
        ) : null}
        <div>
          {contentView}
        </div>
          

      </BasicView>
    )
  }
}

export default Billing
