export const contentData = {
  spanish:{
    layout:{
      title:'Consultoría',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
      menu:[
        {id:0, label:'General'},
        {id:1, label:'Responsables'},
        {id:2, label:'Logs'}
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:'',
        },
        efirma_password:{
          label:'',
          data:'',
        }
      },
      content:{
        rfc:{
          label:'RFC'
        },
        efirma_password:{
          label:'Contraseña eFirma'
        },
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'efirma_cer',name:'eFirma Certificado',path:'efirmacer', status:false},
          {key:'efirma_key',name:'eFirma Llave',path:'efirmakey', status:false},
        ]
      }
    }
  }
}