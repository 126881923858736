import Profile from '../views/Employee/Profile/Profile'
import PasswordProfile from '../views/Employee/Profile/PasswordProfile/PasswordProfile'
import EditProfile from '../views/Employee/Profile/EditProfile/EditProfile'
////***********************USER MODULE************************ */
import Users from '../views/Employee/Users/Users/Users'
import AddUser from '../views/Employee/Users/AddUser/AddUser'
import UserView from '../views/Employee/Users/UserView/UserView'
import EditUser from '../views/Employee/Users/EditUser/EditUser'
import PasswordUser from '../views/Employee/Users/PasswordUser/PasswordUser'
////***********************PROSPECT MODULE************************ */
import Prospects from '../views/Employee/Prospects/Prospects/Prospects'
import EditProspect from '../views/Employee/Prospects/EditProspect/EditProspect'
import ProspectView from '../views/Employee/Prospects/ProspectView/ProspectView'
import AddProspect from '../views/Employee/Prospects/AddProspect/AddProspect'
////***********************CLIENT MODULE************************ */
import Clients from '../views/Employee/Clients/Clients/Clients'
import ClientView from '../views/Employee/Clients/ClientView/ClientView'
//import EditClient from '../views/Employee/Clients/EditClient/EditClient'
//import AddClient from '../views/Employee/Clients/AddClient/AddClient'
////***********************CLIENTUSER MODULE************************ */
import Clientusers from '../views/Employee/Clientusers/Clientusers/Clientusers'
import ClientuserView from '../views/Employee/Clientusers/ClientuserView/ClientuserView';
import EditClientuser from '../views/Employee/Clientusers/EditClientuser/EditClientuser';
import Businesses from '../views/Employee/Businesses/Businesses/Businesses';
import BusinessView from '../views/Employee/Businesses/BusinessView/BusinessView';
import EditBusiness from '../views/Employee/Businesses/EditBusiness/EditBusiness';
import ServiceWrapper from '../views/Employee/Services/ServiceWrapper';
import ProcessWrapper from '../views/Businesses/BusinessView/views/BusinessGeneral/components/ProcessWrapper/ProcessWrapper'
import AddBusiness from '../views/Employee/Businesses/AddBusiness/AddBusiness';
////***********************CONTROL TABLE MODULE****************** */ */
import ControlTableControl from '../views/Employee/ControlTables/ControlTableControl/ControlTableControl';
import ControlTableWrapper from '../views/Employee/ControlTables/ControlTableWrapper'
////***********************TIMEREPORT****************** */ */
import TimeReports from '../views/TimeReports/TimeReports/TimeReports'
import TimeReportView from '../views/TimeReports/TimeReportView/TimeReportView'
import Evaluations from '../views/Evaluations/Evaluations/Evaluations'
import EvaluationGenericView from '../components_v1/Structure/EvaluationGenericView/EvaluationGenericView'
import BusinessTimeReportView from '../views/Employee/Businesses/BusinessTimeReportView/BusinessTimeReportView'
import Statistics from '../views/Statistics/Statistics'
import FullTimeReports from '../views/FullTimeReports/FullTimeReports/FullTimeReports'
import GeneralTimeReportView from '../views/FullTimeReports/GeneralTimeReportView/GeneralTimeReportView'
import FullTimeReportView from '../views/FullTimeReports/FullTimeReportView/FullTimeReportView'
////***********************CONTROL TABLES MODULE****************** */ */
import CTRLStamping from '../views/ControlTables/CTRLStamping/CTRLStamping'
import CTRLAccounting from '../views/ControlTables/CTRLAccounting/CTRLAccounting'

import Businessesv2 from '../views/Businesses/Businesses/Businesses';
import BusinessViewv2 from '../views/Businesses/BusinessView/BusinessView';
import CTRLMonthlyStatement from '../views/ControlTables/CTRLMonthlyStatement/CTRLMonthlyStatement'
import CTRLSocialSecurity from '../views/ControlTables/CTRLSocialSecurity/CTRLSocialSecurity'
import CTRLStateTax from '../views/ControlTables/CTRLStateTax/CTRLStateTax'
import CTRLBillings from '../views/ControlTables/CTRLBillings/CTRLBillings'
import CTRLPhysicalAnnualStatement from '../views/ControlTables/CTRLPhysicalAnnualStatement/CTRLPhysicalAnnualStatement'
import CTRLACAnnualStatement from '../views/ControlTables/CTRLACAnnualStatement/CTRLACAnnualStatement'
import CTRLMoralAnnualStatement from '../views/ControlTables/CTRLMoralAnnualStatement/CTRLMoralAnnualStatement'
import CTRLRiskAnnualStatement from '../views/ControlTables/CTRLRiskAnnualStatement/CTRLRiskAnnualStatement'
import CTRLEfirma from '../views/ControlTables/CTRLEfirma/CTRLEfirma'
import TimeReportGenericView from '../components_v1/Structure/TimeReportGenericView/TimeReportGenericView'
import Process from '../views/Process/Process/Process'
import IntegralSolution from '../views/Process/Process/IntegralSolution/IntegralSolution'
import IntegralSolutionView from '../views/Process/ProcessView/IntegralSolutionView/IntegralSolutionView'
import PhysicalPersons from '../views/Process/Process/PhysicalPersons/PhysicalPersons'
import PhysicalPersonsView from '../views/Process/ProcessView/PhysicalPersonsView/PhysicalPersonsView'
import SocialSecurity from '../views/Process/Process/SocialSecurity/SocialSecurity'
import SocialSecurityView from '../views/Process/ProcessView/SocialSecurityView/SocialSecurityView'
import StateTax from '../views/Process/Process/StateTax/StateTax'
import StateTaxView from '../views/Process/ProcessView/StateTaxView/StateTaxView'
import TimeReportsW from '../views/Statistics/TimeReports/TimeReports'
import EvaluationsW from '../views/Statistics/Evaluations/Evaluations'
import Tasks from '../views/Tasks/Tasks/Tasks'
import TaskView from '../views/Tasks/TaskView/TaskView'
import Stamping from '../views/Process/Process/Stamping/Stamping'
import StampingView from '../views/Process/ProcessView/StampingView/StampingView'
import Bonus from '../views/Bonus/Bonus'
import Home from '../views/Home/Home'
import CTRLCertificate from '../views/ControlTables/CTRLCertificate/CTRLCertificate'
import GeneralPayrollListView from '../views/Payroll/GeneralPayrollListView/GeneralPayrollListView'

const dashboardRoutes = {
  sections: [
    {
      path: "/profile",
      component: Profile,
      sidebar: false,
      icon: 'person',
      exact: true,

    },
    {
      path: "/profile/evaluation/:id",
      icon: 'person',
      label: 'Perfil',
      component: EvaluationGenericView,
      sidebar: false,
      exact: true
    },
    {
      path: "/profile/details/timereport/:timereportid",
      icon: 'person',
      label: 'Perfil',
      component: TimeReportGenericView,
      sidebar: false,
      exact: true
    },
    {
      path: "/profile/change-password",
      label: 'Cambiar Contraseña',
      component: PasswordProfile,
      sidebar: false,
      exact: true
    },
    {
      path: "/profile/edit",
      label: 'Editar Perfil',
      component: EditProfile,
      sidebar: false,
    },
    {
      path: "/home",
      icon: 'home',
      label: 'Inicio',
      component: Home,
      sidebar: true,
      exact:true
    },
    ///*****************USERS****************** */
    {
      path: "/users",
      icon: 'admin_panel_settings',
      label: 'Usuarios',
      component: Users,
      sidebar: true,
      exact: true,
      permission_id: 1
    },
    {
      path: "/users/add",
      icon: 'person',
      label: 'Agregar Usuarios',
      component: AddUser,
      sidebar: false,
      exact: true
    },
    {
      path: "/users/details/:id/timereport/:timereportid",
      icon: 'person',
      label: 'Detalles Usuarios',
      component: TimeReportGenericView,
      sidebar: false,
      exact: true
    },
    {
      path: "/users/details/evaluation/:id",
      icon: 'person',
      label: 'Detalles Usuarios',
      component: EvaluationGenericView,
      sidebar: false,
      exact: true
    },
    {
      path: "/users/details/:id",
      icon: 'person',
      label: 'Detalles Usuarios',
      component: UserView,
      sidebar: false,
      exact: true
    },
    {
      path: "/users/edit",
      icon: 'person',
      label: 'Editar Usuarios',
      component: EditUser,
      sidebar: false,
    },
    {
      path: "/users/change-password",
      icon: 'person',
      label: 'Cambiar Contraseña',
      component: PasswordUser,
      sidebar: false,
    },
    ///*****************PROSPECTS****************** */
    {
      path: "/prospects",
      icon: 'remove_red_eye',
      label: 'Prospectos',
      component: Prospects,
      sidebar: true,
      exact: true,
      permission_id: 2
    },
    {
      path: "/prospects/details/:id",
      icon: 'people',
      label: 'Prospectos Details',
      component: ProspectView,
      sidebar: false,
    },
    {
      path: "/prospects/edit",
      icon: 'people',
      label: 'Prospectos Edit',
      component: EditProspect,
      sidebar: false,
    },
    {
      path: "/prospects/add",
      component: AddProspect,
      sidebar: false,
    },
    ///*****************CLIENTS****************** */
    {
      path: "/clients",
      icon: 'people_alt',
      label: 'Clientes',
      component: Clients,
      sidebar: true,
      exact: true,
      permission_id: 2
    },
    {
      path: "/clients/details/:id",
      component: ClientView,
      sidebar: false,
      exact: true
    },
    /*{
      path: "/clients/add",
      component: AddClient,
      sidebar:false,
      exact:true
    },
    {
      path: "/clients/edit/:id",
      component: EditClient,
      sidebar:false,
    },*/
    ///*****************CLIENTUSERS****************** */
    {
      path: "/clients/details/:id/clientusers",
      component: Clientusers,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/clientusers/:idclientuser",
      component: ClientuserView,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/clientusers/:idclientuser/edit",
      component: EditClientuser,
      sidebar: false,
      exact: true
    },
    ///*****************BUSINESSSES UPDATE****************** */
    {
      path: "/businesses/:idbusiness/timereport/:timereportid",
      component: BusinessTimeReportView,
      sidebar: false,
      exact: true
    },
    {
      path: "/businesses/:id",
      icon: 'business',
      label: 'Negocios',
      component: BusinessViewv2,
    },
    {
      path: "/businesses",
      icon: 'business',
      label: 'Negocios',
      component: Businessesv2,
      sidebar: true,
      permission_id: 3
    },
    ///*****************BUSINESSSES****************** */
    {
      path: "/clients/details/:id/businesses",
      component: Businesses,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/businesses/add",
      component: AddBusiness,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/businesses/:idbusiness",
      component: BusinessView,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/businesses/:idbusiness/timereport/:timereportid",
      component: BusinessTimeReportView,
      sidebar: false,
      exact: true
    },
    {
      path: "/clients/details/:id/businesses/:idbusiness/edit",
      component: EditBusiness,
      sidebar: false,
      exact: true
    },

    ///***************** BUSINESS SERVICES ****************** */
    {
      path: "/clients/details/:id/businesses/:idbusiness/service/:idservice",
      component: ServiceWrapper,
      sidebar: false,
      exact: true
    },
    ///***************** BUSINESS PROCESS ****************** */
    {
      path: "/clients/details/:id/businesses/:idbusiness/process/:idprocesstype",
      component: ProcessWrapper,
      sidebar: false,
      exact: true
    },
    ///***************** CONTROL TABLES *********** */
    {
      path: "/controltables/stamping",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLStamping,
      permission_id: -1
    },
    {
      path: "/controltables/accounting",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLAccounting,
      permission_id: -1
    },
    {
      path: "/controltables/monthly-statement",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLMonthlyStatement,
      permission_id: -1
    },
    {
      path: "/controltables/social-security",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLSocialSecurity,
      permission_id: -1
    },
    {
      path: "/controltables/state-tax",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLStateTax,
      permission_id: -1
    },
    {
      path: "/controltables/physical-annual-statement",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLPhysicalAnnualStatement,
      permission_id: -1
    },
    {
      path: "/controltables/ac-annual-statement",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLACAnnualStatement,
      permission_id: -1
    },
    {
      path: "/controltables/moral-annual-statement",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLMoralAnnualStatement,
      permission_id: -1
    },
    {
      path: "/controltables/risk-annual-statement",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLRiskAnnualStatement,
      permission_id: -1
    },
    {
      path: "/controltables/billing",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLBillings,
      permission_id: -1
    },
    {
      path: "/controltables/efirma",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLEfirma,
      permission_id: -1
    },
    {
      path: "/controltables/certificate",
      icon: 'table_chart',
      label: 'Tablas',
      component: CTRLCertificate,
      permission_id: -1
    },
    {
      path: "/controltables",
      icon: 'table_chart',
      label: 'Tablas',
      component: ControlTableControl,
      sidebar: true,
      exact: true,
      permission_id: 5
    },
    {
      path: "/controltables/details/:id",
      component: ControlTableWrapper,
      sidebar: false,
      exact: true
    },
    ///***************** PROCESS *********** */
    {
      path: "/process",
      icon: 'account_tree',
      label: 'Procesos',
      component: Process,
      sidebar: true,
      exact: true
    },
    {
      path: "/process/integralsolution",
      icon: 'account_tree',
      label: 'Solución Integral',
      component: IntegralSolution,
      exact: true
    },
    {
      path: "/process/integralsolution/:id",
      icon: 'account_tree',
      label: 'Solución Integral Detalles',
      component: IntegralSolutionView,
      exact: true
    },
    {
      path: "/process/physicalpersons",
      icon: 'account_tree',
      label: 'Personas Físicas',
      component: PhysicalPersons,
      exact: true
    },
    {
      path: "/process/physicalpersons/:id",
      icon: 'account_tree',
      label: 'Personas Físicas Detalles',
      component: PhysicalPersonsView,
      exact: true
    },
    {
      path: "/process/socialsecurity",
      icon: 'account_tree',
      label: 'Seguro Social',
      component: SocialSecurity,
      exact: true
    },
    {
      path: "/process/socialsecurity/:id",
      icon: 'account_tree',
      label: 'Seguro Social Detalles',
      component: SocialSecurityView,
      exact: true
    },
    {
      path: "/process/statetax",
      icon: 'account_tree',
      label: 'Impuesto local sobre nómina',
      component: StateTax,
      exact: true
    },
    {
      path: "/process/statetax/:id",
      icon: 'account_tree',
      label: 'Impuesto local sobre nómina detalles',
      component: StateTaxView,
      exact: true
    },
    ///***************** TASKS *********** */
    {
      path: "/tasks",
      icon: 'format_list_bulleted',
      label: 'Tareas',
      component: Tasks,
      notification_id: 'pending_tasks',
      sidebar: true,
      exact: true
    },
    {
      path: "/tasks/:id",
      icon: 'format_list_bulleted',
      label: 'Tarea Detalles',
      component: TaskView,
    },
    {
      path: "/process/stamping",
      icon: 'account_tree',
      label: 'Timbrado y nómina',
      component: Stamping,
      exact: true
    },
    {
      path: "/process/stamping/:id",
      icon: 'account_tree',
      label: 'Timbrado y nómina detalles',
      component: StampingView,
      exact: true
    },
    ///***************** TIME REPORT *********** */
    {
      path: "/timereports/:id",
      icon: 'pending_actions',
      label: 'Timereport Details',
      component: TimeReportView,
      exact: true
    },
    {
      path: "/timereports",
      icon: 'pending_actions',
      label: 'Timereport',
      component: TimeReports,
      sidebar: true,
      notification_id: 'pending_time_reports',
      permission_id: -1

    },
    {
      path: "/fulltimereports/:timereportid",
      icon: 'all_inbox',
      label: 'Timereport General',
      component: FullTimeReportView,
      permission_id: 70,
      exact: true
    },
    {
      path: "/fulltimereports",
      icon: 'all_inbox',
      label: 'Timereport General',
      component: FullTimeReports,
      sidebar: true,
      permission_id: 70

    },
    ///***************** Evaluations *********** */
    {
      path: "/evaluations/:id",
      icon: 'pending_actions',
      label: 'Evaluation Details',
      component: EvaluationGenericView,
      exact: true
    },
    {
      path: "/evaluations",
      icon: 'assignment',
      label: 'Evaluaciones',
      component: Evaluations,
      sidebar: true,
      notification_id: 'pending_evaluations',
      permission_id: 0

    },
    //*****************NOMINA******************* */
    {
      path: "/payroll",
      icon: 'receipt_long',
      label: 'Nómina interna',
      component: GeneralPayrollListView,
      sidebar: true,
      permission_id:79
      
      //exact: true,

    },
    //*****************BONOS******************* */
    {
      path: "/bonus",
      icon: 'attach_money',
      label: 'Bonos',
      component: Bonus,
      sidebar: true,
      permission_id:74
      //exact: true,

    },
    ///***************** STATISTICS *********** */
    {
      path: "/statistics",
      icon: 'analytics',
      label: 'Estadísticas',
      component: Statistics,
      sidebar: true,
      exact: true,
      permission_id: 71

    },
    {
      path: "/statistics/timereports",
      icon: 'analytics',
      label: 'Timereports',
      component: TimeReportsW,
      exact: true
    },
    {
      path: "/statistics/timereports/:id",
      icon: 'analytics',
      label: 'Timereport Details',
      component: TimeReportView,
      exact: true
    },
    {
      path: "/statistics/evaluations",
      icon: 'analytics',
      label: 'Evaluaciones',
      component: EvaluationsW,
      exact: true
    },
    
    {
      redirect: true,
      path: "/",
      to: '/home',
      navbarName: "Redirect"
    },

  ]
};

export default dashboardRoutes;