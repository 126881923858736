import {mainServer} from '../../../../variables/config'

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}

export const request_prospects = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get(`/client/pre/list${params}`);
  return response.data.data
}

export const request_add_prospect = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/client/pre/", data)
  return response.data.data.pre_client_id
}

export const request_delete_prospect = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  await mainServer.instance.delete("/client/"+id)
  let response
  response = await mainServer.instance.get("/client/pre/list");
  return response.data.data
}
