import React, {Component} from 'react'
import StateTax from './Tables/StateTax/StateTax';
import Billing from './Tables/Billing/Billing';
import SocialSecurity from './Tables/SocialSecurity/SocialSecurity';
import Stampings from './Tables/Stampings/Stampings';
import Accounting from './Tables/Accounting/Accounting';
import MoneyLaundering from './Tables/MoneyLaundering/MoneyLaundering';
import Publicity from './Tables/Publicity/Publicity';
import Outsourcing from './Tables/Outsourcing/Outsourcing';
import Technology from './Tables/Technology/Technology';
import Legal from './Tables/Legal/Legal';
import Audit from './Tables/Audit/Audit';
import Constitution from './Tables/Constitution/Constitution';
import Key from './Tables/Key/Key';
import MonthlyStatement from './Tables/MonthlyStatement/MonthlyStatement';
import Satic from './Tables/Satic/Satic';
import PhysicalAnnualStatement from './Tables/PhysicalAnnualStatement/PhysicalAnnualStatement';
import RiskAnnualStatement from './Tables/RiskAnnualStatement/RiskAnnualStatement';
import ACAnnualStatement from './Tables/ACAnnualStatement/ACAnnualStatement';
import MoralAnnualStatement from './Tables/MoralAnnualStatement/MoralAnnualStatement';
import ASiViewer from './Tables/ASiViewer/ASiViewer';
import Infonavit from './Tables/Infonavit/Infonavit';
import ASAdvisory from './Tables/ASAdvisory/ASAdvisory';
import Update from './Tables/Update/Update';
import BankAccountOpening from './Tables/BankAccountOpening/BankAccountOpening';
import ImssInscription from './Tables/ImssInscription/ImssInscription';
import MoralSatInscription from './Tables/MoralSatInscription/MoralSatInscription';
import PhysicalSatInscription from './Tables/PhysicalSatInscription/PhysicalSatInscription';
import StateRegisterInscription from './Tables/StateRegisterInscription/StateRegisterInscription';
import FiscalDiagnostic from './Tables/FiscalDiagnostic/FiscalDiagnostic';
import Consulting from './Tables/Consulting/Consulting';
import ExternalInvestmentRegistration from './Tables/ExternalInvestmentRegistration/ExternalInvestmentRegistration';
import Efirma from './Tables/Efirma/Efirma';

class ControlTableWrapper extends Component{
  render(){
    
    const {id} = this.props.match.params
    const {history, language, catalogs, permissions,
        permissionsType} = this.props
    let contentView = null

    let permissionsASAdvisory = permissions.filter( e => e.id_permission_module > 59)
    permissionsASAdvisory.splice(7,1)

    switch(id){
      case '1':
        contentView = (
          <StateTax
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 38).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '2':
        contentView = (
          <Billing
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 39).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '3':
        contentView = (
          <SocialSecurity
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 40).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '4':
        contentView = (
          <Stampings
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 41).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '5':
        contentView = (
          <Accounting
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 42).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '7':
        contentView = (
          <MoneyLaundering
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 44).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '8':
        contentView = (
          <Publicity
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 45).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '9':
        contentView = (
          <Outsourcing
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 46).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '10':
        contentView = (
          <Technology
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 47).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '11':
        contentView = (
          <Legal
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 48).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '12':
        contentView = (
          <Audit
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 49).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '13':
        contentView = (
          <Constitution
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 50).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '14':
        contentView = (
          <Key
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 51).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '15':
        contentView = (
          <MonthlyStatement
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 52).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '16':
        contentView = (
          <Satic
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 53).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '17':
        contentView = (
          <Update 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 54).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '18':
        contentView = (
          <PhysicalAnnualStatement
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 55).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '19':
        contentView = (
          <RiskAnnualStatement
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 56).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '20':
        contentView = (
          <ACAnnualStatement
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 57).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '21':
        contentView = (
          <MoralAnnualStatement
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 58).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '22':
        contentView = (
          <ASiViewer
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 59).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '23':
        contentView = (
          <BankAccountOpening 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 60).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '24':
        contentView = (
          <ImssInscription 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 61).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '25':
        contentView = (
          <MoralSatInscription 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 62).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '26':
        contentView = (
          <PhysicalSatInscription 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 63).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '27':
        contentView = (
          <StateRegisterInscription 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 64).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '28':
        contentView = (
          <Efirma 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 65).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '29':
        contentView = (
          <FiscalDiagnostic 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 66).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '30':
        contentView = (
          <Infonavit
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 67).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '31':
        contentView = (
          <ExternalInvestmentRegistration 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 68).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '32':
        contentView = (
          <Consulting 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 69).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break      
      case '33':
        contentView = (
          <ASAdvisory 
            history={history}
            ctrlTableID={id}
            language={language}
            catalogs={catalogs}
            permissions={permissionsASAdvisory}
            permissionsType={permissionsType}
          />
        )
        break
      default:
        break
    }

    return(
      <div>
        {contentView}
      </div>
    )
  }
}

export default ControlTableWrapper
