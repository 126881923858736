import { useEffect, useState } from 'react';
import moment from 'moment';
import { private_server } from '../../variables/config';
import { onGetErrorMessage, updateAuthorizationHeader } from '../../shared/utility';

const useHome = ({ history, userID, visitedUserID }) => {

	const [loading, setLoading] = useState(false);
	const [sending, setSending] = useState(false);
	const [error, setError] = useState(null);
	const [success, setSuccess] = useState(null);

	const [banners, setBanners] = useState([])
	const [users_bd, setUsersBd] = useState([])
	const [star_employees, setStarEmployees] = useState([])
	const [banner_selected, setBannerSelected] = useState(null)

	const [current_time, setCurrentTime] = useState(moment().format('LT a'))

	const [modals, setModals] = useState({
        add_banner:false,
        edit_banner:false
    })

	useEffect(() => {
		actions.onInitModule();
	}, []);

	useEffect(() => {
		var timer = setInterval(()=>setCurrentTime(moment().format('LT a')), 10000 )
        return function cleanup() {
            clearInterval(timer)
        }
	}, []);

	const current_date = moment().format('YYYY-MM')

	const evaluation_date = moment().subtract((moment().month%2==1)?2:3, 'month').format('YYYY-MM')
	//console.log(current_date)
	//console.log(evaluation_date)

	const actions = {
		onInitModule: async () => {
			setLoading(true);
			try {	
				updateAuthorizationHeader(private_server)
				const _banners = await private_server.get(`/homebanner/all?order=0&order_by=order`)
				//console.log(_banners.data.data.home_banners)
				setBanners(_banners.data.data.home_banners)
				const _bds = await private_server.get(`/user/birthdays?language_id=2&date=${current_date}&order=0&order_by=birth_date`)
				//console.log(_bds.data.data.employees)
				setUsersBd(_bds.data.data.employees)
				const _star_emp = await private_server.get(`/employeevaluation/top?language_id=2&date=${evaluation_date}&limit=3&offset=0`)
				//console.log(_star_emp.data.data.employee_evaluations)
				setStarEmployees(_star_emp.data.data.employee_evaluations)
				setLoading(false);
			} catch (error) {
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},	
		onUpdateBanners: async () =>{
			setLoading(true);
			try {	
				updateAuthorizationHeader(private_server)
				const _banners = await private_server.get(`/homebanner/all`)
				//console.log(_banners.data.data.home_banners)
				setBanners(_banners.data.data.home_banners)
			} catch (error) {
				setError(onGetErrorMessage(error));
				setLoading(false);
			}
		},
		onChangeModalStatus: (key, status) => {
            let _modals = {...modals}
            _modals[key] = status
            setModals(_modals)
        },
		onEditBanner: (_banner) =>{
			setBannerSelected(_banner)
			actions.onChangeModalStatus('edit_banner', true)
		},
		onSubmit: async () => {
			
		},
	};

	const system = { loading, sending, error, success };
	const view_data = { banners, users_bd, star_employees, current_time, banner_selected};
	

	return { system, view_data, actions, modals };
};



export default useHome;
