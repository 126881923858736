import React from 'react'

import SectionA from './StepsViews/SectionA/SectionA';
import SectionB from './StepsViews/SectionB/SectionB';
import SectionC from './StepsViews/SectionC/SectionC';
import SectionD from './StepsViews/SectionD/SectionD';

export const contentData = {
    spanish: {
        url: 'social_security',
        fixed_id: 'social_security_step_id',
        monthly_steps: [0],
        paths: {
            primary: {
                id: 'ctrl_social_security_id',
                path: 'ctrlsocialsecurity'
            },
            secondary: {
                id: 'ctrl_monthly_statement_id',
                path: 'ctrlmonthlystatements'
            }
        },
        sections: {
            general_info: {
                banner: {
                    content: {
                        business: 'Razón social',
                        fixed_rfc: 'RFC',
                        fixed_importance: 'Importancia',
                        fixed_client_classification: 'Clasificación',
                        fixed_client_status: 'Estatus',
                        fixed_client_responsible: 'Responsable',
                        fixed_client_associate: 'Socio'
                    }
                },
                fiscal: {
                    title: 'Seguro Social',
                    content: {
                        fixed_employer_enrollment: 'Registro Patronal',
                        fixed_state: 'Estado',
                        fixed_city: 'Ciudad',
                        fixed_user: 'Usuarios IDSE',
                        fixed_password: 'Contraseña IDSE',
                        fixed_sipare_user: 'Usuario SIPARE',
                        fixed_sipare_password: 'Contraseña SIPARE',
                        fixed_virtual_desktop_user: 'Usuario Escritorio Virtual',
                        fixed_virtual_desktop_password: 'Contraseña Escritorio Virtual',
                        fixed_risk_premium: 'Prima de Riesgo de Trabajo',
                    }
                },
                taxes: null,
                responsibles: {
                    title: 'Adicionales',
                    content: {
                        fixed_tax_payment_limit_date: 'Fecha Pago Impuestos',
                        fixed_imss_responsible: 'Responsable IMSS',
                        fixed_business_advisor: 'Asesor',
                        fixed_supervisor: 'Supervisor',
                        fixed_analyst: 'Analista',
                    }
                }
            },
            stepper_container: {
                top_banner: {
                    advisory: 1,
                    percent: '% Completado',
                },
                progress: 'Progreso',
                priority: 'Prioridad',
                steps: [
                    {
                        title: 'Elaboración Línea de Captura IMSS',
                        component: <SectionA />,
                    },
                    {
                        title: 'Envío Línea de Captura y Pago',
                        component: <SectionB />,
                    },
                    {
                        title: 'Consulta Opinión del Cumplimiento',
                        component: <SectionC />,
                    },
                    {
                        title: 'Revisión Final',
                        component: <SectionD />,
                    },
                ],
            }
        }
    }
}
