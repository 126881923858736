export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    edit:'Editar',
    convert:'Convertir a cliente',
    nospecialities:'No existen especialidades registradas',
    title:{
      sectionA:'',
      sectionB:'Información de registro',
      sectionC:'Información de contacto',
      sectionD:'Documentos',
      sectionE:'Beneficios',
      secttionF:'Permisos'
    },
    files:[
      {key:'pre_quotation',name:'Cotización',path:'prequotation', status:false},
      {key:'pre_observations_document',name:'Observaciones',path:'preobservations', status:false},
      {key:'contract',name:'Contrato',path:'contract', status:false},
    ],
    header:{title:'Archivos', actions:'Acciones'},
    sectionA:{
      name:{
        label:'Nombre'
      },
      pre_position:{
        label:'Puesto'
      },
      pre_language:{
        label:'Idioma'
      },
      pre_country:{
        label:'País'
      },
      pre_area:{
        label:'Área'
      },
      client_status:{
        label:'Estatus'
      },
      responsible:{
        label:'Responsable'
      }
    },
    sectionB:{
      classification:{
        label:'Clasificación'
      },
      pre_fiscal_regime:{
        label:'Régimen Fiscal'
      },
      pre_quotation:{
        label:'Cotización'
      },
      pre_contact_date:{
        label:'Fecha de contacto'
      },
      pre_contact_medium:{
        label:'Medio de contacto'
      },
      pre_required_date:{
        label:'Fecha requerida'
      },
      pre_end_date:{
        label:'Fecha de fin'
      },
      pre_observations:{
        label:'Observaciones'
      },
      pre_contact_name:{
        label:'Nombre de contacto'
      },
    },
    sectionC:{
      pre_work_email:{
        label:'Correo de trabajo'
      },
      pre_alternative_email:{
        label:'Correo alternativo'
      },
      pre_personal_email:{
        label:'Correo personal'
      },
      pre_office_phone_number:{
        label:'Oficina'
      },
      pre_cellphone:{
        label:'Celular'
      },
      pre_phone_number:{
        label:'Teléfono'
      },
    },
    sectionD:{
      speciality_id:{
        label:'Especialidad'
      },
      licese:{
        label:'Cédula Profesional'
      },
      ssa:{
        label:'S.S.A.'
      },
    }
  },
  
}