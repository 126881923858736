import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_on_key,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData
    ];

    let fixedDataB = [
        currentData.on_key_request_date ? moment(currentData.on_key_request_date).format('DD-MM-YYYY') : noData,
        currentData.on_key_estimated_delivery_date ? moment(currentData.on_key_estimated_delivery_date).format('DD-MM-YYYY') : noData,
        currentData.on_key_quoted_capital ? currentData.on_key_quoted_capital : noData,
        currentData.on_key_shareholder_number ? currentData.on_key_shareholder_number : noData,
        currentData.on_key_associate_type ? currentData.on_key_associate_type : noData,
        currentData.on_key_service_type ? currentData.on_key_service_type : noData,
        {key:'fiscal_address'},
        currentData.legal_representative ? currentData.legal_representative : noData
    ]

    let varData = [
        currentData.process_type ? currentData.process_type : noData,
        currentData.process_status ? currentData.process_status : noData,
        currentData.required_date ? moment(currentData.required_date).format('DD-MM-YYYY') : noData,
        {key:'recepit'},
        {key:'client_response'},
        currentData.client_comment ? currentData.client_comment : noData,
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        process_type_id: currentData.process_type_id,
        process_status_id: currentData.process_status_id,
        required_date: currentData.required_date,
        client_comment: currentData.client_comment,
        responsible_id: currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    tempB.files = [
        {id:currentData.id_ctrl_on_key,key:'fiscal_address',name:'Domicilio Legal',path:'fiscaladdress',
            status: checkStatus(currentData, 'fiscal_address')},
    ]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_on_key,key:'recepit',name:'Acuse',path:'recepit',
            status: checkStatus(currentData, 'recepit')},
        {id:currentData.id_ctrl_on_key,key:'client_response',name:'Respuesta Cliente',path:'clientresponse',
            status: checkStatus(currentData, 'client_response')},
        {id:currentData.id_ctrl_on_key,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_on_key,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
