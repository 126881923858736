import React, { useContext } from 'react';
import { SnackbarContext } from './SnackbarContext';
//

// ----------------------------------------------------------------------

export const useSnackbarContext = () => {
  const context = useContext(SnackbarContext);

  if (!context) throw new Error('SnackbarContext context must be use inside SnackbarProvider');

  return context;
};

