import React, {useEffect, useState} from 'react'
import moment from 'moment'
import {Grid} from '@material-ui/core'
import {withRouter} from 'react-router'
import { withStyles } from '@material-ui/core/styles';
import {cleanData2Table} from './customFunctions'
import { contentData } from './content'
import {MuiPickersUtilsProvider, DatePicker} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from "date-fns/locale/en-US";
import esLocale from "date-fns/locale/es";
/////// IMPORT MODULES //////////
import {request_service, 
  request_downloadFile,
} from './requests'
import ViewCardWrapper from '../../../../../components/Cards/ViewCardWrapper'
import ContentText from '../../../../../components/Texts/ContentText'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import SimpleTable from './components/SimpleTable'
import queryString from 'query-string' 

const localeMap = {
  spanish: esLocale,
  english: enLocale
};


const styles = theme => ({
  card:{
    margin: '20px',
    padding: '64px',
    background: 'white'
  }
})

const Logs = props => {

  const {language, businessID, service_path, ctrlTableIdLabel, catalogs} = props
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  //DATA
  const [service, setService] = useState(false)
  const [selected_document, setSelectedDocument] = useState(null)
  const [selectedYear, setSelectedYear] = useState(null)
  const content = contentData[language]

  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order:0})

  const getParams = () => {
    if(!selectedYear)
      return null
    const year = moment(selectedYear).get('year')
    const start_month = moment(new Date('01/01/'+year)).utc().set({hour:0,minute:0,second:0,millisecond:0}).format()
    const end_month = moment(new Date('12/31/'+year)).utc().set({hour:23,minute:59,second:59,millisecond:59}).format()
    return {start_month:start_month, end_month:end_month}
  }

  const filterByYear = async() => {
    try {
      setLoading(true)
      const {limit, offset, order_by, order} = tableFilter
      let {start_month, end_month} = getParams()
      const param2send = {limit:limit, offset:offset*limit, order:order}
      let params = `?start_month=${start_month}&end_month=${end_month}&${queryString.stringify(param2send)}`
      const _service = await request_service(businessID, service_path, params)
      let key = Object.keys(_service)[1]
      setService(_service[key])
      setTotal(_service.count)
    } catch (error) {
        console.log(error)
    }
    setLoading(false)
  }

  const onDownloadFile = async (data) => {
    try{
      setSending(true)
      await request_downloadFile(data.id, service_path, data.path)
      setSending(false)
    }catch(e){
      console.log(e)
      setError('No fue posible descargar el archivo')
    }
    setSending(false)
  }

  let ctrlTableData_list = []
  if(service) 
    ctrlTableData_list = cleanData2Table(service, content.nodata, ctrlTableIdLabel)

  return(
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[language]}>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item>
          <ViewCardWrapper>
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
            >
                <Grid item>
                    <ContentText text={'Año:'} align='none' medium/>
                </Grid>
                <Grid item>
                    <DatePicker
                        autoOk disableFuture
                        variant="inline"
                        views={["year"]}
                        value={selectedYear}
                        onChange={(date) => setSelectedYear(date)}
                    />
                </Grid>
                <Grid item>
                    <RoundedButton 
                        label={'Filtrar'} 
                        size='small' 
                        color='primary'
                        onClick={filterByYear}
                    />
                </Grid>
            </Grid>
        </ViewCardWrapper>
        </Grid>
        <Grid item>
          <LoadingContainer loading={loading} error={error} onCloseError={() => setError(null)}>
            <SimpleTable
              data={ctrlTableData_list} 
              tableFilter={tableFilter}  
              onUpdateTableFilter={(data) => setTableFilter(data)}
              onDownloadFile={onDownloadFile}
              total={total} 
              loading={loading}
            />
          </LoadingContainer>
        </Grid>
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

export default withRouter(withStyles(styles)(Logs))