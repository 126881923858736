export const contentData = {
  spanish:{
    button:'Editar',
    layout:{
      title:'Representante Legal',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        legal_representative:{
          label:'',
          data:'',
        },
        antiquity:{
          label:'',
          data:''
        },
        rfc:{
          label:'',
          data:''
        },
      },
      content:{
        legal_representative:{
          label:'Nombre',
        },
        antiquity:{
          label:'Antiguedad'
        },
        rfc:{
          label:'RFC'
        },
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'curp', name:'CURP', path:'curp', status:false, businessDocument:true},
          {key:'oficial_id',name:'Identificación oficial',path:'oficialid', status:false, businessDocument:true},
        ]
      }
    }
  }
}
