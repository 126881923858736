export const contentData = {
  spanish:{
    title:'Agregar timbrado',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      state_id:{
        label:'Estado',
        placeholder:'Estado',
      },
      city:{
        label:'Ciudad',
        placeholder:'Ciudad',
      },
      employer_enrollment:{
        label:'Registro Patronal',
        placeholder:'Registro Patronal',
      },
      user:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      tax_payment_limit_date:{
        label:'Fecha límite de pago',
        placeholder:'1,2,3,4,25',
      },
    },
  },
}