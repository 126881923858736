import { onGetDateFormat } from "../../../../../../../../shared/utility"
import { catalogs } from "../../../../../../../../texts/esp/catalogs"

export const cleanData2Table = (data, id, nodata, maxStep, location, type_period) => {

  let table = []
  
  if (data)
    table = data.map(item => {

      //const image = findImageResponsible(item.responsible_id, users)
      let step_id = null
      if (id !== 'stamping')
         step_id = item[`fixed_${id}_step_id`] ? item[`fixed_${id}_step_id`] : 1
      else
          step_id = item[`fixed_proc_${id}s_step_id`] ? item[`fixed_proc_${id}s_step_id`] : 1

      let priority = catalogs.process_priority.find(p => p.value === item.solution_priority)
      let percent = catalogs.process_percent[id].find(e => e.id === step_id)

      let data = {
        id: item[`id_proc_${id}`],
        step_id: step_id,
        business: item.business ? item.business : nodata,
        responsible: item.fixed_responsible ? item.fixed_responsible : nodata,
        fixed_responsible: item.fixed_responsible ?? nodata,
        fixed_payday_limit: item.fixed_payday_limit ? item.fixed_payday_limit : nodata,
        step: {
          id: step_id,
          color: step_id === maxStep ? 'primary' : 'secondary',
          label: id !== 'stamping' ? (item[`fixed_${id}_step`] ? item[`fixed_${id}_step`] : nodata) : (item[`fixed_proc_${id}s_step`] ? item[`fixed_proc_${id}s_step`] : nodata)
        },
        priority: priority,
        percent: percent.value,
        status_id: item.process_semaphore_id,
        // image: image,

        //nuevo
        importance: item.fixed_importance ? item.fixed_importance : nodata,
        fixed_payday_limit: item.fixed_payday_limit ? item.fixed_payday_limit : nodata,
        fixed_client_classification: item?.fixed_client_classification ?? nodata,
        fixed_client_responsible: item?.fixed_client_responsible ?? nodata,
        fixed_business_advisor: item?.fixed_business_advisor ?? nodata,
        fixed_client_associate: item?.fixed_client_associate ?? nodata
      }

      data.auto_date = item.auto_date ? onGetDateFormat(item.auto_date, 'MMMM, YY') : nodata
      if(id === 'stamping') data.auto_date = onGetDateFormat(item.auto_date, 'DD MMM, YY') ?? nodata

      if(location){
        data.fixed_state = item.fixed_state ? item.fixed_state : nodata
        data.fixed_city = item.fixed_city ? item.fixed_city : nodata
      }

      if(type_period){
        data.fixed_roster_type = item.fixed_roster_type ? item.fixed_roster_type : nodata
        data.fixed_ctrl_period_name = item.fixed_ctrl_period_name ? item.fixed_ctrl_period_name : nodata
        data.fixed_payroll_periodicity = item.fixed_payroll_periodicity ? item.fixed_payroll_periodicity : nodata
      }

      return data
    })

  return table
}
