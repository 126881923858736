import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withWidth } from '@material-ui/core';
import styles from './styles.jsx';
import classNames from 'classnames';
import {
  Drawer,
  Grid,
} from '@material-ui/core'

import MenuBar from './MenuBar/MenuBar.jsx';
import ascgBanner from '../../assets/company/30years.png'

class SideBar extends Component{
  toggleDrawer = event => {
    const {toggleDrawer} = this.props
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    toggleDrawer();
  };

  render(){
    
    const {width,classes, history, routes, notifications, open,onChange} = this.props;

    return(
      <div>
          {width === 'xs' || width === 'sm' ?
            <Drawer
              onClose={this.toggleDrawer}
              open={open}
              classes={{paper: classNames(classes.drawerPaper)}}
            >
              <Grid container>
                <Grid item>
                  <MenuBar 
                    history={history} 
                    routes={routes} 
                    notifications={notifications}
                    onClick={onChange}
                  />
                </Grid>
                
              </Grid>
            </Drawer>
            :
            <Drawer
              onClose={this.toggleDrawer}
              open={true}
              classes={{
                paper: classNames(classes.drawerPaper,{
                  [classes.drawerPaperClose]:!open
                })
              }}
              variant="persistent"
              
            >
              <Grid container>
                <Grid item>
                  <MenuBar 
                    history={history} 
                    routes={routes} 
                    notifications={notifications}
                    onClick={onChange}
                  />
                </Grid>
                
              </Grid>
            </Drawer>
          }
      </div>
    );
  }

  onOpenPlatform = (url) => {
    console.log('Abrir plataforma')
  }
}

export default withStyles(styles)(withWidth()(SideBar));
  