import { Grid, Icon, IconButton, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import React from 'react';

const useStyles = makeStyles(theme => ({
    root:{
        background:blueGrey[200],
        color:blueGrey[800],
        borderRadius:32,
        padding:'6px 12px',
        paddingLeft:16
    }
}))

const SingleFilterButton = ({id, label, onRemoveFilter}) => {

    const classes = useStyles()

    return ( 
        <div className={classes.root}>
            <Grid container alignItems='center'>
                <Grid item>{label}</Grid>
                <Grid item><IconButton size='small' onClick={() => onRemoveFilter(id)} style={{marginTop:3}}><Icon fontSize='small'>close</Icon></IconButton></Grid>
            </Grid>
        </div>
     );
}
 
export default SingleFilterButton;