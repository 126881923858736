export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        employee_number:{
          placeholder:'Número de trabajadores'
        },
        payment_date:{
          placeholder:'Fecha de Pago'
        },
        stamping_date:{
          placeholder:'Fecha de Timbrado'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
