import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetDateFormat, onGetTimeDuration } from '../../../../../../shared/utility';
import teamsIcon from '../../../../../../assets/icons/teams.png'
import asIcon from '../../../../../../assets/icons/as.PNG'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const ContactInfoForm = ({title, data}) => {

    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='phone' title='Teléfono' data={data ? data.phone_number : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='mail' title='Correo electrónico' data={data ? data.work_email : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='mail' title='Correo personal' data={data ? data.personal_email : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData image={teamsIcon} title='Usuario Microsoft' data={data ? data.gmail : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData image={asIcon} title='Usuario AS iViewer' data={data ? data.dropbox : null }/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default ContactInfoForm