export const contentData = {
  spanish:{
    layout:{
      title:'Información del usuario',
      navigation:[
        {name:'Usuarios',path:'/users'},
        {name:'Detalles',path:'/users/details'},
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    }
  }
}