import { amber, blue, cyan, lime, deepOrange, green, grey, indigo, orange, pink, purple, red, teal, yellow, lightBlue } from "@material-ui/core/colors";

export const catalogs = {
    areas: [
        { id: 1, value: 1, label: "Dirección General", color: indigo[300] },
        { id: 2, value: 2, label: "División Operativa", color: lightBlue[300] },
        { id: 3, value: 3, label: "División Internacional", color: indigo[400] },
        { id: 4, value: 4, label: "Consejo Familiar", color: lightBlue[400] },
        { id: 5, value: 5, label: "AS Advisory", color: indigo[500] },
        { id: 6, value: 6, label: "AS Asia Desk", color: lightBlue[500] },
        { id: 7, value: 7, label: "AS Auditoria Interna", color: indigo[600] },
        { id: 8, value: 8, label: "AS BPO", color: lightBlue[600] },
        { id: 9, value: 9, label: "AS Capital Humano", color: indigo[700] },
        { id: 10, value: 10, label: "AS Contabilidad", color: lightBlue[700] },
        { id: 11, value: 11, label: "AS Coordinación General", color: indigo[800] },
        { id: 12, value: 12, label: "AS Finanzas", color: lightBlue[800] },
        { id: 13, value: 13, label: "AS Health", color: indigo[900] },
        { id: 14, value: 14, label: "AS Impuestos", color: lightBlue[900] },
        { id: 15, value: 15, label: "AS Internacional", color: "#8c9eff" },
        { id: 16, value: 16, label: "AS Legal", color: "#80d8ff" },
        { id: 17, value: 17, label: "AS Marketing", color: "#536dfe" },
        { id: 18, value: 18, label: "AS News", color: "#40dc4ff" },
        { id: 19, value: 19, label: "AS Sector Financiero", color: "#3d5afe" },
        { id: 20, value: 20, label: "AS Sustentabilidad", color: '#00b0ff' },
        { id: 21, value: 21, label: "AS Technologies", color: '#304ffe' },
        { id: 22, value: 22, label: "AS Xpert", color: '#0091ea' },
        { id: 23, value: 23, label: "ASiViewer", color: '#448aff' },
        { id: 24, value: 24, label: "Omnium", color: '#2979ff' }
    ],
    levels: [
        { id: 1, value: 1, label: "Socio Fundador y Director", color: indigo[300] },
        { id: 2, value: 2, label: "Socio y Director", color: lightBlue[300] },
        { id: 3, value: 3, label: "Socio Minoritario", color: indigo[400] },
        { id: 4, value: 4, label: "Asociado", color: lightBlue[400] },
        { id: 5, value: 5, label: "Director", color: indigo[500] },
        { id: 6, value: 6, label: "Sub Director", color: lightBlue[500] },
        { id: 7, value: 7, label: "Gerente Senior", color: indigo[600] },
        { id: 8, value: 8, label: "Gerente Junior", color: lightBlue[600] },
        { id: 9, value: 9, label: "Senior", color: indigo[700] },
        { id: 10, value: 10, label: "Supervisor", color: lightBlue[700] },
        { id: 11, value: 11, label: "Analista Contable", color: indigo[800] },
        { id: 12, value: 12, label: "Staff 2", color: lightBlue[800] },
        { id: 13, value: 13, label: "Staff 1", color: indigo[900] },
        { id: 14, value: 14, label: "Becario", color: lightBlue[900] },
        { id: 15, value: 15, label: "Asesor", color: "#8c9eff" },
        { id: 16, value: 16, label: "Office Staff", color: "#80d8ff" },
        { id: 17, value: 17, label: "Asistente", color: "#536dfe" }
    ],
    statistics_levels: [
        { id: [12, 13, 16], value: 1, label: "Staff" },
        { id: 10, value: 2, label: "Senior Manager" },
        { id: 9, value: 3, label: "Senior" },
        { id: 7, value: 4, label: "Gerente Senior" },
        { id: 8, value: 5, label: "Gerente Junior" }
    ],
    statistics_areas: [
        { id: 17, value: 1, label: "AS Marketing" },
        { id: 14, value: 2, label: "AS Impuestos" },
        { id: 10, value: 3, label: "AS Contabilidad" },
        { id: 8, value: 4, label: "AS BPO" },
        { id: 5, value: 5, label: "AS Advisory & CS" }
    ],
    client_responsibles: [
        { id: 1, value: 1, label: 'Aceptado', color: green[700] },
        { id: 2, value: 2, label: 'No aceptado', color: red[700] },
        { id: 3, value: 3, label: 'En 0', color: pink[700] },
        { id: 4, value: 4, label: 'Sin impuestos a pagar', color: deepOrange[700] }
    ],
    process: [
        { id: 1, value: 1, label: 'Solución Integral' },
        { id: 2, value: 2, label: 'Personas Físicas' },
        { id: 3, value: 3, label: 'Seguro Social' },
        { id: 4, value: 4, label: 'Impuesto local sobre nómina' },
        { id: 5, value: 5, label: 'Timbrado y cálculo de nómina' },
    ],
    process_accounting_documents_statuses: [
        { id: 1, value: 1, label: 'Sí', color: green[700] },
        { id: 2, value: 2, label: 'No', color: red[700] },
        { id: 3, value: 3, label: 'No aplica', color: grey[500] },
    ],
    process_progress: [
        { id: 0, value: 0, label: 'Completo', color: green[700] },
        { id: 1, value: 1, label: 'No Completo', color: red[700] },
    ],
    process_responsibles: {
        integral_solution: [
            { values: [1, 3, 6, 8, 10, 11, 15], label: "AS Advisory", color: green[700] },
            { values: [2, 4, 5, 9, 12], label: "AS Contabilidad", color: red[700] },
            { values: [7, 13, 14], label: "AS Impuestos", color: red[700] },
        ],
        physical_person: [
            { values: [1, 3, 5, 8], label: "AS Advisory", color: green[700] },
            { values: [2, 4, 6, 7], label: "AS Impuestos", color: red[700] },
        ],
        social_security: [
            { values: [2, 4], label: "AS Advisory", color: green[700] },
            { values: [3], label: "AS Impuestos", color: red[700] },
            { values: [1], label: "AS BPO", color: red[700] },
        ],
        state_tax: [
            { values: [2, 3], label: "AS Advisory", color: green[700] },
            { values: [1], label: "AS BPO", color: red[700] },
        ],
        stamping: [
            { values: [1, 3, 4, 6, 7], label: "AS Advisory", color: green[700] },
            { values: [2, 5], label: "AS BPO", color: red[700] },
        ],
    },
    process_percent: {
        integral_solution: [
            { id: 1, value: 0 },
            { id: 2, value: 8 },
            { id: 3, value: 9 },
            { id: 4, value: 10 },
            { id: 5, value: 30 },
            { id: 6, value: 40 },
            { id: 8, value: 60 },
            { id: 7, value: 45 },
            { id: 9, value: 65 },
            { id: 10, value: 80 },
            { id: 11, value: 85 },
            { id: 12, value: 93 },
            { id: 13, value: 98 },
            { id: 14, value: 99 },
            { id: 15, value: 100 },
        ],
        physical_person: [
            { id: 1, value: 0 },
            { id: 2, value: 15 },
            { id: 3, value: 55 },
            { id: 4, value: 65 },
            { id: 5, value: 85 },
            { id: 6, value: 90 },
            { id: 7, value: 98 },
            { id: 8, value: 100 },
        ],
        social_security: [
            { id: 1, value: 0 },
            { id: 2, value: 70 },
            { id: 3, value: 90 },
            { id: 4, value: 100 },
        ],
        state_tax: [
            { id: 1, value: 0 },
            { id: 2, value: 80 },
            { id: 3, value: 100 },
        ],
        stamping: [
            { id: 1, value: 0 },
            { id: 2, value: 5 },
            { id: 3, value: 55 },
            { id: 4, value: 60 },
            { id: 5, value: 65 },
            { id: 6, value: 95 },
            { id: 7, value: 100 }
        ]
    },
    tax_mailbox_statuses: [
        { id: 0, value: 0, label: 'Negativa', color: red[700] },
        { id: 1, value: 1, label: 'Positiva', color: green[700] },
    ],
    process_status: [
        { value: 1, label: "Requiere Atención", color: yellow[700] },
        { value: 2, label: "En espera", color: red[700] },
        { value: 3, label: "Completado", color: green[700] },
    ],
    process_progress_status: [
        { id: 1, value: 1, label: 'No iniciada', color: grey[500] },
        { id: 2, value: 2, label: 'En proceso', color: blue[700] },
        { id: 3, value: 3, label: 'En revisión', color: amber[700] },
        { id: 4, value: 4, label: 'Completado', color: green[700] },
    ],
    process_priority: [
        { value: 1, label: "Urgente", color: red[700], icon: 'notifications_active' },
        { value: 2, label: "Importante", color: orange[700], icon: 'priority_high' },
        { value: 3, label: "Media", color: yellow[500], icon: 'fiber_manual_record' },
        { value: 4, label: "Baja", color: lime[700], icon: 'south' }
    ],
    task_priority: [
        { id: 1, value: 1, label: "Urgente", color: red[800]},
        { id: 2, value: 2, label: "Importante", color: orange[800]},
        { id: 3, value: 3, label: "Media", color: yellow[800]},
        { id: 4, value: 4, label: "Baja", color: lime[700]}
    ],
    task_status: [
        { id: 1, value: 1, label: "Pendiente", color: "#FF8A00"},
        { id: 2, value: 2, label: "En progreso", color: "#133975"},
        { id: 3, value: 3, label: "Bloqueado", color: "#3F3F4A"},
        { id: 4, value: 4, label: "En revisión", color: "#441375"},
        { id: 5, value: 5, label: "Aceptado", color: "#4B92F8"},
        { id: 6, value: 6, label: "Completado", color: "#71B053"},
        { id: 7, value: 7, label: "Rechazado", color: "#EA1313"}
    ],
    task_type: [
        { id: 1, value: 1, label: "Ordinaria", color: "#222222"},
        { id: 2, value: 2, label: "Extra", color: "#222222"},
        { id: 3, value: 3, label: "Pro-Bono", color: "#222222"}
    ],
    kpiTypes: [
        { key: 'AS Advisory', content: ['Tener contacto con cada uno de nuestros clientes al menos 1 vez cada mes', 'Contestar los correos electrónicos en menos de 24 horas'], target: ['Analizar, interpreta y comunicar de forma clara y sencilla los resultados obtenidos, así como crear valor que resulte en un impacto duradero para ellos', 'Asegurare que cada departamento interno cumpla con los entregables contables, físcales, consultas, timbrado de nóminas y cualquier otro servicio contratado en tiempo y forma pactada con el cliente', 'Aportar un punto de vista independiente sobre la correcta práctica contable, fiscal y legal', 'Atender las consultas y dudas del cliente en un lapso menor a 24 horas'] },
        { key: 'AS BPO', content: ['Tener el 100% de la nómina de nuestros clientes timbrada dentro del mes correspondiente', 'Asegurar que el 100% de las las opiniones del cumplimiento (IMSS) estén positivas cada mes'], target: ['Calcular la nómina y demás prestaciones relacionadas a la relación obrero - patronal de los clientes de manera correcta tomando en cuenta las disposiciones legales vigentes', 'Realizar los procedimientos ante las instruciones competentes(IMSS, Infonavit, Impuesto local) de manera oportuna y correcta', 'Ser un facilitador de información con los departamentos internos de ASCG', 'Atender las consultas y dudas del cliente en un lapso menor a 24 horas', 'Emitir facturas tomando en cuenta todos los lineamientos normativos actuales y las necesidades del cliente'] },
        { key: 'AS Contabilidad', content: ['Registrar el 100% de la informacion de los clientes, de manera detallada analítica y responsable en COI', 'Entregar el 100% de los reportes antes del día de vencimiento (calculo de impuestos, Estados Fiancieros, Reportes especiales)',], target: ['Quel 90% de los clientes califiquen como muy bueno nuestro servicio', 'Que el 80% de la información registrada en COI es 100% confiable', 'Que el 90% de nuestros clientes califiquen como muy bueno nuestro servico en cuanto a calidad, experiencia y profesionalismo', 'Que el 70% de los colaboradores sean capaces de realizar y responder cualquier tarea asignada'] },
        { key: 'AS Impuestos', content: ['Asegurar que el 100% de las Opiniones de Cumplimento estén Positivas cada mes', 'Cumplir con todas las obligaciones fiscales en tiempo y forma 100%'], target: ['Cumplir con todas las obligaciones fiscales en tiempo y forma 100%los impulsores de su negocio, basadas en el cumplimiento normativo vigente', 'Salvaguardar el cumpliemiento de las obligaciones fiscales de nuestros clientes', 'Crear ideas razonables en cada integrante del equipo para poder brindar soluciones críticas frente a la normativa vigente'] },
        { key: 'AS Marketing', content: ['Entregar el trabajo en tiempo y forma como se le solicitan', 'Ser creativo en las actividades que se realizan'], target: ['Crear contenido de valor en redes sociales de la empresa', 'Posicionamiento en redes sociales y mantenimiento de contenido digital en todos los canales de la marca', 'Responsable del desarrollo y ejecución de todas la iniciativas de marketing y publicidad dentro de la firma, las nuevas líneas de negocio desarrolladas o a desarrollar con el objetivo de aumentar las ventas'] },
        { key: 'AS Coordinación General', content: ['El tiempo de respuesta es menor a 24 horas una vez que se recibe un correo, mensaje o prospecto', 'Existe un 100% de seguimiento a las tareas de asignadas, prospectos y pendientes'], target: ['Mantener la responsabilidad, la confianza, credibilidad y fidelidad con nuestro cliente', 'Ser un facilitador en la orientación y atención del cliente', 'Contribuir con el flujo dinámico entre principales grupos internos del despacho'] },
        { key: 'AS Legal', content: ['Contestar los correos electrónicos y/o mensajes en menos de 24 horas', 'Existe un 100% de seguimiento a las tareas de asignadas, prospectos y pendientes	'], target: ['Brindar asesoramiento legal integral a nuestros clientes', 'Facilitar los procesos de inversión extranjera a nuestro país, realizando los procesos de constitución, legalización y tropicalización de compañías extranjeras en Méxicobajo el marco regulatorio vigente', 'Emitir una opinión sustentada, honesta y bajo la legalidad sobre los posibles riesgos legales de nuestros clientes'] },
        { key: 'AS Auditoria Interna', content: ['Realizar 6 auditorías de cumplimiento (contable, fiscal y laboral) al mes', 'Revisar las opiniones del cumplimiento del 75% de nuestros clientes'], target: ['Aportar un enfoque sistemático y disciplinado para evaluar y mejorar la eficacia de los procesos de gestión y control de cada departamento', 'Agrega valor a la firma dando recomendaciones para corregir las debilidades de control interno y para mejorar la eficacia de los procesos', 'Garantizar el correcto cumplimiento de las obligaciones tributarias de nuestros clientes para mitigar los posibles riesgos y contingencias fiscales de nuestros clientes'] }
    ],
    currencies:[
        { id: 1, value: 1, label: "Peso" },
        { id: 2, value: 2, label: "Dólar" },
    ],
    steps: [
        { value: 1, label: "Pendiente", color: amber[700] },
        { value: 2, label: "Autoevaluado", color: orange[700] },
        { value: 3, label: "Evaluado", color: purple[700] },
        { value: 4, label: "Rechazado", color: red[700] },
        { value: 5, label: "Aprobado", color: blue[700] },
        { value: 6, label: "Completado", color: green[700] }
    ],
    employee_statuses: [
        { id: 1, value: 1, label: "Empleado" },
        { id: 2, value: 2, label: "Baja temporal" },
        { id: 3, value: 3, label: "Baja definitiva" }
    ],
    employee_performance: [
        { value: 1, label: "Todas" },
        { value: 2, label: "Orientación de resultados" },
        { value: 3, label: "Calidad" },
        { value: 4, label: "Relaciones interpersonales" },
        { value: 5, label: "Iniciativa" },
        { value: 6, label: "Organización" },
    ],
    employee_performance_status: [
        { value: 1, label: "Muy bajo", color: red[700] },
        { value: 2, label: "Bajo", color: amber[700] },
        { value: 3, label: "Moderado", color: orange[700] },
        { value: 4, label: "Alto", color: blue[700] },
        { value: 5, label: "Muy Alto", color: green[700] }
    ],
    timereport_states: [
        { value: 1, label: 'No requiere supervisión', color: blue[700] },
        { value: 2, label: 'Pendiente de aprobación', color: amber[700] },
        { value: 3, label: 'Aprobado', color: green[700] },
        { value: 4, label: 'Rechazado', color: red[700] },
    ],
    timereport_statuses: [
        { value: 1, label: 'A tiempo', color: green[700] },
        { value: 2, label: 'Debajo del tiempo', color: red[700] },
        { value: 3, label: 'Overtime', color: blue[700] },
    ],
    timereport_tasks: [
        { value: 1, label: 'Trabajo Ordinario', color: blue[700] },
        { value: 2, label: 'Trabajo Extra (Horas extra)', color: purple[700] },
        { value: 3, label: 'Trabajo Pro-bono', color: teal[700] },
    ],
    client_classifications: [
        { value: 1, label: "A" },
        { value: 2, label: "AA" },
        { value: 3, label: "AAA" }
    ],
    client_user_positions: [
        { value: 1, label: "Dueño/Accionista" },
        { value: 2, label: "Consultor" },
        { value: 3, label: "Externo" },
        { value: 4, label: "Directivo" },
        { value: 5, label: "Supervisor" },
        { value: 6, label: "Gerente" },
        { value: 7, label: "Auxiliar" },
        { value: 8, label: "Secretaria" },
        { value: 9, label: "No especificado" }
    ],
    client_user_languages: [
        { value: 1, label: "Español" },
        { value: 2, label: "Inglés" },
        { value: 3, label: "Portugués" },
        { value: 4, label: "Chino" },
        { value: 5, label: "Alemán" },
        { value: 6, label: "Italiano" },
        { value: 7, label: "Coreano" },
        { value: 8, label: "Otros" }
    ],
    client_user_areas: [
        { value: 1, label: "Ventas" },
        { value: 2, label: "Atención a Clientes" },
        { value: 3, label: "Administración" },
        { value: 4, label: "Dirección General" },
        { value: 5, label: "Compras" },
        { value: 6, label: "Finanzas" },
        { value: 7, label: "Contabilidad" },
        { value: 8, label: "Legal" },
        { value: 9, label: "Otro" }
    ],
    client_statuses: [
        { value: 1, label: "Nuevo cliente", color: teal[700] },
        { value: 2, label: "Cliente anual", color: purple[700] },
        { value: 3, label: "Cortesía", color: cyan[700] },
        { value: 4, label: "Cliente", color: indigo[700] },
        { value: 5, label: "Suspendido por no pago", color: red[700] },
        { value: 6, label: "No interesado", color: pink[700] },
        { value: 7, label: "Suspensión de actividades", color: deepOrange[700] },
        { value: 8, label: "Rescindío del servicio", color: amber[700] },
        { value: 9, label: "Prospecto de cliente", color: blue[700] }
    ],
    client_states: [
        { value: 1, label: "Primer Contacto o Llamar" },
        { value: 2, label: "Envíar C.V. por email" },
        { value: 3, label: "Llamar y definir servicio interesado" },
        { value: 4, label: "Preparar cotización" },
        { value: 5, label: "Elaborar propuesta económica con autorización" },
        { value: 6, label: "Enviar propuesta económica" },
        { value: 7, label: "Agendar llamado o reunión con socio" },
        { value: 8, label: "Aceptación propuesta económica" },
        { value: 9, label: "Recabar información para contrato" },
        { value: 10, label: "Borrador de contrato" },
        { value: 11, label: "Firma de contrato" },
        { value: 12, label: "Proceso" },
        { value: 13, label: "Alta de servicio" },
        { value: 14, label: "Diagnóstico Fiscal y Legal" },
        { value: 15, label: "Cliente" },
        { value: 16, label: "Agendar Cita" },
        { value: 17, label: "Seguimiento de atención a clientes" }
    ],
    client_contact_mediums: [
        { value: 1, label: "Facebook" },
        { value: 2, label: "Página Web" },
        { value: 3, label: "Recomendación" },
        { value: 4, label: "Teléfono" },
        { value: 5, label: "Email directo" },
        { value: 6, label: "Revista" }
    ],
    client_restricted: [
        { id: 0, value: 0, label: "No" },
        { id: 1, value: 1, label: "Sí" },
    ],
    client_contract_durations: [
        { id: 1, value: 1, label: "Indefinido" },
        { id: 2, value: 2, label: "Más de 1 año" },
        { id: 3, value: 3, label: "Menor a 1 año" }
    ],
    client_contract_periodicities: [
        { id: 1, value: 1, label: "Mensual" },
        { id: 2, value: 2, label: "Anual" },
        { id: 3, value: 3, label: "Esporádico" },
        { id: 4, value: 4, label: "Bimestral" },
        { id: 5, value: 5, label: "Una vez" }
    ],
    client_who_charges_options: [
        { id: 1, value: 1, label: "AS Consultores" },
        { id: 2, value: 2, label: "SOS Contadores" },
        { id: 3, value: 3, label: "Javier Arreguín" },
        { id: 4, value: 4, label: "Luis Javier Arreguín" }
    ],
    countries: [
        { value: 1, label: "Afganistán" },
        { value: 2, label: "Albania" },
        { value: 3, label: "Argelia" },
        { value: 4, label: "Andorra" },
        { value: 5, label: "Angola" },
        { value: 6, label: "Antigua y Barbuda" },
        { value: 7, label: "Argentina" },
        { value: 8, label: "Armenia" },
        { value: 9, label: "Australia" },
        { value: 10, label: "Austria" },
        { value: 11, label: "Azerbaiyán" },
        { value: 12, label: "Bahamas" },
        { value: 13, label: "Baréin" },
        { value: 14, label: "Bangladés" },
        { value: 15, label: "Barbados" },
        { value: 16, label: "Bielorrusia" },
        { value: 17, label: "Bélgica" },
        { value: 18, label: "Belice" },
        { value: 19, label: "Benín" },
        { value: 20, label: "Bután" },
        { value: 21, label: "Bolivia" },
        { value: 22, label: "Bosnia-Herzegovina" },
        { value: 23, label: "Botsuana" },
        { value: 24, label: "Brasil" },
        { value: 25, label: "Brunéi" },
        { value: 26, label: "Bulgaria" },
        { value: 27, label: "Burkina Faso" },
        { value: 28, label: "Burundi" },
        { value: 29, label: "Camboya" },
        { value: 30, label: "Camerún" },
        { value: 31, label: "Canadá" },
        { value: 32, label: "Cabo Verde" },
        { value: 33, label: "República Centroafricana" },
        { value: 34, label: "Chad" },
        { value: 35, label: "Chile" },
        { value: 36, label: "China" },
        { value: 37, label: "Colombia" },
        { value: 38, label: "Comoras" },
        { value: 39, label: "Costa Rica" },
        { value: 40, label: "Costa de Marfil" },
        { value: 41, label: "Croacia" },
        { value: 42, label: "Cuba" },
        { value: 43, label: "Chipre" },
        { value: 44, label: "República Checa" },
        { value: 45, label: "República Democrática del Congo" },
        { value: 46, label: "Dinamarca" },
        { value: 47, label: "Yibuti" },
        { value: 48, label: "Dominica" },
        { value: 49, label: "República Dominicana" },
        { value: 50, label: "Timor Oriental" },
        { value: 51, label: "Ecuador" },
        { value: 52, label: "Egipto" },
        { value: 53, label: "El Salvador" },
        { value: 54, label: "Guinea Ecuatorial" },
        { value: 55, label: "Eritrea" },
        { value: 56, label: "Estonia" },
        { value: 57, label: "Etiopía" },
        { value: 58, label: "Fiyi" },
        { value: 59, label: "Finlandia" },
        { value: 60, label: "Francia" },
        { value: 61, label: "Gabón" },
        { value: 62, label: "Gambia" },
        { value: 63, label: "Georgia" },
        { value: 64, label: "Alemania" },
        { value: 65, label: "Ghana" },
        { value: 66, label: "Grecia" },
        { value: 67, label: "Granada" },
        { value: 68, label: "Guatemala" },
        { value: 69, label: "Guinea" },
        { value: 70, label: "Guinea-Bisáu" },
        { value: 71, label: "Guyana" },
        { value: 72, label: "Haití" },
        { value: 73, label: "Honduras" },
        { value: 74, label: "Hungría" },
        { value: 75, label: "Islandia" },
        { value: 76, label: "India" },
        { value: 77, label: "Indonesia" },
        { value: 78, label: "Irán" },
        { value: 79, label: "Irak" },
        { value: 80, label: "Irlanda" },
        { value: 81, label: "Israel" },
        { value: 82, label: "Italia" },
        { value: 83, label: "Jamaica" },
        { value: 84, label: "Japón" },
        { value: 85, label: "Jordania" },
        { value: 86, label: "Kazajistán" },
        { value: 87, label: "Kenia" },
        { value: 88, label: "Kiribati" },
        { value: 89, label: "Kuwait" },
        { value: 90, label: "Kirguistán" },
        { value: 91, label: "Laos" },
        { value: 92, label: "Letonia" },
        { value: 93, label: "Líbano" },
        { value: 94, label: "Lesoto" },
        { value: 95, label: "Liberia" },
        { value: 96, label: "Libia" },
        { value: 97, label: "Liechtenstein" },
        { value: 98, label: "Lituania" },
        { value: 99, label: "Luxemburgo" },
        { value: 100, label: "Madagascar" },
        { value: 101, label: "Malaui" },
        { value: 102, label: "Malasia" },
        { value: 103, label: "Maldivas" },
        { value: 104, label: "Malí" },
        { value: 105, label: "Malta" },
        { value: 106, label: "Islas Marshall" },
        { value: 107, label: "Mauritania" },
        { value: 108, label: "Mauricio" },
        { value: 109, label: "México" },
        { value: 110, label: "Micronesia" },
        { value: 111, label: "Moldavia" },
        { value: 112, label: "Mónaco" },
        { value: 113, label: "Mongolia" },
        { value: 114, label: "Montenegro" },
        { value: 115, label: "Marruecos" },
        { value: 116, label: "Mozambique" },
        { value: 117, label: "Birmania" },
        { value: 118, label: "Namibia" },
        { value: 119, label: "Nauru" },
        { value: 120, label: "Nepal" },
        { value: 121, label: "Países Bajos" },
        { value: 122, label: "Nueva Zelanda" },
        { value: 123, label: "Nicaragua" },
        { value: 124, label: "Níger" },
        { value: 125, label: "Nigeria" },
        { value: 126, label: "Corea del Norte" },
        { value: 127, label: "Noruega" },
        { value: 128, label: "Omán" },
        { value: 129, label: "Pakistán" },
        { value: 130, label: "Palaos" },
        { value: 131, label: "Panamá" },
        { value: 132, label: "Papúa Nueva Guinea" },
        { value: 133, label: "Paraguay" },
        { value: 134, label: "Perú" },
        { value: 135, label: "Filipinas" },
        { value: 136, label: "Polonia" },
        { value: 137, label: "Portugal" },
        { value: 138, label: "Catar" },
        { value: 139, label: "República del Congo" },
        { value: 140, label: "República de Macedonia" },
        { value: 141, label: "Rumania" },
        { value: 142, label: "Rusia" },
        { value: 143, label: "Ruanda" },
        { value: 144, label: "San Cristóbal y Nieves" },
        { value: 145, label: "Santa Lucía" },
        { value: 146, label: "San Vicente y las Granadinas" },
        { value: 147, label: "Samoa" },
        { value: 148, label: "San Marino" },
        { value: 149, label: "Sao Tomé y Príncipe" },
        { value: 150, label: "Arabia Saudita" },
        { value: 151, label: "Senegal" },
        { value: 152, label: "Serbia" },
        { value: 153, label: "Seychelles" },
        { value: 154, label: "Sierra Leona" },
        { value: 155, label: "Singapur" },
        { value: 156, label: "Eslovaquia" },
        { value: 157, label: "Eslovenia" },
        { value: 158, label: "Islas Salomón" },
        { value: 159, label: "Somalia" },
        { value: 160, label: "Sudáfrica" },
        { value: 161, label: "Corea del Sur" },
        { value: 162, label: "Sudán del Sur" },
        { value: 163, label: "España" },
        { value: 164, label: "Sri Lanka" },
        { value: 165, label: "Sudán" },
        { value: 166, label: "Surinam" },
        { value: 167, label: "Suazilandia" },
        { value: 168, label: "Suecia" },
        { value: 169, label: "Suiza" },
        { value: 170, label: "Siria" },
        { value: 171, label: "Tayikistán" },
        { value: 172, label: "Tanzania" },
        { value: 173, label: "Tailandia" },
        { value: 174, label: "Togo" },
        { value: 175, label: "Tonga" },
        { value: 176, label: "Trinidad y Tobago" },
        { value: 177, label: "Túnez" },
        { value: 178, label: "Turquía" },
        { value: 179, label: "Turkmenistán" },
        { value: 180, label: "Tuvalu" },
        { value: 181, label: "Uganda" },
        { value: 182, label: "Ucrania" },
        { value: 183, label: "Emiratos Árabes Unidos" },
        { value: 184, label: "Reino Unido" },
        { value: 185, label: "Estados Unidos" },
        { value: 186, label: "Uruguay" },
        { value: 187, label: "Uzbekistán" },
        { value: 188, label: "Vanuatu" },
        { value: 189, label: "Venezuela" },
        { value: 190, label: "Vietnam" },
        { value: 191, label: "Yemen" },
        { value: 192, label: "Zambia" },
        { value: 193, label: "Zimbabue" }
    ],
    worker_limits: [
        { id: 1, value: 1, label: "1, 30" },
        { id: 2, value: 2, label: "31-60" },
        { id: 3, value: 3, label: "61-90" },
        { id: 4, value: 4, label: "91-120" },
        { id: 5, value: 5, label: "121-150" },
        { id: 6, value: 6, label: "151-180" },
        { id: 7, value: 7, label: "181 - 210" },
        { id: 8, value: 8, label: "211 - …" },
    ],
    business_statuses: [
        { id: 1, value: 1, label: "No iniciado", color: red[700] },
        { id: 2, value: 2, label: "En progreso", color: blue[700] },
        { id: 3, value: 3, label: "Retrasado", color: amber[700] },
        { id: 4, value: 4, label: "Completado", color: green[700] }
    ],
    business_fiscal_regimes: [
        { id: 1, value: 1, label: "Persona Física" },
        { id: 2, value: 2, label: "Persona Moral" },
        { id: 3, value: 3, label: "Sin Obligaciones" },
        { id: 4, value: 4, label: "N/A" }
    ],
    /////////////////////////////////////
    accounting_methods: [
        { id: 1, value: 1, label: "Régimen General" },
        { id: 2, value: 2, label: "RESICO" },
        { id: 3, value: 3, label: "Maquiladoras" },
        { id: 4, value: 4, label: "AGAPES" },
        { id: 5, value: 5, label: "No lucrativos" },
        { id: 6, value: 6, label: "Mutualidad" },
        { id: 7, value: 7, label: "Otros" }
    ],
    accounting_documents_statuses: [
        { id: 1, value: 1, label: 'Sí', color: green[700] },
        { id: 2, value: 2, label: 'No', color: red[700] },
        { id: 3, value: 3, label: 'No aplica', color: grey[500] },
    ],
    accounting_information_upload_statuses: [
        { id: 1, value: 1, label: 'ASiViewer', color: blue[700] },
        { id: 2, value: 2, label: 'Sharepoint', color: teal[700] },
        { id: 3, value: 3, label: 'Servidor', color: purple[700] },
        { id: 4, value: 4, label: 'Sistema del cliente', color: cyan[700] },
    ],

    accounting_register_statuses: [
        { id: 1, value: 1, label: 'Completo', color: green[700] },
        { id: 0, value: 0, label: 'No Completo', color: red[700] },
    ],
    ctrl_boolean_responses: [
        { id: 1, value: 1, label: 'Sí', color: green[700] },
        { id: 0, value: 0, label: 'No', color: red[700] },
    ],
    ctrl_compliance_opinion_statuses: [
        { id: 1, value: 1, label: 'Positiva', color: green[700] },
        { id: 2, value: 2, label: 'Negativa', color: red[700] },
    ],
    ctrl_process_statuses: [
        { id: 1, value: 1, label: 'No iniciada', color: grey[500] },
        { id: 2, value: 2, label: 'Recolección de Datos', color: teal[700] },
        { id: 3, value: 3, label: 'En Proceso', color: blue[700] },
        { id: 4, value: 4, label: 'Aplazada', color: amber[700] },
        { id: 5, value: 5, label: 'Completado', color: green[700] },
    ],
    ctrl_declaration_period: [
        { id: 1, value: 1, label: "Del ejercicio" },
        { id: 2, value: 2, label: "Del ejercicio por dictámen" },
        { id: 3, value: 3, label: "Del ejercicio por corrección" },
        { id: 4, value: 4, label: "Del ejercicio por auditoría" }
    ],
    ctrl_declaration_type: [
        { id: 1, value: 1, label: "Tipo" },
        { id: 2, value: 2, label: "Normal" },
        { id: 3, value: 3, label: "Complementaria" },
        { id: 4, value: 4, label: "Sin obligaciones" }
    ],
    ctrl_mailbox_statuses: [
        { id: 1, value: 1, label: 'No iniciada', color: grey[500] },
        { id: 2, value: 2, label: 'Recolección de Datos', color: teal[700] },
        { id: 3, value: 3, label: 'En Proceso', color: blue[700] },
        { id: 4, value: 4, label: 'Aplazada', color: amber[700] },
        { id: 5, value: 5, label: 'Completado', color: green[700] },
    ],
    ctrl_classes: [
        { id: 1, value: 1, label: "I" },
        { id: 2, value: 2, label: "II" },
        { id: 3, value: 3, label: "III" },
        { id: 4, value: 4, label: "IV" },
        { id: 5, value: 5, label: "V" },
        { id: 6, value: 6, label: "VI" },
        { id: 7, value: 7, label: "VII" }
    ],
    ctrl_information_upload_status: [
        { id: 1, value: 1, label: "Int." },
        { id: 2, value: 2, label: "ASiViewer" },
        { id: 3, value: 3, label: "Servidor" },
    ],
    supervisor_statuses: [
        { value: 1, label: "Autorizado", color: green[700] },
        { value: 2, label: "Aplazado", color: purple[700] },
        { value: 3, label: "Ajuste para no pagar", color: cyan[700] },
        { value: 4, label: "En espera de autorización", color: indigo[700] },
        { value: 5, label: "Sin documentos", color: amber[700] },
        { value: 6, label: "En 0", color: pink[700] },
        { value: 7, label: "Espera de documentos", color: deepOrange[700] },
        { value: 8, label: "No autorizado", color: red[700] },
        { value: 9, label: "En corrección por errores de codificado", color: teal[700] }
    ],
    activities: [
        { id: 1, value: 1, label: "Actividades del gobierno y de organismos internacionales y extraterritoriales" },
        { id: 2, value: 2, label: "Agricultura, ganadería, aprovechamiento forestal, pesca y caza" },
        { id: 3, value: 3, label: "Alquiler de bienes muebles, muebles e intangibles" },
        { id: 4, value: 4, label: "Asociaciones Religiosas" },
        { id: 5, value: 5, label: "Asociaciones Sin Fines de Lucro" },
        { id: 6, value: 6, label: "Comercio al por mayor venta extranjero" },
        { id: 7, value: 7, label: "Comercio al por mayor venta nacional" },
        { id: 8, value: 8, label: "Comercio al por menor venta extranjero" },
        { id: 9, value: 9, label: "Comercio al por menor venta nacional" },
        { id: 10, value: 10, label: "Comisionista, agencia de representación y regalias" },
        { id: 11, value: 11, label: "Construcción" },
        { id: 12, value: 12, label: "Desarrollo de Nuevas Tecnologías" },
        { id: 13, value: 13, label: "Dirección de corporativos y empresas" },
        { id: 14, value: 14, label: "Electricidad, agua y suministro de gas por ductos al consumidor final" },
        { id: 15, value: 15, label: "Fabricación, Producción o Elaboración" },
        { id: 16, value: 16, label: "Industrias manufactureras" },
        { id: 17, value: 17, label: "Información en medios masivos" },
        { id: 18, value: 18, label: "Minería" },
        { id: 19, value: 19, label: "Otro no especificado" },
        { id: 20, value: 20, label: "Servicios de Administración del personal" },
        { id: 21, value: 21, label: "Servicios de alojamiento temporal y de preparación de alimentos y bebidas" },
        { id: 22, value: 22, label: "Servicios de apoyo a los negocios y manejo de desechos y servicios de remediación" },
        { id: 23, value: 23, label: "Servicios de asistencia social" },
        { id: 24, value: 24, label: "Servicios de esparcimiento culturales y deportivos, y otros servicios recreativos" },
        { id: 25, value: 25, label: "Servicios de salud" },
        { id: 26, value: 26, label: "Servicios educativos" },
        { id: 27, value: 27, label: "Servicios financieros y de seguros" },
        { id: 28, value: 28, label: "Servicios inmobiliarios" },
        { id: 29, value: 29, label: "Servicios profesionales, científicos y técnicos" },
        { id: 30, value: 30, label: "Sin Obligaciones" },
        { id: 31, value: 31, label: "Transportes, correos y almacenamiento" }
    ],
    sub_activities: [
        { id: 1, value: 1, label: "Persona Física con Actividad Empresarial" },
        { id: 2, value: 2, label: "Persona Física con Servicios Profesionales" },
        { id: 3, value: 3, label: "Persona Física con Arrendamiento" },
        { id: 4, value: 4, label: "Persona Física por el Regímen de Incorporación Fiscal" },
        { id: 5, value: 5, label: "Persona Física con Sueldos y Salarios" },
        { id: 6, value: 6, label: "Persona Física con Dividendos" },
        { id: 7, value: 7, label: "Persona Física con Intereses" },
        { id: 8, value: 8, label: "Persona Física con Actividad Primaria" },
        { id: 9, value: 9, label: "Persona Física Enajenación" },
        { id: 10, value: 10, label: "No Contribuyente" },
        { id: 11, value: 11, label: "Persona Física con Otros" },
        { id: 12, value: 12, label: "Persona Moral del Regimen General" },
        { id: 13, value: 13, label: "Persona Moral Sociedad Civil" },
        { id: 14, value: 14, label: "Persona Moral Asociación Civil" },
        { id: 15, value: 15, label: "Persona Moral Donataria Autorizada" },
        { id: 16, value: 16, label: "Persona Moral de Coordinados" },
        { id: 17, value: 17, label: "No Contribuyente" },
        { id: 18, value: 18, label: "Persona Moral de Otros" },
        { id: 19, value: 19, label: "Persona Moral No Residente en México" }
    ],
    services: [
        { value: 1, label: "Impuesto local sobre nómina" },
        { value: 2, label: "Facturación" },
        { value: 3, label: "Seguro social mensual" },
        { value: 4, label: "Timbrado y cálculo de nómina semanal, quincen" },
        { value: 5, label: "Contabilidad Mensual" },
        { value: 6, label: "Administración de la tesorería" },
        { value: 7, label: "Prevención en Lavado de Dinero" },
        { value: 8, label: "Publicidad" },
        { value: 9, label: "Outsourcing" },
        { value: 10, label: "Desarrollo tecnológico" },
        { value: 11, label: "Servicios legales" },
        { value: 12, label: "Auditoría" },
        { value: 13, label: "Constitución de Empresas Socios Nacionales" },
        { value: 14, label: "Llave en Mano" },
        { value: 15, label: "Declaraciones mensuales (impuestos e informat" },
        { value: 16, label: "SATIC o SIROC" },
        { value: 17, label: "Actualización Fiscal y Contable" },
        { value: 18, label: "Declaración anual físicas " },
        { value: 19, label: "Declaración anual Riesgo de Trabajo" },
        { value: 20, label: "Declaración anual A.C." },
        { value: 21, label: "Declaración anual moral" },
        { value: 22, label: "AS iViewer" },
        { value: 23, label: "Apertura de Cuenta Bancaria" },
        { value: 24, label: "Inscripción al IMSS" },
        { value: 25, label: "Inscripción al SAT Persona Moral" },
        { value: 26, label: "Inscripción al SAT Persona Física" },
        { value: 27, label: "Inscripción registro estatal de contribuyente" },
        { value: 28, label: "Trámite o Renovación E.firma" },
        { value: 29, label: "Diagnóstico Fiscal" },
        { value: 30, label: "Infonavit" },
        { value: 31, label: "Registro en inversión Extranjera" },
        { value: 32, label: "Consultoría" },
        { value: 33, label: "Certificado Digital para Facturar" }
    ],

    payroll_periodicity: [
        { value: 1, label: "Mensual" },
        { value: 2, label: "Semanal" },
        { value: 3, label: "Quincenal" },
    ],

    payroll_systems: [
        { value: 1, label: "NOI" },
        { value: 2, label: "SICOFI" },
        { value: 3, label: "Plataforma del Cliente" },
        { value: 4, label: "Otro" },
    ],

    roster_types: [
        { value: 1, label: "Mixta" },
        { value: 2, label: "Hass" },
    ],
    bonus:[
        { value: 1, label: "Creado", color: '#133975' },
        { value: 2, label: "Rechazado", color: '#FF0000' },
        { value: 3, label: "Aprobado", color: '#4B92F8' },
        { value: 4, label: "Pagado", color: '#34A84E' },
    ]


}