import { Grid, makeStyles, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import InputFile from '../../../../../../components_v1/Forms/InputFile';
import InputRating from '../../../../../../components_v1/Forms/InputRating';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../../shared/utility';
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { request_add_prospect, request_employees } from '../../requests';
import {formData} from './prospectdata/data'
import {contentData} from './prospectdata/content'
import {catalog_matrix} from './prospectdata/catalog_matrix'
import {onInitEmptyForm} from '../../../../../../shared/utility'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const prepareData = (users) => {
    let usersData = [];
  
    if(users){
      usersData = users.map((item) => {
        let firstname = item.first_name ? item.first_name : '';
        let lastname = item.last_name ? item.last_name : ''; 
        return{
          value: item.id_user,
          label: firstname + ' ' + lastname,
          image: item.image
        }
      })
    }
  
    return usersData;
}

const AddProspectModal = (props) => {

    const classes = useStyles()
    const { open, onClose, history, language } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const content = contentData[language]

    useEffect(() => {
        if (!open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitEmptyForm(_form, content.form)
            // Update catalogs
            catalog_matrix.forEach(item => {
                if(item.catalog_key !== 'none'){
                    _form[item.form_key].options = catalogs[item.catalog_key]
                }else{
                    const aux_catalog = prepareData(users)
                    _form[item.form_key].options = aux_catalog
                }
            })
            setForm({..._form})
            setError(''); setLoading(false);
        }
    }, [open,users])

    useEffect(() => {
        const init = async() => {
            try{
                setLoading(true)
                let _users = await request_employees()
                setUsers([..._users])
            }catch(error){
                console.log(error)
            }
            setLoading(false)
        }
        init()
    },[])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetFormData(form)
        //Send data
        setLoading(true)
        
        try {
            let client_id = await request_add_prospect(data2send);
            onClose()
            history.push(`/prospects/details/${client_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.name} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.pre_position_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.pre_language_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.pre_area_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.pre_country_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.client_status_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.client_state_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.pre_contact_name} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputSelect data={form.pre_responsible_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddProspectModal;