export const contentData = {
  spanish:{
    nodata:'-',
    notable:'No existen usuarios IDSE registrados',
    tableTitle:'Usuarios IDSE',
    header:['ID','Estado','Ciudad','Registro Patronal','Usuario IDSE','Clave IDSE','IMSS PFX','IMSS KEY','IMSS REC',
    'Usuario SIPARE','Contraseña SIPARE','Usuario Escritorio Virtual','Contraseña Escritorio Virtual','SIPARE',
    'Tarjeta Patronal','Fecha Límite IMSS','Clave Digital IMSS','Fecha de Caducidad','Prima del Riesgo del Trabajo',
    'Base de Datos','Número de Trabajadores','Opinión del Cumplimiento IMSS','Fecha límite de pago'],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      cerl:{
        name:'Certificado eFirma',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
  },
  english:{

  }
}
