export const formData = {
    pre_office_phone_number:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_office_phone_number',
        type:'number',
        fullWidth: true,
        icon:{
          name:'phone',
          position:'start'
        },
      },
      rules:{
        type:'distance',
        min:10,
        max:100
      }
    },
    pre_cellphone:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_cellphone',
        type:'number',
        fullWidth: true,
        icon:{
          name:'phone',
          position:'start'
        },
        position:'start'
      },
      rules:{
        type:'distance',
        min:10,
        max:100
      }
    },
    pre_phone_number:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_phone_number',
        type:'number',
        fullWidth: true,
        icon:{
          name:'phone',
          position:'start'
        },
        position:'start'
      },
      rules:{
        type:'distance',
        min:10,
        max:100
      }
    },
    pre_work_email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_work_email',
        type:'email',
        fullWidth: true,
        icon:{
          name:'mail',
          position:'start'
        },
        position:'start'
      },
      rules:{
        type:'email',
      }
    },
    pre_alternative_email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_alternative_email',
        type:'email',
        fullWidth: true,
        icon:{
          name:'mail',
          position:'start'
        },
        position:'start'
      },
      rules:{
        type:'email',
      }
    },
    pre_personal_email:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'pre_personal_email',
        type:'email',
        fullWidth: true,
        icon:{
          name:'mail',
          position:'start'
        },
        position:'start'
      },
      rules:{
        type:'email',
      }
    },
  }