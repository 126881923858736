export const contentData = {
  spanish:{
    title:'Editar auditoría',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      rfc:{
        label:'RFC',
        placeholder:'RFC'
      },
      name:{
        label:'Nombre',
        placeholder:'Nombre'
      },
      requirements:{
        label:'Requisitos Técnicos',
        placeholder:'Requisitos Técnicos'
      },
      due_date:{
        label:'Fecha Requerida',
        placeholder:'Fecha Requerida'
      },
      process:{
        label:'Proceso',
        placeholder:'Proceso'
      },
      periodicity_id:{
        label:'Periodicidad',
        placeholder:'Periodicidad'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      },
      estimated_delivery_date:{
        label:'Fecha de Entrega Estimada',
        placeholder:'Fecha de Entrega Estimada'
      },
      comments:{
        label:'Comentarios',
        placeholder:'Comentarios'
      },
      project_costs:{
        label:'Costo del Proyecto',
        placeholder:'Costo del Proyecto'
      },
      provider:{
        label:'Proveedor',
        placeholder:'Proveedor'
      },
      cost:{
        label:'Costo',
        placeholder:'Costo'
      },
      sell_price:{
        label:'Precio de Venta',
        placeholder:'Precio de Venta'
      }
    }
  }
}
