import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import { onGetUserID } from '../../../shared/utility';
import SimpleTable from './components/SimpleTable';
import ToolbarTable from './components/ToolbarTable';
import { request_pending_timereports, request_rejected_timereports } from './requests';
import UserStatisticsView from './views/UserStatisticsView';
import queryString from 'query-string' 
import BusinessStatisticsView from './views/BusinessStatisticsView';

const TimeReportsW = (props) => {

    const {history} = props
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [addtimereportmodal, setAddtimereportmodal] = useState(false)
    const [selected, setSelected] = useState(1) // 1. General View    2. Approved view  3. Rejected  4. Supervisados

    useEffect(() => {
        const initModule = async() => {
            const args = queryString.parseUrl(history.location.search)
            const {view} = args.query
            if(view) setSelected(parseInt(view))
        }
        initModule()
    },[])

    const onChangeView = (id) => {
        console.log('cambiar vista')
        const urlwithargs = `?view=${id}`
        window.history.replaceState(null, null, urlwithargs);
        setSelected(id)
    }


    const menuOptions = [
        {id:1, label:'Por usuario',},
        {id:2, label:'Por negocio'},
    ]


    return ( 
        <div>
            <BasicView 
                title='Estadísticas - Time Reports' 
                error={error} 
                onCloseError={() => setError(null)} 
                loading={loading} 
                setReturn
                success={success} 
                onCloseSuccess={() => setSuccess(null)}
            >
                {/*<AddTimeReportModal open={addtimereportmodal} onClose={() => setAddtimereportmodal(false)} history={history}/>*/}
                <ToolbarTable selected={selected} onChange={onChangeView} menuOptions={menuOptions} 
                onAddRegister={() => setAddtimereportmodal(true)}/>
                <div style={{paddingTop:32}}>
                    {selected === 1 ? <UserStatisticsView history={history} />
                    : <BusinessStatisticsView history={history} />}
                </div>

            </BasicView>
        </div> 
     );
}

export default TimeReportsW