import React from 'react';
import { Grid, Icon, IconButton, makeStyles} from '@material-ui/core';
import { blue, grey, red } from '@material-ui/core/colors';
import DisplayText from '../Texts/DisplayText';
import { PERMISSION_TYPES } from '../../variables/config';
import cx from 'classnames'

const ServiceElement = ({ onDelete, onSelected, service, permission, service_id}) => {

    const classes = useStyles()

    let perm = {not_allowed:false, read:false, edit:false, create:false, delete:false}

    if(permission && permission.permission_type_id === PERMISSION_TYPES.NO_ACCESS){
      perm.not_allowed = true
    }else{
      if(permission && permission.permission_type_id >= PERMISSION_TYPES.READ) perm.read = true 
      if(permission && permission.permission_type_id >= PERMISSION_TYPES.EDIT) perm.edit = true 
      if(permission && permission.permission_type_id >= PERMISSION_TYPES.CREATE) perm.create = true 
      if(permission && permission.permission_type_id >= PERMISSION_TYPES.FULL_ACCESS) perm.delete = true   
    }
  
    return(
      <div className={classes.container}>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item xs>
            <div className={cx({
              [classes.subcontainer]:true,
              [classes.subcontainer_disabled]: perm.not_allowed
            })} onClick={() => !perm.not_allowed ? onSelected(service_id) : undefined}>
              <Grid container spacing={1}>
                <Grid item>
                  <Icon className={classes.folder}>book</Icon>
                </Grid>
                <Grid item xs>
                  <DisplayText variant='body2' className={classes.name}>{service}</DisplayText>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item>
            <IconButton disabled={!perm.delete} className={classes.customHoverFocus} onClick={() => onDelete(service_id)}>
              <Icon fontSize='small'>close</Icon>
            </IconButton>
          </Grid>
        </Grid>
      </div>
    )
}

const useStyles = makeStyles(theme => ({
  card:{borderRadius:'16px'},
    container:{padding:'8px', paddingLeft:'8px', borderTop:`1px solid ${grey[200]}`},
    folder:{color:blue[700]},
    name:{},
    subcontainer:{cursor:'pointer'},
    subcontainer_disabled:{
      opacity:0.6, cursor:'not-allowed'
    },
    customHoverFocus: {
      color:red[700]
    }
}))


export default ServiceElement