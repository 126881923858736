import React from 'react'
import { Card, Checkbox, LinearProgress, Paper, styled, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";
import { blueGrey, grey } from '@material-ui/core/colors';
import DisplayText from '../../../Texts/DisplayText';
import { onGetDateFormat, onGetFullname } from '../../../../shared/utility';
import ActionBar from './ActionBar';
import EmployeePayrollActionBar from './EmployeePayrollActionBar';
import NoTableData from '../../NoTableData';
import { fCurrency, fNumber } from '../../../../utils/formatNumber';

const EmployeePayrollTable = ({
    loading,
    header=[],
    data=[],
    total,
    tableFilter,
    onUpdateTablePagination,
    restricted,
    onSelected,
    year, 
    years,
    month, 
    months,
    onChangeFilter,
    onRequestSearch,
}) => {

    return ( 
        <>
            <EmployeePayrollActionBar 
                year={year} years={years}
                month={month} months={months}
                onChangeFilter={onChangeFilter}
                onRequestSearch={onRequestSearch}
            />
            {loading && <LinearProgress style={{marginBottom:8}}/>}
            <StyledPaper>
                <StyledTable>
                    {header && (
                        <TableHead>
                            <TableRow>
                                {header.map((item, key) =>{
                                    return(
                                        <StyledTableCell key={`payroll-cell-${key}`} style={{background:blueGrey[100], padding:'8px 12px'}}>
                                            <DisplayText variant='subtitle2' >{item?.label ?? '-'}</DisplayText>
                                        </StyledTableCell>
                                    )
                                })}
                            </TableRow>
                        </TableHead>
                    )}
                    <TableBody>
                        {data.map((item, key) => {
                            
                            const name = onGetFullname(item?.user_first_name, item?.user_last_name)
                            const date = onGetDateFormat(item?.date, 'DD MMM YY')

                            return(
                                <StyledTableRow key={`payroll-row-${key}`} onClick={() => onSelected(item)} >
                                    <StyledTableCell key={`payroll-cell-${key}`}>
                                        <DisplayText variant='subtitle2' noWrap >{item?.id_employee_payroll ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText variant='subtitle2' noWrap>{date ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell style={{minWidth:100}} >
                                        <DisplayText variant='subtitle2' >{item?.rfc ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell style={{minWidth:175}} >
                                        <DisplayText variant='subtitle2' >{name ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText variant='subtitle2' >{item?.user_level ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText variant='subtitle2' >{ item?.area ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{ fNumber((item?.extra_minutes ?? 0)/60, '0.00')}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{fNumber((item?.pro_minutes ?? 0)/60, '0.00') }</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{item?.reported_minutes/60 ?? '-'} / {item?.expected_minutes/60 ?? '-'}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{fCurrency(item?.gross_base ?? 0.00)}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{fCurrency(item?.gross_calculated ?? 0.00)}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <DisplayText  variant="body2" >{fCurrency(item?.gross_to_pay ?? 0.00)}</DisplayText>
                                    </StyledTableCell>
                                    <StyledTableCell >
                                        <Checkbox checked={!!item?.updated} />
                                    </StyledTableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </StyledTable>
            </StyledPaper>
            {!!(data && data.length) && (
                <div style={{marginTop:16}}>
                    <ActionBar
                        total={total} 
                        tableFilter={tableFilter} 
                        onUpdateTableFilter={onUpdateTablePagination}
                     />
                </div>
            )}
            {(!data.length && (
                <NoTableData />
            ))}
        </>
     );
}
 
export default EmployeePayrollTable;

const StyledPaper = styled(Card)(({ theme }) => ({
    position:'relative',
    borderRadius:16,
    boxShadow:'none',
    overflowX:'auto'
}));

const StyledTable = styled(Table)(({ theme }) => ({
    marginTop:0,
    borderRadius:8,
    boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    borderColor:grey[300],
    paddingTop:16,
    paddingBottom:16
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:hover':{
        cursor:'pointer',
        background:grey[100]
    }
}));