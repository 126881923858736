import React from 'react'
import { Grid } from '@material-ui/core'
import CardItem from '../../components_v1/Structure/Cards/CardItem'
import BasicView from '../../components_v1/Structure/Layouts/BasicView'
import { contentData } from './content'

const Statistics = ({ history, language }) => {

    const content = contentData[language]
    
    return (
        <BasicView title={content.title}>
            <Grid container spacing={4}>
                {content.statistics.map(data => {
                    return (
                        <Grid item xs={12} sm={3} key={data.id}>
                            <CardItem history={history} {...data} />
                        </Grid>
                    )
                })}
            </Grid>
        </BasicView>
    )
}

export default Statistics