export const contentData = {
  spanish:{
    title:'Cuentas bancarias',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      account:{
        label:'Acceso a cuenta',
        placeholder:'Acceso a cuenta',
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      billing_account_type_id:{
        label:'Tipo de cuenta',
        placeholder:'Tipo de cuenta',
      },
      billing_account_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      stamps_expiration:{
        label:'Expiración',
        placeholder:'Expiración',
      },
    },
  },
}