import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import { contentData } from './content'
import GeneralView from './GeneralView/GeneralView'
import ToolbarTable from './components/ToolbarTable'
import UserTimeReport from './views/UserTimeReport/UserTimeReport'
import UserEvaluations from './views/UserEvaluations/UserEvaluations'
import PermissionRedirect from '../../../../components_v1/Actions/PermissionRedirect'
import { PERMISSION_MODULES, PERMISSION_TYPES } from '../../../../variables/config'
import queryString from 'query-string'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import { Grid } from '@material-ui/core'
import Tasks from './views/UserTasks/Tasks'
import Bonus from './views/Bonus/Bonus'

const menuOptions = [
  { id: 1, label: 'Información principal', },
  { id: 2, label: 'Time Report' },
  { id: 3, label: 'Evaluaciones' },
  { id: 4, label: 'Tareas' },
  { id: 5, label: 'Bonos' },
]


const UserView = ({ history, language, catalogs, permissions, permissionsType, match }) => {

  const [view, setView] = useState(2)
  const content = contentData[language]

  const userID = match.params.id
  let permission = null
  if (permissions) permission = permissions.find(el => el.id_permission_module === PERMISSION_MODULES.USERS)
  const redirectContent = <PermissionRedirect permission={permission} level={PERMISSION_TYPES.READ} />

  const onChangeView = (id) => {
    const urlwithargs = `?view=${id}`
    window.history.replaceState(null, null, urlwithargs);
    setView(id)
  }

  console.log(view)

  let contentView = null

  switch (view) {
    case 1:
      contentView = (
        <GeneralView
          history={history}
          language={language}
          userID={userID}
          catalogs={catalogs}
          permission={permission ? permission.id_permission_type : 5}
          permissions={permissions}
          permissionsType={permissionsType}
        />
      )
      break;
    case 2:
      contentView = (
        <UserTimeReport history={history} match={match} />
      )
      break;
    case 3:
      contentView = (
        <UserEvaluations userID={userID} history={history} language={language} match={match} />
      )
      break;
    case 4:
      contentView = (
        <Tasks visitedUserID={userID} history={history} />
    )
    break;
    case 5:
      contentView = (
          <Bonus history={history} language={language} match={match} userID={userID} />
      )
      break;
  }

  return (
    <BasicView
      history={history}
      title={content.layout.title}
      onChange={onChangeView}
      setReturn
    >
      {redirectContent}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <ToolbarTable selected={view} menuOptions={menuOptions} onChange={onChangeView} />
        </Grid>
        <Grid item xs={12}>
          {contentView}
        </Grid>
      </Grid>
    </BasicView>
  )
}

export default withStyles(styles)(UserView)
