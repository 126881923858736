import React, { useRef, useState } from 'react'
import { Grid, Icon, InputBase, makeStyles } from '@material-ui/core'
import { blueGrey } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'8px 16px',
        borderRadius:32,
    },
    icon:{
        color:blueGrey[500],

    }
}))

const SearchBar = ({
    onRequestSearch
}) => {

    const classes = useStyles()
    const timerRef = useRef()

    const [value, setValue] = useState('')

    const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onInnerRequestSearch, 1000);
    }

    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }

    const onUpdateSearchValue = (e) => {
        setValue(e.target.value)
    }

    const onInnerRequestSearch = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        onRequestSearch(value)
    }

    return(
        <div className={classes.root}>
            <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                <Grid item>
                    <Icon className={classes.icon} fontSize='default'>search</Icon>
                </Grid>
                <Grid item>
                    <InputBase 
                        value={value} 
                        placeholder='Buscar...' 
                        onChange={onUpdateSearchValue}
                        onKeyUp={onStartCountDown}
                        onKeyDown={onStopCountDown}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchBar