import React, { useEffect, useState } from 'react'
import { request_service, request_delete_service } from './requests.jsx'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import queryString from 'query-string' 
import AddItemModal from './modals/AddItem/AddItemModal'
import WarningModal from '../../../../../components_v1/Structure/Modals/WarningModal'
import SimpleTable from './components/SimpleTable'
import EfirmaDetailsModal from './modals/EfirmaDetailsModal/EfirmaDetailsModal'
import { onGetErrorMessage } from '../../../../../shared/utility'
import { contentData } from './content'
import DeleteEfirmaModal from './modals/DeleteEfirmaModal.jsx'

const GeneralView = props => {

  const {language, businessID, permission} = props

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)

  const [service_list, setServiceList] = useState([])
  const [selected_item, setSelectedItem] = useState(null)

  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_efirma', order:0})
  const [search, setSearch] = useState(null)

  //MODALES
  const [additemmodal, setAddItemModal] = useState(false) 
  const [efirma_details_modal, setEfirmaDetailsModal] = useState(false)
  const [warningmodal, setWarningModal] = useState(false)

  //DATA
  const content = contentData[language]

  useEffect(() => {
    const initModule = async() => {
      try {
        setLoading(true)
        await onRequestModelData()
      } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))
      }
      setLoading(false)
    }
    initModule()
  },[tableFilter, search, businessID])

  const onRequestModelData = async() => {
    try {
      const {limit, offset, order_by, order} = tableFilter
      let filterArgs = [
          {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
      ]
      filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
      const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
      let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
      const _service = await request_service(businessID, params)
      setServiceList([..._service.efirmas])
      setTotal(_service.count)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
  }

  const onPreDeleteItem = (id) => {
    console.log(id)
    setSelectedItem(id)
    setWarningModal(true)
    console.log(id)
  }

  const onChangeSearch = (val) => {
    setSearch(val)
    setTableFilter({...tableFilter, offset:0})
  }

  const onSelectedItem = (item) => {
    setSelectedItem(item)
    setEfirmaDetailsModal(true)
  }

  const onUpdateCompleted = async() => {
    try {
      await onRequestModelData()
      setEfirmaDetailsModal(false)
      setAddItemModal(false)
      setWarningModal(false)
    } catch (error) {
      console.log(error)
      setError(onGetErrorMessage(error))
    }
  }

  return(
    <LoadingContainer sending={sending} success={success} error={error} onCloseError={()=>setError(null)} >    
      <div>
        <DeleteEfirmaModal origin={selected_item} message={content.delete_modal.message} open={warningmodal} onClose={() => setWarningModal(false)} onUpdateCompleted={onUpdateCompleted}/>
        <AddItemModal businessID={businessID} open={additemmodal} onClose={() => setAddItemModal(false)} 
        content={content.add_modal} language={language} onUpdateCompleted={onUpdateCompleted}/>
        <EfirmaDetailsModal open={efirma_details_modal} onClose={() => setEfirmaDetailsModal(false)} 
        businessID={businessID} init_data={selected_item} permission={permission} onUpdateCompleted={onUpdateCompleted}/>
        <SimpleTable data={service_list} total={total} loading={loading} permission={permission}
            search={search} onChangeSearch={onChangeSearch} 
            tableFilter={tableFilter}  onUpdateTableFilter={(data) => setTableFilter(data)}
            onSelectedItem={onSelectedItem} onAddRegister={() => setAddItemModal(true)} onRemoveItem={onPreDeleteItem}/>
      </div>
    </LoadingContainer>
  )
}

export default GeneralView