import { Grid, makeStyles } from '@material-ui/core'
import React from 'react'
import SignatureCanvas from 'react-signature-canvas'
import SimpleModal from '../../Modals/SimpleModal'
import DisplayText from '../../../Texts/DisplayText'
import RoundedButton from '../../../Actions/RoundedButton';
import { useRef } from 'react'
import { useState } from 'react'
import { onGetErrorMessage } from '../../../../shared/utility'

const styles = makeStyles(theme => ({
    signBox: {
        border: 'solid 1px',
        borderRadius: 32,
    }
}))

const SignModal = props => {

    const classes = styles()
    const { evaluationID, open, onClose, content, onUpdateCompleted } = props
    
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    
    const [sign, setSign] = useState(null)

    const getSign = () => {
        try {
            setLoading(true)
            let url = sign.getTrimmedCanvas().toDataURL('image/png')
            onUpdateCompleted(url)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <Grid container justify='center' spacing={4}>
                    <Grid item xs={12}>
                        <DisplayText variant='h6' style={{ fontWeight: 500 }} color='primary'>{content.title}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.signBox}>
                            <SignatureCanvas
                                penColor='black'
                                dotSize={2}
                                ref={(ref) => setSign(ref)}
                                canvasProps={{
                                    width: 500,
                                    height: 200,
                                }}
                            />
                        </div>
                    </Grid>
                    <Grid item>
                        <RoundedButton
                            color='secondary'
                            onClick={getSign}
                        >
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default SignModal