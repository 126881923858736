export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
        title:'Información Básica',
        button:'Editar',
        nodata:'Sin Registro',
        data:{
            rfc:{
                label:'',
                data:'',
            },
            name:{
                label:'',
                data:''
            },
            usb_id:{
                label:'',
                data:''
            },
            service_process:{
                label:'',
                data:''
            },
            due_date:{
                label:'',
                data:''
            },
            responsible:{
                label:'',
                data:''
            },
            estimated_delivery_date:{
                label:'',
                data:''
            },
            comments:{
                label:'',
                data:''
            },
            project_costs:{
                label:'',
                data:''
            },
            provider:{
                label:'',
                data:''
            },
            cost:{
                label:'',
                data:''
            },
            sell_price:{
                label:'',
                data:''
            },
            company_type:{
                label:'',
                data:''
            }
        },
        content:{
            rfc:{
                label:'RFC'
            },
            name:{
                label:'Nombre'
            },
            usb_id:{
                label:'USB'
            },
            service_process:{
                label:'Proceso'
            },
            due_date:{
                label:'Fecha Requerida'
            },
            responsible:{
                label:'Responsable'
            },
            estimated_delivery_date:{
                label:'Fecha Estimada de Entrega'
            },
            comments:{
                label:'Comentarios'
            },
            project_costs:{
                label:'Costos del Proyecto'
            },
            provider:{
                label:'Nombre del Provedor'
            },
            cost:{
                label:'Costo'
            },
            sell_price:{
                label:'Precio de Venta'
            },
            company_type:{
                label:'Tipo de Sociedad'
            }
        },
        documents:{
            title:'Documentos',
            header:{title:'Archivos', actions:'Acciones'},
            files:[
                {key:'documents',name:'Documentos',path:'documents',status:false},
                {key:'local_tax_format',name:'Formato "Incripción Impuesto Local"',path:'localtaxformat',status:false},
                {key:'fiscal_situation_proof',name:'Constancia de Situación Fiscal de la Sociedad',path:'fiscalsituationproof',status:false, businessDocument:true},
                {key:'constitutive_act', name:'Copia Certificada Acta Constitutiva',path:'constitutiveact',status:false, businessDocument:true},
                {key:'faculties', name:'Copia Certificada Acreditación de Facultades',path:'faculties',status:false, businessDocument:true},
                {key:'address_proof', name:'Comprobante de Domicilio, a nombre de empresa o socio',path:'addressproof',status:false, businessDocument:true},
                {key:'workplace_croquis', name:'Croquis del Lugar de Trabajo',path:'workplacecroquis',status:false, businessDocument:true},
            ]
        }
    }
  }
}
