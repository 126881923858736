import { onGetDateBimesterFormat, onGetDateFormat } from "../../../shared/utility"

export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {

    const image = findImageResponsible(item.evaluated_id, users)
    
    let data = {
      id_employee_evaluation: item.id_employee_evaluation,
      evaluated: item.evaluated ? item.evaluated : nodata,
      total: item.total ? item.total : nodata,
      evaluation_date: item.evaluation_date ? onGetDateFormat(item.evaluation_date, 'DD MMM YY') : '-',
      date: item.auto_date && item.monthly === 1 ? onGetDateFormat(item.auto_date, 'MMM, YYYY') : item.auto_date ? onGetDateFormat(item.auto_date, 'MMM') + ' - ' + onGetDateBimesterFormat(item.auto_date, 'MMM, YYYY') : '-',
      evaluation_step_id: item.evaluation_step_id,
      image: image,
    }

    return data
  })

  return table
}

const findImageResponsible = (id, users) => {
  let temp = null

  if (id !== null && id !== undefined) {
    if (users.length > 0) {
      temp = users.filter(el => el.id_user === id)
    }
  }

  return temp && temp[0] ? temp[0].image : null
}