import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_ac_annual_statement,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.sat_password ? currentData.sat_password : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.sub_activity ? currentData.sub_activity : noData
    ];

    let varData = [
        currentData.process_status ? currentData.process_status : noData,
        currentData.declaration_period ? currentData.declaration_period : noData,
        currentData.declaration_type ? currentData.declaration_type : noData,

        currentData.income ? currentData.income : noData,
        currentData.expenses ? currentData.expenses : noData,
        currentData.fiscal_utility ? currentData.fiscal_utility : noData,
        currentData.isr_to_pay ? currentData.isr_to_pay : noData,
        currentData.total_to_pay ? currentData.total_to_pay : noData,
        currentData.operation_number ? currentData.operation_number : noData,
        currentData.presentation_date ? moment(currentData.presentation_date).format('DD-MM-YYYY') : noData,
        {key:'worksheet'},
        {key:'annual_receipt'},
        {key:'details_document'},
        currentData.delivery_date ? moment(currentData.delivery_date).format('DD-MM-YYYY') : noData,
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        process_status_id : currentData.process_status_id,
        declaration_period_id : currentData.declaration_period_id,
        declaration_type_id : currentData.declaration_type_id,
        accumulated_income : currentData.accumulated_income,
        deductions : currentData.deductions,
        fiscal_utility : currentData.fiscal_utility,
        isr_to_pay : currentData.isr_to_pay,
        total_to_pay : currentData.total_to_pay,
        accumulated_loses : currentData.accumulated_loses,
        exercise_loses : currentData.exercise_loses,
        isr_in_favor : currentData.isr_in_favor,
        operation_number : currentData.operation_number,
        presentation_date : currentData.presentation_date,
        delivery_date : currentData.delivery_date,
        responsible_id : currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date,
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_ac_annual_statement,key:'annual_receipt',name:'Acuse Anual',path:'annualreceipt',
            status: checkStatus(currentData, 'annual_receipt')},
        {id:currentData.id_ctrl_ac_annual_statement,key:'worksheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'worksheet')},
        {id:currentData.id_ctrl_ac_annual_statement,key:'details_document',name:'Detalles Doc',path:'detailsdocument',
            status: checkStatus(currentData, 'details_document')},
        {id:currentData.id_ctrl_ac_annual_statement,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_ac_annual_statement,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
