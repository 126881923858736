export const contentData = {
    spanish: {
        button: 'Continuar',
        back: 'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        employee_number:{
            label: 'No. de trabajadores',
            id: 'employee_number'
        },
    }
}
