import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import SimpleTable from '../components/SimpleTable';
import { request_timereports, request_timereport_statistic } from '../requests';
import queryString from 'query-string'
import { onGetMonthsCatalog, onGetUserID } from '../../../../shared/utility';
import moment from 'moment'
import DeleteTimeReportModal from '../modals/DeleteTimeReportModal';
import BusinessFilterModal from '../modals/BusinessFilterModal';
import UserFilterModal from '../modals/UserFilterModal';

const GeneralTableView = ({ history }) => {

    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [statistics, setStatistics] = useState(null)
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })
    const [filter, setFilter] = useState({ query: '' })
    const [month, setMonth] = useState(moment().month() + 1)
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const [deleteModal, setDeleteModal] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)
    const [userModal, setUserModal] = useState(false)
    const [businessModal, setBusinessModal] = useState(false)
    const [user, setUser] = useState(null)
    const [business, setBusiness] = useState(null)
    const [task_type, setTaskType] = useState(-1)


    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async () => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const { limit, offset } = tableFilter
                const rangeDate = month_options.find(el => el.value === month)
                let filterArgs = [
                    //{"field":"user_id","operator":"LIKE","value":userID},
                    { "field": "date", "operator": ">=", "value": rangeDate.start_date },
                    { "field": "date", "operator": "<=", "value": rangeDate.end_date }
                ]
                if (task_type !== -1) filterArgs.push({ "field": "task_type_id", "operator": "LIKE", "value": task_type })
                if (business) filterArgs.push({ "field": "business_id", "operator": "LIKE", "value": business.value })
                if (user) filterArgs.push({ "field": "user_id", "operator": "LIKE", "value": user.value })
                const param2send = { language_id: 2, limit: limit, offset: offset * limit, order_by: 'id_time_report', order: 1, start_date: rangeDate.start_date, end_date: rangeDate.end_date }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _timereports = await request_timereports(params)
                const statistics_params = {
                    language_id: 2,
                    start_date: rangeDate.start_date,
                    end_date: rangeDate.end_date
                }
                const _statistics = await request_timereport_statistic(userID, statistics_params)
                setTotal(_timereports.count)
                setTimereports(_timereports.time_reports)
                setStatistics(_statistics && _statistics.length ? _statistics[0] : null)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }

        initModule()
    }, [filter, tableFilter, month, year, business, user, task_type])



    const onChangeFilter = (value, type) => {
        if (type === 'month') setMonth(value)
        if (type === 'year') {
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setMonth(1)
        }
        if (type === 'task_type') setTaskType(value)
    }

    const onRemoveItem = (item) => {
        console.log(item)
        setDeleteModal(true)
        setSelectedItem(item)
    }

    const onOpenFilter = (id) => {
        if (id === 'USER_MODAL') setUserModal(true)
        if (id === 'BUSINESS_MODAL') setBusinessModal(true)
    }

    const onAddFilter = (obj, key) => {
        setTableFilter({ limit: 10, offset: 0 })
        if (key === 'business') setBusiness(obj)
        if (key === 'user') setUser(obj)
    }

    const onRemoveFilter = (key) => {
        setTableFilter({ limit: 10, offset: 0 })
        if (key === 'business') setBusiness(null)
        if (key === 'user') setUser(null)
    }

    const onSelectedItem = (data) => {
        window.open(`/fulltimereports/${data.id_time_report}`, '_blank')
    }

    return (
        <LoadingContainer>
            <>
                <UserFilterModal open={userModal} onClose={() => setUserModal(false)} onAddFilter={onAddFilter} />
                <BusinessFilterModal open={businessModal} onClose={() => setBusinessModal(false)} onAddFilter={onAddFilter} />
                <SimpleTable statistics={statistics ? statistics : {}} onAddFilter={onOpenFilter}
                    month={month} year={year} monthCatalog={month_options} onRemoveFilter={onRemoveFilter}
                    business={business} user={user} task_type={task_type}
                    data={timereports}
                    filter={filter}
                    tableFilter={tableFilter}
                    total={total}
                    loading={loading}
                    onSelectedItem={onSelectedItem}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    onChangeFilter={onChangeFilter} onRemoveItem={onRemoveItem} />
            </>
        </LoadingContainer>
    );
}

export default GeneralTableView;