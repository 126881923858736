import React, {Component} from 'react';
import {Grid} from '@material-ui/core';
import {RoundedButton} from '../Buttons/GeneralButtons';

class SmallNavigation extends Component{

  render(){

    const {data, onChange, selected} = this.props;
    let menuContainer = null;

    if(data){
      menuContainer = data.map(item => {
        return(
          <Grid item  style={{width: 'max-content'}} key={item.id}>
            <RoundedButton 
              size='small'
              onClick={() => onChange(item.id)}
              disabled={item.disabled}
              icon={item.icon}
              label={item.label}
              color={item.id === selected ? 'secondary' : 'white'}
            />
          </Grid>
        )
      })
    }

    return(
      <div>
        <Grid container spacing={2}>
          {menuContainer}
        </Grid>
      </div>
    )
  }
}

export default SmallNavigation;