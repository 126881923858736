import React, { useRef, useState } from 'react'
import { Button, Grid, Icon, IconButton, LinearProgress, makeStyles, Menu, MenuItem, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import empty from '../../../../assets/empty.png'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import { onGetDateFormat } from '../../../../shared/utility'






const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        marginTop:4,
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        /* '&:hover':{
            cursor:'pointer',
            background:grey[100]
        } */
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background: theme.palette.secondary.main,
        padding: '6px 32px',
        borderRadius: 18,
        color: 'white',
        textTransform:'none'
        /* '&:hover':{
            background:theme.palette.primary.dark,
        } */
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        //minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    },
    container:{
        background:'#EDF2F8',
        padding:20,
        borderRadius:10
    }
}))

const MiniTableProcess = props => {

    const classes = useStyles()
    const {data, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, permission, header, id_key, url, history} = props

    const goDetailView = (url, id) =>{
        history.push(`/${url}/${id}`)
    }

    return(
        <div className={classes.container} >
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    const {business, completed_date, limit_date } = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >   
                                                                                  
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{business}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{onGetDateFormat(completed_date, 'DD / MM / YYYY')}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><DisplayText variant='body2'>{onGetDateFormat(limit_date, 'DD / MM / YYYY')}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} align='right' >
                                                <Button className={classes.button} onClick={()=>goDetailView(url, item[id_key])} endIcon={<Icon>navigate_next</Icon>} >Ir</Button>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default MiniTableProcess


