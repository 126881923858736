import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../../../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../../../../components_v1/Texts/DisplayText';
import { onGetFormData, onInitForm, isFormValid, onSetErrorsToForm, onGetEmployeesCatalogs } from '../../../../../../../../../../shared/utility';
import { catalogs } from '../../../../../../../../../../texts/esp/catalogs';

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: 32,
    paddingBottom: 32
  }
}))

const EditModal = ({ id, open, onClose, init_data, employees, onUpdateCompleted }) => {

  const classes = useStyles()

  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {
    let _formData = {...formData}
    //_formData.responsible_id.options = onGetEmployeesCatalogs(employees.filter(e => e.area_id == 5))
    const _employees = onGetEmployeesCatalogs(employees)
    _formData.fixed_responsible_id.options = _employees
    _formData.advisor_id.options = _employees
    _formData.analyst_id.options = _employees
    _formData.supervisor_id.options = _employees

    let _form = JSON.parse(JSON.stringify(_formData))
    if (init_data) {
      console.log(init_data)
      _form = onInitForm(_form, init_data)
    }
    setForm(_form)
    if (!open) { setError(''); setLoading(false); }

  }, [open])

  const onChange = (data) => {
    let temp = { ...form }
    const id = data.config.id
    temp[id] = { ...data }
    setForm(temp)
  }

  const onSubmit = async () => {

    const errors = isFormValid(form)
    /// Check if the form is valid
    if (errors && errors.length) {
      const new_form = onSetErrorsToForm(form, errors)
      setForm(new_form)
      setError('Llene los campos faltantes')
      return
    }

    //Extract data
    let data2send = onGetFormData(form)
    console.log(data2send)
    //Send data
    setLoading(true)
    try {
      await onUpdateCompleted(data2send)
    } catch (error) {
      console.log(error)
      setError('Hubo un error')
      setLoading(false)
    }

  }


  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='md'>
      <div>
        <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Información del negocio</DisplayText>
        <div className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.fixed_responsible_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.advisor_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.analyst_id} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputSelect data={form.supervisor_id} onChange={onChange} />
            </Grid>
            <Grid item xs></Grid>
            <Grid item xs={12} md={6}>
              <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </div>
      </div>
    </SimpleModal>
  );
}

export default EditModal;

const formData = {
  fixed_responsible_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config: {
      id: 'fixed_responsible_id',
      type: 'simple_select',
      fullWidth: true,
      label: 'Responsable impuesto local',
      placeholder: 'Seleccione un responsable'
    },
    rules: { type: 'none' }
  },
  advisor_id: {
    value: false,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'advisor_id',
      type: 'simple_select',
      fullWidth: true,
      label: 'Asesor',
      placeholder: 'Seleccione un asesor'
    },
    rules: { type: 'none' }
  },
  analyst_id: {
    value: false,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'analyst_id',
      type: 'simple_select',
      fullWidth: true,
      label: 'Analista',
      placeholder: 'Seleccione un analista'
    },
    rules: { type: 'none' }
  },
  supervisor_id: {
    value: false,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'supervisor_id',
      type: 'simple_select',
      fullWidth: true,
      label: 'Supervisor',
      placeholder: 'Seleccione un supervisor'
    },
    rules: { type: 'none' }
  },
}