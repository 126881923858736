import { onGetResponsibleObj } from "../../../../../shared/utility";

export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {

    let temp = {
      id:item.id_audit,
      responsible: onGetResponsibleObj(item.responsible_id, users, nodata),
      files:[],
      data:[]
    }

    let array = [
      item.id_audit,
      item.name ? item.name : nodata,
      item.due_date ? item.due_date : nodata,
      item.periodicity ?  item.periodicity : nodata,
      item.responsible ? item.responsible : nodata
    ]

    temp.data = [...array]
    return temp
  })

  return table
};

const findResponsible  = (id, users, nodata) => {
  let temp = {
    first_name: nodata.split(' ')[0], 
    last_name:nodata.split(' ')[1],
    image:"0"
  }
  
  if(id !== null && id !== undefined && users){
    if(users.length > 0){
      let aux = users.find(el => el.id_user === id)
      if(aux) temp = {...aux}
    }
  }
  return temp
}