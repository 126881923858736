export const contentData = {
    spanish:{
      title:'Agregar Usuario Infonavit',
      editTitle:'Editar Usuario Infonavit',
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        user:{
          label:'Usuario Infonavit',
          placeholder:'Usuario Infonavit',
        },
        password:{
          label:'Contraseña Infonavit',
          placeholder:'Contraseña Infonavit',
        },
        email:{
          label:'Correo',
          placeholder:'Correo'
        }
      },
    },
  }