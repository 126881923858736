import React, { useCallback, useEffect, useState } from 'react';
import { Button, CircularProgress, Grid, Icon, Typography } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/styles';
import ReactMarkdown from 'react-markdown';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';

const ChatConvesartion = ({messages, busy, onRetry}) => {

    const classes = useStyles()
    const [retry, seRetry] = useState(false)

    const onCheckChatGPTError = useCallback(
      async() => {
        if(messages.length >= 2){
            const last_message = messages[messages.length - 1]
            console.log("last_message", last_message)
            if(last_message.role === 'user'){
                console.log("Reintentar..........")
                seRetry(true)
            }else{
                seRetry(false)
            }
        }
      },
      [messages, busy],
    )
    

    useEffect(() => {
        onCheckChatGPTError()
    }, [messages])

    return ( 
        <div className={classes.root}>
            <Grid container spacing={1}>
                {messages.map((message, idx) => {

                    if(message?.role === 'user'){
                        let busyContent = null
                        if((idx === messages.length - 1 || idx === messages.length - 2) && busy ){
                            busyContent = (
                                <div style={{
                                    width:32, height:32, //background:'grey'
                                }}>
                                    <CircularProgress size={32} />
                                </div>
                            )
                        }

                        return (
                            <Grid item xs={12} key={`msg-user-${idx}`}>
                                <Grid container justify='flex-end' alignItems='center' spacing={2}>
                                    <Grid item>
                                        {busyContent}
                                    </Grid>
                                    <Grid item>
                                        <div className={classes.user_message}>
                                            <Typography>
                                                {message?.content}
                                            </Typography>
                                        </div>
                                        {(!busy && retry && idx === messages.length - 1) && (
                                            <Grid container justifyContent='flex-end'>
                                                <Grid item>
                                                    <Button color='primary' className={classes.retry_button} onClick={onRetry}>
                                                        <Typography>Reintentar</Typography>
                                                        <Icon>refresh</Icon>
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                           
                                        )}
                                    </Grid>
                                    
                                </Grid>
                            </Grid>
                            
                        )
                    }

                    let _str_content =  message?.content ?? ''
                    _str_content = _str_content

                    
                    return(
                        <Grid item xs={12} key={`msg-ai-${idx}`}>
                            <div className={classes.ai_message} >
                                <Typography>
                                    <ReactMarkdown
                                        children={_str_content}
                                        remarkPlugins={[remarkMath]}
                                        rehypePlugins={[rehypeKatex]}
                                />
                                </Typography>
                            </div>
                        </Grid>
                        
                    )
                })}
            </Grid>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    user_message:{
        background: blueGrey[100],
        padding:"16px 16px",
        borderRadius:16
    },
    retry_button:{
        textTransform:'none',


    }
}))
 
export default ChatConvesartion;
