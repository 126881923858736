import { CircularProgress, Fade, Grid, Icon, makeStyles, Snackbar } from '@material-ui/core';
import React from 'react';
import DisplayText from '../../Texts/DisplayText';
import ErrorModal from '../Modals/ErrorModal';
import { blue, green } from '@material-ui/core/colors';

const LoadingContainer = ({children, loading, error, success, sending, onCloseError, onCloseSuccess}) => {

    const classes = useStyles()

    const successContent = <Snackbar ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  
    autoHideDuration={1500} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><DisplayText style={{color:'white'}}>{'Acción exitosa'}</DisplayText></Grid>
    </Grid>}/>

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><DisplayText style={{color:'white'}}>Enviando...</DisplayText></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>


    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError}/>

    return ( 
        <div>
            {sendingContent}
            {successContent}
            {errorModal}
            {loading ? (
                <Grid container alignItems='center' direction='column'><Grid item><CircularProgress size={32}/></Grid><Grid item><DisplayText>Cargando...</DisplayText></Grid></Grid>
            ) : null}
            <Fade in={!loading}>{children}</Fade>
        </div>
     );
}

const useStyles = makeStyles(theme => ({
    root:{
        padding:32,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    },
    container:{
        padding:'32px 0px'
    },
    success:{
        background:green[700],
        zIndex:3
    },
    sending:{
        background:blue[700]
    }
}))

 
export default LoadingContainer;