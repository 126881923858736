import { useCallback, useEffect, useState } from "react";
import useEmployeePayroll from "../../../hooks/api/useEmployeePayroll";
import useTableSettings from "../../../hooks/useTableSettings";
import moment, { months } from 'moment'
import { onGetMonthsCatalog, onGetYearCatalog } from "../../../shared/utility";
import useModal from "../../../hooks/useModal";

const useGeneralPayrollListView = () => {

    const employeePayrollManager = useEmployeePayroll()

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [done, setDone] = useState(false)
    const [error, setError] = useState(null)
    const [payrolls, setPayrolls] = useState([])
    const [payroll, setPayroll] = useState()
    const [count, setCount] = useState(0)
    const [month, setMonth] = useState(moment().month()+1)
    const [year, setYear] = useState(moment().year())
    const [year_options] = useState(onGetYearCatalog())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const [search, setSearch] = useState("")

    const modalManager = useModal({
        edit_payroll:false
    })

    const onSelectedPayroll = (data) => {
        console.log(data)
        setPayroll(data)
        modalManager.onChangeModalStatus('edit_payroll', true)
    }

    const tableController = useTableSettings({
        /*initial_filter:{
            search:{key:'alias', value:'', operator:'LIKE'},
            start_date:{key:'', value:'', operator:'>='},
            end_date:{key:'', value:'', operator:'<='}
        },*/
        initial_params:{
            order_by:'date',
            order:1,
            limit:25, 
            offset:0,
        }
    })

    const onRequestSearch = useCallback(async(data) => {
        setSearch(data)
        tableController.onResetPagination()
    }, [])

    const onUpdateFilter = (value, type) => {
        if(type === 'month') setMonth(value)
        if(type === 'year'){
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setMonth(1)
        }
    }

    const onGetParams = () => {
        const {pagination} = tableController
        let params = {...pagination}
        params.offset = params.offset * params.limit
        let ANDArgs = []
        const selected_date = month_options.find(el => el.value === month)

        ANDArgs.push({"field":"date","operator":">=","value":`${selected_date.start_date}`})
        ANDArgs.push({"field":"date","operator":"<=","value":`${selected_date.end_date}`})
        if(search){
            ANDArgs.push({
                "OR":[
                    {field:'rfc', operator:'LIKE', value:`%${search}%`},
                    {field:'user_first_name', operator:'LIKE', value:`%${search}%`},
                    {field:'user_last_name', operator:'LIKE', value:`%${search}%`},
                ]
            })
        }
        params.filter = JSON.stringify({AND:ANDArgs})
        return params
    }

    const onRequesPayrolls = async() => {
        const params = onGetParams()
        const _request = await employeePayrollManager.actions.onGetAll(params)
        console.log(_request)
        setPayrolls(_request.data)
        setCount(_request.count)
    }

    const onRefreshData = async() => {
        setLoading(true)
        try {
            await onRequesPayrolls()
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
    }
    
    const initialize = useCallback(async() => {
        setLoading(true)
        try {
            await onRequesPayrolls()
        } catch (error) {
            console.log(error)
        }
        setLoading(false)
        setDone(true)
    }, [])


    //HOOKS
    useEffect(() => {
        if(done) onRefreshData()
    }, [done, search, month, year, tableController.pagination])

    useEffect(() => {
        initialize()
    }, [])

    return {
        loading, 
        sending, 
        error,
        payrolls,
        payroll,
        count,
        tableController,
        month,
        month_options,
        year,
        year_options,
        onUpdateFilter,
        onRequestSearch,
        modalManager,
        onSelectedPayroll,
        setError,
        onRequesPayrolls
    };
}
 
export default useGeneralPayrollListView;