import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import ActionModalBar from '../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../components_v1/Forms/InputText';
import SimpleModal from '../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onGetSelectedFormData, onInitEmptyForm, onInitForm, onSetErrorsToForm } from '../../../shared/utility';
import { catalogs } from '../../../texts/esp/catalogs';
import InputSelect from '../../../components_v1/Forms/InputSelect';
import { request_update_bonus } from '../requests';

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 0
    }
}))

const EditModal = props => {

    const classes = useStyles()
    const { open, onClose, history, language, content, bonus, onUpdateCompleted } = props

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!open) {
            setError(''); setLoading(false);
        }else{
            let _form = JSON.parse(JSON.stringify(formData))
            //_form = onInitEmptyForm(_form, content.form)
            _form = onInitForm(_form, bonus)
            _form.bonus_status_id.options = catalogs.bonus
            setForm({..._form})
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetSelectedFormData(form, bonus)
        console.log(data2send)
        //Send data
        setLoading(true)
        
        try {
            await request_update_bonus(bonus?.id_bonus, data2send);
            onUpdateCompleted()
            onClose()
         

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='xs'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Editar bono</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.amount} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.bonus_status_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

export const formData = {
    amount: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'amount',
            type: 'number',
            fullWidth: true,
            label:'Monto',
            placeholder:'Monto'
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    bonus_status_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'bonus_status_id',
            type: 'simple_select',
            fullWidth: true,
            label:'Estatus',
            placeholder:'Estatus'
        },
        rules: {
            type: 'none',
            //min: 1,
            //max: 100
        }
    }
}

export default EditModal;