import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import InputText from '../../../../../components/Inputs/InputText/InputText'
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect'
import AvatarSelectInput from '../../../../../components/Inputs/AvatarSelectInput/AvatarSelectInput'

import {formData} from './data'
import {contentData} from './content'

const prepareData = (users) => {
  let usersData = [];

  if(users){
    usersData = users.map((item) => {
      let firstname = item.first_name ? item.first_name : '';
      let lastname = item.last_name ? item.last_name : ''; 
      return{
        value: item.id_user,
        label: firstname + ' ' + lastname,
        image: item.image
      }
    })
  }

  return usersData;
}

class EditBasicInfo extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }

  componentDidMount(){
    const {prospect, users, catalogs, language} = this.props;
    const {formData} = this.state;
    const content = contentData[language];
    let temp = {...formData};
    temp['pre_position_id'].options = [...catalogs.client_user_positions]
    temp['pre_language_id'].options = [...catalogs.client_user_languages]
    temp['pre_area_id'].options = [...catalogs.client_user_areas]
    temp['pre_country_id'].options = [...catalogs.countries]
    temp['client_status_id'].options = [...catalogs.client_statuses]
    const userOptions = prepareData(users)
    temp['pre_responsible_id'].options = [...userOptions]

    if(prospect){
      Object.keys(formData).forEach(item => {
        let value = prospect[item];
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value ? value : '';
        temp[item].isValid = value ? true : false;
        temp[item].config = {...temp[item].config,...content.sectionA[item]};
      });
    }
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, prospect} = this.props;
    const {formData} = this.state;
    let data2Send = {};
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid){
        if(formData[item].value !== prospect[item]){
          data2Send = {...data2Send, [item]:formData[item].value}
        }
      }
    })
    onSubmit(data2Send)
  }

  render(){

    const {language, user, issending, error} = this.props
    const {formData} = this.state
    const content = contentData[language]

    let contentA = null
    let avatarContent = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      switch (formData[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break
        case 'avatar_select':
            inputContent = (
              <AvatarSelectInput
                wrap='vertical'
                data={formData[item]}
                onChange={this.onInputChange}
              />
            )
            break
        default:
          inputContent = (
            <InputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    avatarContent = <ProfileAvatar image={user ? user.image : null}/>

    return(
      <EditCardWrapper
        button={content.save_button}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} direction='column' justify='center' alignItems='center'>
                  <Grid item>{avatarContent}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  {contentA}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EditCardWrapper>
    )
  }
}

export default EditBasicInfo