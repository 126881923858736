import React, { useEffect, useState} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {contentData} from './content'
import {request_prospects, request_employees, request_delete_prospect} from './requests'
import queryString from 'query-string' 
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import SimpleTable from './components/SimpleTable'
import AddProspectModal from './modals/AddProspect/AddProspectModal'
import WarningModal from '../../../../components_v1/Structure/Modals/WarningModal'
import {catalogs} from '../../../../texts/esp/catalogs'
const Prospects = props => {

  const {history, language, permissions, permissionsType} = props
  const [isloading, setIsloading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(null)
  const [search, setSearch] = useState(null)
  const [error, setError] = useState(null)

  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_client', order:0})

  const [prospects, setProspects] = useState([])
  const [users, setUsers] = useState([])
  const [selected, setSelected] = useState(null)

  const [addprospectmodal, setAddProspectModal] = useState(false)
  const [warningdeletemodal, setWarningDeleteModal] = useState(false)

  const content = contentData[language]

  let permission = permissions.find(el => el.id_permission_module === 2)
  permission = permission ? permission.id_permission_type : 1

  useEffect(() => { 
    const initModule = async() => {
      try{
        setIsloading(true)
        const args = queryString.parseUrl(history.location.search)
        const {limit, offset, order_by, order} = tableFilter
        let filterArgs = [
            {"field":"name","operator":"LIKE","value":`%${search?search:''}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
        const _prospects = await request_prospects(params)
        setProspects(_prospects.pre_clients)
        setTotal(_prospects.count)

        let response = await request_employees()
        setUsers([response])
      }catch(error){
        console.log(error)
      }
      setIsloading(false)
    }

    initModule() 
  },[tableFilter])

  const onChangeSearch = (val) => {
    setSearch(val)
    //setTableFilter({...tableFilter, offset:0})
  }

  const onPreDeleteProspect = (id) => {
    setSelected(id)
    setWarningDeleteModal(true)
  }

  const onDeleteProspect = async() => {
    try {
      setSending(true)
      const {limit, offset, order_by, order} = tableFilter
      let filterArgs = [
          {"field":"username","operator":"LIKE","value":`%${search?search:''}%`},
      ]
      filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
      const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
      let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
      let _prospects = await request_delete_prospect(selected, params)
      setProspects(_prospects.pre_clients)
      setTotal(_prospects.count)
      setWarningDeleteModal(false)
      setSending(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError('Hubo un error al intentar eliminar el usuario')
    }
    setSending(false)
  }
  
  return(
    <BasicView
      title={content.layout.title}
      success={success}
      error={error}
      sending={sending}
      onCloseSuccess={() => setSuccess(null)} 
      onCloseError={() => setError(null)}
    >
      <WarningModal open={warningdeletemodal} message={content.modals.warning_modal} onClose={() => setWarningDeleteModal(false)} onDelete={onDeleteProspect}/>
      <AddProspectModal open={addprospectmodal} onClose={() => setAddProspectModal(false)} history={history} language={language}/>
      <SimpleTable 
        search={search} 
        onChangeSearch={onChangeSearch} 
        onAddRegister={() => setAddProspectModal(true)}
        onSelectedItem={(data) => history.push(`/prospects/details/${data.id_client}`)}
        data={prospects} 
        tableFilter={tableFilter}  
        onUpdateTableFilter={(data) => setTableFilter(data)}
        total={total} 
        loading={isloading}
        client_statuses={catalogs.client_statuses}
        onRemoveItem={onPreDeleteProspect}
      />
    </BasicView>
    
  )
}

export default withStyles(styles)(Prospects)