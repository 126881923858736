import React from 'react'
import { makeStyles, Grid, Card } from '@material-ui/core'
import DisplayText from '../../../../components_v1/Texts/DisplayText'

const styles = makeStyles(theme => ({
    root: {
        borderRadius: 12,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        padding: '12px 32px',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    content: {
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    }
}))

const Banner = props => {

    const classes = styles()
    const { content, data, nodata } = props

    return (
        <Card className={classes.root}>
            {content.title && (
                <div className={classes.title}>
                    <DisplayText variant='body2' style={{ color: 'white' }} align='center'>
                        {content.title.toUpperCase()}
                    </DisplayText>
                </div>
            )}
            <div className={classes.content}>
                <Grid container justify='space-between' alignItems='center' spacing={1}>
                    {Object.keys(content.content).map((item, key) => {
                        let percent = item === 'fixed_importance' ?'%' : ''
                        return (
                            <Grid item key={key}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2' color='textSecondary'>{content.content[item]}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2'>{data[item] ? data[item]+percent : nodata}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Card>
    )
}

export default Banner