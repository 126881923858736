import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { grey, blue, red } from '@material-ui/core/colors';

import { Icon, Typography, Grid} from '@material-ui/core';
import ContentText from '../Texts/ContentText'

const header_styles = (theme) => ({
  container:{
    padding:'8px'
  },
  text:{
    color: grey[500],
    fontWeight:'500'
  },
  textaction:{
    color: grey[500],
    fontWeight:'500',
  }
})

class HeaderTableW extends Component {

  render(){

    const {classes, data} = this.props;

    return(
        <div className={classes.container}>
          <Grid container>
            <Grid item xs><Typography variant='body2' className={classes.text}>{data.title}</Typography></Grid>
          </Grid>
        </div>
    )
  }
}

const HeaderTable = withStyles(header_styles)(HeaderTableW);

const DocumentItem = (props) => {

  const {disabled, data, onSelectedItem} = props;

  const styles = {
    card:{borderRadius:'16px'},
    container:{
      '&:hover':{
        background: `${grey[200]} !important`
      },
      padding:'8px', 
      border:`1px solid ${grey[200]}`
    },
    folder:{color:blue[700]},
    name:{color:grey[700], fontWeight:'500'},
    subcontainer:{cursor:'pointer'}
  }

  const delete_color = disabled ? grey[400] : red[700]
  return(
      <div style={styles.container}>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          {!disabled ?
          <Grid item xs>
            <div style={styles.subcontainer} onClick={() => onSelectedItem(data.id)}>
              <Grid container spacing={1}>
                <Grid item>
                  <Icon style={styles.folder}>poll</Icon>
                </Grid>
                <Grid item xs>
                  <Typography variant='body2' style={styles.name}>{data.name}</Typography>
                </Grid>
              </Grid>

            </div>
          </Grid>
          :
          <Grid item xs>
            <div style={{color:delete_color}}>
              <Grid container spacing={1}>
                <Grid item>
                  <Icon style={{color:delete_color}}>poll</Icon>
                </Grid>
                <Grid item xs>
                  <Typography variant='body2' style={{color:delete_color}}>{data.name}</Typography>
                </Grid>
              </Grid>

            </div>
          </Grid>
          }
        </Grid>
      </div>
  )
}


class ControlTable extends Component {
  render(){

    const {ctrltableData, onSelectedItem, nodata} = this.props
    let filesView = null;

    if(ctrltableData && ctrltableData.length > 0){
      filesView = ctrltableData.map((item,key) => {
        return(
            <Grid item lg={3} md={4} xs={12} key={key.toString()}>
              <DocumentItem
                  data={item}
                  onSelectedItem={onSelectedItem}
                  disabled={!item.status}
              />
            </Grid>
        )
      })
    }else{
      filesView = (
          <Grid item xs={3}>
            <div style={{paddingTop:32}}>
              <ContentText text={nodata} align='center' medium/>
            </div>
          </Grid>
      )
    }


    return(
        <div>
          <Grid container>
            <Grid item xs={12}>
              <div>
                <Grid container spacing={1}>
                  {filesView}
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>
    )
  }
}

export default ControlTable;
