import moment from 'moment'

export const cleanData2Table = (data, nodata,id) => {
  
  const table = data.map((item, key) => {
    let temp = {
      id:item[id],
      files:[
        {id:item[id],key:'charge_proof', name:'Comprobante Cargo', path:'chargeproof', status: checkStatus(item, 'charge_proof')},
      ],
      data:[]
    }
    

    let array = [
      item[id].toString(), 
      item.completition_date ? moment(item.completition_date).format('DD-MM-YYYY') : nodata,
      item.notes ? item.notes : nodata,
      item.completed ? (item.completed == 1 ? 'Completado' : 'No completado') : 'No completado'
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== "" && item[key] !== null
}
