export const contentData = {
  spanish:{
    title:'Editar outsourcing',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre de trabajador',
        placeholder:'Nombre de trabajador',
      },
      specs:{
        label:'Especificaciones',
        placeholder:'Especificaciones'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      periodicity_id:{
        label:'Periodicidad',
        placeholder:'Periodicidad',
      },
      net_salary:{
        label:'Salario Neto a Depositar',
        placeholder:'Salario Neto a Depositar',
      },
      reimbursement:{
        label:'Reembolso de gastos o viáticos',
        placeholder:'Reembolso de gastos o viáticos',
      },
      substitute_boss:{
        label:'Patrón Sustituto',
        placeholder:'Patrón Sustituto',
      },
      clabe:{
        label:'CLABE del Beneficiario',
        placeholder:'CLABE del Beneficiario',
      },
      bank_id:{
        label:'Banco',
        placeholder:'Banco',
      },
      beneficiary:{
        label:'Nombre Beneficiario',
        placeholder:'Nombre Beneficiario'
      },
      bank_data:{
        label:'Otros Datos Bancarios',
        placeholder:'Otros Datos Bancarios'
      },
      comission:{
        label:'Comisión',
        placeholder:'Comisión',
      },
    },
  },
}