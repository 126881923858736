import { onGetResponsibleObj } from "../../../../../shared/utility"

export const cleanData2Table = (data, nodata, users) => {


  let table = []
  table = data.map(item => {
    let temp = {
      id:item.id_billing_account, 
      status_id: item.billing_account_status_id,
      type_id: item.billing_account_type_id,
      responsible: onGetResponsibleObj(item.responsible_id, users, nodata),
      files:[
        {id:item.id_billing_account,key:'stamps',name:'Estampados',path:'stamps', status: checkStatus(item, 'stamps')},
      ],
      data:[]
    }
    let responsible_fullname = ''
    responsible_fullname = responsible_fullname + (temp.responsible.first_name ? temp.responsible.first_name : nodata)
    responsible_fullname = responsible_fullname + ' '+ (temp.responsible.last_name ? temp.responsible.last_name : '')


    let array = [
      item.id_billing_account, 
      item.billing_account_type ? item.billing_account_type : nodata,
      item.billing_account_status ? item.billing_account_status: nodata,
      item.account ?  item.account : nodata,
      'Contraseña',
      item.stamps_expiration ? item.stamps_expiration: nodata,
      'Documento',
      responsible_fullname
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}

const findResponsible  = (id, users, nodata) => {
  let temp = {
    first_name: nodata.split(' ')[0], 
    last_name:nodata.split(' ')[1],
    image:"0"
  }
  
  if(id !== null && id !== undefined && users){
    if(users.length > 0){
      let aux = users.find(el => el.id_user === id)
      if(aux) temp = {...aux}
    }
  }
  return temp
}