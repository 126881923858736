export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    subtitle:'Detalles',
    edit:'Editar',
    add:'Agregar',
    convert:'Convertir a cliente',
    nospecialities:'No existen especialidades registradas',
    businesses_button:'Negocios',
    title:{
      sectionA:'',
      sectionB:'Información de registro',
      sectionC:'Información de contacto',
      sectionD:'Documentos',
      sectionE:'Información de contrato',
      sectionF:'Flujo del Proceso'
    },
    files:[
      {key:'pre_quotation',name:'Cotización',path:'prequotation', status:false},
      {key:'pre_observations_document',name:'Observaciones',path:'preobservations', status:false},
      //{key:'contract',name:'Contrato',path:'contract', status:false},
      {key:'legal_representative_documents',name:'Representante Legal',path:'legalrepresentative', status:false},
      {key:'constitutive_act',name:'Acta constitutiva',path:'constitutiveact', status:false},
      {key:'privacy_notice',name:'Aviso de privacidad',path:'privacynotice', status:false},
      {key:'national_register_of_foreign_investment',name:'Registro Nacional Inversión Extranjera',path:'foreigninvestment', status:false},
      {key:'public_register_of_property_and_commerce',name:'Registro Público de Comercio y la Propiedad',path:'registerofcommerce', status:false},
    ],
    header:{title:'Archivos', actions:'Acciones'},
    modals:{
      warning_delete_file:'¿Realmente quieres eliminar este archivo? Este proceso no puede ser revertido',
      warning_delete_contact:'¿Realmente quieres eliminar este contacto? Este proceso no puede ser revertido',
    },
    sectionA:{
      name:{
        label:'Nombre'
      },
      pre_position:{
        label:'Puesto'
      },
      classification:{
        label:'Clasificación  '
      },
      pre_fiscal_regime:{
        label:'Régimen Fiscal'
      },
      pre_language:{
        label:'Idioma'
      },
      pre_country:{
        label:'País'
      },
      pre_area:{
        label:'Área'
      },
      client_status:{
        label:'Estatus'
      },
      pre_responsible:{
        label:'Responsable previo'
      },
      responsible:{
        label:'Responsable'
      },
      antiquity:{
        label:'Antiguedad'
      }
    },
    sectionB:{
      classification:{
        label:'Clasificación'
      },
      pre_fiscal_regime:{
        label:'Régimen Fiscal'
      },
      pre_quotation:{
        label:'Cotización'
      },
      pre_contact_date:{
        label:'Fecha de contacto'
      },
      pre_contact_medium:{
        label:'Medio de contacto'
      },
      pre_required_date:{
        label:'Fecha requerida'
      },
      pre_end_date:{
        label:'Fecha de fin'
      },
      pre_observations:{
        label:'Observaciones'
      },
      pre_contact_name:{
        label:'Nombre de contacto'
      },
    },
    sectionC:{
      pre_work_email:{
        label:'Correo de trabajo'
      },
      pre_alternative_email:{
        label:'Correo alternativo'
      },
      pre_personal_email:{
        label:'Correo personal'
      },
      pre_office_phone_number:{
        label:'Oficina'
      },
      pre_cellphone:{
        label:'Celular'
      },
      pre_phone_number:{
        label:'Teléfono'
      },
    },
    sectionD:{
      who_charges:{
        label:'¿Quién cobra?'
      },
      contract_duration:{
        label:'Duración'
      },
      contract_periodicity:{
        label:'Periodicidad'
      },
      anual_increment:{
        label:'Incremento anual'
      },
      outsourcing_comission:{
        label:'Comisión por subcontratación'
      },
      contract_value:{
        label:'Valor del contrato'
      },
      iva:{
        label:'I.V.A.'
      },
      total_value:{
        label:'Valor total'
      },
      contract_special_conditions:{
        label:'Condiciones Especiales'
      },
      
    }
  },
  
}