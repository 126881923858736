import React from 'react';
import { Grid } from '@material-ui/core';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetFormatNumber } from '../../../../../../shared/utility';
import { PERMISSION_TYPES } from '../../../../../../variables/config';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { fCurrency } from '../../../../../../utils/formatNumber';


const WorkingInfoForm = ({title, data, onEdit, permission}) => {

    let can_edit = true
    let can_create = true
    let has_full_access = true
    
    if(permission){
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='RFC' data={data ? data.rfc : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='CURP' data={data ? data.curp : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Tipo de contrato' data={data ? data.contract_type : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Duración de contrato' data={data && data.contract_duration ? `${data.contract_duration} ${data.contract_duration_type}` : '-'}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Tipo de duración' data={data ? data.contract_duration_type : null }/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Estatus' data={data ? data.employee_status : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Divisa' data={data ? data.salary_currency  : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Costo' data={data ? fCurrency(data?.company_cost ?? 0) : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Salario bruto' data={data ? onGetFormatNumber(data.gross_salary) : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Salario neto' data={data ? onGetFormatNumber(data.net_salary) : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='schedule' title='Hora de entrada' data={data ? data.entrance_hour : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='schedule' title='Hora de salida' data={data ? data.exit_hour : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='calendar_today' title='Fecha de entrada' data={data ? data.entry_day : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='calendar_today' title='Fecha de salida' data={data ? data.exit_day : null}/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}

                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default WorkingInfoForm