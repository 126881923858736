import {mainServer} from '../../../../variables/config'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_add_prospect = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.post("/client/pre/", data)
  return response.data.data.pre_client_id
}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}
