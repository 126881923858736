import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    process_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_process_statuses], // pendiente
        formType:'select',
        config:{id:'process_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    provisional_to_charge_isr:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'provisional_to_charge_isr', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_salaries_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_salaries_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_assimilated_fees_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_assimilated_fees_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_professional_services_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_professional_services_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_leasing_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_leasing_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    other_income_isr_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'other_income_isr_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    vat_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'vat_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    vat_to_charge_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'vat_to_charge_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    vat_in_favor_withholdings:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'vat_in_favor_withholdings', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    vat_accreditation:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'vat_accreditation', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    vat_to_charge_in_favor:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'vat_to_charge_in_favor', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    ieps_accreditation:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'ieps_accreditation', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    ieps_to_charge_in_favor:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'ieps_to_charge_in_favor', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    tax_accessories:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'tax_accessories', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    total_to_pay:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'total_to_pay', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    payment_limit_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'payment_limit_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    client_comments:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'client_comments', type:'text', placeholder:'Ingrese un valor', helperText:'Valor no válido'},
        rules:{type:'none'}
    },
    diot_folio:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'diot_folio', type:'text', placeholder:'Ingrese un valor', helperText:'Valor no válido'},
        rules:{type:'none'}
    },
    diot_presentation_limit_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'diot_presentation_limit_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    diot_responsible_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'diot_responsible_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    compliance_opinion_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_compliance_opinion_statuses], //Catalog update required
        formType:'select',
        config:{id:'compliance_opinion_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    mailbox_status_id:{ // Check if it is text or catalog
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_mailbox_statuses], //Catalog update required
        formType:'select',
        config:{id:'mailbox_status_id', type:'text', placeholder:'Ingrese un valor', helperText:'Valor no válido'},
        rules:{type:'none'}
    },
    mailbox_comment:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'mailbox_comment', type:'text', placeholder:'Ingrese un valor', helperText:'Valor no válido'},
        rules:{type:'none'}
    },
    responsible_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'responsible_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}