export const formData = {
  name:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  due_date:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'due_date',
      type:'date',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  periodicity_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'periodicity_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  estimated_delivery_date:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'estimated_delivery_date',
      type:'date',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  }
}
