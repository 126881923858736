import { Grid, Icon, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import React from 'react'
import SimpleCard from './SimpleCard'
import DisplayText from '../../Texts/DisplayText'

const styles = makeStyles(theme => ({
    dot:{
        fontSize:'xx-small',
        color: grey[500]
    }
}))

const CardContentList = props => {

    const { title, content } = props
    const classes = styles()
    const dot = content.length > 1 ? <Icon className={classes.dot}>fiber_manual_record</Icon> : null

    return (
        <div>
            <SimpleCard>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <DisplayText variant='subtitle2' color='primary'>{title}</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            {content.map(text => {
                                return (
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2' align='justify'>{dot} {text}</DisplayText>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}

export default CardContentList