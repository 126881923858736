export const contentData = {
  spanish:{
    layout:{
      title:'Declaración Mensual SAT',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal','Sub-Actividad',
        'Sector de Actividad Económica','Servicio (Situación Fiscal)','Fecha Pago de Impuestos','Clave CIEC'],
        fixedHeaderB: null,
        varHeader: ['Fecha Límite de Presentación','Hoja de Trabajo','Estatus','ISR','Retenciones ISR',
        'IVA','Retenciones IVA','Otros','Recargos y Actualizaciones','Total a Pagar',
        'Fecha Límite de Pago','Acuse','Línea de Captura','Respuesta Cliente','Comentarios Cliente','DIOT Folio',
        'DIOT Archivo','DIOT Fecha Límite Presentación','DIOT Responsable','DIOT Acuse','Buzon Tributario',
        'Opinión de Cumplimiento','Estado de Opinión de Cumplimiento','Otros Documentos','Buzón Fecha de Pago',
        'Buzón Estatus','Buzón Comentarios','Buzón Acuse','Responsable','Notas', 'Horas Extra','Comprobante Horas', 
        'Cargos Extra', 'Comprobante Cargo', 'Cargo a Cobranza', 'Fecha de Completado', 'Clave CIEC',],
        extra: 'Completado'
      },

    }
  }
}
