import axios from "axios"
import { useRef, useState } from "react"
import { onGetErrorMessage, onGetFileExtension } from "../../../../shared/utility"
import { private_server } from "../../../../variables/config"


const usePictureModal = ({submit_url, delete_url, onClose, onCompleted, ratio, img_type, onSetTempImg, temp_img, enableHighResolution}) => {

    const [file, setFile] = useState(null)
    const [src, setSrc] = useState(null)
    const [crop, setCrop] = useState({aspect:ratio ? ratio : 1, width:128, unit:'px'})
    const [croppedImage, setCroppedImage] = useState(null)
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const [valid, setValid] = useState(false)
    let imageRef = useRef()
    
    const actions = {
        onResetComponent: () => {
            setFile(null)
            setSrc(null)
            setCrop({aspect:ratio ? ratio : 1, width:128, unit:'px'})
            setCroppedImage(null)
            setError(null)
            setLoading(false)
            setValid(false)
            imageRef.current = null
        },
        onDropFile: (data) => {
            setFile(data)
            if(data){
                const reader = new FileReader();
                reader.addEventListener("load", () => setSrc(reader.result))
                reader.readAsDataURL(data)
            }
        },
        onImageLoaded: image => imageRef.current = image,
        handleOnCropChange: (crop) => setCrop(crop),
        handleOnCropComplete: async(crop, pixelCrop) => {
            setValid(crop.width > 0 && crop.height > 0)
            if(imageRef && crop.width && crop.height){
                const croppedImg = await actions.getCroppedImg(imageRef.current, crop, 'profile')
                setCroppedImage(croppedImg)
            }
        },
        getCroppedImg: (image, crop) => {
            /* const canvas = document.createElement('canvas');
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = crop.width;
            canvas.height = crop.height;
            const ctx = canvas.getContext('2d');
          
            ctx.drawImage(
              image,
              crop.x * scaleX,
              crop.y * scaleY,
              crop.width * scaleX,
              crop.height * scaleY,
              0,
              0,
              crop.width,
              crop.height,
            );
            // As Base64 string
            // const base64Image = canvas.toDataURL('image/jpeg');
          
            // As a blob
            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    let fileUrl
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl)
                });
            }); */

            //new
            let canvas
            if(!enableHighResolution){
                canvas = document.createElement('canvas');
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                canvas.width = crop.width;
                canvas.height = crop.height;
                const ctx = canvas.getContext('2d');
            
                ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                crop.width * scaleX,
                crop.height * scaleY,
                0,
                0,
                crop.width,
                crop.height,
                );
            }else{
                canvas = document.createElement("canvas");
                const scaleX = image.naturalWidth / image.width;
                const scaleY = image.naturalHeight / image.height;
                var originWidth = crop.width * scaleX;
                var originHeight = crop.height * scaleY;
                // maximum width/height
                var maxWidth = 1200, maxHeight = 1200 / (16 / 9);
                var targetWidth = originWidth,
                targetHeight = originHeight;
                if (originWidth > maxWidth || originHeight > maxHeight) {
                if (originWidth / originHeight > maxWidth / maxHeight) {
                    targetWidth = maxWidth;
                    targetHeight = Math.round(maxWidth * (originHeight / originWidth));
                } else {
                    targetHeight = maxHeight;
                    targetWidth = Math.round(maxHeight * (originWidth / originHeight));
                }
                }
                // set canvas size
                canvas.width = targetWidth;
                canvas.height = targetHeight;
                const ctx = canvas.getContext("2d");

                ctx.drawImage(
                    image, 
                    crop.x * scaleX, 
                    crop.y * scaleY, 
                    crop.width * scaleX, 
                    crop.height * scaleY, 
                    0, 
                    0, 
                    targetWidth, 
                    targetHeight 
                );
            }
            


        
            // As Base64 string
            // const base64Image = canvas.toDataURL('image/jpeg');
        
            // As a blob
            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    let fileUrl
                    window.URL.revokeObjectURL(fileUrl);
                    fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl)
                },'image/png',1);
            });

        },
        handlerURL2Blob: async(url) => {
            let blob = await fetch(url).then(r => r.blob());
            let temp = blob.type.split('/');
            let ext = "."+temp[temp.length-1];
            let newfile = new File([blob], "profile"+ext, {type:blob.type});
            return newfile;
        },
        onDelete: async()=>{
            try {
                setError(null)
                
                await private_server.delete(delete_url)
                onCompleted()
                onClose()
            } catch (error) {
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
            }
            
        },
        onSubmit: async() => {
            if(!valid){
                setError('Seleccione un archivo')
                return
            }
            setLoading(true)
            try {
                if(croppedImage){
                    const file = await actions.handlerURL2Blob(croppedImage)
                    //console.log(croppedImage)
                    let _temp = {...temp_img}
                    _temp[img_type] = croppedImage
                    onSetTempImg(_temp)
                    //const ext = {type:onGetFileExtension(file.name), document:img_type ? img_type : 'web_image'}
                    // Image up load handler
                    //updateAuthorizationHeader(private_server)
                    /* console.log(ext)
                    let response = await private_server.post(submit_url, ext)
                    const options = {headers: {'Content-Type': file.type}}
                    delete axios.defaults.headers.common["Authorization"]
                    await axios.put(response.data.data.path, file, options);
                    onCompleted() */
                    
                    onClose()
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
                const _error = onGetErrorMessage(error)
                setError(_error ? _error.message : null)
                setLoading(false)
            }
        } 
    }

    return { file, src, crop, actions, loading, error}
}

export default usePictureModal