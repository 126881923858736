export const contentData = {
    spanish: {
        title: 'Detalle de evaluación',
        nodata: 'Sin Registro',
        buttons: [
            { id: 1, submit: 'Enviar a aprobación' },
            { id: 2, submit: 'Enviar a aprobación' },
            { id: 3, submit: 'Firmar', reject: 'Rechazar' },
            { id: 4, submit: 'Enviar a aprobación' },
            { id: 5, submit: 'Firmar' },
            { id: 6, submit: 'Descargar evaluación' }
        ],
        modals: {
            sign_modal: {
                title: 'Firma',
                button: 'Aprobar'
            },
            second_sign_modal: {
                title: 'Firma',
                button: 'Completar evaluación'
            },
            edit_evaluator:{
                title:'Editar evaluador',
                button:'Guardar'
            }
        },
        sections: {
            main_info: {
                evaluated: 'Evaluado',
                division: 'Área',
                position: 'Puesto',
                status: 'Estatus',
                evaluator: 'Evaluador',
                evaluation_date: 'Fecha de evaluación'
            },
            global_objective: {
                title: 'Objetivo global de la firma',
                content: [
                    'Ser el despacho de Consultoría contable, fiscal, legal y marketing referente por su alta atención, profesionalidad, internacionalización y constante innovación tecnológica para las MiPyMEs, extrajeras y nacionales, en la Ciudad de México.'
                ]
            },
            kpi: {
                title: 'KPI del departamento',
            },
            department_target: {
                title: 'Objetivo del departamento',
            },
            performance_info: {
                header: [
                    { id: 0, label: 'Área de desempeño' },
                    { id: 1, label: 'Muy bajo' },
                    { id: 2, label: 'Bajo' },
                    { id: 3, label: 'Moderado' },
                    { id: 4, label: 'Alto' },
                    { id: 5, label: 'Muy alto' },
                    { id: 6, label: 'Puntaje' }
                ],
                content: {
                    results: [
                        { id: 'results_1', label: 'Termina su trabajo oportunamente' },
                        { id: 'results_2', label: 'Cumple con las tareas que se le encomiendan' },
                        { id: 'results_3', label: 'Realiza un volumen adecuado de trabajo' },
                        { id: 'results_4', label: 'Está interesado por alcanzar los objetivos del departamento' },
                        { id: 'results_5', label: 'El tiempo de respuesta es menor a 24 horas una vez que se recibe un correo o prospecto.' },
                        { id: 'results_6', label: 'Existe un 90% de seguimiento a las tareas de asginadas, prospectos y pendientes' },
                    ],
                    quality: [
                        { id: 'quality_1', label: 'No comete errores en el trabajo' },
                        { id: 'quality_2', label: 'No requiere de supervisión frecuente' },
                        { id: 'quality_3', label: 'Se muestra profesional en el trabajo' },
                        { id: 'quality_4', label: 'Se muestra respetuoso y amable en el trato al cliente' },
                    ],
                    relations: [
                        { id: 'relations_1', label: 'Se muestra cortés con sus compañeros y personal de la firma' },
                        { id: 'relations_2', label: 'Muestra aptitud para integrarse al equipo' },
                        { id: 'relations_3', label: 'Brinda una adecuada orientación a sus compañeros.' },
                    ],
                    iniciative: [
                        { id: 'iniciative_1', label: 'Muestra nuevas ideas para mejorar los procesos' },
                        { id: 'iniciative_2', label: 'Tiene gran capacidad para resolver problemas' },
                        { id: 'iniciative_3', label: 'Se anticipa a las dificultades' },
                    ],
                    organization: [
                        { id: 'organization_1', label: 'Cumple con su horario de forma puntual' },
                        { id: 'organization_2', label: 'Hace uso racional de los recursos' },
                        { id: 'organization_3', label: 'Está en constante actualización' },
                        { id: 'organization_4', label: 'Se preocupa por defender el prestigio de la firma' }
                    ]
                }
            }
        }
    }
}
