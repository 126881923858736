export const formData = {
  rfc:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'rfc',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'rfc',
    }
  },
  name:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  requirements:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'requirements',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  due_date:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'due_date',
      type:'date',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  process:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'process',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  periodicity_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'periodicity_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
   },
   estimated_delivery_date:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'estimated_delivery_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    comments:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'comments',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    project_costs:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'project_costs',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    provider:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'provider',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    cost:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'cost',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    sell_price:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'sell_price',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    }
};
