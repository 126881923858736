export const contentTasks = {
    spanish: {
        title: 'Tareas',
        menuOptions: [
            {id:1, label:'Tareas Actuales',},
            {id:2, label:'Tareas pasadas'},
        ],
        card:{
            date_title: 'Fecha de entrega',
            priority_title: 'Prioridad',
            priority_icon: 'notifications_active',
            type_title: 'Tipo de tarea',
            type_icon: 'format_list_bulleted'
        },
        addModal:{
            title: 'Crear nueva tarea',
            button: 'Crear'
        },
        filter_options: [
			{ id: 'expiration_date', label: 'Fecha de vencimiento' },
			{ id: 'creation_date', label: 'Fecha de creación' },
			{ id: 'task_priority_id', label: 'Prioridad', revert: true },
		],
    }
}