import { mainServer } from "../../variables/config"

const useAuth = () => {

    const onReAuth = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        mainServer.instance.defaults.headers.common['Authorization'] = logData.token
        let response
        response = await mainServer.instance.get("/login/reauthenticate")
        localStorage.setItem('data',JSON.stringify(response.data.data))
    }

    const onGetUserInfo = async() => {
        const logData = JSON.parse(localStorage.getItem('data'))
        if(!logData) return null
        mainServer.instance.defaults.headers.common['Authorization'] = logData?.token
        let response
        response = await mainServer.instance.get("/user/employee/me")
        return response.data.data.employee
    }

    return {
        onReAuth,
        onGetUserInfo
    };
}
 
export default useAuth;