export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen cuentas bancarias registradas',
    tableTitle:'Timbrados',
    header:['ID','Banco','Sucursal','Cuenta','CLABE','Moneda','Archivos', ],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      cerl:{
        name:'Certificado eFirma',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
  },
  english:{

  }
}