export const contentData = {
  spanish:{
    layout:{
      title:'Prospectos',
      navigation:[
        {name:'Prospectos',path:'/prospects'},
      ],
    },
    modals:{
      warning_modal:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido'
    },
    nodata:'Sin Registro',
    header:['ID','Nombre', 'Responsable','Estatus','Estado'],
    tableTitle:'Lista de prospectos'
  }
}