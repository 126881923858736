export const contentData = {
  spanish:{
      title:'Información Básica',
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        legal_representative:{
            label:'Nombre',
            placeholder:'Nombre',
          },
          antiquity:{
            label:'Antiguedad',
            placeholder:'Antiguedad'
          },
          rfc:{
            label:'RFC',
            placeholder:'RFC'
          },
      }
  }
}