import { onGetDateFormat } from "../../../../shared/utility"
import { catalogs } from "../../../../texts/esp/catalogs"

export const cleanData2Table = (data, nodata, users, location, type_period) => {

  let table = []
  if (data)
    table = data.map(item => {

      //const image = findImageResponsible(item.responsible, users)
      let step_id = item.fixed_proc_stampings_step_id ? item.fixed_proc_stampings_step_id : 1
      let percent = catalogs.process_percent.stamping.find(e => e.id === step_id)
      let priority = catalogs.process_priority.find(p => p.value === item.solution_priority)

      let data = {
        id: item.id_proc_stamping,
        step_id: step_id,
        business: item.business ? item.business : nodata,
        importance: item.fixed_importance ? item.fixed_importance : nodata,
        responsible: item.fixed_responsible ? item.fixed_responsible : nodata,
        fixed_payday_limit: item.fixed_payday_limit ? item.fixed_payday_limit : nodata,
        step: {
          id: step_id,
          color: step_id === 7 ? 'primary' : 'secondary',
          label: item.fixed_proc_stampings_step ? item.fixed_proc_stampings_step : nodata
        },
        priority: priority,
        percent: percent.value,
        status_id: item.process_semaphore_id,
        fixed_client_classification: item?.fixed_client_classification ?? nodata,
        fixed_business_advisor: item?.fixed_business_advisor?? nodata,
        fixed_client_responsible: item?.fixed_client_responsible ?? nodata,
        fixed_client_associate: item?.fixed_client_associate ?? nodata,

        //image: image,
      }

      if(location){
        data.fixed_state = item.fixed_state ? item.fixed_state : nodata
        data.fixed_city = item.fixed_city ? item.fixed_city : nodata
      }

      if(type_period){
        data.fixed_roster_type = item.fixed_roster_type ? item.fixed_roster_type : nodata
        data.fixed_ctrl_period_name = item.fixed_ctrl_period_name ? item.fixed_ctrl_period_name : nodata
        data.fixed_payroll_periodicity = item.fixed_payroll_periodicity ? item.fixed_payroll_periodicity : nodata
      }

      return data
    })

  return table
}

const findImageResponsible = (id, users) => {
  let temp = null

  if (id !== null && id !== undefined) {
    if (users.length > 0) {
      temp = users.filter(el => el.id_user === id)
    }
  }

  return temp && temp[0] ? temp[0].image : null
}