export const formData = {
    employee_number:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'employee_number',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    payment_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'payment_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    stamping_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'stamping_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    document:{
      key:'document',
      config:{
        type:'file'
      }
    },
    responsible_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'responsible_id',
        type:'avatar_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    notes:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'notes',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    extra_hours:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_hours',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    hours_proof:{
      key:'hours_proof',
      config:{
        type:'file'
      }
    },
    extra_charges:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_charges',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    charge_proof:{
      key:'charge_proof',
      config:{
        type:'file'
      }
    },
    collections_charge:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'collections_charge',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    completition_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'completition_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    }
  }
