import React from 'react'

import SectionA from './StepsViews/SectionA/SectionA';
import SectionB from './StepsViews/SectionB/SectionB';
import SectionC from './StepsViews/SectionC/SectionC';
import SectionG from './StepsViews/SectionG/SectionG';
import SectionD from './StepsViews/SectionD/SectionD';
import SectionE from './StepsViews/SectionE/SectionE';
import SectionF from './StepsViews/SectionF/SectionF';

export const contentData = {
    spanish: {
        url: 'stamping',
        fixed_id: 'proc_stampings_step_id',
        monthly_steps: [],
        paths: {
            primary: {
                id: 'ctrl_stamping_id',
                path: 'ctrlstamping'
            },
            secondary: {
                id: 'ctrl_monthly_statements_id',
                path: 'ctrlmonthlystatement'
            }
        },
        sections: {
            general_info: {
                banner: {
                    content: {
                        fixed_name: 'Razón social',
                        fixed_rfc: 'RFC',
                        fixed_client_classification: 'Clasificación',
                        fixed_client_status: 'Estatus',
                        fixed_client_responsible: 'Responsable',
                        fixed_client_associate: 'Socio'
                    }
                },
                fiscal: {
                    title: 'Timbrado',
                    content: {
                        fixed_specs: 'Especificaciones',
                        fixed_workers_limit: 'Límite de trabajadores',
                    },
                    files: [
                        {
                            key: 'fixed_as_roster_registration',
                            url: 'stamping',
                            path: 'asrosterregistration',
                            name: 'Registro AS Nóminas',
                            tableID: 'business_id',
                            status: false
                        },
                        {
                            key: 'fixed_workers_db_analyzed',
                            url: 'stamping',
                            path: 'workersdbanalyzed',
                            name: 'Base de datos analizada',
                            tableID: 'business_id',
                            status: false
                        },
                    ]
                },
                taxes: {
                    title: 'Nómina',
                    content: {
                        fixed_payroll_periodicity: 'Periodicidad',
                        fixed_roster_type: 'Tipo de nómina',
                        fixed_payroll_system: 'Plataforma de emisión',
                        fixed_employee_number: 'Número de empleados',
                    },
                },
                responsibles: {
                    title: 'Responsables',
                    content: {
                        fixed_business_advisor: 'Asesor',
                        fixed_analyst: 'Analista',
                        fixed_supervisor: 'Supervisor',
                        fixed_stamping_responsible: 'Responsable de nómina',
                    }
                }
            },
            stepper_container: {
                top_banner: {
                    advisory: 1,
                    percent: '% Completado',
                },
                progress: 'Progreso',
                priority: 'Prioridad',
                steps: [
                    {
                        title: 'Incidencias',
                        component: <SectionA />,
                    },
                    {
                        title: 'Cálculo de nómina',
                        component: <SectionB />,
                    },
                    {
                        title: 'Revisión y Envío del Cálculo de Nóminas al Cliente',
                        component: <SectionC />,
                    },
                    {
                        title: 'Autorización y Pago de Nómina',
                        component: <SectionD />,
                    },
                    {
                        title: 'Timbrado Recibos Nómina',
                        component: <SectionE />,
                    },
                    {
                        title: 'Envío al cliente',
                        component: <SectionF />,
                    },
                    {
                        title: 'Revisión final',
                        component: <SectionG />,
                    },
                ],
            }
        }
    }
}
