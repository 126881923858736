import {mainServer} from '../../../variables/config'
import axios from 'axios'
import FileSaver from 'file-saver'

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response = await mainServer.instance.get(`/user/employee/list`);
  return response.data.data.employees
}

export const request_business = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/business/${id}` );
  return response.data.data.business
}

export const request_upload_image = async(id, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/business/image/${id}`, data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}

export const request_delete_image = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/business/image/${id}`)
}

/////////////////////////////////// SERVICES  /////////////////////////////////////////////////

export const request_add_service = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/business/service/${id}`, data);
}

export const request_delete_service = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/business/service/${id}`, {params:{...data}});
}

/////////////////////////////////// PROCESS  /////////////////////////////////////////////////

export const request_processes = async(url, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/${url}/all${params}` );
  return response.data.data
}

export const request_add_process = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/business/process/${id}`, data);
}

export const request_delete_process = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/business/process/${id}`, {data:{...data}});
}

export const request_process = async(id, url, path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/${url}/${id}`)
  return response.data.data[path];
}

export const request_update_process = async(id, url, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/${url}/${id}`, data);
}

export const request_businesses = async() => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/business/listall` );
  return response.data.data.businesses
}

/////////////////////////////////// ASSOCIATES  /////////////////////////////////////////////////

export const request_add_associate = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post(`/business/associate/${id}`, data);
}

export const request_delete_associate = async(id, data) => {
  console.log(data)
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/business/associate/${id}`, {params:{...data}});
}

export const request_update_business = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/business/${id}`, data);
}

export const request_update_business_documents = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/businessdocument/${id}`, data);
}

export const request_client_businesses = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/business/listbyclient/${id}` );
  return response.data.data.businesses
}

export const request_business_document = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/businessdocument/${id}` );
  return response.data.data.tributary_mailbox_statuses
}

///////////////////////////////   DOCUMENT'S SECTION   //////////////////////////////////////////////////

export const request_upload_document = async(id, type, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/businessdocument/${id}/${type}`, data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/businessdocument/${id}` );
  return response.data.data.tributary_mailbox_statuses
}

export const request_download_document = async(id, type, name) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/businessdocument/${id}/${type}`)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  console.log(response.data)
  FileSaver.saveAs(response.data, `${name}`);
} 

export const request_delete_document = async(id, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/businessdocument/${id}/${type}`)
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/businessdocument/${id}` );
  return response.data.data.tributary_mailbox_statuses
}


//////////////////////////////// REQUESTS FOR TIME REPORTS  ///////////////////////////////////

export const request_timereports = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/list${params}` );
  return response.data.data
}

export const request_timereport_statistic = async(id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/statistics/business/${id}`, {params:{...params}} );
  return response.data.data.statistics
}

