import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'
import {contentData} from './content'
import GeneralView from './GeneralView/GeneralView'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import { PERMISSION_MODULES } from '../../../../variables/config'


const ProspectView = ({history, language, catalogs, permissions, permissionsType, match}) => {

  const content = contentData[language]  

  let permission = permissions.find(el => el.id_permission_module === 2)
  permission = permission ? permission.id_permission_type : 1
  
  let modalcontent = null

  const prospectID = match.params.id
  let module_permission = null
  if(permissions && permissions.length) module_permission = permissions.find(el => el.permission_module_id === PERMISSION_MODULES.CLIENTS)

  return(
    <BasicView
      history={history}
      title={content.layout.title}
      setReturn
    >
      <GeneralView 
        history={history}
        language={language}
        prospectID={prospectID}
        catalogs={catalogs}
        module_permission={module_permission}
        permission={permission}
        permissionsType={permissionsType}
      />
    </BasicView>
  )
}

export default withStyles(styles)(ProspectView)