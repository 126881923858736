import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons'
import { contentData } from './content'
import { Redirect } from 'react-router'
import { request_deleteFile, request_downloadFile, request_update_password, request_update_user, request_user_add_area, request_user_delete_area, request_user_info, request_user_permissions, request_update_user_permissions } from './requests'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import ChangePasswordModal from './modals/ChangePassword/ChangePasswordModal'
import UserInfoForm from './components/UserInfoForm'
import PictureModal from './modals/PictureModal/PictureModal'
import UserModal from './modals/UserModal'
import WorkingInfoForm from './components/WorkingInfoForm'
import WorkingInfoModal from './modals/WorkingInfo/WorkingInfoModal'
import BenefitContent from './components/BenefitContent'
import BenefitsModal from './modals/Benefits/BenefitsModal'
import ContactInfoForm from './components/ContactInfoForm'
import ContactInfoModal from './modals/ContactInfo/ContactInfoModal'
import DocumentsCards from './components/DocumentsCards'
import DocumentModal from './modals/DocumentModal'
import PermissionContent from './components/PermissionsContent/PermissionContent'
import PermissionsModal from './modals/Permissions/PermissionsModal'

const styles = makeStyles((theme) => ({
  root: { padding: '32px' },
  subtitle: { color: theme.palette.primary.main, fontWeight: '500' },
  nospeciality: { color: grey[700], fontWeight: '500' }
}))


const GeneralView = ({ history, permission, permissions,
  permissionsType, catalogs, language, userID }) => {

  const classes = styles()
  const content = contentData[language]

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  const [selected_document, setSelectedDocument] = useState(null)

  const [user, setUser] = useState(null)
  const [user_permissions, setUserPermissions] = useState([])
  ///////// MODAL'S STATES //////////////////////////////////
  const [profilemodal, setProfileModal] = useState(false)
  const [changepasswordmodal, setChangePasswordModal] = useState(false)
  const [usermodal, setUserModal] = useState(false)
  const [workingmodal, setWorkingModal] = useState(false)
  const [benefitmodal, setBenefitModal] = useState(false)
  const [contactinfomodal, setContactInfoModal] = useState(false)
  const [permissionmodal, setPermissionModal] = useState(false)
  const [workingInfoPermission, setWorkingPermission] = useState(null)
  const [documentmodal, setDocumentModal] = useState(false)
  const [deletefilemodal, setDeleteFileModal] = useState(false)

  useEffect(() => {
    const initModule = async () => {
      try {
        setLoading(true)
        let response = await request_user_info(userID)
        setUser({ ...response })
        response = await request_user_permissions(userID)
        setUserPermissions([...response])
        let _workingInfoPermission = permissions.find(p => p.id_permission_module === 73)
        _workingInfoPermission = _workingInfoPermission ? _workingInfoPermission.id_permission_type : -1
        setWorkingPermission(_workingInfoPermission)
      } catch (error) {
        console.log(error)
      }
      setLoading(false)
    }
    initModule()
  }, [userID])

  const onUpdateCompleted = async () => {
    try {
      setSending(true)
      let response = await request_user_info(userID)
      setUser({ ...response })
      setSending(false)
      setProfileModal(false)
      setDocumentModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const onChangePassword = async (data) => {
    try {
      setSending(true)
      let response = await request_update_password(userID, data)
      setUser({ ...response })
      setSending(false)
      setSuccess(true)
      setChangePasswordModal(false)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const onUpdatePermissions = async (data) => {
    try {
      setSending(true)
      const _user_permissions = await request_update_user_permissions(userID, data)
      setUserPermissions([..._user_permissions])
      let _workingInfoPermission = permissions.find(p => p.id_permission_module === 73)
        _workingInfoPermission = _workingInfoPermission ? _workingInfoPermission.id_permission_type : -1
        setWorkingPermission(_workingInfoPermission)
      setSending(false)
      setSuccess(true)
    } catch (e) {
      console.log(e)
      setError('No fue posible guardar los cambios')
    }
    setSending(false)
  }

  const onUpdateUser = async (data) => {
    try {
      setSending(true)
      if (data.area_id) {
        if (user.area_id) await request_user_delete_area(userID, user.area_id)
        await request_user_add_area(userID, data.area_id)
      }
      const _user = await request_update_user(userID, data)
      setUser({ ..._user })
      setSending(false)
      setUserModal(false)
      setWorkingModal(false)
      setBenefitModal(false)
      setContactInfoModal(false)
      setPermissionModal(false)
      setSuccess(true)
    } catch (e) {
      console.log(e)
      setError('No fue posible guardar los cambios')
    }
    setSending(false)
  }

  //////    DOCUMENTS     /////
  const onUploadDocument = (data) => {
    setSelectedDocument(data)
    setDocumentModal(true)
  }

  const onDownloadDocument = async (filedata) => {
    try {
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/') + 1)
      await request_downloadFile(userID, filedata.path)
    } catch (e) {
      console.log(e)
      setError('No fue posible descargar el documento.')
    }
    setSending(false)
  }

  const onDeleteDocument = async (filedata) => {
    try {
      setSending(true)
      const path = history.location.pathname
      const userID = path.substring(path.lastIndexOf('/') + 1)
      let response = await request_deleteFile(userID, filedata.path)
      setUser({ ...response })
      setSending(false)
      setDeleteFileModal(false)
      setSuccess(true)
    } catch (e) {
      console.log(e)
      setError('No fue posible eliminar el documento')
    }
    setSending(false)
  }

  let files = [...content.files];

  if (user) {
    files.forEach((item, key) => {
      let temp = user[item.key]
      files[key].status = temp && temp !== "0" && temp !== 0 && temp !== ""
    })
  }


  return (
    <LoadingContainer
      loading={loading}
      sending={sending}
      success={success}
      error={error}
      onCloseError={() => setError(null)}
      onCloseSuccess={() => setSuccess(false)}
    >
      <div>
        {permission === 1 ? (
          <Redirect to='/profile' />
        ) : 
          permission >= permissionsType.EDIT ?
            <Grid item xs={12}>
              <Grid container spacing={3} justify='flex-end'>
                <Grid item>
                  <RoundedButton label='Cambiar Contraseña' size='small' color='secondary' onClick={() => setChangePasswordModal(true)} />
                </Grid>
              </Grid>
            </Grid>
            :
            null
        }
        <ChangePasswordModal open={changepasswordmodal} id={userID} onSubmit={onChangePassword} onClose={() => setChangePasswordModal(false)} language={language} />
        <PictureModal open={profilemodal} id={userID} onUpdateCompleted={onUpdateCompleted} onClose={() => setProfileModal(false)} />
        <UserModal sending={sending} open={usermodal} catalogs={catalogs} origin_data={user} language={language} onSubmit={onUpdateUser} onClose={() => setUserModal(false)} content={content.modals.user_modal} />
        <WorkingInfoModal sending={sending} open={workingmodal} catalogs={catalogs} origin_data={user} language={language} onSubmit={onUpdateUser} onClose={() => setWorkingModal(false)} content={content.modals.work_modal} />
        <BenefitsModal sending={sending} open={benefitmodal} origin_data={user ? user.benefits : []} language={language} onSubmit={onUpdateUser} onClose={() => setBenefitModal(false)} />
        <ContactInfoModal sending={sending} open={contactinfomodal} catalogs={catalogs} origin_data={user} language={language} onSubmit={onUpdateUser} onClose={() => setContactInfoModal(false)} />
        <DocumentModal id={userID} open={documentmodal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
        <PermissionsModal sending={sending} open={permissionmodal} origin_data={user_permissions} language={language} onSubmit={onUpdatePermissions} onClose={() => setPermissionModal(false)} />
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <UserInfoForm data={user} onEdit={() => setUserModal(true)} onChangePicture={() => setProfileModal(true)} permission={permission} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                {workingInfoPermission > 1 ? <WorkingInfoForm title={content.title.sectionB} data={user} onEdit={() => setWorkingModal(true)} permission={workingInfoPermission} /> :null}
              </Grid>
              <Grid item xs={12}>
                <ContactInfoForm title={content.title.sectionC} data={user} onEdit={() => setContactInfoModal(true)} permission={permission} />
              </Grid>
              <Grid item xs={12}>
                <DocumentsCards title={content.title.sectionD} permission={permission} files={user} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <BenefitContent title={content.title.sectionE} benefits={user ? user.benefits : null} permission={permission} onEdit={() => setBenefitModal(true)} />
              </Grid>
              <Grid item xs={12}>
                <PermissionContent title={content.title.sectionF} permissions={user_permissions} permission={permission} onEdit={() => setPermissionModal(true)} language={language} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LoadingContainer>

  )
}

export default GeneralView
