export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Detallada',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:'',
        },
        name:{
          label:'',
          data:'',
        },
        company_type:{
          label:'',
          data:'',
        },
        process:{
          label:'',
          data:'',
        },
        due_date:{
          label:'',
          data:'',
        },
        responsible:{
          label:'',
          data:'',
        },
        estimated_delivery_date:{
          label:'',
          data:'',
        },
        comments:{
          label:'',
          data:'',
        },
        project_costs:{
          label:'',
          data:'',
        },
        provider:{
          label:'',
          data:'',
        },
        sell_price:{
          label:'',
          data:'',
        }
      },
      content:{
        rfc:{
          label:'RFC'
        },
        name:{
          label:'Nombre del trabajador'
        },
        company_type:{
          label:'Tipo de sociedad',
        },
        process:{
          label:'Tipo de proceso',
        },
        due_date:{
          label:'Fecha requerida'
        },
        responsible:{
          label:'Responsable'
        },
        estimated_delivery_date:{
          label:'Fecha Estimada de Entrega'
        },
        comments:{
          label:'Comentarios'
        },
        provider:{
          label:'Proveedor'
        },
        project_costs:{
          label:'Costos del Proyecto'
        },
        cost:{
          label:'Costo'
        },
        sell_price:{
          label:'Precio de venta'
        }
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'sat_enrollment',name:'Acuse de inscripción al SAT',path:'satenrollment', status:false},
          {key:'fiscal_situation_proof',name:'Constancia de Situación Fiscal Persona Moral',path:'fiscalsituationproof', status:false, businessDocument:true},
          {key:'attorney_power',name:'Poder Notarial',path:'attorneypower', status:false},
          {key:'rppyc',name:'R.P.P. Y C',path:'rppyc', status:false},
          {key:'address_proof',name:'Comprobante de Domicilio Fiscal (empresa o socio)',path:'addressproof', status:false, businessDocument:true},
          {key:'oficial_id',name:'IFE o Pasaporte Representante Legal',path:'oficialid', status:false, businessDocument:true},
          {key:'questionnaire',name:'Cuestionario',path:'questionnaire', status:false},
          {key:'constitutive_act',name:'Acta Constitutiva',path:'constitutiveact', status:false, businessDocument:true},
          {key:'documents',name:'Documentos',path:'documents', status:false}        
        ]
      }
    }
  }
}