export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    title:'',
    sectionA:{
      rfc:{
        label:'R.F.C.',
        placeholder:'R.F.C.',
      },
      name:{
        label:'Razón Social',
        placeholder:'Razón Social',
      },
      fiscal_regime_id:{
        label:'Régimen Fiscal',
        placeholder:'Régimen Fiscal',
      },
      legal_representative_id:{
        label:'Representante Legal',
        placeholder:'Representante Legal'
      },
      activity_id:{
        label:'Actividades',
        placeholder:'Actividades'
      },
      subactivity_id:{
        label: 'Subactividad',
        placeholder:'Subactividad'
      },
      sub_activity:{
        label:'Detalles de Subactividad',
        placeholder:'Detalles de Subactividad',
      },
      economic_activity_sector:{
        label:'Detalles de Sector de actividad económica',
        placeholder:'Detalles de Sector de actividad económica',
      },
      business_status_id:{
        label:'Estatus',
        placeholder:'Estatus',
      }
    },
  },
}