import React, { useState, useEffect } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import { onGetUserID } from '../../../shared/utility';
import SimpleTable from './components/SimpleTable';
import ToolbarTable from './components/ToolbarTable';
import AddTimeReportModal from './modals/AddTimeReportModal';
import { request_pending_timereports, request_rejected_timereports } from './requests';
import ApprovalTableView from './views/ApprovalTableView';
import GeneralTableView from './views/GeneralTableView';
import RejectedTableView from './views/RejectedTableView';
import SupervisedTableView from './views/SupervisedTableView';
import queryString from 'query-string' 

const TimeReports = (props) => {

    const {history} = props

    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [addtimereportmodal, setAddtimereportmodal] = useState(false)
    const [selected, setSelected] = useState(1) // 1. General View    2. Approved view  3. Rejected  4. Supervisados
    const [pending, setPending] = useState(0)
    const [rejected, setRejected] = useState(0)

    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async() => {
            setLoading(true)
            const _pending = await request_pending_timereports(userID)
            const _rejected = await request_rejected_timereports(userID)
            setPending(_pending)
            setRejected(_rejected)
            const args = queryString.parseUrl(history.location.search)
            const {view} = args.query
            if(view) setSelected(parseInt(view))
            setLoading(false)
        }
        initModule()
    },[])

    const onChangeView = (id) => {
        console.log('cambiar vista')
        const urlwithargs = `?view=${id}`
        window.history.replaceState(null, null, urlwithargs);
        setSelected(id)
    }


    let contentView
    switch (selected) {
        case 1:
            contentView = <GeneralTableView history={history} />
            break;
        case 2:
            contentView = <ApprovalTableView history={history}/>
            break;
        case 3:
            contentView = <RejectedTableView history={history}/>
            break;
        case 4:
            contentView = <SupervisedTableView history={history}/>
            break;    
        default:
            break;
    }



    const menuOptions = [
        {id:1, label:'Mis reportes',},
        {id:3, label:'Rechazados', number:rejected, permissions:true},
        {id:2, label:'Pendientes de aprobación', number:pending, permissions:true},
        {id:4, label:'Supervisados', permissions:true},
    ]


    return ( 
        <div>
            <BasicView title='Time Report' error={error} onCloseError={() => setError(null)} loading={loading}
            success={success} onCloseSuccess={() => setSuccess(null)}>
                <AddTimeReportModal open={addtimereportmodal} onClose={() => setAddtimereportmodal(false)} history={history}/>
                <ToolbarTable selected={selected} onChange={onChangeView} menuOptions={menuOptions} 
                    onAddRegister={() => setAddtimereportmodal(true)}/>
                <div style={{paddingTop:32}}>
                    {contentView}
                </div>

            </BasicView>
        </div> 
     );
}
 
export default TimeReports;