import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles.jsx';
import {
  ClickAwayListener,
  Popper,
  Fade,
  Paper,
  MenuList,
  MenuItem,
  Typography,
  Icon,
  withWidth
} from '@material-ui/core';


class UserPopper extends Component{

  onItemSelected = (item) => {
    const {onClickAway, onItemSelected} = this.props
    onClickAway()
    onItemSelected(item)
  }


  render(){

    const {open, anchorEl, onClickAway, classes, onMouseOut, menu} = this.props;

    return(
      <div onMouseLeave={onMouseOut}>
        <Popper open={open} anchorEl={anchorEl} placement={'bottom-end'} transition className={classes.popper}>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <ClickAwayListener onClickAway={onClickAway} >
                  <MenuList >
                    {menu.map((item,key)=> {
                      return(
                        <MenuItem onClick={() => this.onItemSelected(item.path)} key={key.toString()}>
                          <Icon className={classes.iconUserMenu}>
                            {item.icon}
                          </Icon>
                          <Typography variant='body2'>
                            {item.label}
                          </Typography>
                        </MenuItem>
                      )
                    })
                      
                    }
                    
                  </MenuList>

                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </div>
    );
  }
};

export default withStyles(styles)(withWidth()(UserPopper));
