export const formData = {
    client_documents:{
      key:'client_documents',
      config:{
        type:'file'
      }
    },
    alert_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'alert_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    notice_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'notice_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    folio:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'folio',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    representation_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'representation_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    compliance_officer:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'compliance_officer',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    work_sheet:{
      key:'work_sheet',
      config:{
        type:'file'
      }
    },
    receipt:{
      key:'receipt',
      config:{
        type:'file'
      }
    },
    responsible_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'responsible_id',
        type:'avatar_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    notes:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'notes',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    extra_hours:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_hours',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    hours_proof:{
      key:'hours_proof',
      config:{
        type:'file'
      }
    },
    extra_charges:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'extra_charges',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    charge_proof:{
      key:'charge_proof',
      config:{
        type:'file'
      }
    },
    collections_charge:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'collections_charge',
        type:'number',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    completition_date:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'completition_date',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    }
    
  }
