import React, { Component } from 'react';
import { Provider } from 'react-redux';
import PublicRouters from './routes/router.jsx';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { blueGrey } from '@material-ui/core/colors';
import { createBrowserHistory } from "history";
import reducer from './redux/reducer'
import { createStore } from 'redux';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#133975' }, // Purple and green play nicely together. #091635
    secondary: { main: '#1F77F7' }, // This is just green.A700 as hex. #00acc1
    custom: {main: '#640178'}, //2196F3
    text:{
      secondary:blueGrey[500]
    }
  },
  typography: {
    useNextVariants: true,
    fontFamily:[
      'Poppins',
    ].join(','),
  },
  overrides:{
    MuiTypography:{
      subtitle1:{
        fontSize:'1rem',
        fontWeight:500,
      },
    }
  },
  '@global': {
    '*::-webkit-scrollbar': {
      width: '0.4em'
    },
    '*::-webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '*::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,.1)',
      outline: '1px solid slategrey'
    },
  },
});

const store = createStore(reducer);

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <Provider store={store}>
          <PublicRouters history={createBrowserHistory()}/>
        </Provider>
      </MuiThemeProvider>
    );
  }
}

export default App;
