import React from 'react'
import { Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import ActionBar from './ActionBar'
import empty from '../../../../../../assets/empty.png'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../../../../texts/esp/catalogs'
import GenericStatus from '../../../../../../components_v1/Structure/DisplayData/GenericStatus'
import AvatarName from '../../../../../../components_v1/Structure/DisplayData/AvatarName'
import { onGetDateFormat } from '../../../../../../shared/utility'

const header = [
    {id:1, label:'ID'},
    {id:2, label:'Fecha en que se realizó el servicio'},
    {id:3, label:'Notas'},
    {id:4, label:'Completado'},
    {id:5, label:'Comprobante Carga'},
]

const FileItemWrapperDownload = (props) => {
    const {onDownload, data} = props
    return(
      <Grid container spacing={0} wrap='nowrap'>
        <Grid item>
          <IconButton color='primary' disabled={!data.status} onClick={() => onDownload(data)}>
            <Icon fontSize='small'>cloud_download</Icon>
          </IconButton>
        </Grid>
      </Grid>
    )
}

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        marginTop:4,
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, status, onChangeDate, onChangeFilter, total, tableFilter, 
        onUpdateTableFilter, onSelectedItem, loading, onDownloadFile} = props


    const onInnerChange = (value, id) => {
        console.log(id)
        console.log(value)
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{item.data[0]}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{onGetDateFormat(item.data[1])}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{item.data[2]}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{item.data[3]}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} ><FileItemWrapperDownload data={item.files[0]} onDownload={onDownloadFile}/></TableCell>
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable