import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { grey, amber, red } from '@material-ui/core/colors';

import { Icon, Typography, Grid, IconButton, Hidden} from '@material-ui/core';

const header_styles = (theme) => ({
  container:{
    padding:'8px'
  },
  text:{
    color: grey[500],
    fontWeight:'500'
  },
  textaction:{
    color: grey[500],
    fontWeight:'500',
    padding:'0px 40px'
  }
})

class HeaderTableW extends Component {

  render(){

    const {classes, data} = this.props;

    return(
      <div className={classes.container}>
        <Grid container>
          <Grid item xs={6}><Typography variant='body2' className={classes.text}>{data.title}</Typography></Grid>
          <Grid item xs={6}><Typography variant='body2' className={classes.textaction}>{data.actions}</Typography></Grid>
        </Grid>
      </div>
    )
  }
}

const HeaderTable = withStyles(header_styles)(HeaderTableW);

const DocumentItem = (props) => {
  const {disabled, onUpload, onDownload, onDelete, data,
    permission, permissionsType} = props;
  
  const styles = {
    card:{borderRadius:'16px'},
    container:{padding:'8px', paddingLeft:'8px', borderTop:`1px solid ${grey[200]}`},
    folder:{color:amber[500]},
    name:{color:grey[700], fontWeight:'500'}
  }

  const delete_color = disabled ? grey[400] : red[700]

  return(
      <div style={styles.container}>
        <Hidden smDown>
          <Grid container wrap='nowrap' alignItems='center' spacing={1}>
            <Grid item>
              <Icon style={styles.folder}>folder</Icon>
            </Grid>
            <Grid item xs>
              <Typography variant='body2' style={styles.name}>{data.name}</Typography>
            </Grid>
            
            <Grid item>
              <Grid container>
                {
                  permission >= permissionsType.EDIT ?
                  <Grid item>
                    <IconButton color='primary' onClick={() => onUpload(data)}>
                      <Icon fontSize='small'>cloud_upload</Icon>
                    </IconButton>
                  </Grid> : null
                }
                <Grid item>
                  <IconButton color='primary' disabled={disabled} onClick={() => onDownload(data)}>
                    <Icon fontSize='small'>cloud_download</Icon>
                  </IconButton>
                </Grid>
                {
                  permission >= permissionsType.DELETE ?
                  <Grid item>
                    <IconButton disabled={disabled} onClick={() => onDelete(data)}>
                      <Icon fontSize='small' style={{color:delete_color}}>close</Icon>
                    </IconButton>
                  </Grid> : null
                }
              </Grid>
            </Grid>
            
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid container wrap='nowrap' alignItems='center' spacing={1}>
            <Grid item>
              <Icon style={styles.folder}>folder</Icon>
            </Grid>
            <Grid item xs>
              <Typography variant='body2' style={styles.name}>{data.name}</Typography>
            </Grid>
          </Grid>
          <Grid container alignItems='center' spacing={1}>
            <Grid item>
              <IconButton color='primary' onClick={() => onUpload(data)}>
                <Icon fontSize='small'>cloud_upload</Icon>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton color='primary' disabled={disabled} onClick={() => onDownload(data)}>
                <Icon fontSize='small'>cloud_download</Icon>
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton disabled={disabled} onClick={() => onDelete(data)}>
                <Icon fontSize='small' style={{color:delete_color}}>close</Icon>
              </IconButton>
            </Grid>
            
          </Grid>
        </Hidden>
      </div>
  )
}

class DocumentTable extends Component {
  render(){

    const {header, files, onUpload, onDownload, onDelete, 
    permission, permissionsType} = this.props;

    let filesView = null;

    if(files && files.length > 0){
      filesView = files.map((item,key) => {
        return(
          <Grid item xs={12} key={key.toString()}>
            <DocumentItem 
              data={item}
              disabled={!item.status}
              onUpload={onUpload}
              onDownload={onDownload}
              onDelete={onDelete}
              permission={permission}
              permissionsType={permissionsType}
            />
          </Grid>
        )
      })
    }


    return(
      <div>
        <Grid container>
          <Grid item xs={12}>
            <HeaderTable data={header}/>
          </Grid>
          <Grid item xs={12}>
            <div style={{height:369, maxHeight:369, overflowY:'auto', overflowX:'hidden'}}>
              <Grid container spacing={1}>
                {filesView}
              </Grid>
            </div>
            
          </Grid>
        </Grid>
        

      </div>
    )
  }
}

export default DocumentTable;