export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions: [
            {
                id: 'payment_authorization',
                title: 'Autorización'
            },
            {
                id: 'paid',
                title: 'Pago de Nómina'
            },
        ],
    }
}
