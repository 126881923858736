import React, {useState} from 'react'
import { Grid, Icon, IconButton, makeStyles, Popover } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import cx from 'classnames'
import DisplayText from '../../../../components_v1/Texts/DisplayText'

const useStyles = makeStyles(theme => ({
    root:{
        background:'white',
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:'4px 16px',
        borderRadius:20,
        '&:hover':{
            cursor:'pointer'
        }
    },
    icon:{
        color:blueGrey[500],

    },
    subtitle:{
        paddingLeft:0,
        paddingBottom:0,
        color:theme.palette.primary.main
    },
    popper:{
        marginTop:8,
        padding:'16px 8px',
        paddingLeft:0,
        borderRadius:32,
        width:200,
        boxSizing:'border-box',
        overflow:'hidden'
    },
    container:{
        maxHeight:250,
        //background:'red',
        overflowX:'hidden',
        overflowY:'auto',
        
    },
    item:{
        fontFamily: theme.typography.fontFamily,
        padding:"12px 16px",
        borderTop:`1px solid ${grey[200]}`,
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    selected:{
        fontWeight:500
    },
    filterButton:{
        background:'white',
        color:theme.palette.primary.main,
        '&:hover':{background:'white'}
    }
}))

const AddFilterButton = props => {
    const classes = useStyles()
    const {selected, onChange, log} = props

    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerChange = (id) =>{
        setAnchorEl(null)
        onChange(id)
    }

    const options = [
        {value:'USER_MODAL',label:'Usuario'},
        {value:'BUSINESS_MODAL',label:'Negocio'},
    ]

    return(
        <div>
            <Popover classes={{paper:classes.popper}} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={(e) => setAnchorEl(null)} anchorOrigin={{vertical: 'bottom',horizontal: 'left'}} transformOrigin={{vertical: 'top',horizontal: 'left'}} >
                <div className={classes.container} >
                    {options.map((item,key)=>{
                        return(
                            <div key={key.toString()} className={cx({
                                [classes.item]:true,
                                //[classes.selected]: selected === item.value
                            })} onClick={() => onInnerChange(item.value)}>
                                {item.label}
                            </div>
                        )
                    })}
                </div>
            </Popover>
            <div onClick={(e) => setAnchorEl(e.currentTarget)}>
                <IconButton className={classes.filterButton}><Icon>filter_list</Icon></IconButton>
            </div>
        </div>
        
    )
}

export default AddFilterButton