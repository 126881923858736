import React, { useEffect, useState } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Accordion2, AccordionDetails, AccordionSummary2 } from '../../../../../../components_v1/Structure/Accordion/Accordion';
import ShadedInputText from '../../../../../../components_v1/Forms/ShadedInputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputSelectStatus from '../../../../../../components_v1/Forms/InputSelectStatus';
import { onGetErrorMessage, onInitFormProcess, onUpdateDocumentListProcess } from '../../../../../../shared/utility';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import { contentData } from './content'
import { catalogs } from '../../../../../../texts/esp/catalogs';
import { grey } from '@material-ui/core/colors';
import DocumentInput from '../../../../../../components_v1/Forms/DocumentInput';
import Responsibles from '../Responsibles';
import { request_process } from '../../requests';
import DocumentModal from '../../modals/DocumentModal';

const styles = makeStyles(theme => ({
    banner: {
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32,
        },
        borderBottom: `1px solid ${grey[300]}`
    },
    banner2: {
        padding: 64,
        [theme.breakpoints.down('sm')]: {
            padding: 32,
        },
    },
    subAccordion: {
        backgroundColor: 'rgba(75, 146, 248, 0.15)',
        color: '#0F2358'
    },
    title: {
        fontSize: 18,
        fontWeight: 500
    },
    smallTitle: {
        fontSize: 14,
        fontWeight: 500,
        color: '#607D8B'
    },
}))

const SectionA = ({ id, ctrlID, process, onUpdate, onUpdateStep, language, permission, onDownloadDocument }) => {

    const step = 1
    const classes = styles()

    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [files, setFiles] = useState(JSON.parse(JSON.stringify(filesData)))
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //const [process, setProcess] = useState(null)
    const [selected_document, setSelectedDocument] = useState(null)
    const [accordions, setAccordions] = useState(null)
    const content = contentData[language]

    const [document_modal, setDocumentModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            try {
                setLoading(true)
                //setProcess(_process)
                let _form = JSON.parse(JSON.stringify(formData))
                _form = onInitFormProcess(_form, process, step)
                setForm(_form)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, process, step)
                setFiles(_files)
                let _accordions = content.accordions.map(item => true)
                setAccordions(_accordions)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }
        initModule()
    }, [])

    const onUploadDocument = (data) => {
        setSelectedDocument(data)
        setDocumentModal(true)
    }

    const onUpdateCompleted = async (newStep) => {
        try {
            setSending(true)
            if (newStep)
                await onUpdate(form, true, newStep, content.responsible_banner)
            else {
                let _process = await request_process(id, `?step_id=${step}`)
                let _files = JSON.parse(JSON.stringify(filesData))
                _files = onUpdateDocumentListProcess(_files, _process, step)
                setFiles(_files)
            }
            setDocumentModal(false)
            setSending(false)
            setSuccess(true)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setSending(false)
    }

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const handleAccordions = (id) => {
        let _accordions = [...accordions]
        _accordions[id] = !accordions[id]
        setAccordions(_accordions)
    }

    return (
        <LoadingContainer loading={loading} sending={sending} success={success} error={error} onCloseSuccess={() => setSuccess(false)} onCloseError={() => setError(false)}>
            <div>
                <DocumentModal id={id} idExt={ctrlID} step={step} open={document_modal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted} />
                <Grid container justify='center' spacing={8}>
                    <Grid item xs={12}>
                        <div className={classes.banner}>
                            <ShadedInputText
                                className={classes.selectStatus}
                                color='primary'
                                data={form.observations}
                                onChange={onChange}
                                disabled={!permission}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.banner2}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <DisplayText variant='body2' className={classes.title}>{content.title}</DisplayText>
                                </Grid>
                                <Grid item xs={12}>
                                    {accordions && content.accordions.map((accordionData, key) => {
                                        const file = files.find(f => f.path === `${accordionData.id}_file`)

                                        return (
                                            <Grid item xs={12}>
                                                <Accordion2 expanded={accordions[key]} onChange={() => handleAccordions(key)}>
                                                    <AccordionSummary2
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls={`${accordionData.id}_controls`}
                                                        id={accordionData}
                                                        classes={{ root: classes.subAccordion }}
                                                    >
                                                        <DisplayText variant='body2' align='center'>{accordionData.title}</DisplayText>
                                                    </AccordionSummary2>
                                                    <AccordionDetails>
                                                        <Grid container justify='center' spacing={4}>
                                                            <Grid item xs={12} sm={3}>
                                                                <InputSelectStatus
                                                                    className={classes.selectStatus}
                                                                    color='primary'
                                                                    data={form[accordionData.id]}
                                                                    onChange={onChange}
                                                                    disabled={!permission}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={3}>
                                                                <InputSelect
                                                                    className={classes.selectStatus}
                                                                    color='primary'
                                                                    data={form[`${accordionData.id}_upload_status_id`]}
                                                                    onChange={onChange}
                                                                    disabled={!permission}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={2}>
                                                                <DocumentInput
                                                                    data={file}
                                                                    onUploadDocument={() => onUploadDocument(file)}
                                                                    onDownloadDocument={() => onDownloadDocument(file)}
                                                                    disabled={!permission}
                                                                    downloadEnabled
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12} sm={4}>
                                                                <ShadedInputText
                                                                    className={classes.selectStatus}
                                                                    color='primary'
                                                                    data={form[`${accordionData.id}_comments`]}
                                                                    onChange={onChange}
                                                                    disabled={!permission}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion2>
                                            </Grid>
                                        )
                                    })}
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <Responsibles data={process} step={step} language={language} responsible_banner={content.responsible_banner} />
                    </Grid>
                    <Grid item>
                        <RoundedButton disabled={!permission} color='secondary' onClick={() => onUpdateCompleted(step + 1)}>
                            {content.button}
                        </RoundedButton>
                    </Grid>
                </Grid>
            </div>
        </LoadingContainer>
    )
}

const filesData = [
    { path: 'bank_statements_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'payment_gateway_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'invoices_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'spending_control_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'inventary_control_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'import_petition_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'abroad_expenses_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'internal_control_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'intercompany_operation_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'contract_note_mandate_file', name: 'Archivo', status: false, ctrlpath: true, },
    { path: 'other_file', name: 'Archivo', status: false, ctrlpath: true, },
]
const formData = {
    observations: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'observations',
            type: 'text',
            fullWidth: true,
            label: 'Observaciones',
            helper: 'Introduce tus observaciones',
            multiline: true,
            rows: 6
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    bank_statements: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'bank_statements',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    bank_statements_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'bank_statements_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    bank_statements_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'bank_statements_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    payment_gateway: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'payment_gateway',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    payment_gateway_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'payment_gateway_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    payment_gateway_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'payment_gateway_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    invoices: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'invoices',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    invoices_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'invoices_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    invoices_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'invoices_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    spending_control: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'spending_control',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    spending_control_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'spending_control_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    spending_control_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'spending_control_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    inventary_control: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'inventary_control',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    inventary_control_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'inventary_control_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    inventary_control_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'inventary_control_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    import_petition: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'import_petition',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    import_petition_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'import_petition_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    import_petition_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'import_petition_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    abroad_expenses: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'abroad_expenses',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    abroad_expenses_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'abroad_expenses_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    abroad_expenses_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'abroad_expenses_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    internal_control: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'internal_control',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    internal_control_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'internal_control_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    internal_control_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'internal_control_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    intercompany_operation: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'intercompany_operation',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    intercompany_operation_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'intercompany_operation_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    intercompany_operation_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'intercompany_operation_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    contract_note_mandate: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'contract_note_mandate',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    contract_note_mandate_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'contract_note_mandate_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    contract_note_mandate_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'contract_note_mandate_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
    other: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_documents_statuses],
        config: {
            id: 'other',
            type: 'simple_select',
            fullWidth: true,
            label: 'Disponibilidad',
            helper: 'Disponibilidad'
        },
        rules: {
            type: 'none',
        }
    },
    other_upload_status_id: {
        value: 1,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        options: [...catalogs.accounting_information_upload_statuses],
        config: {
            id: 'other_upload_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Almacen',
            helper: 'Almacen'
        },
        rules: {
            type: 'none',
        }
    },
    other_comments: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        ctrlpath: true,
        config: {
            id: 'other_comments',
            type: 'text',
            fullWidth: true,
            label: 'Comentarios',
            helper: 'Introduce los comentarios',
        },
        rules: {
            type: 'distance',
            min: 1
        }
    },
}

export default SectionA