export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    title:'',
    sectionA:{
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
      },
      last_name:{
        label:'Apellidos(s)',
        placeholder:'Apellidos(s)',
      },
      username:{
        label:'Usuario',
        placeholder:'Usuario',
      },
      client_user_level_id:{
        label:'Nivel de Usuario',
        placeholder:'Nivel de Usuario',
      },
      timezone:{
        label:'Zona Horaria',
        placeholder:'Zona Horaria',
      },
      gender_id:{
        label:'Género',
        placeholder:'Género',
      },
      country_id:{
        label:'País',
        placeholder:'País',
      },
      area_id:{
        label:'Área',
        placeholder:'Área',
      },
      position_id:{
        label:'Puesto',
        placeholder:'Puesto',
      },
    },
  },
}