export const formData = {
  notes:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'notes',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  extra_hours:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_hours',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  hours_proof:{
    key:'hours_proof',
    config:{
      type:'file'
    }
  },
  extra_charges:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_charges',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  charge_proof:{
    key:'charge_proof',
    config:{
      type:'file'
    }
  },
  collections_charge:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'collections_charge',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  completition_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'completition_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  }
  }
