export const onFilteringData = (data, text, direction) => {
  let temp=[]
  // Filter by text
  if(text && text !== ''){
      data.forEach((item)=>{
          const decision = item.businesses_name.toLowerCase().includes(text.toLowerCase())
          if(decision){
              temp.push(item)
          }
      })
  }else{
      temp = [...data]
  }
  // Filter by direction
  if(direction){
      if(direction === 1){
          temp = temp.sort(onAscSorting)
      }else{
          temp = temp.sort(onDesSorting)
      }
  }
  return temp

}

const onAscSorting = (a,b) => {
    return  -b.businesses_name.localeCompare(a.businesses_name)
}

const onDesSorting = (a,b) => {
    return  b.businesses_name.localeCompare(a.businesses_name)
  }