import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid} from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'

import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import ClientuserList from './ClientuserList/ClientuserList'
import WarningModal from '../../../../components/Modals/WarningModal'

import {request_clientusers, request_delete_client} from './requests'
import {cleanData2Table} from './customFunctions'

class Clientusers extends Component{

  state = {
    isloading:false,
    clientusers:[],
    users:[],
    modals:{
      warning_delete_prospect:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onGetPageID = () => {
    return this.props.match.params.id
  }

  initModule = async() => {
    const clientID = this.onGetPageID()
    this.setState({isloading:true})
    let response = await request_clientusers(clientID)
    this.setState({clientusers:[...response]})
    this.setState({isloading:false})
  }

  onClientuserSelected = async(id) => {
    const {history} = this.props
    history.push(history.location.pathname + '/'+id)
  }

  onAddProspect = async() => {
    console.log('Agregar usuario')
    const {history} = this.props
    history.push('/clientusers/add')

  }

  onPreDeleteProspect = (id) => {
    const type = 'warning_delete_prospect'
    this.onEditModal(type, {open:true, data:id})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onDeleteProspect = async(id) => {
    const type = 'warning_delete_prospect'
    try {
      this.onEditModal(type, {issending:true})
      let response = await request_delete_client(id)
      this.setState({clientusers:[...response]})
      this.onEditModal(type, {open:false,issending:false, error:null})
      this.onActivateSuccessSnack()
    } catch (error) {
      if(error.response){
        this.onEditModal(type,{issending:false, error:error.response.data.error.message})
      }
    }
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  
  render(){

    const {history, language} = this.props
    const {clientusers, users, modals, success, isloading} = this.state
    const content = contentData[language]
    const data_clientusers = cleanData2Table(clientusers,content.nodata, users)

    let modalContent = null

    if(modals.warning_delete_prospect){
      modalContent=(
        <WarningModal 
          data={modals.warning_delete_prospect}
          content={content.modals.warning_modal}
          onClose={() => this.onCloseModal('warning_delete_prospect')}
          onDelete={this.onDeleteProspect}
        />
      )
    }
    
    return(
      <BasicView
        history={history}
        content={content.layout}
        success={success}
        onCloseSnackbar={this.onCloseSnackbar} 
        isloading={isloading}
        returnBtn={true}
        onReturn={this.onReturn}
      >
        {modalContent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ClientuserList 
              title={content.tableTile}
              header={content.header}
              data={data_clientusers}
              onItemSelected={this.onClientuserSelected}
              onAddItem={this.onAddProspect}
              onDeleteItem={this.onPreDeleteProspect}
              
            />
          </Grid>
        </Grid>
        
      </BasicView>
      
    )
  }
}

export default withStyles(styles)(Clientusers)