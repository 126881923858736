import React, {Component} from 'react'
import StateTax from './StateTax/StateTax';
import Billing from './Billing/Billing';
import SocialSecurity from './SocialSecurity/SocialSecurity';
import Stamping from './Stamping/Stamping';
import Accounting from './Accounting/Accounting';
import Treasury from './Treasury/Treasury';
import Outsourcing from './Outsourcing/Outsourcing';
import Constitution from './Constitution/Constitution';
import KeyService from './KeyService/KeyService';
import MonthlyStatement from './MonthlyStatement/MonthlyStatement';
import ASiViewer from './ASiViewer/ASiViewer';
import Consulting from './Consulting/Consulting';
import BankAccountOpening from './BankAccountOpening/BankAccountOpening';
import Publicity from './Publicity/Publicity';
import Update from './Update/Update';
import ImssInscription from './ImssInscription/ImssInscription';
import RiskAnnualStatement from './RiskAnnualStatement/RiskAnualStatement';
import MoralSatInscription from './MoralSatInscription/MoralSatInscription';
import Audit from "./Audit/Audit";
import ExternalInvestmentRegistration from './ExternalInvestmentRegistration/ExternalInvestmentRegistration';
import FisicalDiagnosis from './FisicalDiagnosis/FisicalDiagnosis';
import LegalServices from './LegalServices/LegalServices';
import MoneyLaundering from './MoneyLaundering/MoneyLaundering';
import PhysicalAnnualStatement from './PhysicalAnnualStatement/PhysicalAnnualStatement';
import PhysicalSatInscription from './PhysicalSatInscription/PhysicalSatInscription';
import Satic from './Satic/Satic';
import Efirma from "./Efirma/Efirma";
import StateRegisterInscription from './StateRegisterInscription/StateRegisterInscription';
import Technology from './Technology/Technology';
import AnnualStatement from './AnnualStatement/AnnualStatement';
import MoralAnnualStatement from './MoralAnnualStatement/MoralAnnualStatement';
import Infonavit from './Infonavit/Infonavit';
import Certificate from './Certificate/Certificate';



class ServiceWrapper extends Component{
  render(){

    const {idservice, idbusiness, id} = this.props.match.params
    const {history, language, catalogs, 
      permissions, permissionsType} = this.props
    const ids = idservice.split('-')
    const id_service_type = ids[0]
    const id_service = ids[1]
    let contentView = null

    switch(id_service_type){
      case '1':
        contentView = (
          <StateTax
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 6).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '2':
        contentView = (
          <Billing
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 7).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '3':
        contentView = (
          <SocialSecurity
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 8).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '4':
        contentView = (
          <Stamping
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 9).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '5':
        contentView = (
          <Accounting
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 10).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '6':
        contentView = (
          <Treasury
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 11).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '7':
        contentView = (
          <MoneyLaundering
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 12).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '8':
        contentView = (
          <Publicity
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 13).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '9':
        contentView = (
          <Outsourcing
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 14).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '10': 
        contentView = (
          <Technology
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 15).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '11': 
        contentView = (
          <LegalServices
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 16).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '12':
        contentView = (
            <Audit
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 17).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break
      case '13':
        contentView = (
          <Constitution
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 18).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '14':
        contentView = (
          <KeyService
            history={history}
            clientID={id}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 19).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '15':
        contentView = (
          <MonthlyStatement
            history={history}
            businessID={idbusiness}
            clientID={id}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 20).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '16':
        contentView = (
          <Satic
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 21).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '17':
        contentView = (
            <Update
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 22).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break
      case '18':
        contentView = (
            <PhysicalAnnualStatement
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 23).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break
      case '19':
        contentView = (
          <RiskAnnualStatement
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 24).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '20':
        contentView = (
          <AnnualStatement
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 25).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '21':
        contentView = (
          <MoralAnnualStatement
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 26).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '22':
        contentView = (
          <ASiViewer
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 27).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '23':
        contentView = (
          <BankAccountOpening
            history={history}
            clientID={id}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 28).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '24':
        contentView = (
          <ImssInscription
            history={history}
            businessID={idbusiness}
            clientID={id}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 29).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '25':
        contentView = (
          <MoralSatInscription
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 30).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '26': 
        contentView = (
          <PhysicalSatInscription 
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 31).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '27':
        contentView = (
            <StateRegisterInscription
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 32).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break
      case '28':
        contentView = (
            <Efirma
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 33).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break
      case '29': 
        contentView = (
          <FisicalDiagnosis 
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 34).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '30':
        contentView = (
          <Infonavit
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 35).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      case '31':
        contentView = (
          <ExternalInvestmentRegistration
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 36).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      
      case '32':
        contentView = (
          <Consulting
            history={history}
            businessID={idbusiness}
            serviceID={id_service}
            language={language}
            catalogs={catalogs}
            permission={permissions.find(e => e.id_permission_module == 37).id_permission_type}
            permissionsType={permissionsType}
          />
        )
        break
      
      case '33':
        contentView = (
            <Certificate
                history={history}
                businessID={idbusiness}
                serviceID={id_service}
                language={language}
                catalogs={catalogs}
                permission={permissions.find(e => e.id_permission_module == 33).id_permission_type}
                permissionsType={permissionsType}
            />
        )
        break

      default:

        break
    }

    return(
      <div>
        {contentView}
      </div>
    )
  }
}

export default ServiceWrapper
