import React, {useEffect, useState} from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment'
import RoundedButton from '../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { catalogs } from '../../../../texts/esp/catalogs';
import InputText from '../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import { request_timereports_businesses, request_timereports_services, request_timereports_supervisors, request_update_timereport } from '../requests';
import { isFormValid, onGetCatalogs, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../shared/utility';
import InputRating from '../../../../components_v1/Forms/InputRating';

const TimeReportForm = (props) => {

    const theme = useTheme();
    const { id, timereport, onUpdateData, onUpdateError, userID} = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [file, setFile] = useState(null)
    const [businesses, setBusinesses] = useState([])
    const [services, setServices] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        if(timereport){
            let _form = onInitForm(formData, timereport)
            _form.date.value = onGetDateFormat(_form.date.value)
            if(timereport.business_id) setBusinesses([{value:timereport.business_id, label:timereport.business ? timereport.business : ''}] )
            if(timereport.service_id) setServices([{value:timereport.service_id, label:timereport.service ? timereport.service : ''}] )
            if(timereport.supervisor_id) setSupervisors([{value:timereport.supervisor_id, label:timereport.supervisor ? timereport.supervisor : ''}] )

            setForm(_form)
            setError(''); setLoading(false);
        }
    }, [timereport])

    const onChange = (data) => {
        console.log(`Form:${data.value}`)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchBusiness = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _businesses = await request_timereports_businesses(e.target.value)
            const _business_catalog = onGetCatalogs(_businesses, 'business_id', 'business')
            setBusinesses(_business_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSearchService = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _services = await request_timereports_services(e.target.value)
            const _service_catalog = onGetCatalogs(_services, 'id_time_report_service', 'name')
            setServices(_service_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSearchSupervisor = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _supervisors = await request_timereports_supervisors(e.target.value)
            const _supervisor_catalog = onGetCatalogs(_supervisors, 'user_id', 'user')
            setSupervisors(_supervisor_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, timereport)
        //Add extra data
        if(data2send.start_hour) data2send.start_hour = `${data2send.start_hour}:00`
        if(data2send.end_hour) data2send.end_hour = `${data2send.end_hour}:00`

        // Reset state to pending for approval because it is no the supervisor
        if(userID !== timereport.supervisor_id) data2send.state_id = 2

        //Send data
        setLoading(true)
        try {
            const _timereport = await request_update_timereport(id,data2send);
            onUpdateData(_timereport)

        } catch (error) {
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
        }
        setLoading(false)

    }


    let business_form = {...form.business_id}
    business_form.options = [...businesses]

    let service_form = {...form.service_id}
    service_form.options = [...services]

    let supervisor_form = {...form.supervisor_id}
    supervisor_form.options = [...supervisors]

    return ( 
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            <DisplayText variant='h6'> Información detallada</DisplayText>
                        </Grid>
                        {/*<Grid item>
                            <RoundedButton onClick={onSubmit} loading={loading}>Guardar</RoundedButton>
                        </Grid>*/}
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                    <Grid item xs={12}>
                            <InputText data={form.date} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.start_hour} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.end_hour} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={business_form} onChange={onChange} onInputChange={onSearchBusiness}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={service_form} onChange={onChange} onInputChange={onSearchService}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.task_description} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.meeting_notes} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={supervisor_form} onChange={onChange} onInputChange={onSearchSupervisor}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputRating size={ismobile ? 'medium' : 'large'} data={form.complexity} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputRating size={ismobile ? 'medium' : 'large'} data={form.client_satisfaction} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.task_type_id} onChange={onChange} />
                        </Grid>
                        
                        {/*<Grid item xs={12}>
                            <Grid container alignItems='center'><Grid item>
                                <RoundedButton onClick={onSubmit} loading={loading}>Guardar</RoundedButton>
                            </Grid></Grid>
                        </Grid>*/}
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default TimeReportForm;


const formData = {
    date: {
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        disabled:true,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha *',
            disabled:true,
            helper: 'La fecha seleccionada solo puede ser de máximo 2 días anteriores',
            minDate: moment().subtract(2,'days').format('YYYY-MM-DD'),
            onKeyDown:(e) => e.preventDefault()
        },
        rules: {type: 'dateRange', min:moment().subtract(2,'days').format('YYYY-MM-DD')}
    },
    business_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        options:[],
        config: {
            id: 'business_id',
            type: 'select',
            fullWidth: true,
            label: 'Negocio *',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido',
            disabled:true,
        },
        rules: {type: 'select'}
    },
    service_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        options:[],
        config: {
            id: 'service_id',
            type: 'select',
            fullWidth: true,
            label: 'Servicio',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido',
            disabled:true,
        },
        rules: {type: 'select'}
    },
    task_description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        config: {
            id: 'task_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción de la tarea *',
            placeholder:'Ingrese la descripción',
            helper: 'Este campo es requerido',
            multiline:true,
            rows:3,
            disabled:true,
        },
        rules: {type: 'distance', max:1024}
    },
    start_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        config: {
            id: 'start_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de inicio *',
            helper: 'Este campo es requerido',
            disabled:true,
        },
        rules: {type: 'time'}
    },
    end_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        config: {
            id: 'end_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de final *',
            helper: 'Este campo es requerido',
            disabled:true,
        },
        rules: {type: 'time'}
    },
    complexity: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        config: {
            id: 'complexity',
            type: 'rating',
            fullWidth: true,
            label: 'Complejidad de la tarea *',
            helper: 'Debe seleccionar una opción',
            disabled:true,
        },
        rules: {type: 'numeric', min:1, max:10}
    },
    client_satisfaction: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        config: {
            id: 'client_satisfaction',
            type: 'rating',
            fullWidth: true,
            label: 'Satisfacción del cliente *',
            helper: 'Debe seleccionar una opción',
            disabled:true,
        },
        rules: {type: 'numeric', min:1, max:10}
    },
    supervisor_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        disabled:true,
        options:[],
        config: {
            id: 'supervisor_id',
            type: 'select',
            fullWidth: true,
            label: 'Supervisor',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido',
            disabled:true,
        },
    },
    task_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        disabled:true,
        options:[...catalogs.timereport_tasks],
        config: {
            id: 'task_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de tarea *',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido',
            disabled:true,
        },
        rules: {type: 'select'}
    },
    meeting_notes: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        disabled:true,
        config: {
            id: 'meeting_notes',
            type: 'text',
            fullWidth: true,
            label: 'Minuta de la reunión',
            placeholder:'Ingrese un texto...',
            helper: 'Este campo es requerido',
            multiline:true,
            rows:6,
            disabled:true,
        },
        rules: {type: 'none'}
    },
}
