import { Grid } from '@material-ui/core';
import React from 'react'
import empty from '../../assets/empty.png'
import DisplayText from '../Texts/DisplayText';

const NoTableData = () => {
    return ( 
        <div style={{padding:'40px 40px'}} >
            <Grid container justify='center' alignItems='center' direction='column'>
                <Grid item>
                    <img src={empty} alt='' width={180}/>
                </Grid>
                <Grid item>
                    <DisplayText>No se encontraron registros</DisplayText>
                </Grid>
            </Grid>
        </div>
     );
}
 
export default NoTableData;