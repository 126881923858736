import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import empty from '../../../../../../../../../assets/empty.png'
import { request_business, request_employees, request_process, request_update_process } from '../../../../../../requests'
import { onGetErrorMessage } from '../../../../../../../../../shared/utility'
import Banner from '../../Banner'
import { contentData } from './content'
import DisplayText from '../../../../../../../../../components_v1/Texts/DisplayText'
import BasicView from '../../../../../../../../../components_v1/Structure/Layouts/BasicView'
import { nodata } from '../../../../../../../../../texts/esp/general'
import EditModal from './modals/EditModal'
import History from '../../History/History'

const StateTax = ({ history, language, id, clientID }) => {

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const [process, setProcess] = useState(null)
  const [employees, setEmployees] = useState([])
  const [edit_modal, setEditModal] = useState(false)


  const content = contentData[language]

  useEffect(() => {
    const initModule = async () => {
      try {
        setLoading(true)

        const _employees = await request_employees()
        setEmployees(_employees)

        const _social_security = await request_process(id, 'statetax', 'business_state_tax')
        const _clients = await request_process(clientID, 'client', 'client')
        const _business = await request_business(id)
        setProcess({ ..._social_security, ..._clients, advisor: _business.advisor, advisor_id: _business.advisor_id })

        setLoading(false)
      } catch (e) {
        setLoading(false)
      }
    }
    initModule()
  }, [])

  const onUpdateCompleted = async (data) => {
    try {
      setSending(true)
      await request_update_process(id, 'statetax', data.fixed_responsible_id ? {...data, responsible_id: data.fixed_responsible_id} : data)
      await request_update_process(id, 'business', data)
      const _social_security = await request_process(id, 'statetax', 'business_state_tax')
      const _clients = await request_process(clientID, 'client', 'client')
      const _business = await request_business(id)
      setProcess({ ..._social_security, ..._clients, advisor: _business.advisor, advisor_id: _business.advisor_id })

      setSending(false)
      setSuccess(true)
      setEditModal(false)
    } catch (error) {
      setError(onGetErrorMessage(error))
      console.log(error)
    }

    setSending(false)
  }

  return (
    <BasicView
      history={history}
      title={content.title}
      loading={loading}
      error={error}
      onCloseError={() => setError(null)}
      success={success}
      onCloseSuccess={() => setSuccess(null)}
      setReturn
    >
      <div>
        <EditModal open={edit_modal} onClose={() => setEditModal(false)} init_data={process} employees={employees} onUpdateCompleted={onUpdateCompleted} />
        {process ?
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Banner content={content.banners.general} data={process} nodata={nodata} onEdit={() => setEditModal(true)} onUpdateCompleted={onUpdateCompleted} />
            </Grid>
            <Grid item xs={12}>
              <History type='state_tax' content={content.banners.history} id={id} data={process} nodata={nodata} />
            </Grid>
          </Grid>
          :
          <div style={{ marginTop: 40 }}>
            <Grid container justify='center' alignItems='center' direction='column'>
              <Grid item>
                <img src={empty} alt='' width={180} />
              </Grid>
              <Grid item>
                <DisplayText>No se encontraron registros</DisplayText>
              </Grid>
            </Grid>
          </div>
        }
      </div>
    </BasicView>
  )
}

export default StateTax
