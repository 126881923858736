import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'
import InputText from '../../../../../components/Inputs/InputText/InputText';
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect';


import {formData} from './data'
import {contentData} from './content'


class EditWorkInfo extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }

  componentDidMount(){
    const {user, catalogs, language} = this.props;
    const {formData} = this.state;
    const content = contentData[language];
    let temp = {...formData};
    temp['contract_type_id'].options = [...catalogs.user_contract_types]
    temp['employee_status_id'].options = [...catalogs.user_status]
    temp['contract_duration_type_id'].options = [...catalogs.user_contract_durations]

    if(user){
      Object.keys(formData).forEach(item => {
        let value = user[item];
        if(temp[item].config.type === 'date'){
          const momentDate = moment(value)
          if(momentDate.isValid()) value = momentDate.format('YYYY-MM-DD')
          //value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        if(temp[item].config.type === 'time'){
          value = value ? value : null;
          value = value === 'Invalid date' ? null : value;
        }
        temp[item].value = value ? value : '';
        temp[item].isValid = value ? true : false;
        temp[item].config = {...temp[item].config,...content.sectionA[item]};
      });
    }
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, user} = this.props;
    const {formData} = this.state;
    let data2Send = {};
    
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid){
        if(formData[item].value !== user[item]){
          data2Send = {...data2Send, [item]:formData[item].value}
        }
      }
    })
    onSubmit(data2Send)
  }

  render(){

    const {language, issending, error} = this.props
    const {formData} = this.state
    const content = contentData[language]

    let contentA = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      switch (formData[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
        default:
          inputContent = (
            <InputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    
    return(
      <EditCardWrapper
        title={content.title}
        button={content.save_button}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
          <Grid container spacing={3}>
            {contentA}
          </Grid>
      </EditCardWrapper>
    )
  }
}

export default EditWorkInfo