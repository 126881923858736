export const contentData = {
    spanish: {
        title: 'Documentos',        
        button:'Continuar',
        accordions: [
            {
                id: 'bank_statements',
                title: 'Estados de cuentas bancarios',
            },
            {
                id: 'payment_gateway',
                title: 'Estado de Cuenta de Pasarela de Pagos'
            },
            {
                id: 'invoices',
                title: 'Facturas'
            },
            {
                id: 'spending_control',
                title: 'Control de Caja/Gastos'
            },
            {
                id: 'inventary_control',
                title: 'Control de Inventarios'
            },
            {
                id: 'import_petition',
                title: 'Pedimentos de Importación'
            },
            {
                id: 'abroad_expenses',
                title: 'Gastos al extranjero'
            },
            {
                id: 'internal_control',
                title: 'Control Interno'
            },
            {
                id: 'intercompany_operation',
                title: 'Operaciones Intercompañia'
            },
            {
                id: 'contract_note_mandate',
                title: 'Contratos/Pagares/Contrato de Mandato'
            },
            {
                id: 'constitutive_act',
                title: 'Actas Constitutivas y/o Actas de Asamblea'
            },
            {
                id: 'other',
                title: 'Otro'
            },
        ],
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        }
    }
}
