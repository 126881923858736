import React, { useEffect, useState } from 'react'
import { onGetErrorMessage, onGetMonthsCatalog } from '../../../../../shared/utility';
import { request_evaluations_general, request_evaluations_general_history } from '../../requests';
import queryString from 'query-string'
import moment from 'moment'
import { cleanData2BChart, cleanData2MixChart } from './customFunctions';
import { contentData } from './content';

const useGeneral = ({ history, language, permissions, permissionsType }) => {
    //RECORDS STATE
    const [loadingA, setLoadingA] = useState(false)
    const [loadingB, setLoadingB] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //DATA
    const [evaluationsA, setEvaluationsA] = useState([])
    const [evaluationsB, setEvaluationsB] = useState([])
    //
    const [month, setMonth] = useState(moment().add(-1, 'months').month() + 1)
    const [year, setYear] = useState(moment().add(-1, 'months').year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().add(-1, 'months').year()))
    const [startDate, setStartDate] = useState(moment().add(-1, 'months'))
    const [endDate, setEndDate] = useState(moment())

    useEffect(() => {
        const initModule = async () => {
            setLoadingA(true)
            try {
                const rangeDate = month_options.find(el => el.value === month)
                const param2send = { language_id: 2, date: rangeDate.start_date, order: 1 }
                const params = `?${queryString.stringify(param2send)}`
                const _evaluations = await request_evaluations_general(params)
                setEvaluationsA(_evaluations)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoadingA(false)
        }

        initModule()
    }, [month])

    useEffect(() => {
        const initModule = async () => {
            setLoadingB(true)
            try {
                const _startDate = moment(startDate).startOf('month').format('YYYY-MM-DD')
                const _endDate = moment(endDate).endOf('month').format('YYYY-MM-DD')
                const param2send = { language_id: 2, start_date: _startDate, end_date: _endDate, order_by: 'id_employee_evaluation', order: 1 }
                const params = `?${queryString.stringify(param2send)}`
                const _evaluations = await request_evaluations_general_history(params)
                setEvaluationsB(_evaluations)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoadingB(false)
        }

        initModule()
    }, [startDate, endDate])

    const actions = {
        onChangeFilter: (value, type) => {
            if (type === 'month') setMonth(value)
            if (type === 'year') {
                setYear(value)
                setMonthOptions(onGetMonthsCatalog(value.toString()))
                setMonth(1)
            }
        },
        onChangeDate: (date, id) => {
            if (id === 'start_date') {
                if (!moment(date).isSame(startDate))
                    setStartDate(date)
                if (moment(date).isSameOrAfter(endDate))
                    setEndDate(moment(date).add(1, 'M'))
            }
            if (id === 'end_date') {
                if (!moment(date).isSame(endDate))
                    setEndDate(date)
                if (moment(date).isSameOrBefore(startDate))
                    setStartDate(moment(date).add(-1, 'M'))
            }
        },
        onSelectedItem: (data) => history.push(`/evaluations/${data.id_employee_evaluation}`),
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(null)
    }

    const content = contentData[language]
    const chartA = cleanData2BChart(evaluationsA, content.chart.bars)
    const chartB = cleanData2MixChart(evaluationsB, content.mix_chart.bars, startDate, endDate)

    const system = { loadingA, loadingB, sending, success, error }
    const view_data = { content, chartA, chartB, month, year, month_options, startDate, endDate }

    return { system, view_data, actions }
}

export default useGeneral