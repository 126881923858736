export const contentData = {
  spanish:{
    layout:{
      title:'Inscripción al SAT - Persona Moral',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
      menu:[
        {id:0, label:'General'},
        {id:1, label:'Socios'},
        {id:2, label:'Representante Legal'},
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        usb_id:{
          label:'',
          data:'',
        },
        process:{
          label:'',
          data:'',
        },
        responsible:{
          label:'',
          data:'',
        },
        comments:{
          label:'',
          data:'',
        },
        estimated_delivery_date:{
          label:'',
          data:'',
        },
        request_date:{
          label:'',
          data:''
        },
        project_costs:{
          label:'',
          data:'',
        },
        provider:{
          label:'',
          data:'',
        }
      },
      content:{
        usb_id:{
          label:'USB'
        },
        process:{
          label:'Tipo de proceso'
        },
        due_date:{
          label:'Fecha requerida'
        },
        responsible:{
          label:'Responsable'
        },
        comments:{
          label:'Comentarios'
        },
        estimated_delivery_date:{
          label:'Fecha estimada de entrega'
        },
        request_date:{
          label:'Fecha Requerida'
        },
        project_costs:{
          label:'Costos del proyecto'
        },
        provider:{
          label:'Nombre del Proveedor'
        },

      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'constitutive_act',name:'Acta Constitutiva',path:'constitutiveact', status:false, businessDocument:true},
          {key:'faculties',name:'Acreditación de facultades',path:'faculties', status:false, businessDocument:true},
          {key:'address_proof',name:'Comprobante de domicilio',path:'addressproof', status:false, businessDocument:true},
          {key:'sat_enrollment_format',name:'Formato de inscripción al SAT',path:'satenrollmentformat', status:false, businessDocument:false},
          {key:'documents',name:'Documentos',path:'documents', status:false, businessDocument:false},
        ]
      }
    }
  }
}