export const contentData = {
  spanish:{
    layout:{
      title:'Usuarios',
      navigation:[
        {name:'Usuarios',path:'/users'},
      ],
    },
    modals:{
      warning_modal:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
    },
    nodata:'Sin Registro',
    header:['ID','Usuario','Nombre', 'Apellidos','Email','Teléfono'],
    tableTitle:'Lista de usuarios'
  }
}
