import React, {useEffect, useState} from 'react'
import Caption from '../../../../../../../../components/Texts/Caption';
import PopperWrapper from '../../../../../../../../components/Poppers/PopperWrapper';
import { Fab, Grid, Icon, makeStyles } from '@material-ui/core';

const InfoButton = ({icon, data, label, onClick}) => {

    const classes = button_styles()
    const [open, setOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
  
    const onCloseMenu = () => {
      setOpen(false)
      setAnchorEl(null)
    }
   
  
    const onOpenMenu = (event) => {
      const { currentTarget } = event;
      setAnchorEl(currentTarget)
      setOpen(true)
    }
  
    return(
      <div>
        <PopperWrapper
          open={open}
          anchorEl={anchorEl}
          onClickAway={onCloseMenu}
        >
          {data ? 
            <div style={{padding:4, paddingLeft:8, paddingRight:8, maxWidth:200}}>
            <Grid container>
              <Grid item xs={12}>
                <Caption text={label} variant='light'/>
              </Grid>
              <Grid item xs={12}>
                <Caption text={data} variant='bold' medium/>
              </Grid>
            </Grid>
          </div>
          : null}
        </PopperWrapper>
        {onClick ? 
        <Fab size='small' className={classes.button} onClick={onClick}>
          <Icon fontSize='small' className={classes.icon}>
            {icon}
          </Icon>
        </Fab> 
        : 
        <Fab size='small' className={classes.button} onClick={onOpenMenu}>
        <Icon fontSize='small' className={classes.icon}>
          {icon}
        </Icon>
      </Fab>}
      </div>
    )
  }
  
  const button_styles = makeStyles(theme => ({
    root:{},
    button:{
      background:'white',
      boxShadow:'1px 2px 2px 1px rgba(82, 91, 115, .12)',
      '&:hover':{
        background:'white',
      }
    },
    icon:{
      color:theme.palette.primary.main
    }
  }))

  export default InfoButton