import {mainServer} from '../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_service = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/statetax/"+id.toString());
  let service = response.data.data.business_state_tax;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  const formData = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}
  return formData;
}

export const request_updateService = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/statetax/"+id.toString(), data);
  await mainServer.instance.patch("/businessdocument/"+id.toString(), {rfc:data.rfc});

  let response;
  response = await mainServer.instance.get("/statetax/"+id.toString());
  let service = response.data.data.business_state_tax;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  const formData = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}
  return formData;
}

export const request_uploadFile = async(id,path,file, ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/businessdocument/'+id+'/'+path;

  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);

  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/statetax/"+id.toString());
  return response.data.data.business_state_tax;
}

export const request_downloadFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  let url = '/businessdocument/'+id+'/'+path;
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}

export const request_deleteFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/businessdocument/'+id+'/'+path;
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/statetax/"+id.toString())
  return response.data.data.business_state_tax
}