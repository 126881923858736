import React, {Component} from 'react'
import {Grid} from '@material-ui/core'
import ModuleList from './ModuleList/ModuleList'

import {
  request_users,
  request_audits,
  request_add_audits,
  request_edit_audits,
  request_delete_audits,
  request_uploadFile,
  request_downloadFile
} from './requests.jsx'

import FormAModal from '../../../../../components/Modals/FormAModal'
import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal'
import WarningModal from '../../../../../components/Modals/WarningModal'
import CustomSnackbar from '../../../../../components/Snackbars/CustomSnackbar'


import {formData as adddata} from './additem/data'
import {contentData as addcontent} from './additem/content'
import {catalog_matrix as addmatrix} from './additem/catalog_matrix'


import {cleanData2Table} from './customFunctions'
import { contentData } from './content'
import SimpleLoader from '../../../../../components/Loaders/SimpleLoader';
import ServiceDetails from '../ServiceDetails/ServiceDetails';

class ServiceModule extends Component {

  state = {
    isloading:false,
    service_list:[],
    service_item_id:null,
    selected_item:null,
    users:[],
    modals:{
      additem:{open:false,issending:false,error:null},
      edititem:{open:false,issending:false,error:null, extra:null},
      filemodal:{open:false,issending:false,error:null,extra:null},
      warning_delete_item:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
  }

  componentDidMount(){
    this.initModule()
  }

  initModule = async() => {
    const {businessID} = this.props
    this.setState({isloading:true})
    let response = await request_audits(businessID)
    this.setState({service_list:[...response]})
    response = await request_users()
    this.setState({users:[...response]})
    this.setState({isloading:false})
  }

  onAddItem = async(data, type) => {
    try{
      const {businessID} = this.props
      const redata = {...data, business_id:businessID}
      this.onEditModal(type,{issending:true})
      let response = await request_add_audits(businessID,redata)
      this.setState({service_list:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }

    }
  }

  onEditItem = async(data, type) => {
    try{
      const {businessID} = this.props
      const {selected_item} = this.state
      this.onEditModal(type,{issending:true})
      let response = await request_edit_audits(businessID,data, selected_item.id_outsourcing)
      this.setState({service_list:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }

    }
  }

  onPreDeleteItem = (id) => {
    const type = 'warning_delete_item'
    this.onEditModal(type, {open:true, data:id})
  }

  onDeleteItem = async(id) => {
    const type = 'warning_delete_item'
    try{
      const {businessID} = this.props
      this.onEditModal(type,{issending:true})
      let response = await request_delete_audits(businessID, id)
      this.setState({service_list:[...response]})
      this.onCloseModal(type)
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal(type,{issending:false})
      if(e.response){
        this.onEditModal(type,{error:e.response.data.error.message})
      }

    }
  }

  onFileActionManager = async(action, data) => {
    if(action === 'upload'){
      this.onOpenFileModal('filemodal',data)
    }else{
      this.onDownloadFile(data)
    }
  }

  onSubmitFile = async(filepath, file, data) => {
    try{
      const {businessID} = this.props
      this.onEditModal('filemodal',{issending:true})
      const aux = file[0].name.split('.')
      const ext = {ext:aux[aux.length-1]}
      let response = await request_uploadFile(businessID, filepath, file[0], ext, data.id)
      this.setState({service_list:[...response]})
      this.onCloseModal('filemodal')
      this.onActivateSuccessSnack()
    }catch(e){
      this.onEditModal('filemodal',{issending:false})
      if(e.response){
        this.onEditModal('filemodal',{error:e.response.data.error.message})
      }
    }
  }

  onDownloadFile = async (data) => {
    try{
      await request_downloadFile(data.id, data.path)
    }catch(e){
      console.log(e)
    }
  }

  onCloseCard = () => {
    this.setState({service_item_id:null})
  }

  onOpenSubModule = (id) => {
    this.setState({service_item_id:id})
  }

  onItemSelected = (id) => {
    const {service_list} = this.state
    let aux = service_list.filter(el => el.id_outsourcing === id)
    if(aux.length > 0){
      this.setState({selected_item:aux[0]})
      this.onOpenFileModal('edititem')
    }
  }

  onOpenFileModal = (section, filedata) => {
    const {modals} = this.state
    let tempModals = {...modals}
    tempModals[section].open=true
    if(filedata){
      tempModals[section].data = {...filedata}
    }
    this.setState({modals:{...tempModals}})
  }

  onCloseModal = (type) => {
    this.onEditModal(type, {open:false, issending:false, error:null})
  }

  onEditModal = (section, data) => {
    const {modals} = this.state;
    let tempModals = {...modals};
    tempModals[section]={...tempModals[section],...data};
    this.setState({modals:{...tempModals}})
  }

  onCloseSnackbar = (id) => {
    let temp = this.state[id]
    temp.open = false
    this.setState({[id]:{...temp}})
  }

  onActivateSuccessSnack = () => {
    const {success} = this.state
    let success_temp = {...success}
    success_temp.open = true
    this.setState({success:{...success_temp}})
  }

  render(){
    const {isloading, service_list, service_item_id, modals, success, users} = this.state
    const {language, catalogs, history, permission, permissionsType} = this.props
    const content = contentData[language]
    let contentView = null

    let stamps_list = cleanData2Table(service_list, content.nodata, users)

    if(!isloading){
      contentView = (
        <ModuleList
          title={content.tableTitle}
          header={content.header}
          data={stamps_list}
          onItemSelected={this.onOpenSubModule}
          onEditItem={this.onItemSelected}
          onAddItem={() => this.onOpenFileModal('additem')}
          onDeleteItem={this.onPreDeleteItem}
          onFileAction={this.onFileActionManager}
          nodata={content.notable}
          permission={permission}
          permissionsType={permissionsType}
        />
      )
    }else{
      contentView = (<Grid container justify='center'><Grid item><SimpleLoader /></Grid></Grid>)
    }

    let modalcontent = null

    if(modals.warning_delete_item){
      modalcontent=(
        <WarningModal
          data={modals.warning_delete_item}
          content={content.modals.warning_delete_item}
          onClose={() => this.onCloseModal('warning_delete_item')}
          onDelete={this.onDeleteItem}
        />
      )
    }

    if(modals.additem.open){
      modalcontent = (
        <FormAModal
          addSpace={true}
          catalogs={catalogs.business_documents}
          data={modals.additem}
          catalog_matrix={addmatrix}
          content={addcontent[language]}
          users={users}
          form_data={adddata}
          onClose={() => this.onCloseModal('additem')}
          onSubmit={(data) => this.onAddItem(data, 'additem')}

        />
      )
    }

    if(modals.filemodal.open){
      modalcontent = (
        <FileManagerModal
          data={modals.filemodal}
          open={true}
          language={language}
          onClose={() => this.onCloseModal('filemodal')}
          onSubmit={this.onSubmitFile}
          onDelete={this.onDeleteFile}
        />
      )
    }

    let subcontentView = null

  if(service_item_id){
    const {businessID} = this.props
    subcontentView = <ServiceDetails
      businessID={businessID}
      moduleID={service_item_id}
      language={language}
      catalogs={catalogs}
      history={history}
      onClose={this.onCloseCard}
      permission={permission}
      permissionsType={permissionsType}
    />
  }


    return(
      <div>
        {modalcontent}
        <Grid container spacing={4}>
          <Grid item xs={12}>
            {subcontentView}
          </Grid>
          <Grid item xs={12}>
            {contentView}
          </Grid>
        </Grid>
        <CustomSnackbar open={success.open} autoHideDuration={success.timeout} onClose={() => this.onCloseSnackbar(success.type)} message={success.message} variant={success.type}/>
      </div>
    )
  }
}

export default ServiceModule
