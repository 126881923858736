export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen registros',
    tableTitle:'',
    header:['ID','Nombre','Especificaciones','Fecha Requerida',
    'Periodicidad','Costos del Proyecto','Costo','Precio de Venta',],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
  },
  english:{

  }
}