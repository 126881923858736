import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../components_v1/Actions/ActionModalBar';
import InputSelect from '../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onGetSelectedFormData, onSetErrorsToForm } from '../../../../../shared/utility';
import { request_add_associate } from '../requests';


const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        //paddingBottom:32
    }//
}))

const AddAssociateModal = (props) => {

    const classes = useStyles()
    const { id, open, onClose, associates, businesses, onUpdateCompleted } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        let _associates = []
        businesses.forEach(item => {
            const findAssociate = associates.find(el => el.businses_id === item.value)
            if(!findAssociate && item.value !== parseInt(id)) _associates.push(item)
        })
        let temp = JSON.parse(JSON.stringify(formData))
        temp.associate_id.options = _associates
        setForm(temp)
        if(!open){setError(''); setLoading(false);}
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {

        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            setError('Seleccione algún servicio')
            return
        }

        //Extract data
        let data2send = onGetFormData(form)
        console.log(data2send)

        //Send data
        setLoading(true)
        try {
            await request_add_associate(id, data2send)
            onUpdateCompleted()
            //history.push(`/timereports/${timereport_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Agregar socio</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputSelect data={form.associate_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Agregar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddAssociateModal;

const formData = {
  associate_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'associate_id', type:'simple_select', fullWidth: true,
      label:'Seleccione un socio', 
      placeholder:'Seleccione una opción...'
    },
    rules:{
      type:'none',
    }
  }, 
}