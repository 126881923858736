export const contentData = {
  spanish:{
    title:'Editar outsourcing',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre',
      }, 
      company_type_id:{
        label:'Tipo de Sociedad',
        placeholder:'Tipo de Sociedad',
      },
      process_id:{
        label:'Seleccionar Proceso',
        placeholder:'Seleccionar Proceso',
      },
      due_date:{
        label:'Fecha requerida',
        placeholder:'Fecha requerida',
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
      estimated_delivery_date:{
        label:'Fecha Estimada de Entrega',
        placeholder:'Fecha Estimada de Entrega',
      },
      comments:{
        label:'Comentarios',
        placeholder:'Comentarios',
      }, 
      project_costs:{
        label:'Costos del Proyecto',
        placeholder:'Costos del Proyecto',
      },
      provider:{
        label:'Proveedor',
        placeholder:'Proveedor',
      }, 
      cost:{
        label:'Costo',
        placeholder:'Costo',
      }, 
      sell_price:{
        label:'Precio de venta',
        placeholder:'Precio de venta',
      }, 
      
      
    },
  },
}