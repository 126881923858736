import React from 'react';
import {Grid} from '@material-ui/core';
import RoundedButton from '../../Actions/RoundedButton';

const SmallNavigation = props => {

  const {data, onChange, selected} = props
  let menuContainer = null;

  if(data){
    menuContainer = data.map(item => {
      return(
        <Grid item  style={{width: 'max-content'}} key={item.id}>
          <RoundedButton 
            size='small'
            onClick={() => onChange(item.id)}
            disabled={item.disabled}
            icon={item.icon}
            color={item.id !== selected ? 'white' : ''}
          >
            {item.label}
          </RoundedButton>
        </Grid>
      )
    })
  }

  return(
    <div>
      <Grid container spacing={2}>
        {menuContainer}
      </Grid>
    </div>
  )
}

export default SmallNavigation;