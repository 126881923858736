export const contentData = {
  spanish:{
    title:'Crear aduditoria',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      name:{
        label:'Nombre',
        placeholder:'Nombre'
      },
      due_date:{
        label:'Fecha Requerida',
        placeholder:'Fecha Requerida'
      },
      periodicity_id:{
        label:'Periodicidad',
        placeholder:'Periodicidad'
      },
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable'
      },
      estimated_delivery_date:{
        label:'Fecha de Entrega Estimada',
        placeholder:'Fecha de Entrega Estimada'
      }
    },
  },
}
