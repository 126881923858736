export const formData = {
  sectionA:{
    username:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'username',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    password:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'password',
        type:'password',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    passwordConfirm:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'passwordConfirm',
        type:'password',
        fullWidth: true,
      },
      rules:{
        type:'equals',
      }
    },
    first_name:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'first_name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    last_name:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'last_name',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    user_level_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'user_level_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    /*language_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'language_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },*/
    timezone:{
      value: 'Mexico/General',
      error: false,
      isVisited: true,
      isRequired: true,
      isValid: true,
      options:[],
      config:{
        id:'timezone',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    employee_status_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      options:[],
      config:{
        id:'employee_status_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },
    work_email:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'work_email',
        type:'email',
        fullWidth: true,
      },
      rules:{
        type:'email',
      }
    },
    entry_day:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'entry_day',
        type:'date',
        fullWidth: true,
      },
      rules:{
        type:'date',
      }
    },
  },
}

export const permissions_benefits = {
  permissions:[
    {"module_id":1,"permission_id":5},
    {"module_id":2,"permission_id":5},
    {"module_id":3,"permission_id":5},
    {"module_id":4,"permission_id":5},
    {"module_id":5,"permission_id":5},
    {"module_id":6,"permission_id":5},
    {"module_id":7,"permission_id":5},
    {"module_id":8,"permission_id":5},
    {"module_id":9,"permission_id":5},
    {"module_id":10,"permission_id":5},
    {"module_id":11,"permission_id":5},
    {"module_id":12,"permission_id":5},
    {"module_id":13,"permission_id":5},
    {"module_id":14,"permission_id":5},
    {"module_id":15,"permission_id":5},
    {"module_id":16,"permission_id":5},
    {"module_id":17,"permission_id":5}
  ],
  benefits:[
    {"benefit_id":1,"status":1},
    {"benefit_id":2,"status":1},
    {"benefit_id":3,"status":1},
    {"benefit_id":4,"status":1},
    {"benefit_id":5,"status":1},
    {"benefit_id":6,"status":1},
    {"benefit_id":7,"status":1},
    {"benefit_id":8,"status":1},
    {"benefit_id":9,"status":1},
    {"benefit_id":10,"status":1},
    {"benefit_id":11,"status":1},
    {"benefit_id":12,"status":1}
  ]
}