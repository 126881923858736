export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        accordion_title: 'Revisión contable',
        buttons_label: 'Disponibilidad',
        responsible_banner: {
            responsible: 'revisor',
            responsible_id: 'revised_by',
            completed_date: 'revision_date',
            ctrlpath:true
        }
    }
}
