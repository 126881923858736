export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions: [
            {
                id: 'capture_line_sent',
                title: 'Envío de Línea de captura'
            },
            {
                id: 'payment_reception',
                title: 'Recepción del pago'
            },
            {
                id: 'asiviewer_upload',
                title: 'Cargar en ASiViewer'
            },
        ],
    }
}
