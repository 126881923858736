export const contentData = {
  spanish:{
    layout:{
      title:'Impuesto local sobre nómina',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal'],
        fixedHeaderB: ['Estado','Ciudad','Usuario Registro Estatal','Clave Registro Estatal',
        'Número de Seguridad Social'],
        varHeader: ['Fecha de Pago de Impuestos','Fecha Límite de Presentación','Hoja de Trabajo',
        'Proceso','Estatus','Información Atención a Cliente 1','Información Atención a Cliente 2',
        'Cantidad a Pagar','Recargos y Actualizaciones','Total a Pagar', 
        'Línea de Captura IMSS/Infonavit', 'Fecha Límite de Pago','Responsable',
        'Respuesta de Cliente','Comentario de Cliente', 'Notas', 'Horas Extra', 'Comprobante Horas', 
        'Cargos Extra', 'Comprobante Cargo', 'Cargo a Cobranza', 'Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
