export const contentData = {
    spanish: {
        title: 'Solución Integral',
        header: [
            { id: 1, label: 'Razón Social', key: 'business' },
            { id: 9, label: 'Clasificación', key: 'fixed_client_classification' },
            { id: 20, label: 'Importancia', key: 'fixed_importance' },
            { id: 21, label: 'Socio', key: 'fixed_client_associate' },
            { id: 23, label: 'Responsable', key: 'fixed_client_responsible' },
            { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
            { id: 24, label: 'Turno de', key: 'fixed_responsible' },
            { id: 4, label: 'Fecha límite', key: 'fixed_payday_limit' },
            { id: 5, label: 'No. de paso', key: 'fixed_integral_solution_step_id' },
            { id: 6, label: 'Prioridad', key: 'solution_priority' },
            { id: 7, label: 'Porcentaje' },
            { id: 8, label: 'Estatus', key: 'process_semaphore_id' },
        ],
        modals: {
            sign_modal: {
                title: 'Firma',
                button: 'Aprobar'
            },
            second_sign_modal: {
                title: 'Firma',
                button: 'Completar evaluación'
            }
        },
    }
}
