import React, { useState, useEffect } from 'react'
import EvaluationPDF from '../../../../../components_v1/Structure/EvaluationGenericView/components/EvaluationPDF'
import { onGetErrorMessage, onGetMonthsCatalog, onGetUserID } from '../../../../../shared/utility';
import queryString from 'query-string'
import moment from 'moment'
import { request_employees, request_evaluations } from '../../requests';
import { cleanData2Table } from './customFunctions';
import { contentData } from './content';
import { Redirect } from 'react-router';
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const useRecords = ({ history, language, permissions, permissionsType }) => {

    const content = contentData[language]

    //RECORDS STATE
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //DATA
    const [evaluations, setEvaluations] = useState([])
    const [users, setUsers] = useState([])
    const [task_type, setTaskType] = useState(-1)
    //
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })
    //
    const [status, setStatus] = useState(0)
    const [month, setMonth] = useState(moment().add(-1, 'months').month() + 1)
    const [year, setYear] = useState(moment().add(-1, 'months').year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().add(-1, 'months').year()))

    useEffect(() => {
        const initModule = async () => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const { limit, offset } = tableFilter
                const rangeDate = month_options.find(el => el.value === month)
                let filterArgs = [
                    { "field": "auto_date", "operator": ">=", "value": rangeDate.start_date },
                    { "field": "auto_date", "operator": "<=", "value": rangeDate.end_date },
                ]
                if (status > 0)
                    filterArgs = [{ "field": "evaluation_step_id", "operator": "LIKE", "value": status }, ...filterArgs]

                const param2send = { language_id: 2, limit: limit, offset: offset * limit, order_by: 'id_employee_evaluation', order: 1 }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _evaluations = await request_evaluations(params)
                setTotal(_evaluations.count)
                setEvaluations(_evaluations.employee_evaluations)
                let response = await request_employees()
                setUsers([...response])
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }

        initModule()
    }, [tableFilter, month, year, status, task_type])



    const actions = {
        onChangeTableFilter: (data) => setTableFilter(data),
        onChangeFilter: (value, type) => {
            setTableFilter({ limit: 10, offset: 0 })
            if (type === 'status') {
                setStatus(value)
                setTableFilter({ limit: 10, offset: 0 })
            }
            if (type === 'month') setMonth(value)
            if (type === 'year') {
                setYear(value)
                setMonthOptions(onGetMonthsCatalog(value.toString()))
                setMonth(1)
            }
        },
        onGeneratePDF: async (documentData) => {
            try {
                setSending(true)
                const blob = await pdf(<EvaluationPDF {...documentData} />).toBlob()
                saveAs(blob, "evaluation.pdf");
            } catch (error) {
                console.log(error)
            }
            setSending(false)
        },
        onSelectedItem: (data) => history.push(`/evaluations/${data.id_employee_evaluation}`),
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(null)
    }

    const data2table = cleanData2Table(evaluations, content.nodata, users)
    let redirectContent = null
    let _permission = permissions.find(e => e.id_permission_module === 0)
    if (_permission && _permission.permission_type_id === permissionsType.NO_ACCESS) redirectContent = <Redirect to='/profile' />

    const system = { loading, sending, success, error }
    const view_data = { content, evaluations, task_type, total, tableFilter, status, month, year, month_options, redirectContent, data2table }

    return { system, view_data, actions }
}

export default useRecords