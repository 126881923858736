import React, {Component} from 'react'
import {TableRow,TableCell, IconButton, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../../components/Tables/SimpleTable'
import {TableItem} from '../../../../../../components/DisplayData/DisplayData'
import cx from 'classnames'
import {onFilterDataArray} from './customFunctions'
import {FileItemWrapper, FileItemWrapperDownload} from '../../../../../../components/DisplayData/DisplayData'


const styles = (theme) => ({
  root:{},
  tablecell:{
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablecellNoPointer:{
    padding:'16px 16px',
    border:0,
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class ModuleList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    this.setState({rowsPerPage:value})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  onSearch = (value) => {
    this.setState({searchText:value})
  }

  render(){

    const {classes, title, header, data, onItemSelected, onAddItem, 
      onDeleteItem, onFileAction, nodata, permission, permissionsType} = this.props
    const {rowsPerPage,page,searchText} = this.state

    const filterData = onFilterDataArray(data, searchText)
    const sliceArray = filterData.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, sliceArray.length - page * rowsPerPage)
    //let emptylist = this.onFillEmptyArray(emptyRows, rowsPerPage)

    return(

      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={filterData.length}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={permission >= permissionsType.CREATE}
          onAddItem={onAddItem}
          nodata={nodata}
        >

          {sliceArray.map((item,key)=>{
            return(
              <TableRow
                key={key.toString()}
                className={classes.tablerow}>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox' onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[2]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[3]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[4]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[5]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[0]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[0]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[1]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[1]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[2]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[2]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[9]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[10]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[11]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[12]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[3]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[3]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[4]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[4]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[15]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[5]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[5]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[17]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[18]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[6]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[6]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[20]}
                  />
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                padding='checkbox'>
                  {
                    permission >= permissionsType.EDIT ? 
                      <FileItemWrapper 
                        data={item.files[7]}
                        onFileAction={onFileAction}
                      />
                    :
                      <FileItemWrapperDownload
                        data={item.files[7]}
                        onFileAction={onFileAction}
                      />
                  }
                </TableCell>
                <TableCell className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                onClick={permission >= permissionsType.EDIT ? (() => onItemSelected(item.id)) : null}>
                  <TableItem
                    data={item.data[22]}
                  />
                </TableCell>
                {
                  permission >= permissionsType.DELETE ? 
                    <TableCell 
                      className={cx({[classes.tablecellNoPointer]:true, [classes.tablecell]:permission >= permissionsType.EDIT})} 
                      padding='checkbox' align='center' onClick={() => onDeleteItem(item.id)}>
                      <IconButton style={{padding:8}} onClick={() => onDeleteItem(item.id)}>
                        <Icon fontSize='small'>delete</Icon>
                      </IconButton>
                    </TableCell>
                  : null
                }
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }
}

export default withStyles(styles)(ModuleList)
