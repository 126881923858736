import React, {useState, useEffect} from 'react'
import { Grid, Icon, makeStyles } from '@material-ui/core'
import { red } from '@material-ui/core/colors'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal'
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar'
import { request_delete_document } from '../requests'
import { onGetErrorMessage } from '../../../../../../shared/utility'

const useStyles = makeStyles(theme => ({
    icon:{
        color:red[700],
        fontSize:54,
        border:`2px solid ${red[700]}`,
        padding:8,
        borderRadius:'50%'
    },
}))

const DeleteDocumentModal = props => {

    const classes = useStyles()
    const {serviceID, businessID, document, open, onClose, onUpdateCompleted} = props
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setError('')
        setLoading(false)
    }, [open])

    const onSubmit = async() => {
        setLoading(true)
        try {
            if(document.businessDocument){
                console.log('Delete to business documents')
                await request_delete_document(businessID, document.path, 'BUSINESS')
            }else{
                console.log('Delete to service documents')
                await request_delete_document(serviceID, document.path, 'SERVICE')
            }
            onUpdateCompleted()
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
            setLoading(false)
        }
    }

    return(
        <SimpleModal open={open} onClose={onClose}>
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container direction='column' alignItems='center' spacing={2}>
                            <Grid item>
                                <Icon fontSize='large' className={classes.icon}>clear</Icon>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='h6'  align='center'>¿Estás seguro?</DisplayText>
                            </Grid>
                            <Grid item>
                                <DisplayText variant='body1' align='center'> ¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido</DisplayText>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ActionModalBar loading={loading} error={error} btnLabel='Eliminar' btnColor='red' onSubmit={onSubmit}/>
                    </Grid>
                </Grid>
            </div>
        </SimpleModal>
    )
}

export default DeleteDocumentModal