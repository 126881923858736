import { mainServer } from "../../../variables/config";
import axios from 'axios'
import FileSaver from 'file-saver'

export const request_control_table = async(params) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    const response = await mainServer.instance.get(`/ctrlmonthlystatements/all${params}`);
    return response.data.data
}

export const request_update_control_table_item = async(id, data, params) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.patch(`/ctrlmonthlystatements/${id}`,data)
    const response = await mainServer.instance.get(`/ctrlmonthlystatements/all${params}`);
    return response.data.data
}

export const request_employees = async () => {
    const logData = JSON.parse(localStorage.getItem('data'))
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token
    let response;
      response = await mainServer.instance.get("/user/employee/list");
      return response.data.data.employees
}

export const request_upload_document = async(id, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.post(`/ctrlmonthlystatements/${id}/${path}`, data,  {params:{language_id:2}})
    const options = {headers: {'Content-Type': file.type}}
    delete axios.defaults.headers.common["Authorization"]
    await axios.put(response.data.data.path, file, options);
}
  
export const request_download_document = async(id, path, ext) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response = await mainServer.instance.get(`/ctrlmonthlystatements/${id}/${path}`,  {params:{name:'evidence'}})
    delete axios.defaults.headers.common["Authorization"]
    response = await axios.get(response.data.data.path, {responseType: 'blob'});
    FileSaver.saveAs(response.data, `${path}.${ext}`);
} 

export const request_delete_document = async(id, path, file, data) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    await mainServer.instance.delete(`/ctrlmonthlystatements/${id}/${path}`,  {params:{language_id:2}})
}
  