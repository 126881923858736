export const formData = {
  
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },

}