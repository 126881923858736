export const contentData = {
	spanish: {
		title: 'Información Basica',
		nodata: 'Sin Registro',
		error: 'Sin registro',
		button: 'Guardar',
		cancelButton: 'Cancelar',
		image_button: 'Cambiar',
		form: {
			rfc: {
				label: 'RFC',
				placeholder: 'RFC',
			},
			specs: {
				label: 'Especificaciones',
				placeholder: 'Especificaciones',
			},
			workers_limit_id: {
				label: 'Límite de empleados',
				placeholder: 'Límite de empleados',
			},
			payroll_responsible_id: {
				label: 'Responsable Nómina',
				placeholder: 'Responsable Nómina',
			},
			supervisor_id: {
				label: 'Supervisor',
				placeholder: 'Supervisor',
			},
			analyst_id: {
				label: 'Analista',
				placeholder: 'Analista',
			},
		},
	},
};
