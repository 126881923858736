import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_risk_annual_statement,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData,
        currentData.sub_activity ? currentData.sub_activity : noData,
        currentData.economic_activity_sector ? currentData.economic_activity_sector : noData,
        currentData.idse_user_employer_enrollment ? currentData.idse_user_employer_enrollment : noData,
    ];

    let fixedDataB = [
        currentData.idse_user_state ? currentData.idse_user_state : noData,
        currentData.idse_user_city ? currentData.idse_user_city : noData,
        currentData.idse_user_user ? currentData.idse_user_user : noData,
        currentData.idse_user_password ? currentData.idse_user_password : noData,
        {key:'idse_user_digital_certificate'},
        currentData.idse_user_digital_certificate_password ? currentData.idse_user_digital_certificate_password : noData,
        currentData.idse_user_sua_password ? currentData.idse_user_sua_password : noData,
        currentData.idse_user_employer_card ? currentData.idse_user_employer_card : noData,
    ]

    let varData = [
        currentData.presentation_limit_date ? moment(currentData.presentation_limit_date).format('DD-MM-YYYY') : noData,
        {key:'worksheet'},
        {key:'capture_line'},
        currentData.process_status ? currentData.process_status : noData,
        currentData.class ? currentData.class : noData,
        {key:'declaration'},
        currentData.presentation_date ? moment(currentData.presentation_date).format('DD-MM-YYYY') : noData,
        {key:'client_response'},
        currentData.client_comment ? currentData.client_comment : noData,
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        presentation_limit_date : currentData.presentation_limit_date ,
        process_status_id : currentData.process_status_id,
        class_id : currentData.class_id,
        presentation_date : currentData.presentation_date,
        client_comment : currentData.client_comment ,
        responsible_id : currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    tempA.files = [
        {id:currentData.id_ctrl_risk_annual_statement,key:'idse_user_digital_certificate',
            name:'Certificado Digital IDSE',path:'idseuserdigitacertificate',
            status: checkStatus(currentData, 'idse_user_digital_certificate')}
    ]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    tempB.files = [
        {id:currentData.id_ctrl_constitution,key:'idse_user_digital_certificate',name:'Adjuntar Documentos',path:'idseuserdigitalcertificate',
            status: checkStatus(currentData, 'idse_user_digital_certificate')},
    ]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_risk_annual_statement,key:'client_response',name:'Respuesta Cliente',path:'clientresponse',
            status: checkStatus(currentData, 'client_response')},
        {id:currentData.id_ctrl_risk_annual_statement,key:'worksheet',name:'Hoja de Trabajo',path:'worksheet',
            status: checkStatus(currentData, 'worksheet')},
        {id:currentData.id_ctrl_risk_annual_statement,key:'declaration',name:'Declaración',path:'declaration',
            status: checkStatus(currentData, 'declaration')},
            {id:currentData.id_ctrl_risk_annual_statement,key:'capture_line',name:'Línea de Captura',path:'captureline',
            status: checkStatus(currentData, 'capture_line')},
        {id:currentData.id_ctrl_risk_annual_statement,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_risk_annual_statement,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
