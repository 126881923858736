export const formData = {
  name: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'name',
      type: 'text',
      fullWidth: true,
    },
    rules: {
      type: 'distance',
      min: 1,
      max: 100
    }
  },
  pre_position_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'pre_position_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  classification_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'classification_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  importance: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'importance',
      type: 'number',
      fullWidth: true,
      step:"0.0001"
    },
    rules: {
      type: 'numeric',
      min: 0
    }
  },
  pre_fiscal_regime_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'pre_fiscal_regime_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  pre_language_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'pre_language_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  pre_area_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'pre_area_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  pre_country_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'pre_country_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  client_status_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'client_status_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  client_state_id: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [],
    config: {
      id: 'client_state_id',
      type: 'simple_select',
      fullWidth: true,
    },
    rules: {
      type: 'none',
    }
  },
  pre_contact_name: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config: {
      id: 'pre_contact_name',
      type: 'text',
      fullWidth: true,
    },
    rules: {
      type: 'distance',
      min: 1,
      max: 100
    }
  },
  pre_responsible_id: {
    value: null,
    isVisited: false,
    isValid: false,
    isRequired: true,
    options: [],
    config: {
      id: 'pre_responsible_id',
      type: 'avatar_select',
      fullWidth: true,
      isClearable: false,

    },
    rules: {
      type: 'select',
    }
  },
  responsible_id: {
    value: null,
    isVisited: false,
    isValid: false,
    isRequired: true,
    options: [],
    config: {
      id: 'responsible_id',
      type: 'avatar_select',
      fullWidth: true,
      isClearable: false,
    },
    rules: {
      type: 'select',
    }
  },
  antiquity: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'antiquity',
      type: 'date',
      fullWidth: true,
    },
    rules: {
      type: 'date',
    }
  },
  restricted: {
    value: null,
    isVisited: false,
    isValid: false,
    isRequired: true,
    options: [],
    config: {
      id: 'restricted',
      type: 'simple_select',
      fullWidth: true,
      isClearable: false,
    },
    rules: {
      type: 'select',
    }
  },
}