import React from 'react'
import { Grid, Icon, IconButton, LinearProgress, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import ActionBar from './ActionBar'
import empty from '../../../../../assets/empty.png'
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { catalogs } from '../../../../../texts/esp/catalogs'
import GenericStatus from '../../../../../components_v1/Structure/DisplayData/GenericStatus'
import AvatarName from '../../../../../components_v1/Structure/DisplayData/AvatarName'
import { PERMISSION_TYPES } from '../../../../../variables/config'

const header = [
    {id:1, label:'ID'},
    {id:2, label:'Nombre'},
    {id:3, label:'R.F.C'},
    {id:4, label:'Regimen Fiscal'},
    {id:5, label:'Estatus'},
    {id:6, label:''},
]


const useStyles = makeStyles(theme => ({
    root:{},
    addIcon:{
        background:theme.palette.secondary.main,
        borderRadius:'100%',
        color:'white',
        '&:hover':{
            background:theme.palette.secondary.dark,
        }
    },
    paper:{
        marginTop:4,
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const {data, search, onChangeSearch, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, 
        permission,onRemoveItem,onAddRegister} = props


    const onInnerChange = (value, id) => {
        console.log(id)
        console.log(value)
    }

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        //can_edit = permission.id_permission_type > PERMISSION_TYPES.READ
        can_create = permission.id_permission_type > PERMISSION_TYPES.EDIT
        has_full_access = permission.id_permission_type > PERMISSION_TYPES.CREATE
    }

    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item xs/>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>
                            { can_create ?
                                <Grid item> <IconButton className={classes.addIcon} color='primary' onClick={onAddRegister}><Icon>add</Icon></IconButton> </Grid>
                            : null}
                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress /></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {

                                    const {id_business, name, rfc, business_fiscal_regime, business_status_id, image} = item

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{id_business}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><AvatarName name={name} imgSrc={image} /></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{rfc}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><DisplayText variant='body2'>{business_fiscal_regime}</DisplayText></TableCell>
                                            <TableCell className={classes.cell} onClick={() => onSelectedItem(item)}><GenericStatus id={business_status_id} variant='body2' options={catalogs.business_statuses}/></TableCell>
                                            {has_full_access ?
                                                <TableCell className={classes.cell} onClick={() => onRemoveItem(item.id_business)}><IconButton size='small'><Icon>delete</Icon></IconButton></TableCell>
                                            : null}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable