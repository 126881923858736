import { mainServer } from '../../../variables/config';
import axios from 'axios';
import FileSaver from 'file-saver';

export const request_task = async (id) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	const response = await mainServer.instance.get(`/task/${id}`);
	return response.data.data.task;
};

export const request_task_users = async (params) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	const response = await mainServer.instance.get(`/taskuser/all${params}`);
	return response.data.data.task_users;
};

export const request_edit_task = async (id, data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.patch(`/task/${id}`, data);
};

export const request_upload_document = async (id, file, data, url) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	let response = await mainServer.instance.post(`/${url}/${id}/file`, data);
	const options = { headers: { 'Content-Type': file.type } };
	delete axios.defaults.headers.common['Authorization'];
	await axios.put(response.data.data.upload_url, file, options);
};

export const request_download_document = async (id, namefile, url) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	let response = await mainServer.instance.get(`/${url}/${id}/file`, { params: { document: 'document' } });
	delete axios.defaults.headers.common['Authorization'];
	response = await axios.get(response.data.data.path, { responseType: 'blob' });
	FileSaver.saveAs(response.data, namefile);
};

export const request_delete_document = async (id, url) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.delete(`/${url}/${id}/file`, { data: { document: 'document' } });
};

export const request_add_user = async (data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.post(`/taskuser`, data);
};

export const request_users = async () => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	let response;
	response = await mainServer.instance.get(`/timereport/employees`);
	return response.data.data.employees;
};

export const request_delete_task_user = async (id) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.delete(`/taskuser/${id}`);
};

export const request_set_main_user = async (id, data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.patch(`/taskuser/${id}`, data);
};

export const request_subtasks = async (params) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	const response = await mainServer.instance.get(`/task/all${params}`);
	return response.data.data.tasks;
};

export const request_create_task = async (data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	let response;
	response = await mainServer.instance.post(`/task`, data);
	return response.data.data.task_id;
};

export const request_delete_task = async (id) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.delete(`/task/${id}`);
};

export const request_task_comments = async (params) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	const response = await mainServer.instance.get(`/taskcomment/all${params}`);
	return response.data.data.task_comments;
};


export const request_create_comment = async (data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.post(`/taskcomment`, data);
};

export const request_delete_comment = async (id) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.delete(`/taskcomment/${id}`);
};

export const request_edit_comment = async (id, data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.patch(`/taskcomment/${id}`, data);
};
