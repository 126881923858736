export const contentData = {
  spanish:{
    layout:{
      title:'Declaración Anual de Riesgos',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal','Actividad',
        'Actividad Económica','Número de Seguridad Social o Registro Patronal (IMSS)'],
        fixedHeaderB: ['Estado','Ciudad','Usuario IDSE','Contraseña IDSE', 'Certificado Digital IDSE',
        'Contraseña Certificado Digital IDSE','Contraseña SUA','Alta Patronal'],
        varHeader: ['Fecha Límite de Presentación','Hoja de Trabajo','Línea de Captura',
        'Estatus','Clase','Declaración','Fecha de Presentación','Respuesta Cliente',
        'Comentario Cliente','Responsable', 'Notas', 'Horas Extra','Comprobante Horas',
        'Cargos Extra', 'Comprobante Cargo', 'Cargo a Cobranza','Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
