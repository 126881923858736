import { Grid, Icon, makeStyles, useTheme } from '@material-ui/core';
import React, {Component} from 'react';
import { connect } from 'react-redux';
import BasicView from '../../../../components/Layouts/BasicView/BasicView';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { PERMISSION_MODULES, PERMISSION_TYPES } from '../../../../variables/config';
import {contentData} from './content'
import ControlTablesList from './ControlTablesList/ControlTablesList';

const control_tables = [
    {id:1, permission_module_id:PERMISSION_MODULES.CTRL_ACCOUNTING, title:'Contabilidad', url:'/controltables/accounting', visible:true},
    {id:2, permission_module_id:PERMISSION_MODULES.CTRL_MONTHLY_STATEMENT, title:'Impuestos mensuales', url:'/controltables/monthly-statement', visible:true},
    {id:3, permission_module_id:PERMISSION_MODULES.CTRL_SOCIAL_SECURITIES, title:'Seguro Social', url:'/controltables/social-security', visible:true},
    {id:4, permission_module_id:PERMISSION_MODULES.CTRL_STAMPING, title:'Nóminas', url:'/controltables/stamping', visible:true},
    {id:5, permission_module_id:PERMISSION_MODULES.CTRL_STATE_TAXES, title:'Impuesto local sobre nómina', url:'/controltables/state-tax', visible:true},
    {id:6, permission_module_id:PERMISSION_MODULES.CTRL_BILLING, title:'Facturación', url:'/controltables/billing', visible:true},
    {id:7, permission_module_id:PERMISSION_MODULES.CTRL_PHYSICAL_ANNUAL_STATEMENT, title:'Declaración Anual Física', url:'/controltables/physical-annual-statement', visible:true},
    {id:8, permission_module_id:PERMISSION_MODULES.CTRL_AC_ANNUAL_STATEMENT, title:'Declaración Anual AC', url:'/controltables/ac-annual-statement', visible:true},
    {id:9, permission_module_id:PERMISSION_MODULES.CTRL_MORAL_ANNUAL_STATEMENT, title:'Declaración Anual Moral', url:'/controltables/moral-annual-statement', visible:true},
    {id:10, permission_module_id:PERMISSION_MODULES.CTRL_RISK_ANNUAL_STATEMENT, title:'Declaración Anual de Riesgo', url:'/controltables/risk-annual-statement', visible:true},
    {id:11, permission_module_id:PERMISSION_MODULES.CTRL_EFIRMA, title:'Efirma', url:'/controltables/efirma', visible:true},
    {id:12, permission_module_id:PERMISSION_MODULES.CTRL_CERTIFICATE, title:'Certificado Digital para Facturar', url:'/controltables/certificate', visible:true},
    //{id:7, title:'Declaración anual - Moral', url:'/controltables/moral-annual-statement'},
]


class ControlTableControl extends Component{

    state = {
        isloading:false,
        success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
        modals:{
            warning_delete_prospect:{open:false, issending:false, error:null, id:null}
          },
    }


    onCloseSnackbar = (id) => {
        let temp = this.state[id]
        temp.open = false
        this.setState({[id]:{...temp}})
    }
    
    onControlTableSelected = (id) => {
        const {history} = this.props
        history.push('/controltables/details/'+id)
    }

    render(){

        const {history, language, catalogs, permissions} = this.props
        const {isloading, success} = this.state

        //console.log(permissions)

        const content = contentData[language]

        const onSelectedCard = (url) => history.push(url) 

        let _control_tables = [...control_tables]
        if(permissions && permissions.length){
            control_tables.forEach((item,key) => {
                const permCtrl = permissions.find(el => el.permission_module_id === item.permission_module_id)
                if(!permCtrl) console.log(item)
                if(permCtrl && permCtrl.permission_type_id === PERMISSION_TYPES.NO_ACCESS) _control_tables[key].visible = false
            })
        }

        return(
            <BasicView
                history={history}
                content={content.layout}
                isloading={isloading}
                success={success}
                onCloseSnackbar={this.onCloseSnackbar} 
            >
                <div>
                    <Grid container spacing={3}>
                        {_control_tables.map(item => {
                            if(!item.visible) return null
                            return(
                                <Grid item xs={12} md={3} key={item.id.toString()}>
                                    <TableCard {...item} onSelectedCard={onSelectedCard}/>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                {/*<ControlTablesList
                    history={history}
                    language={language}
                    catalogs={catalogs}
                    onSelectedItem={this.onControlTableSelected}
                    permissions={permissions}
                    permissionsType={permissionsType}
                />*/}
            </BasicView>
        );
    }
}

const mapStateToProps = state => {
    return{
        permissions:state.permissions
    }
}

export default connect(mapStateToProps)(ControlTableControl);

const useStyles = makeStyles(theme => ({
    root:{
        '&:hover':{cursor:'pointer'}
    }
}))

const TableCard = ({title, url, onSelectedCard}) => {

    const theme = useTheme()
    const classes = useStyles()
    return(
        <div onClick={() => onSelectedCard(url)} className={classes.root} >
            <SimpleCard>
                <Grid container spacing={1} wrap='nowrap'>
                    <Grid item>
                        <Icon style={{fontSize:48, color:theme.palette.secondary.main}}>table_view</Icon>
                    </Grid>
                    <Grid item xs>
                        <DisplayText>{title}</DisplayText>
                    </Grid>
                </Grid>
            </SimpleCard>
        </div>
    )
}