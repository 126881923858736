import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetTimeDuration, onUpdateDocumentList } from '../../../../../../shared/utility';
import { PERMISSION_TYPES } from '../../../../../../variables/config';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import DocumentElement from '../../../../../../components_v1/Forms/DocumentElement';

const ContractCard = ({files, data, onEdit, onChangePicture, permission, onUpload, onDownload, onDelete}) => {

    const classes = useStyles()
    const _files = onUpdateDocumentList(local_files, data)

    let can_view = true
    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        console.log('Permisos', permission)
        can_view = permission.id_permission_type > PERMISSION_TYPES.NO_ACCESS
        can_edit = permission.id_permission_type > PERMISSION_TYPES.READ
        can_create = permission.id_permission_type > PERMISSION_TYPES.EDIT
        has_full_access = permission.id_permission_type > PERMISSION_TYPES.CREATE
        console.log(can_edit)
    }

    return ( 
        <div>
            {can_view ? (
                <SimpleCard>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText color='primary' variant='subtitle1'>{'Información de contrato'}</DisplayText>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='¿Quién cobra?' data={data ? data.who_charges : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Duración' data={data ? data.contract_duration : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Periodicidad'  data={data ? data.contract_periodicity : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='trending_up' title='Incremento anual' data={data ? data.anual_increment : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='attach_money' title='Comisión por subcontratación' data={data ? data.outsourcing_comission : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='attach_money' title='Valor de contrato' data={data ? data.contract_value : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='attach_money' title='I.V.A.' data={data ? data.iva : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData icon='attach_money' title='Valor total' data={data ? data.total_value : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Condiciones especiales' data={data ? data.contract_special_conditions : null}/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                        <Grid item xs={12}>
                            <div>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <div className={classes.header}>
                                            <Grid container>
                                                <Grid item xs><DisplayText variant='body2' color='textSecondary'>Documento</DisplayText></Grid>
                                                <Grid item><DisplayText variant='body2' color='textSecondary'>Acciones</DisplayText></Grid>
                                            </Grid> 
                                        </div>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <div className={classes.container}>
                                            <Grid container>
                                                {_files.map(item => <Grid item xs={12} key={item.key}>
                                                    <DocumentElement can_edit={can_edit} has_full_access={has_full_access}  data={item} onUpload={onUpload} onDownload={onDownload} onDelete={onDelete}/>
                                                </Grid>)}
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </Grid>
                    
                    </Grid>
                    </SimpleCard>
            ) : null}
        </div>
       
     );
}
 
const useStyles = makeStyles(theme => ({
    container:{
        maxHeight:369,
        overflowY:'auto'
    },
    header:{
        padding:8,
        paddingRight:24
    }
}))

export default ContractCard;

const local_files=[
    {key:'contract',name:'Contrato',path:'contract', status:false},
]