import React, { useState, useEffect } from 'react';
import LoadingContainer from '../../../../components_v1/Structure/Layouts/LoadingContainer';
import SimpleTable from '../components/SimpleTable';
import { request_timereports } from '../requests';
import queryString from 'query-string'
import { onGetMonthsCatalog, onGetUserID } from '../../../../shared/utility';
import moment from 'moment'

const SupervisedTableView = ({ history }) => {

    const [loading, setLoading] = useState()
    const [error, setError] = useState(null)
    const [timereports, setTimereports] = useState([])
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })
    const [filter, setFilter] = useState({ query: '' })
    const [month, setMonth] = useState(moment().month() + 1)
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))
    const [task_type, setTaskType] = useState(-1)


    const userID = onGetUserID()

    useEffect(() => {
        const initModule = async () => {
            //setSuccess('Bienvenido')
            setLoading(true)
            try {
                const { limit, offset } = tableFilter
                const rangeDate = month_options.find(el => el.value === month)
                let filterArgs = [
                    { "field": "supervisor_id", "operator": "LIKE", "value": userID },
                    { "field": "state_id", "operator": "!=", "value": 2 },
                    { "field": "date", "operator": ">=", "value": rangeDate.start_date },
                    { "field": "date", "operator": "<=", "value": rangeDate.end_date }
                ]
                if (task_type !== -1) filterArgs.push({ "field": "task_type_id", "operator": "LIKE", "value": task_type })
                const param2send = { language_id: 2, limit: limit, offset: offset * limit, order_by: 'id_time_report', order: 1, start_date: rangeDate.start_date, end_date: rangeDate.end_date }
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _timereports = await request_timereports(params)
                setTotal(_timereports.count)
                setTimereports(_timereports.time_reports)
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        const selectedYear = '2021'
        const months = onGetMonthsCatalog(selectedYear)
        // console.log(months)


        initModule()
    }, [filter, tableFilter, month, year, task_type])

    const onChangeFilter = (value, type) => {
        if (type === 'month') setMonth(value)
        if (type === 'year') {
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            setMonth(1)
        }
        if (type === 'task_type') setTaskType(value)
    }

    console.log(tableFilter)

    return (
        <LoadingContainer>

            <SimpleTable
                month={month} year={year} monthCatalog={month_options} task_type={task_type}
                data={timereports}
                filter={filter}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onSelectedItem={(data) => window.open(`/timereports/${data.id_time_report}`, '_blank')}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onChangeFilter={onChangeFilter} />
        </LoadingContainer>
    );
}

export default SupervisedTableView;