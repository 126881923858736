import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import { onGetErrorMessage, onGetMonthsCatalog, onGetUserID } from '../../../../../../../../shared/utility'
import { request_employees, request_processes } from '../../../../../requests'
import moment from 'moment'
import queryString from 'query-string'
import { cleanData2Table } from './customFunctions'
import LoadingContainer from '../../../../../../../../components_v1/Structure/Layouts/LoadingContainer'
import ResponsibleFilterModal from './modals/ResponsibleFilterModal'
import BusinessFilterModal from './modals/BusinessFilterModal'
import SimpleTable from './components/SimpleTable'


const styles = makeStyles(theme => ({
    root: {
        marginBottom: 32,
        borderRadius: 12,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    title: {
        color: theme.palette.primary.main,
        padding: '12px 32px',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    content: {
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    }
}))

const History = props => {
 
    const classes = styles()
    const { content, id, data, nodata, onEdit, type } = props

    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)
    const [success, setSuccess] = useState(false)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ language_id: 2, limit: 10, offset: 0, order: 0, order_by: `auto_date` })

    const [process, setProcess] = useState([])
    const [users, setUsers] = useState([])
    const [year, setYear] = useState(null)

    const [responsible, setResponsible] = useState(null)
    const [business, setBusiness] = useState(null)
    const [responsibleModal, setResponsibleModal] = useState(false)
    const [businessModal, setBusinessModal] = useState(false)


    useEffect(() => {
        const current_date = moment().subtract(1, 'month')
        const prev_year = current_date.year()
        setYear(prev_year)
    }, [])

    useEffect(() => {
        if (year)
            onRefresh()
    }, [tableFilter, year, responsible, business])

    const onRefresh = async () => {

        setLoading(true)
        try {
            const userID = onGetUserID()
            let param2send = { ...tableFilter }
            let filterArgs = [
                { "field": "auto_date", "operator": ">=", "value": `${year}-01-01` },
                { "field": "auto_date", "operator": "<=", "value": `${year}-12-31` },
                { "field": 'business_id', "operator": "=", "value": id }
            ]

            if (business) filterArgs.push({ "field": "business_id", "operator": "LIKE", "value": business.value })

            //anterior
            //if (responsible) filterArgs.push({ "field": "fixed_responsible_id", "operator": "LIKE", "value": responsible.value })

            //nuevo
            let ORfilter = []
            if(responsible){
                ORfilter.push({ "field": "fixed_responsible_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_client_classification_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_business_advisor_id", "operator": "=", "value": responsible.value })
                ORfilter.push({ "field": "fixed_client_associate_id", "operator": "=", "value": responsible.value })
                filterArgs.push({"OR":ORfilter})
            }

            param2send.offset = tableFilter.offset * tableFilter.limit
            //filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
            let filter = encodeURIComponent(JSON.stringify({AND:filterArgs}))
            const params = `?${queryString.stringify(param2send)}&filter=${filter}`
            console.log(content.url)
            const _process = await request_processes(`proc${content.url}`, params)
            console.log(_process)
            setTotal(_process.count)
            setProcess(_process[content.path])
            let response = await request_employees()
            setUsers([...response])
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        setLoading(false)
    }

    const onOpenFilter = (id) => {
        if (id === 'RESPONSIBLE_MODAL') setResponsibleModal(true)
        if (id === 'BUSINESS_MODAL') setBusinessModal(true)
    }

    const onAddFilter = (obj, key) => {
        console.log(obj, key)
        if (key === 'business') setBusiness(obj)
        if (key === 'responsible') setResponsible(obj)
    }

    const onRemoveFilter = (key) => {
        console.log(key)
        if (key === 'business') setBusiness(null)
        if (key === 'responsible') setResponsible(null)
    }

    const onChangeFilter = (value) => {
        setYear(value)
    }

    let data2table = []
    if (process)
        data2table = cleanData2Table(process, content.id, nodata, content.maxStep, content.location, content.type_period)

    return (
        <>
            <ResponsibleFilterModal open={responsibleModal} onClose={() => setResponsibleModal(false)} onAddFilter={onAddFilter} />
            <BusinessFilterModal open={businessModal} onClose={() => setBusinessModal(false)} onAddFilter={onAddFilter} />
            <SimpleTable
                header={content.header}
                year={year}
                data={data2table}
                origin_data={process}
                tableFilter={tableFilter}
                total={total}
                loading={loading}
                onSelectedItem={(id) => window.open(`/process/${content.url}/${id}`, '_target')}
                onUpdateTableFilter={(data) => setTableFilter(data)}
                onChangeFilter={onChangeFilter}
                onAddFilter={onOpenFilter}
                onRemoveFilter={onRemoveFilter}
                onRefresh={onRefresh}
                responsible={responsible}
                business={business}
                location={content.location}
                type_period={content.type_period}
                type={type}
            />
        </>
    )
}

export default History