import React, { useEffect, useState } from 'react'
import SimpleModal from '../../components_v1/Structure/Modals/SimpleModal';
import { Grid, Typography, styled } from '@material-ui/core';
import useForm from '../../hooks/useForm';
import InputText from '../../components_v1/Forms/InputText';
import RoundedButton from '../../components_v1/Actions/RoundedButton';
import DisplayError from '../../components_v1/Structure/DisplayData/DisplayError';
import useEmployeePayroll from '../../hooks/api/useEmployeePayroll';

const EditEmployeePayrollModal = ({
    open, onClose, origin, onRefresh
}) => {

    const formManager = useForm(form_data)
    const {form, onChangeForm, onInitForm, onValidateForm, onGetFormData} = formManager
    const [error, setError] = useState()
    const [sending, setSending] = useState(false)

    const employeePayrollManager = useEmployeePayroll()

    const onSubmit = async() => {
        const valid = onValidateForm(form)
    
        if(!valid){
            setError({message:'Debe llenar todos los campos solicitados'})
            return
        }

        const data2send = onGetFormData(origin, {
            eraseEmptySpaces:true
        })
        console.log("Informacion a actualizar ", data2send)

        setSending(true)
        try {
            await employeePayrollManager.actions.onUpdate(origin?.id_employee_payroll, data2send)
            await onRefresh()
        } catch (error) {
            console.log(error)
            setSending(false)
            return
        }
        setSending(false)
        onClose()

    }

    useEffect(() => {
        if(open && origin){
            console.log(origin)
            onInitForm(origin)
            setError(null)
        }
    }, [open, origin])

    return ( 
        <SimpleModal
            open={open}
            onClose={onClose}
        >
            <Typography variant='h6' color='primary'>Detalles de nómina</Typography>
            <StyledContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <InputText data={form.gross_to_pay} onChange={onChangeForm}/>
                    </Grid>
                    <Grid item xs={12}>
                        <InputText data={form.update_motive} onChange={onChangeForm}/>
                    </Grid>
                    <Grid item xs={12}>
                        <DisplayError text={error} />
                    </Grid>
                    <Grid item xs={12}>
                        <RoundedButton loading={sending} onClick={onSubmit}>
                            Guardar
                        </RoundedButton>
                    </Grid>
                </Grid>
            </StyledContainer>
        </SimpleModal>
     );
}
 
export default EditEmployeePayrollModal;

const form_data = {
    gross_to_pay: {
        value:0,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'gross_to_pay',
            type: 'number',
            fullWidth: true,
            autoComplete:"new-password",
            label:'Salario a pagar',
            placeholder:'Salario a pagar',
            helperText:'Debe ser un valor numérico positivo'
        },
        rules: {type: 'numeric', min:0}
    },
    update_motive: {
        value:'',
        error: true,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'update_motive',
            type: 'text',
            rows:5,
            multiline:true,
            fullWidth: true,
            autoComplete:"new-password",
            label:'Motivo de actualización',
            placeholder:'Motivo de actualización',
            helperText:'Debe tener al menos un caracter'
        },
        rules: {type: 'distance', min:0, max:2048}
    },

}

const StyledContainer = styled('div')(({ theme }) => ({
    margin:'16px 0px'
}));