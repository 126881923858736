import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetDateFormat, onGetTimeDuration } from '../../../../../../shared/utility';
import nouser from '../../../../../../assets/nouser.png'
import StatusData from '../../../../../../components_v1/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../../texts/esp/general';
import { red } from '@material-ui/core/colors';
import AvatarPicture from '../../../../../../components_v1/Structure/DisplayData/AvatarPicture';
import { PERMISSION_TYPES } from '../../../../../../variables/config';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const ContactInfoForm = ({title, data, onEdit, onChangePicture, permission}) => {

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    return ( 
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1'>{title}</DisplayText>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'call'} title='Oficina' data={data ? data.pre_office_phone_number : null}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'call'} title='Celular' data={data ? data.pre_cellphone : null}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'call'} title='Telefono' data={data ? data.pre_phone_number : null}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'mail'} title='Correo de trabajo' data={data ? data.pre_work_email : null }/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'mail'} title='Correo alternativo' data={data ? data.pre_alternative_email : null}/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <VerticalData icon={'mail'} title='Correo personal' data={data ? data.pre_personal_email : null}/>
                </Grid>
                {can_edit ? <Grid item xs={12}>
                    <Grid container justify='flex-end'>
                        <Grid item>
                            <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                        </Grid>
                    </Grid>
                </Grid> : null}
            </Grid>
        </SimpleCard>
     );
}
 
export default ContactInfoForm