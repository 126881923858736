import React, {Component} from 'react'
//import { withStyles,} from '@material-ui/core/styles'
import {Grid} from '@material-ui/core'
import SimpleModal from '../../../../../components/Modals/SimpleModal'
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect';

const prepareRFCdata = (rfc) => {
  let rfclist = [];

  if(rfc !== null && rfc !== undefined){
    rfclist = rfc.map((item) => {

      return {
        value: item.id_business,
        label: item.name,
        id: item.id_business,
        firstname: item.name,
        lastname:null,
        image:item.image
      }
    })
  }

  return rfclist;
}

const contentData = {
  spanish:{
    form:{
      business_id:{
        label:'Seleccione un negocio',
        placeholder:'Negocio',
      },
    }
  },
  english:{
    
  }
}

const formData = {
  business_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'business_id',
      type:'select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
}

class AddBusinessModal extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData)),
    isValid: false,
  }

  componentDidMount(){
    const {formData} = this.state;
    const {language, businesses} = this.props;

    const content = contentData[language];
    let temp = {...formData};
    let rfclist = prepareRFCdata(businesses)
    
    temp['business_id'].options = [...rfclist]
    temp['business_id'].config = {
      ...temp['business_id'].config,
      ...content.form['business_id']
    }

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit} = this.props
    const {formData, isValid} = this.state

    if(isValid){
      let data2Send = {}
      Object.keys(formData).forEach((item)=>{
        if(formData[item].isValid){
            data2Send = {...data2Send, [item]:formData[item].value}
        }
      })
      onSubmit(data2Send)
    }
    
  }

  render(){

    const {onClose, data, content} = this.props
    const {formData} = this.state

    
    return(
      <SimpleModal
        data={data}
        onClose={onClose}
        content={content}
        onClick={this.onSubmit}
      >
        <div style={{minWidth:'350px'}}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <InputSimpleSelect 
                wrap='vertical'
                data={formData['business_id']}
                onChange={this.onInputChange}
              />
            </Grid>
            <Grid item xs={12} style={{height:'200px'}}/>
          </Grid>
        </div>
        
      </SimpleModal>
    )
  }
}

export default AddBusinessModal

