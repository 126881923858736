import { onGetDateFormat } from "../../../shared/utility"
import { nodata } from "../../../texts/esp/general"

export const cleanData2Table = (data, nodata, content) => {

    let _data = {}
    if (data)
        _data = {
            evaluator: {
                title: content.evaluator,
                data: data.evaluator ? data.evaluator : nodata,
                image: data.evaluator_image
            },
            evaluation_date: {
                title: content.evaluation_date,
                data: data.evaluation_date ? onGetDateFormat(data.evaluation_date, 'DD MMM YY') : nodata,
            },
            evaluated: {
                title: content.evaluated,
                name: data.evaluated ? data.evaluated : nodata,
                image: data.evaluated_image,
            },
            division: {
                title: content.division,
                data: data.evaluated_area ? data.evaluated_area : nodata,
            },
            position: {
                title: content.position,
                data: data.evaluated_level ? data.evaluated_level : nodata,
            },
            status: {
                title: content.status,
                id: data.evaluation_step_id,
                data: data.evaluation_step ? data.evaluation_step : nodata,
            }

        }

    return _data

}

export const cleanData2TablePerformance = (data, content, catalog) => {
    let _data = {}

    if (data) {

        let master_sum = 0
        _data = {
            total: master_sum,
            data2table: {}
        }

        Object.keys(content).forEach(item => {

            let total_id = `${item}_total`
            let sum = 0

            let tempData = content[item].map((evaluation) => {
                let score = data[evaluation.id] ? data[evaluation.id] : null
                let status = score ? catalog.find(e => e.value === score) : null

                //console.log(data[evaluation.id])
                sum += score ? score : 0
                return {
                    id: evaluation.id,
                    total_id: total_id,
                    label: evaluation.label,
                    status: status,
                    score: score && score <= 5 ? score : nodata,
                }
            })

            master_sum +=  sum ? sum : 0

            _data.data2table[item] = {
                total: sum,
                data: [...tempData]
            }
        })
        _data.total = master_sum
    }

    return _data
}

export const checkTableState = (data, content) => {
    let fullState = true
    if (data) {
        Object.keys(content).forEach(item => {
            content[item].map((evaluation) => {
                fullState = fullState && data[evaluation.id] && data[evaluation.id] > 0
            })
        })
    }else{
        return false
    }

    return fullState
}

const findImageResponsible = (id, users) => {
    let temp = null

    if (id !== null && id !== undefined) {
        if (users.length > 0) {
            temp = users.filter(el => el.id_user === id)
        }
    }

    return temp && temp[0] ? temp[0].image : null
}

export const onRecalculateScoreValues = (data) => {
    const tags = ['iniciative', 'organization','quality','relations','results']
    let data2send = {}
    let generic_total = 0
    tags.forEach(item => {
        const key_numbers = Object.keys(data).filter(el => el.includes(item))
        const end_point = key_numbers.length - 1
        const start_point = 1
        let inner_sum = 0
        for(let i=start_point; i<end_point + 1; i++){
            inner_sum += data[`${item}_${i}`] ? data[`${item}_${i}`] : 0
        }
        data2send[`${item}_total`] = inner_sum
        generic_total += inner_sum
    })
    data2send[`total`] = generic_total
    return data2send
}