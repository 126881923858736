import React, {Component} from 'react'
import { TableHead, TableCell, Table, TableBody, TableRow, Checkbox, AppBar, Tabs, Tab, Typography, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'
import cx from 'classnames'
import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'


import {formData} from './data'
import {contentData} from './content'

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    maxHeight:'300px',
    overflowX:'auto'
},
  table:{
    overflowX:'auto'
  },
  cellheader:{
    fontSize:12,
    padding:'0px 8px'
  },
  cell:{
    border:0,
    fontSize:14,
    color:grey[700],
    fontWeight:'500'
  },
  permission_even:{
    backgroundColor: 'black',
  },
  permission_odd: {
    backgroundColor: 'white'
  }
})

function TabRow(props){
  
  const onChangeItem = (module_id, permission_id, old) => {
    if(permission_id !== old){
      let data2send = {permission_module_id:module_id, permission_type_id:permission_id}
      props.onSubmit(data2send)
    }
  }

  const {kkey, module, module_id, permission_id} = props

  return(
    <TableRow key={kkey.toString()}>
      <TableCell style={styles.cell}>{module}</TableCell>
      <TableCell style={styles.cell} padding='checkbox' align='center'>
        <Checkbox 
          checked={permission_id===1} 
          onChange={(event,status) => onChangeItem(module_id,1,permission_id)}
        />
      </TableCell>
      <TableCell style={styles.cell} padding='checkbox' align='center'>
        <Checkbox 
          checked={permission_id===2} 
          onChange={(event,status) => onChangeItem(module_id,2,permission_id)}
        />
      </TableCell>
      <TableCell style={styles.cell} padding='checkbox' align='center'>
        <Checkbox 
          checked={permission_id===3} 
          onChange={(event,status) => onChangeItem(module_id,3,permission_id)}
        />
      </TableCell>
      <TableCell style={styles.cell} padding='checkbox' align='center'>
        <Checkbox 
          checked={permission_id===4} 
          onChange={(event,status) => onChangeItem(module_id,4,permission_id)}
        />
      </TableCell>
      <TableCell style={styles.cell} padding='checkbox' align='center'>
        <Checkbox 
          checked={permission_id===5} 
          onChange={(event,status) => onChangeItem(module_id,5,permission_id)}
        />
      </TableCell>
      
    </TableRow>
  )
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );

}

class EditPermissions extends Component {

  state = {
    value:0,
    formData: JSON.parse(JSON.stringify(formData))
  }
  
  handleChange = (event,value) => {
    this.setState({value: value})
  }

  format_permissions = (localPermissions) =>{
    const {permissions} = this.props
    const local = {...localPermissions}
    let counter = 0;

    if(permissions.length > 0)
      Object.keys(localPermissions).map(key =>{
        let block = [...local[key]]
        block.map(item => {
          item.id_permission_type = permissions[counter++].id_permission_type
        })
        local[key] = [...block]
      })
    
    return local
  }

  render(){

    const {classes, language, onSubmit, issending, error} = this.props
    const {value} = this.state
    const content = contentData[language]
    const permissionsFormat = this.format_permissions(content.permissions)
    
    const header = (
      <AppBar position="static" color="primary">
          <Tabs variant="fullWidth" value={value} onChange={this.handleChange}>
              {content.header.map((item, key) => {
                  return <Tab value={key} label={item}/>
              })}
          </Tabs>
      </AppBar>
  )

    const pKeys = Object.keys(permissionsFormat);

    const tabs = content.header.map((item,key) => {
      return (
          <TabPanel value={value} index={key}>
            <Table>
                <TableHead>
                  <TableRow>
                  <TableCell className={classes.cellheader}/>
                  {content.table.header.map(item =>{
                    return (
                        <TableCell 
                          padding='checkbox'
                          className={classes.cellheader} 
                          align='center'
                          key={key.toString()}
                        >
                          {item}
                        </TableCell>
                    )
                  })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {permissionsFormat[pKeys[key]].map((item,key) =>{
                    //key, module, module_id, permission_id       
                    return <TabRow
                            className={cx({
                              [classes.permission_even]: key%2 == 0,
                              [classes.permission_odd]: key%2 != 0
                            })}
                            kkey={key}
                            module={item.name}
                            module_id={item.id_permission_module}
                            permission_id={item.id_permission_type}
                            onSubmit={onSubmit}
                          />
                  })}
                </TableBody>
            </Table>
          </TabPanel>
      )
    })

    return(
      <EditCardWrapper
        title={content.title}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
          <div className={cx({
            [classes.table]:true,
            [classes.root]:true
          })}>
            {header}
            {tabs}
          </div>
      </EditCardWrapper>
    )
  }
}

export default withStyles(styles)(EditPermissions)