import { Icon, IconButton, Input, InputBase, makeStyles, Typography } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React, { useState, useEffect, useRef } from 'react';

const InputChat = ({
    onSearch
}) => {

    const classes = useStyles()
    const timerRef = useRef()
    const [value, setValue] = useState("")

    const onChange = (e) => {
        setValue(e.target.value)
    }

    const handleKeyPress = (event) => {
        if(event.key === 'Enter'){
            onSubmit()
        }
    }

    const onSubmit = () => {
        onSearch(value)
        setValue("")
    }

    /*const onStartCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
        timerRef.current = setTimeout(onRequestSearch, 300);
    }
  
    const onStopCountDown = () => {
        if(timerRef.current) clearTimeout(timerRef.current)
    }
  
    const onRequestSearch = async() => { 
        if(timerRef.current) clearTimeout(timerRef.current) 
        onSearch(value)           
    }*/

    return (  
        <>
            <div className={classes.root}> 
                <InputBase 
                    value={value}
                    fullWidth
                    style={{padding:"8px 24px"}}
                    onKeyDown={handleKeyPress}
                    onChange={onChange}
                />
                <IconButton>
                    <Icon>
                        send
                    </Icon>
                </IconButton>
            </div>
            <Typography align='center' variant='caption' component='p' style={{padding:16}}>
             ASiBot es operado por un Assistant de Open IA entrenado. ASiBot no es un profesional de servicios financieros, contables y legales.
            </Typography>
        </>
    );
}

const useStyles = makeStyles(theme => ({
    root:{
        width:'100%',
        border:`1px solid ${grey[300]}`,
        display:'flex',
        flexDirection:'row',
        borderRadius:16,
        //padding:'0xp 32px',
        boxSizing:'border-box'
    }
}))
 
export default InputChat;