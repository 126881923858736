import {mainServer} from '../../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_timereports = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/list${params}` );
  return response.data.data
}

export const request_timereport_statistic = async(id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/statistics/user/${id}`, {params:{...params}} );
  return response.data.data.statistics
}

export const request_permissions = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/permission/"+id+'/list');
  return response.data.data.user_permissions
}

export const request_uploadFile = async(id,path,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/user/'+path+'/'+id
  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_downloadFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/user/'+path+'/'+id
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}

export const request_deleteFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = '/user/'+path+'/'+id
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_upload_image = async(id, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/user/image/${id}`, data)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}

export const request_delete_image = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/user/image/${id}`)
}