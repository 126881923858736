import React, {Component} from 'react'
import { Redirect } from 'react-router'
import moment from 'moment'
import BasicView from '../../../../../components/Layouts/BasicView/BasicView'
import { withStyles } from '@material-ui/core/styles'
import styles from '../styles.jsx'
import WarningModal from '../../../../../components/Modals/WarningModal'
import FileManagerModal from '../../../../../components/Modals/FileManagerModal/FileManagerModal'
import ControlTableForm from '../../../../../components/Forms/ControlTableForm/ControlTableForm'
import {formData} from './servicedata/data'
import {contentData as formContentData} from './servicedata/content'
import {catalog_matrix} from './servicedata/catalog_matrix'
import {contentData} from './content'
import {cleanData2Table} from './customFunctions'
import {onFilteringData} from '../customFunctions'

import {
    request_control_table_by_month,
    request_control_table_by_range,
    request_downloadFile,
    request_update_control_table,
    request_uploadFile,
    request_users
}from './requests';

class Key extends Component{

    state = {
        service:[],
        users:[],
        month:null,
        initDate:null,
        finalDate:null,
        filterBy: 0,
        completed: false,
        isloading:false,
        searchText:null,
        direction:0,
        modals:{
            filemodal:{open:false,issending:false,error:null,extra:null},
        },
        success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
    }

    componentDidMount() {
        this.initModule();
    }

    initModule = async() => {
        try{
            this.setState({isloading:true});
            const today = moment(new Date()).format('YYYY-MM-DD')
            this.setState({month: today, initDate: today, finalDate: today})
            let response = await request_control_table_by_month(0,today);
            this.setState({service:[...response]});
            response = await request_users()
            this.setState({users:[...response]})
            this.setState({isloading:false});
        }catch(e){
            console.log(e);
        }
    }

    updateData = async(completed) =>{
        try{
            this.setState({isloading:true});
            const today = moment(new Date()).format('YYYY-MM-DD')
            this.setState({completed:completed, filterBy:0,
                month:today, initDate: today, finalDate: today})
            const intCompleted = completed ? 1 : 0
            let response = await request_control_table_by_month(intCompleted,today);
            this.setState({service:[...response]});
            this.setState({isloading:false});
            return [...response]
        }catch(e){
            console.log(e);
        }
    }

    filterByMonth = async(filterBy, month) =>{
        try{
            this.setState({isloading:true});
            this.setState({month:month, filterBy: filterBy})
            const completed = this.state.completed ? 1 : 0
            let response = await request_control_table_by_month(completed, month);
            this.setState({service:[...response]});
            this.setState({isloading:false});
            return [...response]
        }catch(e){
            console.log(e);
        }
    }

    filterByRange = async(filterBy, initDate, finalDate) =>{
        try{
            this.setState({isloading:true});
            this.setState({filterBy: filterBy, initDate: initDate, finalDate: finalDate});
            const completed = this.state.completed ? 1 : 0
            let response = await request_control_table_by_range(completed, initDate, finalDate);
            this.setState({service:[...response]});
            this.setState({isloading:false});
            return [...response]
        }catch(e){
            console.log(e);
        }
    }

    onEditItem = async(id, data) => {
        try{
            const {filterBy} = this.state
            let response = await request_update_control_table(id, data, filterBy);
            this.setState({service:[...response]});
            this.onActivateSuccessSnack()
        }catch(e){
            console.log('error:'+ e.response.data.error.message)
        }
    }

    onOpenFileModal = (section, filedata) => {
        const {modals} = this.state
        let tempModals = {...modals}
        tempModals[section].open=true
        if(filedata){
          tempModals[section].data = {...filedata} 
        }
        this.setState({modals:{...tempModals}})
    }

    onSubmitFile = async(filepath, file, data) => {
        try{
            this.onEditModal('filemodal',{issending:true})
            const aux = file[0].name.split('.')
            const ext = {ext:aux[aux.length-1]}
            let response = await request_uploadFile(data.id, data.path, file[0], ext)
            this.setState({service:[...response]})
            this.onCloseModal('filemodal')
            this.onActivateSuccessSnack()
        }catch(e){
            this.onEditModal('filemodal',{issending:false})
            if(e.response){
                this.onEditModal('filemodal',{error:e.response.data.error.message})
            }
        }
    }

    onDownloadFile = async (data) => {
        try{
            await request_downloadFile(data.id, data.path)
        }catch(e){
            console.log(e)
        }
    }

    onFileActionManager = async(action, data) => {
        if(action === 'upload'){
            this.onOpenFileModal('filemodal',data)
        }else{
            this.onDownloadFile(data)
        }
    }
    
    onActivateSuccessSnack = () => {
        const {success} = this.state
        let success_temp = {...success}
        success_temp.open = true
        this.setState({success:{...success_temp}})
    }

    onCloseSnackbar = (id) => {
        let temp = this.state[id]
        temp.open = false
        this.setState({[id]:{...temp}})
    }

    onCloseModal = (type) => {
        this.onEditModal(type, {open:false, issending:false, error:null})
    }

    onEditModal = (section, data) => {
        const {modals} = this.state;
        let tempModals = {...modals};
        tempModals[section]={...tempModals[section],...data};
        this.setState({modals:{...tempModals}})
    }

    onReturn = () => {
        this.props.history.goBack()
    }

    render(){

        const {history, language, catalogs, permission,
                permissionsType} = this.props
        const {month, initDate, finalDate, filterBy, completed, success, 
            isloading, modals, service, users, searchText, direction} = this.state

        const content = contentData[language]
        let dataTable = {fixedDataA:[],fixedDataB:[],varData:[]};
        let temp = onFilteringData(service, searchText, direction)

        temp.forEach(data =>{
            dataTable = cleanData2Table(dataTable,data,content.basicform.nodata);
        });

        let modalcontent = null
        if(modals.warning_delete_file){
            modalcontent=(
                <WarningModal
                data={modals.warning_delete_file}
                content={content.modals.warning_delete_file}
                onClose={() => this.onCloseModal('warning_delete_file')}
                onDelete={this.onDeleteFile}
                />
            )
        }

        if(modals.filemodal.open){
            modalcontent = (
                <FileManagerModal
                data={modals.filemodal}
                open={true}
                language={language}
                onClose={() => this.onCloseModal('filemodal')}
                onSubmit={this.onSubmitFile}
                onDelete={this.onDeleteFile}
                />
            )
        }



        let contentView = (
            <ControlTableForm
                staticview={content.basicform}
                data={dataTable}
                formData={formData}
                content={formContentData[language]}
                catalog_matrix={catalog_matrix}
                catalogs={catalogs.control_tables}
                users={users}
                onEditData={this.onEditItem}
                onFileAction={this.onFileActionManager}
                updateData={this.updateData}
                completed={completed}
                month={month}
                initDate={initDate}
                finalDate={finalDate}
                filterBy={filterBy}
                filterByMonth={this.filterByMonth}
                filterByRange={this.filterByRange}
                language={language}
                direction={direction}
                onChangeState={this.onChangeState}
                onSearch={this.onSearch}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        
     

        return(
            <BasicView
                history={history}
                content={content.layout}
                onChange={this.onChangeView}
                isloading={isloading}
                returnBtn={true}
                onReturn={this.onReturn}
                success={success}
                onCloseSnackbar={this.onCloseSnackbar}
            >
                {modalcontent}
                {permission === 1 ? (
                <Redirect to='/profile'/>
                ) : null}
                <div>
                {contentView}
                </div>
            </BasicView>
        )
    }

    onSearch = (data) => {
        this.setState({searchText:data})
    }

    onChangeState = () => {
        const {direction} = this.state
        let new_value = direction
        new_value++
        if(new_value > 2){
            new_value = 0
        }
        this.setState({direction:new_value})
    }

}

export default withStyles(styles)(Key);
