import { useState, useCallback } from 'react';

const useModal = (init_data) => {

    const [modals, setModals] = useState(JSON.parse(JSON.stringify(init_data)))

    const onReset = useCallback(() => {
        let _modals = JSON.parse(JSON.stringify(init_data))
        setModals(_modals)
    },[modals])

    const onChangeModalStatus = useCallback((key, status) => {
        let _modals = {...modals}
        _modals[key] = status
        setModals(_modals)
    },[modals])

    return {
        modals,
        setModals,
        onChangeModalStatus,
        onReset
    }
}

export default useModal