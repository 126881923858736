export const formData = {
  
  rfc:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'rfc',
      type:'text',
      fullWidth: true,
    },
    rules:{
      rules:{
        type:'rfc',
      }
    }
  },
  sat_password:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'sat_password',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  ciec_key:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'ciec_key',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  }
}