import React from 'react';
import { Grid, Icon, IconButton, makeStyles, useTheme } from '@material-ui/core';
import { blue, grey, red } from '@material-ui/core/colors';
import DisplayText from '../Texts/DisplayText';
import { PERMISSION_TYPES } from '../../variables/config';
import cx from 'classnames'
import { catalogs } from '../../texts/esp/catalogs';

const ProcessElement = ({ onDelete, onSelected, permission, process_id, id_business_process, pending, has_full_access }) => {

  const classes = useStyles()
  const theme = useTheme()
  const process = catalogs.process.find(p => p.value === process_id)
  const icons = [{ icon: 'check', color: theme.palette.primary.main }, { icon: 'priority_high', color: red[700] }]

  return (
    <div className={classes.container}>
      <Grid container wrap='nowrap' alignItems='center' spacing={1}>
        <Grid item xs>
          <div className={cx({
            [classes.subcontainer]: true,
          })} onClick={() => !permission || permission >= PERMISSION_TYPES.READ ? onSelected(process_id, id_business_process) : undefined}>
            <Grid container spacing={1}>
              <Grid item>
                <Icon className={classes.icon} style={pending !== null ? { background: icons[pending].color } : {}}>{pending !== null ?  icons[pending].icon : null}</Icon>
              </Grid>
              <Grid item xs>
                <DisplayText variant='body2' className={classes.name}>{process.label}</DisplayText>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item>
          <IconButton disabled={!has_full_access} className={classes.customHoverFocus} onClick={() => onDelete(process_id)}>
            <Icon fontSize='small'>close</Icon>
          </IconButton>
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  card: { borderRadius: '16px' },
  container: { padding: '8px', paddingLeft: '8px', borderTop: `1px solid ${grey[200]}` },
  name: {},
  subcontainer: { cursor: 'pointer' },
  subcontainer_disabled: {
    opacity: 0.6, cursor: 'not-allowed'
  },
  customHoverFocus: {
    color: red[700]
  },
  icon: {
    color: 'white',
    borderRadius: '100%',
    fontSize:18,
    padding: 2
  }
}))


export default ProcessElement