import React, {Component} from 'react'
import { Grid } from '@material-ui/core'

import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'
import ItemSelection from '../../../../../components/Inputs/ItemSelection/ItemSelection'


import {formData} from './data'
import {contentData} from './content'

class EditBenefits extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }

  onSubmit = (id, status) => {
    let data2send = {benefits:[{benefit_id:id, status:status}]}
    this.props.onSubmit(data2send)
  }

  render(){

    const {language, user, issending, error} = this.props
    const content = contentData[language]

    let contentA = null
    let benefits = []
    if(user){
      benefits = [...user.benefits]
    }

    contentA = benefits.map((item,key)=>{
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          <ItemSelection 
            label={item.benefit}
            status={item.status === 1}
            id={item.benefit_id}
            onChange={this.onSubmit}
          />
        </Grid>
      )
    })
    
    return(
      <EditCardWrapper
        title={content.title}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
          <Grid container spacing={2}>
            {contentA}
          </Grid>
      </EditCardWrapper>
    )
  }
}

export default EditBenefits