import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Typography, Grid, Icon } from '@material-ui/core'
import cx from 'classnames'

import { SideBar } from '../../../variables/config';
import asiviewer from '../../../assets/platforms/asiviewer.png'
import ascgBanner from '../../../assets/company/30years.png'
import NotificationBubble from '../../../components_v1/Texts/NotificationBubble';


const styles = (theme) => ({
  root: {
    background: theme.palette.primary.main,
    color: 'white',
    paddingTop: '84px',
    height: '100vh'
  },
  button: {
    boxSizing: 'border-box',
    width: `${SideBar.width}px`,
    padding: '16px 8px',
    position:'relative',
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      background: 'rgba(255,255,255,0.3)',
      cursor: 'pointer'
    }
  },
  buttonSelected: {
    background: theme.palette.secondary.light,
    transition: theme.transitions.create(['background'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      cursor: 'pointer',
      background: theme.palette.secondary.light,
    }
  },
})

class MenuBar extends Component {

  isCompatibleURL = (history, path) => {
    return history.location.pathname.split('/')[1] === path.split('/')[1];
  }

  render() {

    const { classes, history, routes, notifications, onClick } = this.props;

    return (
      <div className={classes.root}>
        <Grid container direction='column' alignItems='center'>
          {routes.map((item, key) => {
            if (item.sidebar) {
              const selected = this.isCompatibleURL(history, item.path);
              //console.log(item)
              return (
                <Grid item key={key.toString()}>
                  <div
                    onClick={() => onClick(item.path)}
                    className={cx({
                      [classes.button]: true,
                      [classes.buttonSelected]: selected
                    })}
                  >
                    {notifications && item.notification_id && notifications[item.notification_id] > 0 &&
                      <NotificationBubble number={notifications[item.notification_id]} />
                    }
                    <div>
                      <Grid container direction='column' alignItems='center'>
                        <Grid item>
                          <Icon>{item.icon}</Icon>
                        </Grid>
                        <Grid item>
                          <Typography variant='body2' style={{ fontSize: 12 }} align='center'>
                            {item.label}
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                    
                </Grid>
              )
            }
            return null
          })}
          <Grid item><div style={{ width: 83, height: 32 }} /></Grid>
          <Grid item>
            <img src={ascgBanner} alt='' width={64} style={{padding:20}} />
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(MenuBar)