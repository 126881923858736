import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import ActionModalBar from '../../../Actions/ActionModalBar';
import SimpleModal from '../../../Structure/Modals/SimpleModal';
import DisplayText from '../../../Texts/DisplayText';
import InputFile from '../../../Forms/InputFile';
import { onGetErrorMessage, onGetFileExtension } from '../../../../shared/utility';
import { request_timereport, request_upload_document } from '../requests';

const DocumentModal = ({id, open, onClose, onUpdateData}) => {

    const [file, setFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    const onSubmit = async() => {try {
        if(!file){
            setError('Debe adjuntar un documento')
            return
        }
        setLoading(true)
        const fileext = onGetFileExtension(file.name)
        const file2send = {name:'evidence', ext:fileext}
        await request_upload_document(id, file, file2send)
        const _timereport = await request_timereport(id)
        onUpdateData(_timereport)
        onClose()

    } catch (error) {
        console.log(error)
        setError(onGetErrorMessage(error))   
    } setLoading(false)}

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm'>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText variant='h6' color='primary'>Subir documento</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <InputFile id='file' file={file} label='Adjuntar evidencia' 
                        onChange={(file) => setFile(file)} onDelete={() => setFile(null)}/>
                </Grid>
                <Grid item xs={12}>
                    <ActionModalBar btnLabel='Subir' loading={loading} error={error} onSubmit={onSubmit}/>
                </Grid>
            </Grid>
        </SimpleModal>
     );
}
 
export default DocumentModal;