import React, { useState } from 'react'
import { Grid, Icon, makeStyles, Popover } from '@material-ui/core'
import { blueGrey, grey } from '@material-ui/core/colors'
import DisplayText from '../Texts/DisplayText'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import es from "date-fns/locale/es";

const useStyles = makeStyles(theme => ({
    root: {
        background: 'white',
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding: '4px 16px',
        borderRadius: 20,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    icon: {
        color: blueGrey[500],

    },
    subtitle: {
        paddingLeft: 0,
        paddingBottom: 0,
        color: theme.palette.primary.main
    },
    popper: {
        marginTop: 8,
        padding: '16px 8px',
        paddingLeft: 0,
        borderRadius: 32,
        width: 200,
        boxSizing: 'border-box',
        overflow: 'hidden'
    },
    container: {
        maxHeight: 250,
        //background:'red',
        overflowX: 'hidden',
        overflowY: 'auto',

    },
    item: {
        fontFamily: theme.typography.fontFamily,
        padding: "12px 16px",
        borderTop: `1px solid ${grey[200]}`,
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    },
    selected: {
        fontWeight: 500
    }
}))

const DateButton = props => {
    const classes = useStyles()
    const { label, format, onChange, variant, openTo, views,
        helper, minDate, maxDate, value } = props

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
            <div className={classes.root}>
                <DisplayText variant='caption' className={classes.subtitle}>{label}</DisplayText>
                <Grid container alignItems='center' spacing={1} wrap='nowrap'>
                    <Grid item>
                        <DatePicker
                            variant={variant ? variant : 'inline'}
                            openTo={openTo}
                            disableToolbar
                            views={views}
                            format={format}
                            helperText={helper}
                            minDate={minDate}
                            maxDate={maxDate}
                            value={value}
                            onChange={onChange}
                            adjustDateOnChange
                            InputProps={{
                                disableUnderline: true
                            }}
                        />
                    </Grid>
                </Grid>
            </div>
        </MuiPickersUtilsProvider>

    )
}

export default DateButton