export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        alert_id:{
          placeholder:'Alerta'
        },
        notice_id:{
          placeholder:'Aviso'
        },
        folio:{
          placeholder:'Folio'
        },
        representation_date:{
          placeholder:'Fecha de Representación'
        },
        compliance_officer:{
          placeholder:'Oficial de Cumplimiento'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
