export const contentData = {
    spanish:{
        title:'Información Basica',
        nodata:'Sin Registro',
        error:'Sin registro',
        button:'Guardar',
        cancelButton:'Cancelar',
        image_button:'Cambiar',
        form:{
            rfc:{
                label:'RFC',
                placeholder:'RFC'
            },
            sat_password:{
                label:'Contraseña SAT',
                placeholder:'Contraseña SAT'
            },
            taxpayer_type_id:{
                label:'Tipo de Contribuyente',
                placeholder:'Tipo de Contribuyente'
            },
            tax_payment_limit_date:{
                label:'Fecha Límite de Pago Impuestos',
                placeholder:'Fecha Límite de Pago Impuestos'
            },
        }
    }
};
