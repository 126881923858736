import React, { useState, useEffect } from 'react';
import BasicView from '../../components_v1/Structure/Layouts/BasicView';
import SimpleTable from './components/SimpleTable';
import { request_bonus, request_businesses, request_permissions } from './requests';
import queryString from 'query-string' 
import moment from 'moment';
import InfoModal from './modals/InfoModal';
import EditModal from './modals/EditModal';
import { onGetMonthsCatalog } from '../../shared/utility';


const Bonus = (props) => {

    const {history, language} = props
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    //const [addbusinessmodal, setAddBussinessModal] = useState(false)
    const [businesses, setBusinessView] = useState([])
    const [permission_type, setPermissionType] = useState(null)

    //Table states
    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'bonus_status_id', order:1})
    const [search, setSearch] = useState(null)

    //modals
    const [bonus_selected, setBonuSelected] = useState(null)
    const [info_modal, setInfoModal] = useState(false)
    const [edit_modal, setEditModal] = useState(false)

    const [period, setPeriod] = useState(Math.ceil((moment().month()-2)/2))
    const [year, setYear] = useState(moment().year())
    const [month_options, setMonthOptions] = useState(onGetMonthsCatalog(moment().year()))

    //console.log(moment().month()-1)

    useEffect(() => {
        initModule()
    },[tableFilter, period, year])

    const initModule = async() => {try {
        //console.log(search)
        setLoading(true)

        const permissions = await request_permissions()
        const permission = permissions.user_permissions.find(el => el.permission_module_id === 74)
        setPermissionType(permission.permission_type_id)
        //console.log(permission)

        const args = queryString.parseUrl(history.location.search)
        const {limit, offset, order_by, order} = tableFilter
        //const month_options = onGetMonthsCatalog(moment().year())
        const rangeDate1 = month_options.find(el => el.value === period*2-1)
        const rangeDate2 = month_options.find(el => el.value === period*2)
        //console.log(rangeDate1.start_date)
        //console.log(rangeDate2.end_date)
        let filterArgs = [
            {"field":"employee","operator":"LIKE","value":`%${search?search:''}%`},
            {"field":"start_date","operator":">=","value":rangeDate1.start_date},
            {"field":"start_date","operator":"<","value":rangeDate2.end_date}
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
        //let params = `?${queryString.stringify(param2send)}` //&filter=${filterArgs}
        const _bonus = await request_bonus(params)
        console.log(_bonus)
        setBusinessView(_bonus.bonuses)
        setTotal(_bonus.count)
        //console.log(_businesses.businesses)
    } catch (error) {
        console.log(error)
    }
        setLoading(false)
    }

    const onChangeSearch = (val) => {
        console.log(val)
        setSearch(val)
        //setTableFilter({...tableFilter, offset:0})
    }

    const onChangeFilter = (value, type) => {
        if(type === 'period') setPeriod(value)
        if(type === 'year'){
            setYear(value)
            setMonthOptions(onGetMonthsCatalog(value.toString()))
            console.log(onGetMonthsCatalog(value.toString()))
            setPeriod(1)
        }
    }

    const onSelectedItem = (type, data) =>{
        setBonuSelected(data)
        if(type === 'edit'){
            setEditModal(true)
        }else{
            setInfoModal(true)
        }
    }

    return ( 
        <div>
            <InfoModal open={info_modal} onClose={()=>setInfoModal(false)} bonus={bonus_selected} history={history} />
            <EditModal open={edit_modal} onClose={()=>setEditModal(false)} bonus={bonus_selected} onUpdateCompleted={initModule}  />
            <BasicView 
                title='Bonos' 
                error={error} 
                onCloseError={() => setError(null)}
                success={success} 
                onCloseSuccess={() => setSuccess(null)}
            >
                {/*<AddBusinessModal open={addbusinessmodal} onClose={() => setAddBussinessModal(false)} history={history} language={language}/>*/}
                <SimpleTable 
                    search={search} 
                    onChangeSearch={onChangeSearch} 
                    //onAddRegister={() => setAddBussinessModal(true)}
                    //onSelectedItem={permission_type > 2 ? onSelectedItem : null}
                    permission={permission_type}
                    onSelectedItem={onSelectedItem}
                    data={businesses} 
                    tableFilter={tableFilter}  
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                    total={total} 
                    loading={loading} 
                    year={year}
                    period={period}
                    onChangeFilter={onChangeFilter}
                />

            </BasicView>
        </div> 
     );
}
 
export default Bonus;