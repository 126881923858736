import { Card, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    root:{
        borderRadius:12,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
        padding:32,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    }
}))

const SimpleCard = ({children, padding}) => {
    const classes = useStyles()

    let styles = {}
    if(padding === 'small') styles = {...styles, padding:12}

    return(
        <Card className={classes.root} style={styles}>
            {children}
        </Card>
    )
}

export default SimpleCard