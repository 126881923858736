import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_social_security,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData
    ];

    let fixedDataB = [
        currentData.idse_user_employer_enrollment ? currentData.idse_user_employer_enrollment : noData,
        currentData.idse_user_number_employees ? currentData.idse_user_number_employees : noData, 
        currentData.idse_user_user ? currentData.idse_user_user : noData,
        currentData.idse_user_password ? currentData.idse_user_password : noData,
        currentData.idse_user_sipare ? currentData.idse_user_sipare : noData,
        currentData.idse_user_expiration_date ? currentData.idse_user_expiration_date : noData
    ]

    let varData = [
        {key:'imss_capture_line'},
        {key:'client_response'},
        currentData.payment_date ? moment(currentData.payment_date).format('DD-MM-YYYY') : noData,
        currentData.amount ? currentData.amount : noData ,
        {key:'payment_proof'},
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        payment_date: currentData.payment_date,
        amount: currentData.amount,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_social_security,key:'imss_capture_line',name:'Línea de Captura IMSS/Infonavit',
            path:'imsscaptureline', status:checkStatus(currentData, 'imss_capture_line')},
        {id:currentData.id_ctrl_social_security,key:'client_response',name:'Respuesta del Cliente',path:'clientresponse',
            status: checkStatus(currentData, 'client_response')},
        {id:currentData.id_ctrl_social_security,key:'payment_proof',name:'Acuse de Pago',path:'paymentproof',
            status: checkStatus(currentData, 'payment_proof')},
        {id:currentData.id_ctrl_social_security,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_social_security,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
