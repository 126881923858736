export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
        title:'Información Básica',
        button:'Editar',
        nodata:'Sin Registro',
        data:{
            name:{
                label:'',
                data:''
            },
            specs:{
                label:'',
                data:''
            },
            requirements:{
                label:'',
                data:''
            },
            due_date:{
                label:'',
                data:''
            },
            process:{
                label:'',
                data:''
            },
            periodicity:{
                label:'',
                data:''
            },
            responsible:{
                label:'',
                data:''
            },
            estimated_delivery_date:{
                label:'',
                data:''
            },
            comments:{
                label:'',
                data:''
            },
            project_costs:{
                label:'',
                data:''
            },
            provider:{
                label:'',
                data:''
            },
            cost:{
                label:'',
                data:''
            },
            sell_price:{
                label:'',
                data:''
            }
        },
        content:{
            name:{
                label:'Nombre'
            },
            specs:{
                label:'Especificaciones del Proyecto'
            },
            requirements:{
                label:'Requisitos Técnicos'
            },
            due_date:{
                label:'Fecha Requerida'
            },
            process:{
                label:'Proceso A Realizar'
            },
            periodicity:{
                label:'Periodicidad'
            },
            responsible:{
                label:'Responsable'
            },
            estimated_delivery_date:{
                label:'Fecha Estimada de Entrega'
            },
            comments:{
                label:'Comentarios'
            },
            project_costs:{
                label:'Costos del Proyecto'
            },
            provider:{
                label:'Nombre del Provedor'
            },
            cost:{
                label:'Costo'
            },
            sell_price:{
                label:'Precio de Venta'
            }
        },
        documents:{
            title:'Documentos',
            header:{title:'Archivos', actions:'Acciones'},
            files:[
                {key:'documents',name:'Documentos',path:'documents',status:false}
            ]
        }
    }
  }
}