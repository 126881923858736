import React from 'react'
import { Popper, makeStyles, ClickAwayListener, Card, Popover } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    popper:{}
}))

const PopperContainer = props => {

    const classes = useStyles()
    const {anchorEl, topOffset, onClose, children} = props

    return(
        <Popover onClose={onClose}  open={Boolean(anchorEl)} anchorEl={anchorEl} className={classes.popper} 
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}>
            {children} 
        </Popover>
    )
}

export default PopperContainer