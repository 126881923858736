import {mainServer} from '../../../../../variables/config.jsx';
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_idse_users = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}

export const request_accounts = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/billingaccount/list/"+id.toString());
  return response.data.data.idse_users;
}

export const request_stamps = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}

export const request_add_idse_user = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/idseuser",data);
  let response;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}


export const request_edit_idse_user = async (id, data, accountID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/idseuser/" + accountID, data);
  let response;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}

export const request_delete_idse_user = async (id, stampID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/idseuser/" + stampID);
  let response;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}

/**************************************** */
export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

export const request_uploadFile = async(id,path,file, ext, extraID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'idseuser/' + extraID + '/' + path
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/idseuser/list/"+id.toString());
  return response.data.data.idse_users;
}

export const request_downloadFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'idseuser/' + id + '/' + path;
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}