import {grey} from '@material-ui/core/colors';
import wallpaper from '../../assets/fondo_inicio.png'
import smsmiembrologo from '../../assets/LogoSMSMIEMBRO.png'

const styles = theme => ({
  root:{
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    width:'100%',
    minHeight:'100vh',
    backgroundImage:`url(${wallpaper})`,
    backgroundPosition:'center',
    backgroundSize:'cover',
    [theme.breakpoints.down('sm')]: {
      background: theme.palette.primary.dark,
    },
  },
  container:{
    boxSizing:'border-box',
    paddingTop:100,
    position:'relative',
    [theme.breakpoints.down('sm')]: {
      padding:40,
      paddingLeft:8,
      paddingRight:8
    },
  },
  card:{
    borderRadius:40,
  },
  iconBanner:{
    position:'absolute',
    background:'white',
    width:64,
    zIndex:16,
    left:'50%',
    transform:'translate(-50%, -50%)',
    borderRadius:'50%',
    padding:8,
    boxShadow: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)'
    //height:64,

  },
  cardContent:{
    boxSizing:'border-box',
    paddingTop:54,
    paddingBottom:54,
    paddingLeft:16,
    paddingRight:32,
    width:400,
    [theme.breakpoints.down('sm')]: {
      width:'100%',
    },
  },
  backgroundDesktop: {
    position: "absolute",
    zIndex: "1",
    height: "100%",
    width: "100%",
    display: "block",
    background:`url(${wallpaper})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat'
  },
  backgroundMobile:{
    background: theme.palette.primary.main,
    position: "absolute",
    zIndex: "1",
    height: "150%",
    width: "100%",
    display: "block",
  },
  firstLogo:{
    width:254
  },
  secondLogo:{
    width:254
  },
  //logo:{
  //  width:300,
  //  height:'auto',
  //  [theme.breakpoints.down('sm')]: {
  //    width:'100%'
  //  },
  //},
  formContent:{
    padding:'0px'
  },
  content:{
    position:'absolute',
    padding:32
  },
  
  button:{
    marginTop:' 0px',
    background: `linear-gradient(135deg, ${theme.palette.primary.main} , ${theme.palette.secondary.main})`,
    borderRadius:'20px',
    height:'40px',
    width:'200px'
  },
  
  mobileFormContent:{
    margin: '20px 20px'
  },
  mobileContainer:{
    display:'flex',
    position: "relative",
    zIndex: "50",
    margin:'0 0',
    height:'100vh'
  },
  typoMobile:{
    color:'white'
  },
  errorMessage:{
    color:'red',
    fontSize:'14px',
    fontWeight:'600'
  },
  ima:{
    margin:'auto',
    width:"250px", 
  },
  letter:{
    fontStyle: 'italic',
    color:grey[900],
    fontWeight:'500',
  },
  bor:{
    border:'1px solid red'
},
});

export default styles;
