import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onInitForm, onSetErrorsToForm } from '../../../../../../shared/utility';
import InputCheckbox from '../../../../../../components_v1/Forms/InputCheckbox';
import { request_employees } from '../requests';

const useStyles = makeStyles(theme => ({
    form: {
        paddingTop: 32,
        paddingBottom: 32
    }
}))

const prepareData = (users) => {
    let usersData = [];

    if (users) {
        usersData = users.map((item) => {
            let firstname = item.first_name ? item.first_name : '';
            let lastname = item.last_name ? item.last_name : '';
            return {
                value: item.id_user,
                label: firstname + ' ' + lastname,
                image: item.image
            }
        })
    }

    return usersData;
}

const UserModal = props => {

    const classes = useStyles()
    const { open, sending, onClose, content, catalogs, origin_data, history, language } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    useEffect(() => {
        const init = async () => {
            try {
                setLoading(true)
                let _users = await request_employees()
                setUsers([..._users])
            } catch (error) {
                console.log(error)
            }
            setLoading(false)
        }
        init()
    }, [])


    useEffect(() => {
        if (open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, origin_data, content.form)
            // Update catalogs
            catalog_matrix.forEach(item => {
                if (item.catalog_key !== 'none') {
                    _form[item.form_key].options = catalogs[item.catalog_key]
                } else {
                    const aux_catalog = prepareData(users)
                    _form[item.form_key].options = aux_catalog
                }
            })

            if (origin_data) {
                console.log(origin_data)
                _form.restricted.value = origin_data.restricted === 1
                _form.task_approver.value = origin_data.task_approver === 1
            }

            setForm({ ..._form })
            setError('')
            setLoading(false)
        }
    }, [open, users, origin_data])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        try {
            setLoading(true)
            const errors = isFormValid(form)
            /// Check if the form is valid
            if (errors && errors.length) {
                console.log(errors)
                const new_form = onSetErrorsToForm(form, errors)
                setForm(new_form)
                return
            }

            //Extract data
            let data2send = onGetFormData(form)

            data2send.restricted = data2send.restricted ? 1 : 0
            data2send.task_approver = data2send.task_approver ? 1 : 0
            //console.log(data2send)
            //Send data
            props.onSubmit(data2send)
        } catch (e) {
            console.log(e)
            setError('No fue posible actualizar la información')
        }
        setLoading(false)
    }

    return (
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <InputCheckbox typography='subtitle1' data={form.task_approver} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputCheckbox typography='subtitle1' data={form.restricted} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.first_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.last_name} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.gender_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.scolarity_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.specialization} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.user_level_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.area_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.direct_boss_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.birth_date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputSelect data={form.timezone} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={sending} error={error} onSubmit={onSubmit} />
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
    );
}

const catalog_matrix = [
    { form_key: 'gender_id', catalog_key: 'user_genders' },
    { form_key: 'scolarity_id', catalog_key: 'user_scolarities' },
    { form_key: 'employee_status_id', catalog_key: 'employee_statuses' },
    { form_key: 'user_level_id', catalog_key: 'user_levels' },
    { form_key: 'area_id', catalog_key: 'user_areas' },
    { form_key: 'direct_boss_id', catalog_key: 'none', propname: 'users' },
    { form_key: 'timezone', catalog_key: 'timezones' },
]

const formData = {
    restricted: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'restricted',
            type: 'checkbox',
            fullWidth: true,
            label: '¿Tiene acceso a contenido restringido?',
            helper: 'Campo inválido'
        },
        rules: {
            type: 'checkbox',
        }
    },
    task_approver: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'task_approver',
            type: 'checkbox',
            fullWidth: true,
            label: '¿Es supervisor?',
            helper: 'Campo inválido'
        },
        rules: {
            type: 'checkbox',
        }
    },
    first_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'first_name',
            type: 'text',
            fullWidth: true,
            helper: 'Campo inválido',
            label: 'Nombre(s)',
            placeholder: 'Nombre(s)',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    last_name: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'last_name',
            type: 'text',
            fullWidth: true,
            label: 'Apellido(s)',
            placeholder: 'Apellido(s)',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    gender_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'gender_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Género',
            placeholder: 'Género',
        },
        rules: {
            type: 'none',
        }
    },
    scolarity_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'scolarity_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Escolaridad',
            placeholder: 'Escolaridad',
        },
        rules: {
            type: 'none',
        }
    },
    specialization: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'specialization',
            type: 'text',
            fullWidth: true,
            label: 'Especialización',
            placeholder: 'Especialización',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    employee_status_id:{
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'employee_status_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Puesto',
            placeholder: 'Puesto',
        },
        rules: {
            type: 'none',
        }
    },
    user_level_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'user_level_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Posición',
            placeholder: 'Posición',
        },
        rules: {
            type: 'none',
        }
    },
    area_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'area_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Área',
            placeholder: 'Área',
        },
        rules: {
            type: 'none',
        }
    },
    direct_boss_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'direct_boss_id',
            type: 'simple_select',
            fullWidth: true,
            label: 'Jefe directo',
            placeholder: 'Jefe directo',
        },
        rules: {
            type: 'none',
        }
    },
    birth_date: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'birth_date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha de nacimiento',
            placeholder: 'Fecha de nacimiento',
        },
        rules: {
            type: 'distance',
            min: 1,
            max: 100
        }
    },
    /*language_id:{
      value: null,
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      options:[],
      config:{
        id:'language_id',
        type:'simple_select',
        fullWidth: true,
      },
      rules:{
        type:'none',
      }
    },*/
    timezone: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'timezone',
            type: 'simple_select',
            fullWidth: true,
            label: 'Zona Horaria',
            placeholder: 'Zona Horaria',
        },
        rules: {
            type: 'none',
        }
    },
}

export default UserModal;