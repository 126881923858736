import {mainServer} from '../../../../../variables/config.jsx';
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_service = async (id, servicePath, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/${servicePath}/${id}/betweenmonths${params}`);
  return response.data.data;
}
/**************************************** */
export const request_downloadFile = async(id,service_path, path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = `/${service_path}/${id}/${path}`
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}