export const formData = {
  work_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'work_email',
      type:'email',
      fullWidth: true,
      icon:{name:'mail',position:'start'},
    },
    rules:{
      type:'email',
    }
  },
  alternative_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'alternative_email',
      type:'email',
      fullWidth: true,
      icon:{name:'mail',position:'start'},
    },
    rules:{
      type:'email',
    }
  },
  personal_email:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'personal_email',
      type:'email',
      fullWidth: true,
      icon:{name:'mail',position:'start'},
    },
    rules:{
      type:'email',
    }
  },
  phone_number:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'phone_number',
      type:'number',
      fullWidth: true,
      icon:{name:'phone',position:'start'},
    },
    rules:{
      type:'distance',
      min:10,
      max:100
    }
  },
  extension:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extension',
      type:'number',
      fullWidth: true,
      icon:{name:'phone',position:'start'},
    },
    rules:{
      type:'none',
    }
  },
  cellphone:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'cellphone',
      type:'number',
      fullWidth: true,
      icon:{name:'phone',position:'start'},
    },
    rules:{
      type:'distance',
      min:10,
      max:100
    }
  },

}