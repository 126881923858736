import React, { useState, useEffect } from 'react';
import { catalogs } from '../../../texts/esp/catalogs';
import { nodata } from '../../../texts/esp/general';
import StatusData from './StatusData';

const TimeReportStatus = ({id, variant}) => {

    const status = catalogs.timereport_statuses.find(el => el.value === id)

    return ( 
        <div>
            <StatusData data={status ? status.label : nodata} color={status ? status.color : null} variant={variant ? variant : 'body1'}/>
        </div>
     );
}
 
export default TimeReportStatus;