import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../../../../components_v1/Texts/DisplayText';
import { onGetFormData, onInitForm, isFormValid, onSetErrorsToForm } from '../../../../../../../../../../shared/utility';
import { catalogs } from '../../../../../../../../../../texts/esp/catalogs';

const useStyles = makeStyles(theme => ({
  form: {
    paddingTop: 32,
    paddingBottom: 32
  }
}))

const EditTaxesModal = ({ id, open, onClose, init_data, onUpdateCompleted }) => {

  const classes = useStyles()

  const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')

  useEffect(() => {

    let _form = JSON.parse(JSON.stringify(formData))
    if (init_data) {
      _form = onInitForm(_form, init_data)
    }
    setForm(_form)
    if (!open) { setError(''); setLoading(false); }

  }, [open])

  const onChange = (data) => {
    let temp = { ...form }
    const id = data.config.id
    temp[id] = { ...data }
    setForm(temp)
  }

  const onSubmit = async () => {

    const errors = isFormValid(form)
    /// Check if the form is valid
    if (errors && errors.length) {
      const new_form = onSetErrorsToForm(form, errors)
      setForm(new_form)
      setError('Llene los campos faltantes')
      return
    }

    //Extract data
    let data2send = onGetFormData(form)
    //Send data
    setLoading(true)
    try {
      await onUpdateCompleted(data2send)
    } catch (error) {
      console.log(error)
      setError('Hubo un error')
      setLoading(false)
    }

  }
 

  return (
    <SimpleModal open={open} onClose={onClose} maxWidth='md'>
      <div>
        <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>Información del negocio</DisplayText>
        <div className={classes.form}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <InputText data={form.payday_limit} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.sending_date_limit} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.tax_preview} onChange={onChange} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputText data={form.coi} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <InputText data={form.utility_coefficient} onChange={onChange} />
            </Grid>
            <Grid item xs={12}>
              <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit} />
            </Grid>
          </Grid>
        </div>
      </div>
    </SimpleModal>
  );
}

export default EditTaxesModal;

const formData = {
  payday_limit: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'payday_limit',
      type:'number',
      fullWidth: true,
      label: 'Límite de envio de impuestos',
      placeholder: 'Ingrese un número positivo'
    },
    rules: { type: 'none' }
  },
  sending_date_limit: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'sending_date_limit',
      type:'number',
      fullWidth: true,
      label: 'Envio estados financieros',
      placeholder: 'Ingrese un número positvo'
    },
    rules: { type: 'none' }
  },
  tax_preview: {
    value: false,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'tax_preview',
      type: 'number',
      fullWidth: true,
      label: 'Previo de impuestos',
      placeholder: 'Ingrese un número mayor a cero'
    },
    rules: {
      type: 'numeric',
      min: 1
    }
  },
  coi: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options: [...catalogs.business_fiscal_regimes],
    config: {
      id: 'coi',
      type: 'number',
      fullWidth: true,
      label: 'Número en COI',
      placeholder: 'Ingrese un número positivo'
    },
    rules: {
      type: 'none',
    }
  },
  utility_coefficient: {
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config: {
      id: 'utility_coefficient',
      type: 'number',
      fullWidth: true,
      label: 'Coeficiente de utilidad',
      placeholder: 'Ingrese un número positivo'
    },
    rules: {
      type: 'none'
    }
  },
}