export const contentData = {
  spanish:{
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
        title:'Información Básica',
        button:'Editar',
        nodata:'Sin Registro',
        data:{
            name:{
                label:'',
                data:''
            },
            taxpayer_type:{
                label:'',
                data:''
            },
            process:{
                label:'',
                data:''
            },
            due_date:{
                label:'',
                data:''
            },
            responsible:{
                label:'',
                data:''
            },
            estimated_delivery_date:{
                label:'',
                data:''
            },
            comments:{
                label:'',
                data:''
            },
            project_cost:{
                label:'',
                data:''
            },
            provider:{
                label:'',
                data:''
            },
            cost:{
                label:'',
                data:''
            },
            sell_price:{
                label:'',
                data:''
            }
        },
        content:{
            name:{
                label:'Nombre'
            },
            taxpayer_type:{
                label:'Tipo de sociedad'
            },
            process:{
                label:'Proceso A Realizar'
            },
            due_date:{
                label:'Fecha Requerida'
            },
            responsible:{
                label:'Responsable'
            },
            estimated_delivery_date:{
                label:'Fecha Estimada de Entrega'
            },
            comments:{
                label:'Comentarios'
            },
            project_cost:{
                label:'Costos del Proyecto'
            },
            provider:{
                label:'Nombre del Provedor'
            },
            cost:{
                label:'Costo'
            },
            sell_price:{
                label:'Precio de Venta'
            }
        },
        documents:{
            title:'Documentos',
            header:{title:'Archivos', actions:'Acciones'},
            files:[
                {key:'address_proof_center',name:'Comprobante de Domicilio del Centro de Trabajo',path:'addressproofcenter',status:false},
                {key:'workplace_croquis', name:'Croquis del Centro de Trabajo',path:'workplacecroquis',status:false},
                {key:'attorney_power_act',name:'Primera y última hoja del Poder Notarial',path:'attorneypoweract',status:false},
                {key:'attorney_power_seal',name:'Primera y última hoja de la Escritura Pública',path:'attorneypowerseal',status:false},
                {key:'imss_enrollment_format',name:'Formato "Alta IMSS"',path:'imssenrollmentformat',status:false},
                {key:'documents',name:'Adjuntar Documentos',path:'documents',status:false},
                {key:'constitutive_act',name:'Acta Constitutiva',path:'constitutiveact', status:false, businessDocument:true},
                {key:'efirma_cer',name:'Certificado eFirma',path:'efirmacer', status:false, businessDocument:true},
                {key:'efirma_key',name:'Llave eFirma',path:'efirmakey', status:false, businessDocument:true},
            ]
        }
    }
  }
}