export const cleanData2Table = (data, nodata, catalogs) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_bank_account, 
      files:[
        {id:item.id_bank_account,key:'files',name:'Archivos',path:'files', status: checkStatus(item, 'files')},
      ],
      data:[]
    }

    let array = [
      item.id_bank_account, 
      item.bank ? item.bank : nodata,
      item.branch_office ? item.branch_office: nodata,
      item.account_number ?  item.account_number : nodata,
      item.clabe ?  item.clabe : nodata,
      item.currency ?  item.currency : nodata,
      'files',
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
