import { updateObject } from '../shared/utility'
import { actionTypes } from './actions'

const initialState = {
    user:null,
    permissions:null,
    isauth:false,
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case actionTypes.AUTH_UPDATE_USER:
            return updateObject(state, {user:action.user})
        case actionTypes.AUTH_UPDATE_PERMISSIONS:
            return updateObject(state, {permissions:action.permissions})
        default:
            return state;

    }
}

export default reducer;