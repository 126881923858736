export const formData = {
  payroll_periodicity_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'payroll_periodicity_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  roster_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'roster_type_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  payroll_system_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'payroll_system_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  employee_number:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'employee_number',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'numeric',
      min:0,
      max:999999
    },
  },
  note:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'note',
      multiline: true,
      rows: 6,
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:200
    },
    doubleSpace: true
  },

}