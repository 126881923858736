import { Grid, Icon, IconButton } from '@material-ui/core'
import React from 'react'
import DisplayText from '../Texts/DisplayText'

const DocumentInput = ({ data, onUploadDocument, onDownloadDocument, disabled, downloadEnabled }) => {

    const { key, name, path, status } = data

    return (
        <Grid container justify='center'>
            <Grid item xs={12}>
                <DisplayText variant='subtitle2' align='center' color={'primary'}>{name}</DisplayText>
            </Grid>
            <Grid item><IconButton color='secondary' disabled={disabled} onClick={() => onUploadDocument(key, path)}><Icon fontSize='small'>cloud_upload</Icon></IconButton></Grid>
            <Grid item><IconButton color='primary' disabled={!status || (disabled && !downloadEnabled)} onClick={() => onDownloadDocument(key, path)}><Icon fontSize='small'>cloud_download</Icon></IconButton></Grid>
        </Grid>
    )
}

export default DocumentInput