
export const cleanData2Table = (data, nodata, users) => {

  const table = data.map(item => {
    
    let temp = {
      id:item.id_key, 
      files:[],
      data:[]
    }

    let array = [
      item.id_key, 
      item.name ? item.name : nodata,
    ]

    temp.data = [...array]
    return temp
  })

  return table
}
