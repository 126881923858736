export const contentData = {
  spanish:{
    title:'Agregar',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      number:{
        label:'Cuenta',
        placeholder:'Cuenta',
      },
      branch_office:{
        label:'Sucursal',
        placeholder:'Sucursal',
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña',
      },
      expiration_date:{
        label:'Fecha de expiración',
        placeholder:'Fecha de expiración',
      },
      used:{
        label:'Estatus',
        placeholder:'Estatus',
      },
    },
  },
}