export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    save_button:'Guardar',
    image_button:'Cambiar',
    title:'',
    sectionA:{
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)',
      },
      last_name:{
        label:'Apellido(s)',
        placeholder:'Apellido(s)',
      },
      gender_id:{
        label:'Género',
        placeholder:'Género',
      },
      scolarity_id:{
        label:'Escolaridad',
        placeholder:'Escolaridad',
      },
      specialization:{
        label:'Especialización',
        placeholder:'Especialización',
      },
      user_level_id:{
        label:'Posición',
        placeholder:'Posición',
      },
      area_id:{
        label:'Área',
        placeholder:'Área',
      },
      birth_date:{
        label:'Fecha de nacimiento',
        placeholder:'Fecha de nacimiento',
      },
      language_id:{
        label:'Idioma',
        placeholder:'Idioma',
      },
      timezone:{
        label:'Zona Horaria',
        placeholder:'Zona Horaria',
      }
    },
  },
}