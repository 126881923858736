import { Snackbar, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }));

function Alert(props) {
return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const SnackbarSuccess = ({open, onClose}) => {

    const classes = useStyles();
    
    return (
      <div className={classes.root}>
        <Snackbar open={open} autoHideDuration={6000} onClose={onClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
          <Alert onClose={onClose} severity="success">
            ¡Información actualizada exitosamente!
          </Alert>
        </Snackbar>
      </div>
    );

}


export default SnackbarSuccess