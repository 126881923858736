export const contentData = {
    spanish: {
        title: 'Solución Integral',
        url: 'procintegralsolution',
        edit_url: 'ctrlaccounting',
        path: 'proc_integral_solution',
        button: 'Editar',
        banners: {
            fiscal: {
                title: 'Fiscal',
                button: 'Editar',
                form: {
                    accounting_method: 'Método de pago de impuestos'
                }
            },
            taxes: {
                title: 'Impuestos',
                button: 'Editar',
                form: {
                    payday_limit: 'Límite de envio de impuestos',
                    sending_date_limit: 'Envio estados financieros',
                    tax_preview: 'Previo de impuestos',
                    coi: 'Número en COI',
                    utility_coefficient: 'Coeficiente de utilidad'
                }
            },
            responsibles: {
                title: 'Responsables',
                button: 'Editar',
                form: {
                    advisor: 'Asesor',
                    analyst: 'Analista',
                    supervisor: 'Supervisor',
                    staff: 'Staff',
                    fixed_responsible: 'Impuestos'
                }
            },
            history: {
                title: 'Historial',
                filter: 'Año',
                url: 'integralsolution',
                path: 'proc_integral_solutions',
                id: 'integral_solution',
                maxStep: 15,
                header: [
                    { id: 1, label: 'Periodo', key: 'auto_date' },
                    /* { id: 2, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 3, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 4, label: 'No. de paso', key: 'fixed_integral_solution_step_id' },
                    { id: 5, label: 'Prioridad', key: 'solution_priority' },
                    { id: 6, label: 'Porcentaje' },
                    { id: 7, label: 'Estatus', key: 'process_semaphore_id' }, */

                    //nuevo
                    { id: 9, label: 'Clasificación', key: 'fixed_client_classification' },
                    { id: 20, label: 'Importancia', key: 'fixed_importance' },
                    { id: 21, label: 'Socio', key: 'fixed_client_associate' },
                    { id: 23, label: 'Responsable', key: 'responsible' },
                    { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
                    { id: 24, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 4, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 5, label: 'No. de paso', key: 'fixed_integral_solution_step_id' },
                    { id: 6, label: 'Prioridad', key: 'solution_priority' },
                    { id: 7, label: 'Porcentaje' },
                    { id: 8, label: 'Estatus', key: 'process_semaphore_id' },
                ],
            }
        },
        modals: {
            edit_modal: {

            }
        }
    }
}
