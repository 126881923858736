export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        presentation_limit_date:{
          placeholder:'Fecha Límite de Presentación'
        },
        process_status_id:{
          placeholder:'Estatus'
        },
        isr:{
          placeholder:'ISR'
        },
        isr_retentions:{
          placeholder:'Retenciones ISR'
        },
        iva:{
          placeholder:'IVA'
        },
        iva_retentions:{
          placeholder:'Retenciones IVA'
        },
        other:{
          placeholder:'Otros'
        },
        surcharges_and_updates:{
          placeholder:'Recargos y Actualizaciones'
        },
        total_to_pay:{
          placeholder:'Total a Pagar'
        },
        payment_limit_date:{
          placeholder:'Fecha Límite de Pago'
        },
        client_comments:{
          placeholder:'Comentarios Cliente'
        },
        diot_folio:{
          placeholder:'DIOT folio'
        },
        diot_presentation_limit_date:{
          placeholder:'DIOT Fecha Límite de Presentación'
        },
        diot_responsible_id:{
          placeholder:'DIOT Responsable'
        },
        compliance_opinion_status_id:{
          placeholder:'Estado de Opinión de Cumplimiento'
        },
        payment_date_mailbox:{
          placeholder:'Buzón Fecha de Pago'
        },
        mailbox_status_id:{
          placeholder:'Buzón Estatus'
        },
        mailbox_comment:{
          placeholder:'Buzón Comentarios'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        },
        ciec_key:{
          placeholder: 'Clave CIEC'
        }
      }
    }
  }
