export const ADD_SESSION = 'ADD_SESSION'

export const AUTH_UPDATE_USER = 'AUTH_UPDATE_USER'
export const AUTH_UPDATE_PERMISSIONS = 'AUTH_UPDATE_PERMISSIONS'

export const CHANGE_AUTH_STATUS = 'CHANGE_AUTH_STATUS' 
export const UPDATE_CART_ITEMS = 'UPDATE_CART_ITEMS'
export const UPDATE_APOLLO_CLIENT = 'UPDATE_APOLLO_CLIENT'

export const actionTypes = {
    AUTH_UPDATE_USER: 'AUTH_UPDATE_USER',
    AUTH_UPDATE_PERMISSIONS: 'AUTH_UPDATE_PERMISSIONS',
    AUTH_UPDATE_NOTIFICATIONS: 'AUTH_UPDATE_NOTIFICATIONS'
}