import { makeStyles, Grid, Card, IconButton, Icon } from '@material-ui/core'
import React from 'react'
import DisplayText from '../../../../components_v1/Texts/DisplayText'

const styles = makeStyles(theme => ({
    root: {
        height: '100%',
        borderRadius: 12,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    title: {
        backgroundColor: theme.palette.primary.main,
        padding: '12px 32px',
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    content: {
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    }
}))

const CardContainer = props => {

    const classes = styles()
    const { content, data, files, nodata, onDownloadDocument } = props

    return (
        <Card className={classes.root}>
            {content.title && (
                <div className={classes.title}>
                    <DisplayText variant='body2' style={{ color: 'white' }} align='center'>
                        {content.title.toUpperCase()}
                    </DisplayText>
                </div>
            )}
            <div className={classes.content}>
                <Grid container justify='flex-start' alignItems='center' spacing={2}>
                    {Object.keys(content.content).map((item, key) => {
                        return (
                            <Grid item xs={12} sm={6} key={key}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <DisplayText variant='subtitle2' color='textSecondary'>{content.content[item]}</DisplayText>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DisplayText variant='body2'>{data[item] ? data[item] : nodata}</DisplayText>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )
                    })}
                    {files && files.map((file, key) => {
                        return (
                            <Grid item xs={12} sm={6} key={key}>
                                <InternDocumentInput
                                    data={file}
                                    onDownloadDocument={() => onDownloadDocument(file)}
                                    downloadEnabled
                                />
                            </Grid>
                        )
                    })}
                </Grid>
            </div>
        </Card>
    )
}

export default CardContainer


const InternDocumentInput = ({ data, onDownloadDocument }) => {

    const { key, name, path, status } = data
    
    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <DisplayText variant='subtitle2' color={'textSecondary'}>{name}</DisplayText>
            </Grid>
            <Grid item xs={12}>
                <IconButton color='primary' disabled={!status} onClick={() => onDownloadDocument(key, path)}><Icon fontSize='small'>cloud_download</Icon></IconButton>
            </Grid>
        </Grid>
    )
}