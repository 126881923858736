import React from 'react'
import MomentUtils from '@date-io/moment';
import {
    MuiPickersUtilsProvider,
    DatePicker,
  } from '@material-ui/pickers';
import { grey } from '@material-ui/core/colors';
import { Checkbox, Grid, withStyles } from '@material-ui/core';
import moment from 'moment'
import {ruleValidation} from './customFunctions.jsx';
import DisplayText from '../Texts/DisplayText.jsx';


const InputCheckbox = props => {    

    const {onChange, data, disabled, typography} = props
    const {value, isVisited, isValid} = props.data
    const {id, label,placeholder, helper} = props.data.config


    const handleDateChange = (e, value) => {
        let temp = {...data};
        temp.value = value;
        temp.isValid = true
        temp.isVisited = true;
        onChange(temp);
    }


    const error = isVisited && !isValid;

    return(
        <div>
          <Grid container wrap='nowrap' alignItems='center'>
            <Grid item>
              <DisplayText variant={typography ? typography : 'subtitle2'}  color={error ? 'error' : 'inherit'}>{label}</DisplayText>
            </Grid>
            <Grid item xs>
              <Checkbox id={id} checked={value} onChange={handleDateChange}/>
            </Grid>
          </Grid>
            
        </div>
    )
}

export default InputCheckbox