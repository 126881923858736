export const catalog_matrix = [
  {form_key:'taxpayer_type_id', catalog_key:'tax_payer_types'},
  {form_key:'fiscal_situation_id', catalog_key:'fiscal_situations'},
  {form_key:'importers_list_id', catalog_key:'importer_list'},
  {form_key:'accounting_method_id', catalog_key:'accounting_methods'},
  {form_key:'inventory_system_id', catalog_key:'inventory_systems'},
  {form_key:'inventory_evaluation_method_id', catalog_key:'inventory_evaluation_methods'},
  {form_key:'financial_states_norm_id', catalog_key:'financial_states_norms'},
  {form_key:'commercial_relation_id', catalog_key:'commercial_relations'},
  {form_key:'financial_states_periodicity_id', catalog_key:'financial_states_periodicities'},
  {form_key:'report_language_id', catalog_key:'report_languages'},
  {form_key:'tax_preview', catalog_key:'tax_preview'},
  {form_key:'as_responsible_id', catalog_key:'none', propname:'users'},
  {form_key:'analyst_id', catalog_key:'none', propname:'users'},
  {form_key:'supervisor_id', catalog_key:'none', propname:'users'},
  {form_key:'staff_id', catalog_key:'none', propname:'users'},




  //{form_key:'responsible_id', catalog_key:'none', propname:'users'},
]