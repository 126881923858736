import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import {
  ClickAwayListener,
  Popper,
  Fade,
  Paper,
} from '@material-ui/core';
import { fade } from '@material-ui/core/styles/colorManipulator';


const styles = theme => ({
  popper:{
    marginTop:8,
    zIndex:3000,
  },
  iconUserMenu:{
    color: theme.palette.primary.main,
  },
  menuitem:{
    '&:hover':{
      background: fade(theme.palette.primary.main,0.1)
    }
  },
  paper:{
    borderRadius:8,
  }
});


class PopperWrapper extends Component{
  render(){

    const {open, anchorEl, onClickAway, classes} = this.props;    

    return(
        <Popper 
          open={open} 
          anchorEl={anchorEl} 
          placement='bottom-start'
          transition 
          className={classes.popper} 
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={onClickAway} >
                  {this.props.children}
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
    );
  }
};

export default withStyles(styles)(PopperWrapper)
