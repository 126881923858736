import { Grid, makeStyles } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import RoundedButton from '../../../../components_v1/Actions/RoundedButton';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetCatalogs, onGetFormData, onSetErrorsToForm, onGetUserID, onGetFileExtension } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import { request_create_timereport, request_timereports_businesses, request_timereports_services, request_timereports_supervisors, request_upload_document } from '../requests';

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const BusinessFilterModal = (props) => {

    const classes = useStyles()
    const { open, onClose, onRequestUsers, history, onAddFilter } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [file, setFile] = useState(null)
    const [businesses, setBusinesses] = useState([])
    const [services, setServices] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        if (!open) {
            setForm(JSON.parse(JSON.stringify(formData)))
            setError(''); setLoading(false); setBusinesses([])
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchBusiness = async(e) => {
        if(!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _businesses = await request_timereports_businesses(e.target.value)
            const _business_catalog = onGetCatalogs(_businesses, 'business_id', 'business')
            setBusinesses(_business_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }

        const data2send = businesses.find(el => el.value === form.business_id.value)

        onAddFilter(data2send, 'business')
        onClose()

    }

    // Update catalogs in real time 

    let business_form = {...form.business_id}
    business_form.options = [...businesses]

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='sm' padding='small'>
            <div>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <DisplayText variant='subtitle1'>Seleccione un negocio</DisplayText>
                            <InputSelect data={business_form} onChange={onChange} onInputChange={onSearchBusiness}/>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item><RoundedButton onClick={onSubmit} >Seleccionar</RoundedButton></Grid>
                            </Grid>
                        </Grid>
                    
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default BusinessFilterModal;

const formData = {
    business_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options:[],
        config: {
            id: 'business_id',
            type: 'select',
            fullWidth: true,
            label: '',
            placeholder:'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: {type: 'select'}
    },
    
}