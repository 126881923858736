export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        accordion_title: 'Enviadas al cliente',
        buttons_label: 'Disponibilidad',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        accordions:[
            {
                id: 'sent_to_client',
                title: 'Enviadas al cliente',
            },
            {
                id: 'client_response',
                title: 'Respuestas al cliente'
            },
        ]
    }
}
