import { Avatar, Grid, Typography, makeStyles } from "@material-ui/core";
import React from "react";
import { onGetDateFormat, onGetFullname } from "../../../shared/utility";
import AvatarPicture from "../../../components_v1/Structure/DisplayData/AvatarPicture";


const styles = makeStyles(theme => ({
    day:{
        background:theme.palette.primary.main,
        borderRadius:8,
        width:36,
        height:36,
        color:'white',
        display:'flex',
        justifyContent:'center',
        alignItems:'center'
    }
}))

const DisplayBirthday = ({first_name, last_name, birth_date, image}) => {

    const classes = styles()

    return(
        <Grid container spacing={2} alignItems="center">
            <Grid item><AvatarPicture imgSrc={image} size={40}/></Grid>
            <Grid item xs><Typography align="left">{onGetFullname(first_name, last_name)}</Typography></Grid>
            <Grid item>
                <Typography style={{fontSize:'0.5rem', fontWeight:600}} >{onGetDateFormat(birth_date, 'MMM')}</Typography>
                <div className={classes.day} >
                    <Typography>{onGetDateFormat(birth_date, 'DD')}</Typography>
                </div>
            </Grid>
        </Grid>
    )
}

export default DisplayBirthday