export const contentData = {
    spanish: {
        button: 'Finalizar',
        back: 'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
    }
}
