export const contentData = {
    spanish: {
        title: 'Timbrado y cálculo de nómina',
        url: 'procstamping',
        path: 'proc_stampings',
        button: 'Editar',
        banners: {
            general: {
                title: '',
                button: 'Editar',
                form: {
                    responsible: 'Responsable nómina',
                    analyst: 'Analista nómina',
                    supervisor: 'Supervisor nómina',
                    advisor: 'Asesor del cliente',
                    client_responsible: 'Responsable de cliente',
                }
            },
            history: {
                title: 'Historial',
                filter: 'Año',
                url: 'stamping',
                path: 'proc_stampings',
                id: 'stamping',
                maxStep: 7,
                type_period: true,
                header: [
                     { id: 1, label: 'Periodo', key: 'auto_date' },
                    /*{ id: 2, label: 'Periodo Nómina', key: 'fixed_ctrl_period_name' },
                    { id: 10, label: 'Periodicidad', key: 'fixed_payroll_periodicity' },
                    { id: 3, label: 'Periodo', key: 'auto_date' },
                    { id: 4, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 5, label: 'Fecha límite', key: 'fixed_payday_limit' },
                    { id: 6, label: 'No. de paso', key: 'fixed_stamping_step_id' },
                    { id: 7, label: 'Prioridad', key: 'solution_priority' },
                    { id: 8, label: 'Porcentaje' },
                    { id: 9, label: 'Estatus', key: 'process_semaphore_id' }, */

                    //nuevo
                    { id: 2, label: 'Tipo', key:'fixed_roster_type' },
                    { id: 3, label: 'Periodo Nómina', key:'fixed_ctrl_period_name' },
                    { id: 11, label: 'Periodicidad', key:'fixed_payroll_periodicity' },
                    { id: 12, label: 'Clasificación', key: 'fixed_client_classification' },
                    { id: 20, label: 'Importancia', key: 'fixed_importance' },
                    { id: 21, label: 'Socio', key: 'fixed_client_associate' },
                    { id: 23, label: 'Responsable', key: 'responsible' },
                    { id: 22, label: 'Asesor', key: 'fixed_business_advisor' },
                    { id: 24, label: 'Turno de', key: 'fixed_responsible' },
                    { id: 6, label: 'Fecha límite', key:'fixed_payday_limit' },
                    { id: 7, label: 'No. de paso', key:'fixed_proc_stampings_step_id' },
                    { id: 8, label: 'Prioridad', key:'solution_priority' },
                    { id: 9, label: 'Porcentaje', },
                    { id: 10, label: 'Estatus', key:'process_semaphore_id' },
                ],
            }
        },
    }
}
