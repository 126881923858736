import React from "react";
import { grey } from "@material-ui/core/colors";

const styles = theme => ({
    root: {
      borderRadius:8,
      padding:32,
      backgroundColor: 'white'
    },
    hidden:{
        display:'none'
    },
    hiddenTable:{
        backgroundColor:theme.secondary
    },
    generalTable: {
        overflowX: 'auto',
        backgroundColor: 'white',
        padding:'0px',
        overflowY: 'hidden'
    },
    noWidth:{
        maxWidth:'none'
    },
    borderTable:{
        borderRight:'solid 2px',
        color: grey[300],
        marginRight:'20px'
    },
    icon:{
        color:'white',
        backgroundColor:theme.palette.primary.main,
    },
    subIcon:{
        fontSize: 14
    },
    card:{
        margin: '20px',
        padding: '64px',
        background: 'white'
    },
    selectionButton:{
        padding:'0px 10px 0px 10px'
    },
  });

export default styles;
