import React from 'react'
import { Button, CircularProgress, Fab, Grid, Icon, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableHead, TableRow, withStyles } from '@material-ui/core'
import { onFormatNumber, onGetDateFormat, onGetDecimalNumber, onGetFullname, onGetTimeDuration } from '../../../../shared/utility'
import { blueGrey, grey } from '@material-ui/core/colors'
import SearchBar from './SearchBar'
import FilterButton from './FilterButton'
import ActionBar from './ActionBar'
import empty from '../../../../assets/empty.png'
import DisplayText from '../../../../components_v1/Texts/DisplayText'
import TimeReportState from '../../../../components_v1/Structure/DisplayData/TimeReportState'
import UserStatistics from '../../../../components_v1/Structure/Tables/TimeReportTable/components/UserStatistics'
import TimeReportStatus from '../../../../components_v1/Structure/DisplayData/TimeReportStatus'
import { catalogs } from '../../../../texts/esp/catalogs'
import { Rating } from '@material-ui/lab'
import StarData from '../../../../components_v1/Structure/DisplayData/StarData'
import FilterDate from './FilterDate'


const statusCatalog = [{id:-1, value:-1, label:'Todos'}, ...catalogs.timereport_statuses]
const searchCatalog = [{id:1, value:'first_name', label:'Nombre '},{id:2, value:'username', label:'Usuario'}]

const header = [
    {id:1, label:'Negocio'},
    {id:3, label:'Estatus'},
    {id:4, label:'Horas contratadas'},
    {id:5, label:'Horas trabajadas'},
    {id:8, label:'Duración promedio'},
    {id:9, label:'Complejidad'},
    {id:10, label:'Satisfacción'},
]


const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
        boxShadow:'0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        '&:hover':{
            cursor:'pointer',
            background:grey[100]
        }
    },
    cellHeader:{
        borderColor:grey[300],
        paddingTop:16,
        paddingBottom:16
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const BusinessSimpleTable = props => {

    const classes = useStyles()
    const {data, status, search, onChangeSearch, onChangeFilter, total, tableFilter, onUpdateTableFilter, onSelectedItem, loading, 
        startDate, endDate, onChangeDate, year, month, monthCatalog, onRemoveItem} = props


    return(
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} > 
                    <Grid item xs={12}>
                        <Grid container wrap='nowrap' spacing={2} alignItems='flex-end'>
                            <Grid item><FilterDate id='start_date' label='Fecha inicial' value={startDate} onChange={onChangeDate}/></Grid>
                            <Grid item><FilterDate id='end_date' label='Fecha final' value={endDate} onChange={onChangeDate}/></Grid>
                            <Grid item><FilterButton label='Estatus' options={statusCatalog} selected={status} onChange={(val) => onChangeFilter(val,'status')}/></Grid>
                            <Grid item xs/>
                            <Grid item><SearchBar value={search} onChange={onChangeSearch}/></Grid>

                        </Grid>
                    </Grid>
                    
                </Grid>
            </div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item><CircularProgress size={32}/></Grid>
                </Grid>
            ) : null}
            <Paper className={classes.paper}>
                {data && data.length ? (
                    <div className={classes.tableWrapper}>
                        <Table className={classes.table}>
                            {header ? (
                                <TableHead>
                                    <TableRow>
                                        {header.map(item=>{
                                            return(
                                                <TableCell padding={item.id === 1 ? 'default' : 'default'} className={classes.cellHeader} key={item.id.toString()}>
                                                    <DisplayText variant='subtitle2' >{item.label}</DisplayText>
                                                </TableCell>
                                            )
                                        })}
                                    </TableRow>
                                </TableHead>
                            ):null}
                            <TableBody>
                                {data.map((item,key) => {
                                    const {business, average_client_satisfaction, average_complexity, average_duration,
                                        area, client_number, scheduled_time, time_report_status, time_report_status_id, worked_time} = item

                                    //const report_date = onGetDateFormat(date, 'DD MMM YY')
                                    //const timdata = onGetTimeDuration(duration)

                                    return(
                                        <TableRow key={key.toString()} className={classes.row} >
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{business}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><TimeReportStatus id={time_report_status_id} variant='body2'/></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{onGetTimeDuration(scheduled_time)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{onGetTimeDuration(worked_time)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><DisplayText variant='body2'>{onGetTimeDuration(average_duration)}</DisplayText></TableCell>
                                            <TableCell className={classes.cell}><StarData data={parseFloat(average_complexity).toFixed(1)}/></TableCell>
                                            <TableCell className={classes.cell}><StarData data={parseFloat(average_client_satisfaction).toFixed(1)}/></TableCell>
                                            {/*<TableCell className={classes.cell}><TimeReportState id={state_id} variant='body2'/></TableCell>*/}
                                        </TableRow>
                                    )
                                })}
                                
                            </TableBody>
                        </Table>
                    </div>
                    
                ): null}
            </Paper>
            {data && data.length ? <div className={classes.actions}>
                <ActionBar total={total} tableFilter={tableFilter} onUpdateTableFilter={onUpdateTableFilter}/>
            </div> : null}
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default BusinessSimpleTable