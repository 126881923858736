export const cleanData2Table = (associates, associate_documents, nodata, catalogs) => {
  const table = associates.map((item, key) => {
    let temp = {
      id:item.associate_id, 
      files:[
        {id:item.associate_id,key:'oficial_id',name:'Identificación Oficial',path:'oficialid', status: checkStatus(associate_documents[key], 'oficial_id')},
        {id:item.associate_id,key:'birth_certificate',name:'Acta de nacimiento',path:'birthcertificate', status: checkStatus(associate_documents[key], 'birth_certificate')},
        {id:item.associate_id,key:'curp',name:'CURP',path:'curp', status: checkStatus(associate_documents[key], 'curp')},
        {id:item.associate_id,key:'migratory_form',name:'Forma Migratoria',path:'migratoryform', status: checkStatus(associate_documents[key], 'migratory_form')},
        {id:item.associate_id,key:'address_proof',name:'Comprobante de domicilio',path:'addressproof', status: checkStatus(associate_documents[key], 'address_proof')},
        {id:item.associate_id,key:'fiscal_situation_proof',name:'Constancia de situación fiscal',path:'fiscalsituationproof', status: checkStatus(associate_documents[key], 'fiscal_situation_proof')},
        {id:item.associate_id,key:'passport',name:'Pasaporte',path:'passport', status: checkStatus(associate_documents[key], 'passport')},

      ],
      data:[]
    }
    

    let array = [
      item.associate_id.toString(), 
      item.associate,
      associate_documents[key].rfc ? associate_documents[key].rfc : nodata 
    ]

    temp.data = [...array]
    return temp
  })

  return table
}

const checkStatus = (item, key) => {
  return item[key] !== "0" && item[key] !== 0 && item[key] !== "" && item[key] !== null
}
