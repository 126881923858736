import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_legal,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData
    ];

    let fixedDataB = [
        currentData.legal_requirements ? currentData.legal_requirements : noData,
        currentData.legal_due_date ? moment(currentData.legal_due_date).format('DD-MM-YYYY') : noData,
        currentData.process ? currentData.process : noData,
        currentData.legal_periodicity ? currentData.legal_periodicity : noData,
        currentData.legal_responsible ? currentData.legal_responsible : noData,
        currentData.legal_comments ? currentData.legal_comments : noData,
        currentData.legal_project_cost ? currentData.legal_project_cost.toString() : noData,
        {key:'legal_documents'},
        currentData.legal_provider ? currentData.legal_provider : noData
    ]

    let varData = [
        {key:'recepit'},
        currentData.process_status ? currentData.process_status : noData,
        currentData.delivery_date ? moment(currentData.delivery_date).format('DD-MM-YYYY') : noData,
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        process_status_id: currentData.process_status_id,
        delivery_date: currentData.delivery_date,
        responsible_id: currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    tempB.files = [
        {id:currentData.id_ctrl_legal,key:'legal_documents',name:'Adjuntar Documentos',path:'legaldocuments',
            status: checkStatus(currentData, 'legal_documents')},
    ]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_legal,key:'recepit',name:'Acuse',path:'recepit',
            status: checkStatus(currentData, 'recepit')},
        {id:currentData.id_ctrl_legal,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_legal,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
