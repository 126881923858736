import React, {Component} from 'react'
import { Grid} from '@material-ui/core'
import ViewCardWrapper from '../../Cards/ViewCardWrapper'
import {DataItemWrapper, Subtitle} from '../../DisplayData/DisplayData'

import {updateForm} from './customFunctions'
import DocumentTable from '../../Tables/DocumentTable';

class ServiceFormB extends Component {

  onEditData = () => {
    console.log('Editar')
  }

  state = {
    modals:{
      filemodal:{open:false,issending:false,error:null,extra:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},

  }



  render(){

    const {service, business_documents, staticview, onEditData, onUploadFile, 
      onDownloadFile, onDeleteFile, onClose, permission, permissionsType} = this.props

    let viewForm = {...staticview.data}
    viewForm = updateForm(viewForm, service, staticview.content)
    let staticContent = Object.keys(viewForm).map((item,key)=>{
      return(
        <Grid item xs={12} md={6}  key={key.toString()}>
          <DataItemWrapper 
            label={viewForm[item].label}
            data={viewForm[item].data}
            error={staticview.nodata}
            icon={viewForm[item].icon}
          />
        </Grid>
      )
    })

    let files = [...staticview.documents.files]
    if(service){
      files.forEach((item,key) => {
        if(item.businessDocument){
          if(business_documents){
            let temp = business_documents[item.key]
            files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
          }else{
            files[key].status = false
          }

        }else{
          let temp = service[item.key]
          files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
        }

      })
    }

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <ViewCardWrapper
              title={staticview.title}
              button={staticview.button}
              onClick={onEditData}
              onClose={onClose}
              disabled={!(permission >= permissionsType.EDIT)}
            >
              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {staticContent}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Subtitle data={'Documentos'} />
                    </Grid>
                    <Grid item xs={12}>
                      <DocumentTable 
                        header={staticview.documents.header}
                        files={files}
                        onUpload={onUploadFile}
                        onDownload={onDownloadFile}
                        onDelete={onDeleteFile}
                        permission={permission}
                        permissionsType={permissionsType}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ViewCardWrapper>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ServiceFormB

