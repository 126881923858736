import React, { useState } from 'react';
import BasicView from '../../../components_v1/Structure/Layouts/BasicView';
import useGeneralPayrollListView from './useGeneralPayrollListView';
import EmployeePayrollTable from '../../../components_v1/platform/employeepayroll/EmployeePayrollTable/EmployeePayrollTable';
import EditEmployeePayrollModal from '../../../modals/payrolls/EditEmployeePayrollModal';


const GeneralPayrollListView = () => {

    const {
        loading, 
        sending, 
        error,
        payrolls,
        payroll,
        count,
        tableController,
        onUpdateFilter,
        setError,
        year, 
        year_options,
        month, 
        month_options,
        modalManager,
        onRequestSearch,
        onSelectedPayroll,
        onRequesPayrolls
    } = useGeneralPayrollListView()


    return ( 
        <BasicView
            title="Nómina interna"
            error={error}
            onCloseError={() => setError(null)}
        >
            <EditEmployeePayrollModal 
                open={modalManager.modals.edit_payroll}
                onClose={() => modalManager.onChangeModalStatus('edit_payroll', false)}
                origin={payroll}
                onRefresh={onRequesPayrolls}
            />
            <EmployeePayrollTable 
                loading={loading}
                header={[
                    {id:'id_employee_payroll', label:'ID'},
                    {id:'date', label:'Fecha'},
                    {id:'rfc', label:'RFC'},
                    {id:'user', label:'Usuario'},
                    {id:'user_level', label:'Puesto'},
                    {id:'area', label:'Área'},
                    {id:'extra_minutes', label:'Extra hr'},
                    {id:'pro_minutes', label:'Pro-bono hr'},
                    {id:'expected_minutes', label:'Horas'},
                    {id:'gross_base', label:'Máximo'},
                    {id:'gross_calculated', label:'Calculado'},
                    {id:'gross_to_pay', label:'A pagar'},
                    {id:'updated', label:'Actualizado'},
                ]}
                data={payrolls}
                restricted={false}
                total={count}
                tableFilter={tableController.pagination}
                onUpdateTablePagination={(_data) => tableController.setPagination(_data)}
                //Filter params
                year={year} years={year_options}
                month={month} months={month_options} 
                onChangeFilter={onUpdateFilter}
                onRequestSearch={onRequestSearch}
                onSelected={onSelectedPayroll}
            />
        </BasicView>
    );
}
 
export default GeneralPayrollListView;