export const contentData = {
  spanish:{
    layout:{
      title:'Negocios del cliente',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
      ],
    },
    modals:{
      warning_modal:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
    },
    nodata:'Sin Registro',
    header:['ID','Nombre', 'R.F.C.','Régimen Fiscal','Estatus'],
    tableTitle:'Lista de clientes'
  }
}