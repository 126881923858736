import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import moment from 'moment'

import EditCardWrapper from '../../../../../components/Cards/EditCardWrapper'
import ProfileAvatar from '../../../../../components/Avatars/ProfileAvatar'
import InputText from '../../../../../components/Inputs/InputText/InputText'
import InputSimpleSelect from '../../../../../components/Inputs/InputSimpleSelect/InputSimpleSelect'
import AvatarSelectInput from '../../../../../components/Inputs/AvatarSelectInput/AvatarSelectInput'
import { RoundedButton } from '../../../../../components/Buttons/GeneralButtons';
import {formData} from './data'
import {contentData} from './content'

class EditClientInfo extends Component {

  state = {
    formData: JSON.parse(JSON.stringify(formData))
  }

  componentDidMount(){
    const {business, catalogs, language, businesses_catalog} = this.props;
    const {formData} = this.state;
    const content = contentData[language]
    let temp = {...formData}

    //let associates_catalog = this.getAssociatesCatalog()
    temp['fiscal_regime_id'].options = catalogs.business_fiscal_regimes;
    temp['activity_id'].options = catalogs.activities;
    temp['subactivity_id'].options = catalogs.sub_activities;
    temp['legal_representative_id'].options = businesses_catalog;
    temp['business_status_id'].options = catalogs.business_statuses;
    
    if(business){
      Object.keys(formData).forEach(item => {
        let value = business[item];
        //console.log(`key:${item}, value:${value}`)
        if(temp[item].config.type === 'date'){
          value = value ? moment(value).format('YYYY-MM-DD') : null;
        }
        temp[item].value = value ? value : '';
        temp[item].isValid = value ? true : false;
        temp[item].config = {...temp[item].config,...content.sectionA[item]};
      });
      if(temp.monthly_quoted_time.value) temp.monthly_quoted_time.value = Math.floor(temp.monthly_quoted_time.value/60)
    }
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})

  }

  onInputChange = (data) => {
    const {formData} = this.state;
    const id = data.config.id;
    let temp = {...formData};
    temp[id] = {...data};
    const isValidForm = this.validationForm(temp);
    this.setState({formData:{...temp}, isValid: isValidForm})
  }

  validationForm = (data) => {
    let isValid = true;
    Object.keys(data).forEach((item) => {
      if(data[item].isRequired && !data[item].isValid){
        isValid = false;
      }
    })
    return isValid;
  }

  onSubmit = () => {
    const {onSubmit, business} = this.props;
    const {formData} = this.state;
    let data2send = {};
    Object.keys(formData).forEach((item)=>{
      if(formData[item].isValid){
        //console.log(`key:${item}, form:${formData[item].value}, current:${business[item]}`)
        if(formData[item].value !== business[item]){
          data2send = {...data2send, [item]:formData[item].value}
        }
      }
    })
    if(data2send.monthly_quoted_time) data2send.monthly_quoted_time = parseInt(data2send.monthly_quoted_time)*60
    onSubmit(data2send)
  }

  render(){

    const {language, business, issending, error, onChangeImage} = this.props
    const {formData} = this.state
    const content = contentData[language]

    let contentA = null
    let avatarContent = null

    contentA = Object.keys(formData).map((item,key)=> {

      let inputContent = null
      switch (formData[item].config.type) {
        case 'simple_select':
          inputContent = (
            <InputSimpleSelect
              wrap='vertical'
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break
        case 'avatar_select':
            inputContent = (
              <AvatarSelectInput
                wrap='vertical'
                data={formData[item]}
                onChange={this.onInputChange}
              />
            )
            break
        default:
          inputContent = (
            <InputText
              data={formData[item]}
              onChange={this.onInputChange}
            />
          )
          break;
      }
      return(
        <Grid item xs={12} md={6} key={key.toString()}>
          {inputContent}
        </Grid>
      )
    })
    avatarContent = <ProfileAvatar image={business ? business.image : null}/>

    return(
      <EditCardWrapper
        button={content.save_button}
        onSubmit={this.onSubmit}
        issending={issending}
        error={error}
      >
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={2} direction='column' justify='center' alignItems='center'>
                  <Grid item>{avatarContent}</Grid>
                  <Grid item>
                    <RoundedButton 
                      label='Modificar'
                      size='small'
                      color='secondary'
                      onClick={onChangeImage}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container spacing={3}>
                  {contentA}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </EditCardWrapper>
    )
  }
  
}

export default EditClientInfo