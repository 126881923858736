import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core';
import { Font, Document, Page, Text, View, Image, StyleSheet } from '@react-pdf/renderer';
import topBanner from '../../../../assets/evaluation/top-banner.png'
import ASLogo from '../../../../assets/ASCGLOGO.png'
import bold from '../../../../assets/evaluation/calibrib.ttf'
import regular from '../../../../assets/evaluation/Calibri.ttf'
import Abold from '../../../../assets/evaluation/Arialb.ttf'
import Aregular from '../../../../assets/evaluation/Arial.ttf'
import { monthsName, onGetDateFormat, onTruncateText } from '../../../../shared/utility';
import { catalogs } from '../../../../texts/esp/catalogs';
import { nodata } from '../../../../texts/esp/general';
import moment from 'moment';

Font.register({
    family: 'Calibri',
    fonts: [
        { src: regular, fontStyle: 'normal', fontWeight: 'normal' },
        { src: bold, fontStyle: 'bold', fontWeight: 900 }
    ]
});

Font.register({
    family: 'Arial',
    fonts: [
        { src: Aregular, fontStyle: 'normal', fontWeight: 'normal' },
        { src: Abold, fontStyle: 'bold', fontWeight: 900 }
    ]
})

const classes = StyleSheet.create({
    page: {
        flexDirection: 'row',
        backgroundColor: 'white',
        fontSize: 7,
    },
    banner: {
        width: '100vw',
        height: 90
    },
    section: {
        margin: 10,
        padding: 10,
        marginBottom: 0,
        paddingBottom: 0,
        marginTop: 0,
        flexGrow: 1,
        color: 'black',
    },
    row: {
        display: "flex",
        flexDirection: "row",
    },
    column: {
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
    },
    th: {
        fontFamily: 'Arial',
        fontStyle: 'bold',
        paddingLeft: 2,
        paddingRight: 2,
        backgroundColor: "#f2f2f2",
        color: "#002637",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderColor: "#ffffff",
    },
    thBlue: {
        fontFamily: 'Arial',
        fontStyle: 'bold',
        paddingLeft: 2,
        paddingRight: 2,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#002060",
        color: "#ffffff",
        display: "flex",
        flexDirection: "row",
        borderWidth: 1,
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderColor: "#ffffff",
    },
    td: {
        minHeight: 10,
        fontFamily: 'Arial',
        paddingLeft: 2,
        paddingRight: 2,
        fontWeight: 400,
        backgroundColor: "#f2f2f2",
        color: "#002637",
        display: "flex",
        flexDirection: "row",
        borderWidth: 1,
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderColor: "#ffffff",
        justifyContent: "center",
    },
    td2: {
        fontFamily: 'Calibri'
    },
    td3: {
        justifyContent: 'flex-start',
        fontFamily: 'Calibri',
        paddingLeft: 10,
        paddingRight: 10,
        textAlign: 'justify'
    },
    tdB: {
        fontFamily: 'Arial',
        fontStyle: 'bold',
        paddingLeft: 2,
        paddingRight: 2,
        backgroundColor: "#f2f2f2",
        color: "#002637",
        display: "flex",
        flexDirection: "row",
        borderWidth: 1,
        borderLeftStyle: "solid",
        borderLeftWidth: 1,
        borderRightStyle: "solid",
        borderRightWidth: 1,
        borderTopStyle: "solid",
        borderTopWidth: 1,
        borderColor: "#ffffff",
        justifyContent: "flex-start",
        alignItems: "center",
    },
    logo: {
        width: '100%',
        height: 'auto',
    },
    title: {
        fontFamily: 'Calibri',
        fontStyle: 'bold',
        fontSize: 16,
        color: 'white',
        backgroundColor: '#002060'
    },
    subtitle: {
        fontFamily: 'Calibri',
        fontStyle: 'bold',
        fontSize: 10,
    },
    subtitle2: {
        fontFamily: 'Calibri',
        fontSize: 10,
    },
    sign: {
        width: 80,
        height: 30,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
});


const EvaluationPDF = props => {

    const { auto_date, created_at, accepted_date, evaluated, evaluated_area,
        evaluated_level, evaluator, total, comments, rejection_comments,
        evaluator_signature, evaluated_signature, evaluation_date } = props

    let kpiContent = []
    let targetContent = []
    console.log(props)
    const type = catalogs.kpiTypes.find(kpi => kpi.key === evaluated_area)

    if (type) {
        kpiContent = [...type.content]
        targetContent = [...type.target]
    }

    let evaluationPeriod = nodata

    if(auto_date){
        const temp_date = moment(auto_date)
        if(temp_date.isValid()){
            evaluationPeriod = `${monthsName[temp_date.month()]} ${temp_date.year()}`
        }
    }


    return (
        <Document>
            <Page size="LETTER" style={classes.page}>
                <View>
                    <Image src={topBanner} style={classes.banner} />
                    <View style={classes.section}>
                        <View style={{ ...classes.row, marginTop: -40, alignItems: 'center' }}>
                            <View style={{ ...classes.column, width: '65%' }}>
                                <Text style={classes.title}>FORMATO DE EVALUACIÓN MENSUAL DEL DESEMPEÑO LABORAL</Text>
                            </View>
                            <View style={{ ...classes.column, width: '20%' }}>
                                <Image src={ASLogo} style={classes.logo} />
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={{ ...classes.row, marginTop: -30 }}>
                            <View style={{ ...classes.column, width: '40%' }}>
                                <Text style={classes.subtitle}>Mes de Evaluación:</Text>
                                <Text style={classes.subtitle}>Evaluado (Colaborador):</Text>
                                <Text style={classes.subtitle}>División:</Text>
                                <Text style={classes.subtitle}>Puesto:</Text>
                                <Text style={classes.subtitle}>Evaluador (Jefe Inmediato):</Text>
                            </View>
                            <View style={{ ...classes.column, width: '60%' }}>
                                <View style={{ ...classes.td, ...classes.td2 }}>
                                    <Text>{evaluationPeriod}</Text>
                                </View>
                                <View style={{ ...classes.td, ...classes.td2 }}>
                                    <Text>{evaluated ? evaluated : '-'}</Text>
                                </View>
                                <View style={{ ...classes.td, ...classes.td2 }}>
                                    <Text>{evaluated_area ? evaluated_area : '-'}</Text>
                                </View>
                                <View style={{ ...classes.td, ...classes.td2 }}>
                                    <Text>{evaluated_level ? evaluated_level : '-'}</Text>
                                </View>
                                <View style={{ ...classes.td, ...classes.td2 }}>
                                    <Text>{evaluator ? evaluator : '-'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '40%' }}>
                                <Text style={classes.subtitle}>Objetivo Global de la Firma:</Text>
                            </View>
                            <View style={{ ...classes.column, width: '60%' }}>
                                <View style={{ ...classes.td, ...classes.td3 }}>
                                    <Text>Ser el despacho de Consultoría contable, fiscal, legal y marketing referente por su alta atención, profesionalidad, internacionalización y constante innovación tecnológica para las MiPyMEs, extrajeras y nacionales, en la Ciudad de México.</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '40%' }}>
                                <Text style={classes.subtitle}>Objetivos del Departamento:</Text>
                            </View>
                            <View style={{ ...classes.column, width: '60%' }}>
                                {targetContent.length > 0 ? targetContent.map(target => {
                                    return (
                                        <View style={{ ...classes.td, ...classes.td3 }}>
                                            <Text>{target}</Text>
                                        </View>
                                    )
                                })
                                    :
                                    <View style={{ ...classes.td, ...classes.td3 }}>
                                        <Text>-</Text>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '40%' }}>
                                <Text style={classes.subtitle}>KPI DEL DEPARTAMENTO:</Text>
                            </View>
                            <View style={{ ...classes.column, width: '60%' }}>
                                {kpiContent.length > 0 ? kpiContent.map(kpi => {
                                    return (
                                        <View style={{ ...classes.td, ...classes.td3 }}>
                                            <Text>{kpi}</Text>
                                        </View>
                                    )
                                })
                                    :
                                    <View style={{ ...classes.td, ...classes.td3 }}>
                                        <Text>-</Text>
                                    </View>
                                }
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '55%' }}>
                                <View style={{ ...classes.th, height: 20 }}>
                                    <Text>ÁREA DEL DESEMPEÑO</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.th}>
                                    <Text>MUY BAJO</Text>
                                </View>
                                <View style={classes.th}>
                                    <Text>1</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.th}>
                                    <Text>BAJO</Text>
                                </View>
                                <View style={classes.th}>
                                    <Text>2</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.th}>
                                    <Text>MODERADO</Text>
                                </View>
                                <View style={classes.th}>
                                    <Text>3</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.th}>
                                    <Text>ALTO</Text>
                                </View>
                                <View style={classes.th}>
                                    <Text>4</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.th}>
                                    <Text>MUY ALTO</Text>
                                </View>
                                <View style={classes.th}>
                                    <Text>5</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={{ ...classes.th, height: 20 }}>
                                    <Text>PUNTAJE</Text>
                                </View>
                            </View>
                        </View>
                        <View style={classes.row}>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>ORIENTACIÓN DE RESULTADOS</Text>
                                </View>
                            </View>
                        </View>
                        <EvaluationRow data={props} id='results' label={[
                            'Termina su trabajo oportunamente',
                            'Cumple con las tareas que se le encomienda',
                            'Realiza un volumen adecuado de trabajo',
                            'Está interesado por alcanzar los objetivos del departamento',
                            'El tiempo de respuesta es menor a 24 horas una vez que se recibe un correo o prospecto',
                            'Existe un 90% de seguimiento a las tareas de asginadas, prospectos y pendientes'
                        ]} />
                        <View style={classes.row}>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>CALIDAD</Text>
                                </View>
                            </View>
                        </View>
                        <EvaluationRow data={props} id='quality' label={[
                            'No comete errores en el trabajo',
                            'No requiere de supervisión frecuente',
                            'Se muestra profesional en el trabajo',
                            'Se muestra respetuoso y amable en el trato al cliente',
                        ]} />
                        <View style={classes.row}>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>RELACIONES INTERPERSONALES</Text>
                                </View>
                            </View>
                        </View>
                        <EvaluationRow data={props} id='relations' label={[
                            'Se muestra cortés con sus compañeros y personal de la firma',
                            'Muestra aptitud para integrarse al equipo',
                            'Brinda una adecuada orientación a sus compañeros.',
                        ]} />
                        <View style={classes.row}>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>INICIATIVA</Text>
                                </View>
                            </View>
                        </View>
                        <EvaluationRow data={props} id='iniciative' label={[
                            'Muestra nuevas ideas para mejorar los procesos',
                            'Tiene gran capacidad para resolver problemas',
                            'Se anticipa a las dificultades',
                        ]} />
                        <View style={classes.row}>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>ORGANIZACIÓN</Text>
                                </View>
                            </View>
                        </View>
                        <EvaluationRow data={props} id='organization' label={[
                            'Cumple con su horario de forma puntual',
                            'Hace uso racional de los recursos',
                            'Está en constante actualización',
                            'Se preocupa por defender el prestigio de la firma'
                        ]} />
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '87%' }}>
                                <View style={{ ...classes.thBlue, justifyContent: 'flex-start' }}>
                                    <Text>PUNTAJE TOTAL:</Text>
                                </View>
                            </View>
                            <View style={classes.column}>
                                <View style={classes.thBlue}>
                                    <Text>{total ? total : '-'}</Text>
                                </View>
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={classes.th}>
                                <Text>Comentarios y/o compromisos del evaluador</Text>
                            </View>
                        </View>
                        <View style={classes.row}>
                            <View style={classes.td}>
                                <Text>{comments ? onTruncateText(comments, 149) : 'Sin comentarios.'}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={classes.th}>
                                <Text>Comentarios y/o compromisos del evaluado</Text>
                            </View>
                        </View>
                        <View style={classes.row}>
                            <View style={classes.td}>
                                <Text>{rejection_comments ? onTruncateText(rejection_comments, 149) : 'Sin comentarios.'}</Text>
                            </View>
                        </View>
                    </View>
                    <View style={classes.section}>
                        <View style={classes.row}>
                            <View style={{ ...classes.column, width: '50%', textAlign: 'center' }}>
                                <View style={{ ...classes.row, width: '90%', paddingBottom: 10, borderBottom: '1px solid black' }}>
                                    <View style={{ ...classes.column, width: '100%' }}>
                                        {evaluator_signature ?
                                            <Image source={evaluated_signature} style={classes.sign} />
                                            : <Text>-</Text>}
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={classes.column}>
                                        <Text style={classes.subtitle2}>{evaluated}</Text>
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={{ ...classes.column, color: 'dimgrey' }}>
                                        <Text>{accepted_date ? onGetDateFormat(accepted_date, 'DD/MM/YYYY') : '-'}</Text>
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={classes.column}>
                                        <Text>Evaluado (Colaborador):</Text>
                                    </View>
                                </View>
                            </View>
                            <View style={{ ...classes.column, width: '50%', textAlign: 'center' }}>
                                <View style={{ ...classes.row, width: '90%', paddingBottom: 10, borderBottom: '1px solid black' }}>
                                    <View style={{ ...classes.column, width: '100%' }}>
                                        {evaluator_signature ?
                                            <Image source={evaluator_signature} style={classes.sign} />
                                            : <Text>-</Text>}
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={classes.column}>
                                        <Text style={classes.subtitle2}>{evaluator}</Text>
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={{ ...classes.column, color: 'dimgrey' }}>
                                        <Text>{evaluation_date ? onGetDateFormat(evaluation_date, 'DD/MM/YYYY') : '-'}</Text>
                                    </View>
                                </View>
                                <View style={classes.row}>
                                    <View style={classes.column}>
                                        <Text>Evaluador (Jefe Inmediato):</Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>
                </View>
            </Page>
        </Document >
    )
}

const EvaluationRow = ({ data, id, label }) => {
    let data2print = Object.keys(data).filter(e => e.substring(0, id.length) === id)
    data2print = data2print.slice(0, -1)

    return data2print.map((dataID, key) => {
        return (
            <View style={classes.row}>
                <View style={{ ...classes.column, width: '55.9%' }}>
                    <View style={classes.tdB}>
                        <Text>{label[key]}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 48 }}>
                        <Text>{data[dataID] === 1 ? 'X' : '-'}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 30 }}>
                        <Text>{data[dataID] === 2 ? 'X' : '-'}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 53 }}>
                        <Text>{data[dataID] === 3 ? 'X' : '-'}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 30 }}>
                        <Text>{data[dataID] === 4 ? 'X' : '-'}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 47 }}>
                        <Text>{data[dataID] === 5 ? 'X' : '-'}</Text>
                    </View>
                </View>
                <View style={classes.column}>
                    <View style={{ ...classes.td, width: 43 }}>
                        <Text>{data[dataID] ? data[dataID] : '-'}</Text>
                    </View>
                </View>
            </View>
        )
    })
}
export default EvaluationPDF