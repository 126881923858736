import React, {Component} from 'react'
import {TableRow,TableCell, IconButton, Icon } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import SimpleTable from '../../../../../../components/Tables/SimpleTable'
import {TableItem} from '../../../../../../components/DisplayData/DisplayData'

import {onFilterDataArray, getStatusColor} from './customFunctions'
//import {FileItemWrapper} from '../../../../../../components/DisplayData/DisplayData'


const styles = (theme) => ({
  root:{},
  tablecell:{
    padding:'16px 16px',
    border:0,
    '&:hover':{
      cursor:'pointer'
    }
  },
  tablerow:{
    '&:hover':{
      background:'rgba(0,0,0,0.05)'
    }
  }
})

class ModuleList extends Component{

  state = {
    rowsPerPage:5,
    page:0,
    searchText:'',
  }

  handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value,10)
    this.setState({rowsPerPage:value})
  }

  handleChangePage = (event, newPage) => {
    this.setState({page:newPage})
  }

  onSearch = (value) => {
    this.setState({searchText:value})
  }

  render(){

    const {classes, title, header, data, onItemSelected, onAddItem, 
      onDeleteItem,nodata, permission, permissionsType} = this.props
    const {rowsPerPage,page,searchText} = this.state

    const filterData = onFilterDataArray(data, searchText)
    const sliceArray = filterData.slice(page*rowsPerPage, page * rowsPerPage + rowsPerPage)
    //const emptyRows = rowsPerPage - Math.min(rowsPerPage, sliceArray.length - page * rowsPerPage)
    //let emptylist = this.onFillEmptyArray(emptyRows, rowsPerPage)

    return(
      
      <div className={classes.root}>
        <SimpleTable
          title={title}
          header={header}
          rowsPerPage={rowsPerPage}
          page={page}
          rows={filterData.length}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onSearch={this.onSearch}
          addItem={permission >= permissionsType.CREATE}
          onAddItem={onAddItem}
          nodata={nodata}
        >
          
          {sliceArray.map((item,key)=>{
            return(
              <TableRow 
                key={key.toString()} 
                className={classes.tablerow}>
                <TableCell className={classes.tablecell} padding='checkbox' onClick={() => onItemSelected(item.id)}>
                  <TableItem 
                    data={item.data[0]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onItemSelected(item.id)}>
                  <TableItem 
                    data={item.data[1]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onItemSelected(item.id)}>
                  <TableItem 
                    src={
                      <Icon style={{fontSize:'12px', color:getStatusColor(item.status)}}>
                        brightness_1
                      </Icon>
                    }
                    data={item.data[2]}
                  />
                </TableCell>
                <TableCell className={classes.tablecell} onClick={() => onItemSelected(item.id)}>
                  <TableItem 
                    data={item.data[3]}
                  />
                </TableCell>
                
                {
                  permission >= permissionsType.DELETE ?
                  <TableCell className={classes.tablecell} padding='checkbox' align='center' >
                    <IconButton style={{padding:8}} onClick={() => onDeleteItem(item.id)}>
                      <Icon fontSize='small'>delete</Icon>
                    </IconButton>
                  </TableCell>
                  : null
                }
                
              </TableRow>
            )
          })}
        </SimpleTable>
      </div>
    )
  }
}

export default withStyles(styles)(ModuleList)