const styles = theme => ({
  root: {
    padding: theme.spacing.unit * 1,
    
  },
  avatar: {
    //margin: 3,
    backgroundColor: 'transparent',
    color: 'white',
    border: `2px solid ${theme.palette.secondary.main}`,
   // background:'red'
  },
  button: {
    padding: "10px 0px",
    borderRadius: '5px',
    "&:hover": {
      backgroundColor: "#EEE"
    }
  },
  text: {
    color: theme.palette.secondary.main
  },
  user: {
    color: 'rgba(255,255,255,1)',
  },
  avatar_container:{
    //backgroundColor: "#EEE",
    position:'relative'
  }
});

export default styles;
