import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    operations_number:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'operations_number', type:'number', placeholder:'1,2,3,...', helperText:'Valor no válido'},
        rules:{type:'none'}
    },
    information_upload_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_information_upload_statuses],
        formType:'select',
        config:{id:'information_upload_status_id', type:'text', placeholder:'Seleccione una opcion', helperText:'La opcion no es válida'},
        rules:{type:'none'}
    },
    bank_statements:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'bank_statements', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    payment_gateway:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'payment_gateway', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    spending_control:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'spending_control', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    inventary_control:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'inventary_control', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    import_petition:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'import_petition', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    payroll_social_security_taxes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'payroll_social_security_taxes', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    professional_services_payment:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'professional_services_payment', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    payment_service_invoice:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'payment_service_invoice', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    abroad_expenses:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'abroad_expenses', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    insurance_policy:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'insurance_policy', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    leases_rentals:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'leases_rentals', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    intercompany_operation:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'intercompany_operation', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    non_tax_provisions:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'non_tax_provisions', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    interest_loans_payments:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'interest_loans_payments', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    capital_contributions:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'capital_contributions', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    amortizations_deprecations:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_documents_statuses],
        formType:'select',
        config:{id:'amortizations_deprecations', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    comments:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'comments', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    coi_register:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'coi_register', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    bank_reconciliation:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'bank_reconciliation', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    tax_calculation:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'tax_calculation', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    real_staff_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'real_staff_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    supervisor_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.supervisor_statuses],
        formType:'select',
        config:{id:'supervisor_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    real_supervisor_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'real_supervisor_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    revision_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'revision_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    financial_statements:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'financial_statements', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    electronic_accounting:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'electronic_accounting', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    balance_sheet:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.accounting_register_statuses],
        formType:'select',
        config:{id:'balance_sheet', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    accounting_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.supervisor_statuses],
        formType:'select',
        config:{id:'accounting_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    real_analyst_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'real_analyst_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}