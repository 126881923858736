import React from 'react';
import { Grid } from '@material-ui/core';
import DisplayText from '../../Texts/DisplayText';

const HorizontalData = ({title, data, size}) => {
    return ( 
        <Grid container>
            <Grid item xs={12} md={4}>
                <DisplayText variant={size === 'small' ? 'subtitle2' : 'subtitle1'}>{title}</DisplayText>
            </Grid>
            <Grid item xs={12} md={8}>
                <DisplayText variant={size === 'small' ? 'body2' : 'body1'}>{data}</DisplayText>
            </Grid>
        </Grid>
     );
}
 
export default HorizontalData;