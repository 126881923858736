export const contentData = {
  spanish:{
    title:'Agregar responsable',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      responsible_id:{
        label:'Responsable',
        placeholder:'Responsable',
      },
    },
  },
}