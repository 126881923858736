import React from 'react';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames'
import { Grid, Icon, IconButton, makeStyles, TableCell } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    root:{
        borderBottom:`1px solid ${blueGrey[100]}`,
        //padding:'4px 8px',
        minWidth:150,
        maxWidth:150,
        padding:'8px 16px'
        //fontFamily:theme.typography.fontFamily,
        //fontSize:14,
        //height:'100%',
        //verticalAlign:'middle'
    },
    fixed:{
        background:'white',
        background:blueGrey[100],
        //borderBottom:`1px solid ${blueGrey[200]}`,
        //color:'white',
        position: '-webkit-sticky !important',
        position:'sticky !important',
        left:0,
        zIndex:3

    },
    fixed_header:{
        background:'white',
        background:blueGrey[100],
        //borderBottom:`1px solid ${blueGrey[200]}`,
        //color:'white',
        position: '-webkit-sticky !important',
        position:'sticky !important',
        left:0,
        zIndex:5

    },
    addBtn:{
        marginLeft:4,
        background:theme.palette.primary.main,
        color:'white',
        '&:hover':{background:theme.palette.primary.dark}
    }
}))

const TCell = ({children, style, className, width, fixed, add,  type, position, padding, log, onVisibleClick}) => {

    const classes = useStyles()

    let innerStyles = {}
    if(width) innerStyles = {...innerStyles, minWidth:width, maxWidth:width}
    if(fixed) innerStyles = {...innerStyles, left:position}
    if(padding === 'checkbox') innerStyles = {...innerStyles, padding:'8px', paddingRight:0}
    
    return ( 
        <TableCell className={cx({
            [classes.root]:true,
            [classes.fixed]:fixed,
            [classes.fixed_header]:fixed && type==='header'
        })} style={{...style, ...innerStyles}} >
            <Grid container alignItems='center' wrap='nowrap'>
                <Grid item xs>
                    {children}
                </Grid>
                {add ? <Grid item><IconButton size='small' className={classes.addBtn} onClick={onVisibleClick}><Icon fontSize='small' style={{fontSize:16}}>{add.icon ? add.icon : 'add'}</Icon></IconButton></Grid> : null}
            </Grid>
        </TableCell>
     );
}
 
export default React.memo(TCell);