import React from 'react'
import { Grid } from '@material-ui/core';
import { ResponsiveContainer, BarChart, XAxis, YAxis, CartesianGrid, Bar, Legend, LabelList } from 'recharts';
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer'
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';

const MixChart = ({ data, content, loading, error, success, actions }) => {

    return (
        <LoadingContainer loading={loading} error={error} onCloseError={actions.onCloseError} success={success} onCloseSuccess={actions.onCloseSuccess}>
            <BarChartW content={content} {...data} />
        </LoadingContainer>
    )
}

const BarChartW = ({ content, count, data }) => {
    
    const bars = Object.keys(content.bars)
    console.log(count)
    return (
        <SimpleCard>
            <Grid container justify='center' spacing={2}>
                <Grid item xs={12}>
                    <DisplayText color='primary' style={{ fontSize: 20, fontWeight: 500 }} variant='body2'>{content.title} ({count})</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <div style={{ overflow: 'hidden', 'flex-basis': "50%" }}>
                        <ResponsiveContainer width="99%" height='100%' minHeight={400}>
                            <BarChart
                                layout="vertical"
                                data={data}
                                margin={{
                                    top: 30,
                                    right: 10,
                                    bottom: 10,
                                    left: 55,
                                }}
                            >
                                <XAxis
                                    type='number'
                                    axisLine={false}
                                    style={{ fontSize: 16, fontWeight: 600, color: '#455A64' }}
                                    domain={[0, 100]}
                                    tick={{ fontFamily: 'Poppins' }}
                                    tickLine={false}
                                    tickMargin={20}
                                    tickCount={11}
                                />
                                <YAxis
                                    type='category'
                                    dataKey="name"
                                    scale="band"
                                    axisLine={false}
                                    style={{ fontSize: 16, fontWeight: 500, color: '#455A64' }}
                                    tick={{ fontFamily: 'Poppins' }}
                                    tickLine={false}
                                    tickMargin={20}
                                />
                                <CartesianGrid horizontal={false} />
                                <Legend verticalAlign='top' align='right' wrapperStyle={{ marginTop: -30 }} formatter={renderColorfulLegendText} />
                                {bars.map((bar_id, key) => {
                                    return (
                                        <Bar
                                            type="monotone"
                                            dataKey={bar_id}
                                            name={content.bars[bar_id].label}
                                            fill={content.bars[bar_id].color}
                                            barSize={28}
                                            stackId="singleBar"
                                            isAnimationActive={false}
                                            radius={key === bars.length - 1 ? [0, 10, 10, 0] : null}
                                        >
                                            <LabelList dataKey={bar_id} content={renderCustomizedLabel} />
                                        </Bar>
                                    )
                                })}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;

    return <span style={{ fontWeight: 500, fontFamily: 'Poppins', color: '#455A64' }}>{value}</span>;
};

const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    
    return (
        <g>
            <text
                x={x + width / 2}
                y={y + height / 2}
                fill="#fff"
                textAnchor="middle"
                dominantBaseline="central"
                style={{ fontFamily: 'Poppins', fontWeight: 600 }}
            >
                {value > 0 ? parseFloat(value).toFixed(2) : '-'}
            </text>
        </g>
    );
};

export default MixChart