import React, {Component} from 'react'
import { Redirect } from 'react-router'
import {contentData} from './content'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import ServiceModule from './ServiceModule/ServiceModule';
import LegalRepresentative from "../Modules/LegalRepresentative/LegalRepresentative";
import EmployerRegistration from "../Modules/EmployerRegistration/EmployerRegistration";
import Logs from "../Modules/Logs/Logs";

class StateRegisterInscription extends Component {

  state = {
    pageSelected:0
  }

  onReturn = () => {
    this.props.history.goBack()
  }

  onChangeView = (id) => {
    this.setState({pageSelected:id})
  }

  render(){

    const {history, language, catalogs, businessID, 
          permission, permissionsType} = this.props
    const {pageSelected} = this.state

    const content = contentData[language]

    let contentView = null

    switch(pageSelected){
      case 0:
        contentView = (
            <ServiceModule
                history={history}
                language={language}
                catalogs={catalogs}
                businessID={businessID}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        break
      case 1:
        contentView = (
            <LegalRepresentative
                history={history}
                language={language}
                catalogs={catalogs}
                businessID={businessID}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        break
      case 2:
        contentView = (
            <EmployerRegistration
                history={history}
                language={language}
                catalogs={catalogs}
                businessID={businessID}
                permission={permission}
                permissionsType={permissionsType}
            />
        )
        break
      case 3:
        contentView = (
            <Logs
              history={history}
              language={language}
              catalogs={catalogs}
              businessID={businessID}
              service={'ctrlstateregisterinscription'}
              ctrlTableIdLabel={'id_ctrl_state_register_inscription'}
            />
        )
        break
      
    }

    return(
      <BasicView
        history={history}
        content={content.layout}
        onChange={this.onChangeView}
        returnBtn={true}
        onReturn={this.onReturn}
        selected={pageSelected}
      >
        {permission === 1 ? (
          <Redirect to='/profile'/>
        ) : null}
        <div>
          {contentView}
        </div>

      </BasicView>
    )
  }
}

export default StateRegisterInscription
