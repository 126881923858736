import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Icon, IconButton, makeStyles, Snackbar } from '@material-ui/core';
import DisplayText from '../../Texts/DisplayText';
import ErrorModal from '../Modals/ErrorModal';
import { blue, green } from '@material-ui/core/colors';
import { withRouter } from 'react-router';
import SmallNavigation from '../Navigation/SmallNavigation'

const useStyles = makeStyles(theme => ({
    root:{
        padding:32,
        [theme.breakpoints.down('sm')]:{
            padding:16,
        }
    },
    container:{
        padding:'32px 0px'
    },
    success:{
        background:green[700],
        zIndex:3
    },
    sending:{
        background:blue[700]
    },
    menu:{
        marginTop:20
    }
}))


const BasicView = ({children, loading, error, onCloseError, success, onCloseSuccess, title, sending, 
    setReturn, history, nopadding, menu, selected, onChange, nopaddingroot}) => {

    const classes = useStyles()


    const loadingContent = (
        <Grid container justify='center' alignItems='center' direction='column' spacing={1}>
            <Grid item>
                <CircularProgress size={32}/>
            </Grid>
            <Grid item>
                <DisplayText>Cargando...</DisplayText>
            </Grid>
        </Grid>
    )

    const successContent = <Snackbar ContentProps={{className:classes.success}} open={Boolean(success)} onClose={onCloseSuccess}  
    autoHideDuration={1500} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><Icon>done</Icon></Grid>
        <Grid item><DisplayText style={{color:'white'}}>{'Acción exitosa'}</DisplayText></Grid>
    </Grid>}/>

    const sendingContent = <Snackbar  ContentProps={{className:classes.sending}} open={Boolean(sending)} 
    message={<Grid container alignItems='center' spacing={1}>
        <Grid item><CircularProgress size={18} style={{color:'white'}}/></Grid>
        <Grid item><DisplayText style={{color:'white'}}>Enviando...</DisplayText></Grid>
    </Grid>}  anchorOrigin={{vertical:'bottom', horizontal:'left'}}/>

    const errorModal = <ErrorModal open={Boolean(error)} message={error} onClose={onCloseError}/>

    let menuContent = null
    if(menu){
      menuContent = (
        <div className={classes.menu}>
            <Grid container justify='flex-start'>
                <Grid item>
                    <SmallNavigation
                        selected={selected}
                        data={menu}
                        onChange={onChange}
                    />
                </Grid>
            </Grid>
        </div>
      )
    }

    return ( 
        <div className={classes.root} style={nopaddingroot ? {padding:0} : undefined}>
            <Grid container alignItems='center'>
                {setReturn ? (
                    <Grid item><IconButton onClick={() => history.goBack()}><Icon fontSize='large'>navigate_before</Icon></IconButton></Grid>
                ) : null}
                <Grid item xs><DisplayText variant='h5' color='primary'>{title}</DisplayText></Grid>
            </Grid>
            {menuContent}
            <div className={classes.container} style={nopadding ? {paddingTop:0} : undefined}>
                {sendingContent}
                {successContent}
                {errorModal}
                {loading ? loadingContent : null}
                {!loading && children}
            </div>
        </div>
    );
}
 
export default withRouter(BasicView);