export const contentData = {
  spanish:{
    layout:{
      title:'Contabilidad',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Negocios',path:'/clients/details/:id/businesses'},
        {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
        {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
      ],
      menu:[
        {id:0, label:'General'},
        {id:1, label:'Cuentas bancarias'},
        {id:2, label:'Obligaciones fiscales'},
        //{id:3, label:'Logs'}
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información Básica',
      button:'Editar',
      nodata:'Sin Registro',
      data:{
        rfc:{
          label:'',
          data:''
        },
        sat_password:{
          label:'',
          data:''
        },
        taxpayer_type:{
          label:'',
          data:''
        },
        fiscal_situation:{
          label:'',
          data:''
        },
        payday_limit:{
          label:'',
          data:''
        },
        importer_list:{
          label:'',
          data:''
        },
        as_responsible:{
          label:'',
          data:''
        },
        analyst:{
          label:'',
          data:''
        },
        supervisor:{
          label:'',
          data:''
        },
        staff:{
          label:'',
          data:''
        },
        coi:{
          label:'',
          data:''
        },
        accounting_method:{
          label:'',
          data:''
        },
        initial_capital:{
          label:'',
          data:''
        },
        inventory_system:{
          label:'',
          data:''
        },
        inventory_evaluation_method:{
          label:'',
          data:''
        },
        financial_states_norm:{
          label:'',
          data:''
        },
        commercial_relation:{
          label:'',
          data:''
        },
        financial_states_periodicity:{
          label:'',
          data:''
        },
        sending_date_limit:{
          label:'',
          data:''
        },
        report_language:{
          label:'',
          data:''
        },
        tax_preview:{
          label:'',
          data:''
        },
      },
      content:{
        rfc:{
          label:'RFC'
        },
        sat_password:{
          label:'Contraseña SAT'
        },
        taxpayer_type:{
          label:'Tipo de Contribuyente'
        },
        fiscal_situation:{
          label:'Situación Fiscal'
        },
        payday_limit:{
          label:'Fecha límite de Pago de Impuestos'
        },
        importer_list:{
          label:'Padrón de importadores'
        },
        as_responsible:{
          label:'Responsable'
        },
        analyst:{
          label:'Analista'
        },
        supervisor:{
          label:'Supervisor'
        },
        staff:{
          label:'Staff'
        },
        coi:{
          label:'No. COI'
        },
        start_date:{
          label:'Fecha de inicio de contabilidad'
        },
        accounting_method:{
          label:'Método de gestión'
        },
        initial_capital:{
          label:'Capital inicial'
        },
        inventory_system:{
          label:'Sistema de inventarios'
        },
        inventory_evaluation_method:{
          label:'Método de valuación de inventarios'
        },
        financial_states_norm:{
          label:'Normativa de Estados Financieros'
        },
        commercial_relation:{
          label:'Relaciones Comerciales'
        },
        financial_states_periodicity:{
          label:'Periodicidad de Estados Financieros'
        },
        sending_date_limit:{
          label:'Fecha Límite de envío de Edo. Financieros'
        },
        report_language:{
          label:'Idioma que reportan'
        },
        tax_preview:{
          label:'Previo de impuestos'
        },
      },
      documents:{
        title:'Documentos',
        header:{title:'Archivos', actions:'Acciones'},
        files:[
          {key:'trial_balance_initial',name:'Balanza de Comprobación con Saldos Iniciales',path:'trialbalanceinitial', status:false,businessDocument:true},
          {key:'financial_statements',name:'Estados Financieros',path:'financialstatements', status:false,businessDocument:true},
          {key:'accounting_backup',name:'Respaldo de la Contabilidad',path:'accountingbackup', status:false,businessDocument:true},
          {key:'customs_declaration',name:'Pedimentos de Importación',path:'customsdeclaration', status:false,businessDocument:true},
          {key:'foreign_expenses',name:'Gastos Extranjeros',path:'foreignexpenses', status:false,businessDocument:true},
          {key:'expense_control',name:'Control de Gastos',path:'expensecontrol', status:false,businessDocument:true},
          {key:'aux',name:'Auxiliares',path:'aux', status:false},
          {key:'ada_table',name:'Tabla ADA',path:'adatable', status:false, businessDocument:true},
          {key:'tax_sheet',name:'Hoja de Impuestos',path:'taxsheet', status:false, businessDocument:true},
          {key:'tax_loss',name:'Pérdidas Fiscales',path:'taxloss', status:false, businessDocument:true},
          {key:'accounting_policies',name:'Políticas Contables',path:'accountingpolicies', status:false},
          {key:'work_sheet_annual_declaration', name:'Papel de Trabajo Declaración Anual', path:'worksheetannualdeclaration', status:false, businessDocument:true}
        ]
      }
    }
  }
}
