import React from 'react'
import { Grid, LinearProgress, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import empty from '../../../../../../../../assets/empty.png'
import DisplayText from '../../../../../../../../components_v1/Texts/DisplayText'
import InfoButton from './InfoButton'

const useStyles = makeStyles(theme => ({
    root:{},
    paper:{
        borderRadius:8,
    },
    cell:{
        borderColor:grey[200],
    },
    row:{
        padding:'18px 32px',
        borderBottom:`solid ${grey[200]} 1px`
    },
    button:{
        background:theme.palette.primary.main,
        padding:'6px 12px',
        borderRadius:32,
        color:'white',
        '&:hover':{
            background:theme.palette.primary.dark,
        }
    },
    toolbar:{
        marginBottom:16
    },
    actions:{
        marginTop:16
    },
    table:{
        minWidth:1100
    },
    tableWrapper:{
        //overflowX: 'auto',
    },
    nodata:{
        marginTop:40
    }
}))

const SimpleTable = props => {

    const classes = useStyles()
    const { data, onEditItem, onDeleteItem, loading} = props


    return(
        <div>
            {loading ? (
                <Grid container justify='center'>
                    <Grid item xs={12}><LinearProgress/></Grid>
                </Grid>
            ) : null}
            <div className={classes.paper}>
                {data && data.length ? (
                    <Grid>
                        <Grid item>
                            {data.map((item,key) => {
                                const {id_user, first_name, last_name, work_email, phone_number} = item

                                const name = `${first_name} ${last_name}`
                                return(
                                    <div className={classes.row}>
                                        <Grid container key={key.toString()} justify='space-between' alignItems='center'>
                                            <Grid item className={classes.cell}><DisplayText variant='body2'>{name}</DisplayText></Grid>
                                            <Grid item className={classes.cell}>
                                                <Grid container spacing={1}>
                                                    <Grid item className={classes.cell}><InfoButton icon='mail_outline' label='E-mail' data={work_email}/></Grid>
                                                    <Grid item className={classes.cell}><InfoButton icon='phone' label='Teléfono' data={phone_number}/></Grid>
                                                    <Grid item className={classes.cell}><InfoButton icon='edit' onClick={() => onEditItem(item)}/></Grid>
                                                    <Grid item className={classes.cell}><InfoButton icon='delete' onClick={() => onDeleteItem(id_user)}/></Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </div>
                                )
                            })}
                            
                        </Grid>
                    </Grid>
                ): null}
            </div>
            {data.length === 0 && !loading ? (
                <div className={classes.nodata}>
                    <Grid container justify='center' alignItems='center' direction='column'>
                        <Grid item>
                            <img src={empty} alt='' width={180}/>
                        </Grid>
                        <Grid item>
                            <DisplayText>No se encontraron registros</DisplayText>
                        </Grid>
                    </Grid>
                </div>
                
            ) : null}
            
        </div>
    )
}

export default SimpleTable