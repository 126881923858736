import {mainServer} from '../../../variables/config'
import axios from 'axios'

export const request_timereports = async(params) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get(`/timereport/list${params}` );
    return response.data.data
}

export const request_delete_timereport = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete(`/timereport/${id}` );
}


export const request_pending_timereports = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/timereport/pending/${id}`, {params:{language_id:2}});
  return response.data.data.count
}

export const request_rejected_timereports = async(id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/timereport/rejected/${id}`, {params:{language_id:2}});
  return response.data.data.count
}

export const request_timereport_statistic = async(id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/statistics/user/${id}`, {params:{...params}} );
  return response.data.data.statistics
}

export const request_create_timereport = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.post(`/timereport`, data);
  return response.data.data.time_report_id
}

export const request_upload_document = async(id, file, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.post(`/timereport/${id}/file`, data,  {params:{language_id:2}})
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get(`/timereport/${id}`,  {params:{language_id:2}});
  return response.data.data.time_report
}

export const request_timereports_businesses = async(value) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/businesses`, {params:{offset:0}});
  return response.data.data.businesses
}

export const request_timereports_services = async(value) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  //response = await mainServer.instance.get(`/timereport/services`, {params:{name:value, limit:20, offset:0}});
  //sin filtro
  response = await mainServer.instance.get(`/timereport/services`, {params:{offset:0}});
  return response.data.data.services
}

export const request_timereports_supervisors = async(value) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/timereport/approvers`, {params:{name:value, limit:20, offset:0}});
  return response.data.data.approvers
}

