export const contentData = {
    spanish: {
        title: 'Subproceso',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date',
            //ctrlpath: true
        },
        accordions: [
            {
                id: 'not_negative_bank_account_final_balance',
                title: 'Saldo Final de la Cuenta de Bancos no esté en negativo'
            },
            {
                id: 'recorded_bank_movements_every_month',
                title: 'Movimientos en Bancos estén registrados cada mes'
            },
            {
                id: 'bank_account_final_balance_correspons_to_statements',
                title: 'Saldo Final de la Cuenta de Bancos​ corresponda a los Estados de Cuenta Bancarios'
            },
            {
                id: 'client_account_registered_in_sales_vat_payable',
                title: 'Cuentas de Clientes estén registrado en Ventas e IVA por pagar'
            },
            {
                id: 'client_account_does_not_have_movements',
                title: 'Cuenta de Clientes no tengan movimientos'
            },
            {
                id: 'client_collection_bank_deposits',
                title: 'Clientes Cobro Depósitos Bancarios'
            },
            {
                id: 'not_negative_customers_balance',
                title: 'Clientes Saldo No esté negativo'
            },
            {
                id: 'monthlyvatinfavorcancellationifcreditablevatpaidishigher',
                title: 'IVA a favor Cancelación Mensual si es mayor el IVA acreditable pagado'
            },
            {
                id: 'vat_in_favor_application_of_balance_following_months',
                title: 'IVA a favor Aplicación de Saldo Meses Siguientes'
            },
            {
                id:'inventories_record_every_month_pass_through_at_cost',
                title:'Inventarios​ Registro​ Cada mes traspaso al costo​'
            },
            {
                id:'global_proof_veritifacion_creditable_vat_paid',
                title:'IVA acreditable pagado​ Verificar​ Prueba global​'
            },
            {
                id:'advance_payment_movements',
                title:'Pagos anticipados​ Movimientos ​ A que se refieren ​​'
            },
            {
                id:'advance_payment_supplier',
                title:'Anticipo a proveedores​ Aplicación​ Traspaso a proveedores '
            },
            {
                id:'machinery_equipment_balance',
                title:'Maquinaria y equipo​ Saldo ​ Registro como Activo ​'
            },
            {
                id:'suppliers_accounts_no_movements',
                title:'Proveedores​ Cuentas​ No Tengan Movimientos​'
            },
            {
                id:'suppliers_payment_bank_transfers',
                title:'Proveedores​ Pago​ Transferencias bancarias​'
            },
            {
                id:'suppliers_balance_no_positive',
                title:'Proveedores​ Saldo​ No esté positivo​'
            },
            {
                id:'creditos_veritfy_bank_transfers',
                title:'Acreedores​ Verificar​ Transferencias bancarias​'
            },
            {
                id:'creditors_balance_no_movements',
                title:'Acreedores​ Saldo ​ No tengan movimientos​'
            },
            {
                id:'transferred_vat_collected_register_bank_deposits',
                title:'IVA Trasladado Cobrado​ Registro​ Depósitos bancarios​​'
            },
            {
                id:'advance_application_transfer_clients',
                title:'Anticipo a Clientes​ Aplicación​ Traspaso a clientes​'
            },
            {
                id:'social_capital_check_variations',
                title:'Capital Social​ Verificar​ Variaciones​'
            },
            {
                id:'sales_return_balance_verify_clients',
                title:'Ventas y Devoluciones s/vta​ Verifican ​ Clientes​​'
            },
            {
                id:'sales_return_balance_non_positive',
                title:'Ventas y Devoluciones s/vta​ Saldo​ No esté Positivo​'
            },
            {
                id:'costs_vertify_warehouse_transfer_monthly',
                title:'Costos​ Verificar​ Traspaso del almacén cada mes​'
            },
            {
                id:'expenses_salaries_monthly',
                title:'Gastos​ Sueldos y Salarios​ Registro mensual​'
            },
            {
                id:'honorary_expenses_similar_to_salaries_monthly',
                title:'Gastos​ Honorarios Asimilados a Sueldos (HAS)​ Registro mensual​'
            },
            {
                id:'expenses_imss_rcv_infonavit',
                title:'Gastos​ Coutas IMSS, RCV e INFONAVIT​ Registro por concepto Mensual/Bimestral​'
            },
            {
                id:'state_tax_expenses_monthly',
                title:'Gastos​ Impuestos estatales Registro Mensual​'
            },
            {
                id:'payment_service_ascg_monthly',
                title:'Gastos​ Honorarios de la Firma ASCG​ Registro Mensual​'
            },
            {
                id:'expenses_payment_federal_state_taxes_monhtly',
                title:'Gastos​ Pago de Impuestos Federales y Estatales​ Registro Mensual​'
            },
            {
                id:'leases_expenses_monthly_record',
                title:'Gastos​ Arrendamientos​ Registro Mensual​'
            },
            {
                id:'other_products_financial_movements_creditors',
                title:'Otros productos y financieros​ Movimientos ​ Solo Acreedores​'
            },
            {
                id:'other_expenses_financial_movements_debtors',
                title:'Otros gastos y financieros​ Movimientos ​ Solo Deudores ​'
            },
        ],
    }
}
