import React from 'react';
import { Badge, Button, Grid, Icon, makeStyles } from '@material-ui/core';
import { blueGrey } from '@material-ui/core/colors';
import cx from 'classnames'

const ToolbarTable = (props) => {

    const { selected, onChange, menuOptions } = props

    return (
        <div>
            <Grid container spacing={2}>
                {menuOptions.map(item => {
                    return <Grid item>
                        <CustomButton
                            number={item.number}
                            onClick={() => onChange(item.id)}
                            selected={item.id === selected}
                            onClick={() => onChange(item.id)}
                        >
                            {item.label}
                        </CustomButton>
                    </Grid>
                })}
                <Grid item xs />
                <Grid item>  </Grid>
            </Grid>
        </div>
    );
}

export default ToolbarTable;

const useButtonStyles = makeStyles(theme => ({
    root: { position: 'relative' },
    button: {
        background: blueGrey[100],
        color: blueGrey[700],
        borderRadius: 8,
        padding: '8px 16px',
        '&:hover': { background: blueGrey[200] }
    },
    selected: {
        color: 'white',
        background: theme.palette.primary.main,
        fontWeight: 500,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        '&:hover': { background: theme.palette.secondary.dark }
        //border:`1px solid ${theme.palette.primary.main}`
    }
}))

const CustomButton = props => {
    const classes = useButtonStyles()
    const { children, selected, onClick, number } = props



    return (
        <div className={classes.root}>
            <Badge badgeContent={number} color='error'>
                <Button onClick={onClick} className={cx({
                    [classes.button]: true,
                    [classes.selected]: selected
                })}>{children}</Button>
            </Badge>

        </div>
    )
}