export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      notable:'No existen usuarios infonavit registrados',
      tableTitle:'Usuarios Infonavit',
      header:['ID','Usuario Infonavit','Contraseña','Correo',
      'Opinión del Cumplimiento INFONAVIT', ],
      modals:{
        warning_delete_item:{
          title:'¿Estás seguro?',
          message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
          cancelButton:'Cancelar',
          deleteButton:'Eliminar'
        },
        cerl:{
          name:'Certificado eFirma',
          button:'Guardar',
          cancelButton:'Cancelar'
        }
      },
    },
    english:{

    }
  }
