import React from 'react';
import SimpleTable from './components/SimpleTable';
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer';
import useRecords from './useRecords';

const Records = (props) => {

    const { system, view_data, actions } = useRecords({ ...props })
    const { content, evaluations, task_type, total, tableFilter, status,
        month, year, month_options, redirectContent, data2table } = view_data

    return (
        <div>
            {redirectContent}
            <LoadingContainer sending={system.sending} error={system.error} onCloseError={actions.onCloseError} success={system.success} onCloseSuccess={actions.onCloseSuccess}>
                <div style={{ paddingTop: 32 }}>
                    <SimpleTable
                        header={content.header}
                        task_type={task_type}
                        month={month}
                        year={year}
                        status={status}
                        monthCatalog={month_options}
                        data={data2table}
                        origin_data={evaluations}
                        tableFilter={tableFilter}
                        total={total}
                        loading={system.loading}
                        generatePDF={actions.onGeneratePDF}
                        onSelectedItem={actions.onSelectedItem}
                        onUpdateTableFilter={actions.onChangeTableFilter}
                        onChangeFilter={actions.onChangeFilter}
                    />
                </div>
            </LoadingContainer>
        </div>
    );
}

export default Records