import React, { useEffect, useState } from 'react'
import { Grid, makeStyles} from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import moment from 'moment'
import {contentData} from './content'
import { Redirect } from 'react-router'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import ProspectInfoForm from './components/ProspectInfoForm'
import PictureModal from './modals/PictureModal/PictureModal'
import { request_deleteFile, request_downloadFile, request_prospect, request_update_prospect } from './requests'
import EditProspectModal from './modals/EditProspect/EditProspectModal'
import RegisterInfoForm from './components/RegisterInfoForm'
import EditRegisterInfoModal from './modals/EditRegisterInfo/EditRegisterInfoModal'
import ContactInfoForm from './components/ContactInfoForm'
import ProspectStepper from './components/ProspectStepper'
import DocumentsCards from './components/DocumentsCards'
import EditContactModal from './modals/EditContact/EditContactModal'
import DocumentModal from './modals/DocumentModal'

const styles = makeStyles((theme) => ({
  root:{padding:'32px'},
  subtitle:{color:theme.palette.primary.main, fontWeight:'500'},
  nospeciality:{color:grey[700], fontWeight:'500'}
}))

const findResponsible  = (id, users, nodata) => {
  if(id !== null && id !== undefined){
    if(users.length > 0){
      let temp = users.filter(el => el.id_user === id)
      return JSON.parse(JSON.stringify(temp[0]))
    }
  }
  return {
    first_name: nodata.split(' ')[0], 
    last_name:nodata.split(' ')[1],
    image:"0"
  }
}


const GeneralView = ({catalogs, prospectID, language, module_permission,permission, permissionsType}) => {

  const classes = styles()
  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [error, setError] = useState(null)
  const [selected_document, setSelectedDocument] = useState(null)
  const [prospect, setProspect] = useState(null)  
  const content = contentData[language]

  ///////// MODAL'S STATES //////////////////////////////////
  const [additemmodal, setAddItemModal] = useState(false)
  const [edititemmodal, setEditItemModal] = useState(false)
  const [profilemodal, setProfileModal] = useState(false)
  const [editregisteritemmodal, setEditRegisterItemModal] = useState(false)
  const [editcontactmodal, setEditContactModal] = useState(false)
  const [deletefilemodal, setDeleteFileModal] = useState(false)
  const [documentmodal, setDocumentModal] = useState(false)

  const [stepper, setStepper] = useState(false)
  const [success, setSuccess] = useState(false)

  useEffect(() => {
    const initModule = async() => {
      try{
        setLoading(true)
        let response = await request_prospect(prospectID)
        setProspect({...response})
      }catch(error){
        console.log(error)
      }
      setLoading(false)
    }
    initModule()
  },[prospectID])


  const onUpdateCompleted = async() => {
    try {
      setSending(true)
      let response = await request_prospect(prospectID)
      setProspect({...response})
      setSending(false)
      setProfileModal(false)
      setDocumentModal(false)
      setEditContactModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
    }
    setSending(false)
  }

  const onChange = (stepID) => {
    onUpdateProspect({client_state_id: stepID})
  }

  const onUpdateProspect = async(data) => {
    try {
      setSending(true)
      let response = await request_update_prospect(prospectID, data)
      setProspect({...response})
      setSending(false)
      setEditContactModal(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)      
    }
    setSending(false)
  }

  const onUploadDocument = (data) => {
    setSelectedDocument(data)
    setDocumentModal(true)
}

const onDownloadDocument = async (filedata) => {
  try{
    setSending(true)
    await request_downloadFile(prospectID, filedata.path)
  }catch(e){
    console.log(e)
    setError('No fue posible descargar el documento.')
  }
  setSending(false)
}

const onDeleteDocument = async(filedata) => {
  try{
    setSending(true)
    let response = await request_deleteFile(prospectID, filedata.path)
    setProspect({...response})
    setSending(false)
    setDeleteFileModal(false)
    setSuccess(true)
  }catch(e){
    console.log(e)
    setError('No fue posible eliminar el documento')
  }
  setSending(false)
}

  let files = [...content.files]
  if(prospect){
    files.forEach((item,key) => {
      let temp = prospect[item.key]
      files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
    })
  }

  let disabledConvertButton = true 

  if(prospect){
    if(prospect.client_state_id >= 17){
      disabledConvertButton = false
    }
  }

  
  const editButton = permission >= permissionsType.EDIT

  return(
    <LoadingContainer
      loading={loading} 
      sending={sending} 
      success={success} 
      error={error}         
      onCloseError={() => setError(null)} 
      onCloseSuccess={() => setSuccess(false)}
    >
      <div>
        {permission === 1 ? (
            <Redirect to='/profile'/>
          ) : null}
        <DocumentModal id={prospectID} open={documentmodal} document={selected_document} onClose={() => setDocumentModal(false)} onUpdateCompleted={onUpdateCompleted}/>
        <PictureModal open={profilemodal} id={prospectID} onUpdateCompleted={onUpdateCompleted} onClose={() => setProfileModal(false)}/>
        <EditProspectModal open={edititemmodal} origin_data={prospect} language={language} onSubmit={onUpdateProspect} onClose={() => setEditItemModal(false)}/>
        <EditRegisterInfoModal open={editregisteritemmodal} origin_data={prospect} language={language} onSubmit={onUpdateProspect} onClose={() => setEditRegisterItemModal(false)}/>
        <EditContactModal open={editcontactmodal} origin_data={prospect} language={language} onSubmit={onUpdateProspect} onClose={() => setEditContactModal(false)}/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ProspectInfoForm permission={module_permission}  data={prospect} onEdit={() => setEditItemModal(true)} onChangePicture={() => setProfileModal(true)}/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <RegisterInfoForm title={content.title.sectionB} permission={module_permission}  data={prospect} onEdit={() => setEditRegisterItemModal(true)}/>
              </Grid>
              <Grid item xs={12}>
                <ContactInfoForm title={content.title.sectionC}  permission={module_permission}  data={prospect} onEdit={() => setEditContactModal(true)}/>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <DocumentsCards title={content.title.sectionD} permission={module_permission} files={prospect} onUpload={onUploadDocument} onDownload={onDownloadDocument} onDelete={onDeleteDocument}/>
              </Grid>
              <Grid item xs={12}>
                <ProspectStepper 
                  title={content.title.sectionF}
                  language={language}
                  step={prospect ? prospect.client_state_id : 0}
                  steps={catalogs.client_states}
                  onChange={onChange}
                  permission={permission}
                  permissionsType={permissionsType}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </LoadingContainer>
  )
}

export default GeneralView