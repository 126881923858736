import React from 'react'
import BankAccountOpening from '../BankAccountOpening/BankAccountOpening';
import ImssInscription from '../ImssInscription/ImssInscription';
import MoralSatInscription from '../MoralSatInscription/MoralSatInscription';
import PhysicalSatInscription from '../PhysicalSatInscription/PhysicalSatInscription';
import StateRegisterInscription from '../StateRegisterInscription/StateRegisterInscription';
import Efirma from '../Efirma/Efirma';
import FiscalDiagnostic from '../FiscalDiagnostic/FiscalDiagnostic';
import ExternalInvestmentRegistration from '../ExternalInvestmentRegistration/ExternalInvestmentRegistration';
import Consulting from '../Consulting/Consulting';

export const contentData = {
    spanish:{
      nocontent:'No existen tablas para mostrar',
      layout:{
        title:'AS Advisory',
        navigation:[
            {name:'Tablas de Control',path:'/controltables'},
            {name:'Detalles',path:'/controltables/details/:id'},
        ],
        menu:null
      },
      modals:{
        warning_delete_file:{
          title:'¿Estás seguro?',
          message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
          cancelButton:'Cancelar',
          deleteButton:'Eliminar'
        },
        edit_service:{
          title:'Editar servicio',
          button:'Guardar',
          cancelButton:'Cancelar'
        }
      },
      control_tables:[
        {name:'Apertura de Cuenta Bancaria', id:1, component: <BankAccountOpening/>, state:false},
        {name:'Inscripción al IMSS', id:2, component: <ImssInscription/>, state:false},
        {name:'Inscripción al SAT Persona Moral', id:3, component: <MoralSatInscription/>, state:false},
        {name:'Inscripción al SAT Persona Física', id:4, component: <PhysicalSatInscription/>, state:false},
        {name:'Inscripción Registro Estatal de Contribuyentes', id:5, component: <StateRegisterInscription/>, state:false},
        {name:'Trámite o Renovación E.firma', id:6, component: <Efirma/>, state:false},
        {name:'Diagnóstico Fiscal', id:7, component: <FiscalDiagnostic/>, state:false},
        {name:'Registro en Inversión Extranjera', id:8, component: <ExternalInvestmentRegistration/>, state:false},
        {name:'Consultoría', id:9, component: <Consulting/>, state:false},
      ]
    },
    
  }