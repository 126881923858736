import {mainServer} from '../../../variables/config'
import axios from 'axios'

export const request_tasks = async(params) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get(`/task/all${params}` );
    return response.data.data
}

export const request_businesses = async() => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/business/listall`);
  return response.data.data.businesses
}

export const request_create_task = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.post(`/task`, data);
  return response.data.data.task_id
}

export const request_add_user = async (data) => {
	const logData = JSON.parse(localStorage.getItem('data'));
	mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
	await mainServer.instance.post(`/taskuser`, data);
};