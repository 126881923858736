import React from 'react'
import { Snackbar } from "@material-ui/core";
import { createContext, useCallback, useState } from "react";
import { makeStyles } from '@material-ui/styles'
import MuiAlert from '@material-ui/lab/Alert';

export const SnackbarContext = createContext(null);

export const SnackbarProvider = ({children}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false)
    const [message, setMessage] = useState("")
    const [variant, setVariant] = useState("success")

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpen(false);
    };

    const enqueueSnackbar = useCallback((message, variant) => {
        setMessage(message)
        setVariant(variant)
        setOpen(true)
    }, [])


    return(
        <SnackbarContext.Provider
            value={{
                enqueueSnackbar
            }}
        >
            <Snackbar open={open} autoHideDuration={3000} onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Alert onClose={handleClose} severity={variant}>
                    {message}
                </Alert>
            </Snackbar>
            {children}
        </SnackbarContext.Provider>
    )

}

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& > * + *': {
        marginTop: theme.spacing(2),
        },
    },
}));