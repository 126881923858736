export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    error:'Sin registro',
    add_button:'Agregar',
    title:{
      sectionA:'Información Básica',
      sectionB:'Información Detallada',
      sectionC:'Detalles',
    },
    sectionA:{
      username:{
        label:'Usuario',
        placeholder:'Usuario'
      },
      password:{
        label:'Contraseña',
        placeholder:'Contraseña'
      },
      passwordConfirm:{
        label:'Confirmar contraseña',
        placeholder:'Confirmar contraseña'
      },
      first_name:{
        label:'Nombre(s)',
        placeholder:'Nombre(s)'
      },
      last_name:{
        label:'Apellido(s)',
        placeholder:'Apellido(s)'
      },
      user_level_id:{
        label:'Posición',
        placeholder:'Posición'
      },
      /*language_id:{
        label:'Idioma',
        placeholder:'Idioma'
      },*/
      timezone:{
        label:'Zona Horaria',
        placeholder:'Zona Horaria'
      },
      employee_status_id:{
        label:'Estatus',
        placeholder:'Estatus'
      },
      work_email:{
        label:'Correo de trabajo',
        placeholder:'Correo de trabajo'
      },
      entry_day:{
        label:'Fecha de inicio',
        placeholder:'Fecha de inicio'
      },
    },
  },
  
}