import React from 'react';
import { Icon, IconButton, makeStyles, TableBody, TableRow } from '@material-ui/core';
import TCell from './TCell';
import TData from './TData';
import TEditableData from './TEditableData';
import { PERMISSION_TYPES } from '../../../../../variables/config';

const useStyles = makeStyles(theme => ({
    button:{
        //background:theme.palette.primary.main,
        //color:'white'
    }
}))

const TBody = ({data, dataConfig, onEditItem, editable, selectedItem, idRef, onSaveItem, onInputChange, form, onUploadDocument, onDownloadDocument, onDeleteDocument, permission}) => {

    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }

    const classes = useStyles()
    return ( 
        <TableBody>
            {data.map((item,key)=>{
                let isEditable = false
                if(key === 0){
                    //console.log(item)
                }
                if(editable && selectedItem){ // Check if the row is editable
                    isEditable = selectedItem[idRef] === item[idRef]
                    if(isEditable) console.log(`El elemento a editar es ${selectedItem[idRef]}`)
                }
                return(
                    <TableRow key={key.toString()}>
                        <TCell fixed={true} width={26} padding='checkbox'>
                            <IconButton size='small' disabled={!can_edit} className={classes.button} color='primary' onClick={() => !isEditable ? onEditItem(item) : onSaveItem(item)}><Icon fontSize='small'>{isEditable ? 'save' : 'edit'}</Icon></IconButton>
                        </TCell>
                        {dataConfig.map((el,id)=>{
                                if(isEditable && el.editable){ // Check if the column item is editable
                                    let formID = el.formId ? el.formId : el.id 
                                    const formItem = form[formID]
                                    return <TEditableData key={el.id} fixed={el.fixed} data={formItem} onChange={onInputChange}/>
                                }
                                if(el.hidden) return null
                                const value = item[el.id]
                                return <TData {...el} value={value} key={el.id} can_edit={can_edit} can_create={can_create} has_full_access={has_full_access}
                                onUploadDocument={(column) => onUploadDocument(item[idRef], column)} 
                                onDownloadDocument={(column) => onDownloadDocument(item[idRef], column,el.bussinesDocument,item.business_id,el.efirmaDoc, item, el.id)}
                                onDeleteDocument={(column) => onDeleteDocument(item[idRef], column)}/>
                        })}
                    </TableRow>
                )
            })}
        </TableBody>
     );
}
 
export default React.memo(TBody);