import {mainServer} from '../../../../variables/config'
import axios from 'axios'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_user_info = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/me");
  return response.data.data.employee
}

export const request_update_user = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/user/employee/me", data);
  let response;
  response = await mainServer.instance.get("/user/employee/me");
  return response.data.data.employee
}

export const request_update_permissions = async(data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/permission/me", data);
  let response;
  response = await mainServer.instance.get("/permission/me/list");
  return response.data.data.user_permissions
}

export const request_uploadFile = async(path,file,ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = '/user/'+path+'/me'
  let response = await mainServer.instance.post(url, ext)

  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  response = await mainServer.instance.get("/user/employee/me");
  return response.data.data.employee
}


export const request_deleteFile = async (path) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = '/user/'+path+'/me'
  await mainServer.instance.delete(url)
  let response;
  response = await mainServer.instance.get("/user/employee/me");
  return response.data.data.employee
}

