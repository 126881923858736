export const formData = {
  
    user:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'user',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    password:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'password',
        type:'text',
        fullWidth: true,
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
    },
    email:{
      value: '',
      error: false,
      isVisited: false,
      isRequired: false,
      isValid: false,
      config:{
        id:'email',
        type:'email',
        fullWidth: true,
        icon:{name:'mail',position:'start'},
      },
      rules:{
        type:'email',
      }
    }
  }