const styles = theme => ({
  popper:{
    zIndex:3000
  },
  iconUserMenu:{
    color: theme.palette.primary.main,
    paddingRight:8
  },
});

export default styles;
