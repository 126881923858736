import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { request_employees, request_evaluations } from './requests'
import LoadingContainer from '../../../../../../components_v1/Structure/Layouts/LoadingContainer'
import { onGetErrorMessage, onGetUserID } from '../../../../../../shared/utility'
import EvaluationPDF from '../../../../../../components_v1/Structure/EvaluationGenericView/components/EvaluationPDF'
import SimpleTable from './components/SimpleTable'
import FilterButton from './components/FilterButton'
import Estadistics from './components/Estadistics'
import { cleanData2Chart, cleanData2Table } from './customFunctions'
import { contentData } from './content'
import queryString from 'query-string'
import moment from 'moment'
import DateButton from '../../../../../../components_v1/Forms/DateButton'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

const useStyles = makeStyles(theme => ({
    root: {},
    toolbar: {
        marginBottom: 16
    },
}))

const UserEvaluations = (props) => {

    const classes = useStyles()
    const { history, language, userID } = props
    const content = contentData[language]

    const [loading, setLoading] = useState()
    const [sending, setSending] = useState()
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })

    const [filter, setFilter] = useState(1)
    const [evaluations, setEvaluations] = useState([])
    const [users, setUsers] = useState([])
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(moment(startDate).add(1, 'months'))

    useEffect(() => {
        const initModule = async () => {
            setLoading(true)
            try {
                
                const { limit, offset } = tableFilter
                let filterArgs = [
                    { "field": 'evaluated_id', "operator": "LIKE", "value": userID }
                ]
                let param2send = {}

                if (filter === 2) {
                    filterArgs = [
                        ...filterArgs,
                        { 'field': 'auto_date', 'operator': '>=', 'value': moment(startDate).startOf('month') },
                        { 'field': 'auto_date', 'operator': '<=', 'value': moment(endDate).endOf('month') }
                    ]
                    param2send = { language_id: 2, offset: offset, order_by: 'auto_date', order: 1 }
                } else
                    param2send = { language_id: 2, offset: offset, limit: limit, order_by: 'auto_date', order: 1 }
                console.log(filterArgs)
                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _evaluations = await request_evaluations(params)
                setTotal(_evaluations.count)
                setEvaluations(_evaluations.employee_evaluations)
                let response = await request_employees()

                setUsers([...response])
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }

        initModule()
    }, [tableFilter, startDate, endDate, filter])

    const handleDate = date => {
        setStartDate(date)
        if (moment(date).isSameOrAfter(endDate))
            setEndDate(moment(date).add(1, 'M'))
    }

    const generatePDF = async (documentData) => {
        try {
            setSending(true)
            const blob = await pdf(<EvaluationPDF {...documentData} />).toBlob()
            saveAs(blob, "evaluation.pdf");
        } catch (error) {
            console.log(error)
        }
        setSending(false)
    }

    const data2table = cleanData2Table(evaluations, content.nodata, users)
    const data2chart = cleanData2Chart(evaluations, content.charts, startDate, endDate)
    let contentView = null

    switch (filter) {
        case 1:
            contentView = (
                <SimpleTable
                    header={content.header}
                    data={data2table}
                    tableFilter={tableFilter}
                    total={total}
                    loading={loading}
                    origin_data={evaluations}
                    generatePDF={generatePDF}
                    onSelectedItem={(data) => history.push(`/users/details/evaluation/${data.id_employee_evaluation}`)}
                    onUpdateTableFilter={(data) => setTableFilter(data)}
                />
            )
            break;
        case 2:
            contentView = (
                <Estadistics data={data2chart} content={content.charts} />
            )
            break;
        default:
            break;
    }
    return (
        <div>
            <div className={classes.toolbar}>
                <Grid container spacing={2} justify='space-between' alingItems='flex-start'>
                    <Grid item>
                        {filter == 2 ?
                            <Grid container spacing={2}>
                                <Grid item>
                                    <DateButton
                                        openTo="year"
                                        views={["year", "month"]}
                                        format={'MMMM / yyyy'}
                                        label="Fecha inicial"
                                        value={startDate}
                                        onChange={handleDate}
                                    />
                                </Grid>
                                <Grid item>
                                    <DateButton
                                        openTo="year"
                                        views={["year", "month"]}
                                        format={'MMMM / yyyy'}
                                        label="Fecha final"
                                        minDate={moment(new Date(startDate)).add(1, 'M')}
                                        value={endDate}
                                        onChange={setEndDate}
                                    />
                                </Grid>
                            </Grid>
                            : null}
                    </Grid>
                    <Grid item>
                        <FilterButton options={content.filter} selected={filter} onChange={(val) => setFilter(val)} />
                    </Grid>
                </Grid>
            </div>
            <LoadingContainer
                error={error}
                onCloseError={() => setError(null)}
                loading={loading}
                sending={sending}
                success={success}
                onCloseSuccess={() => setSuccess(null)}
            >
                <div style={{ paddingTop: 32 }}>
                    {contentView}
                </div>
            </LoadingContainer>
        </div>
    );
}

export default UserEvaluations