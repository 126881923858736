export const contentData = {
  spanish:{
    title:'Agregar timbrado',
    nodata:'Sin Registro',
    error:'Sin registro',
    button:'Guardar',
    cancelButton:'Cancelar',
    image_button:'Cambiar',
    form:{
      bank_id:{
        label:'Banco',
        placeholder:'Banco',
      },
      branch_office:{
        label:'Sucursal',
        placeholder:'Sucursal',
      },
      account_number:{
        label:'No. de cuenta',
        placeholder:'No. de cuenta',
      },
      clabe:{
        label:'CLABE',
        placeholder:'CLABE',
      },
      currency_id:{
        label:'Moneda',
        placeholder:'Moneda',
      },
    },
  },
}