import {mainServer} from '../../../../variables/config'

export const sleep = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
}

export const request_user_info = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/user/employee/" + id);
  return response.data.data.employee
}

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}

export const request_prospect = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
  response = await mainServer.instance.get("/client/pre/"+id)
  return response.data.data.pre_client
}


export const request_update_prospect = async(id,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/client/pre/"+id, data);
  let response;
  response = await mainServer.instance.get("/client/pre/"+id)
  return response.data.data.pre_client
}
