import React, { useEffect, useState } from 'react';
import { Grid, Switch, useMediaQuery, useTheme } from '@material-ui/core';
import moment from 'moment'
import RoundedButton from '../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../components_v1/Structure/Cards/SimpleCard';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { catalogs } from '../../../../texts/esp/catalogs';
import InputText from '../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import { request_timereports_businesses, request_timereports_services, request_timereports_supervisors, request_update_timereport } from '../requests';
import { isFormValid, onGetCatalogs, onGetDateFormat, onGetErrorMessage, onGetFormData, onGetSelectedFormData, onInitForm, onSetErrorsToForm } from '../../../../shared/utility';
import InputRating from '../../../../components_v1/Forms/InputRating';
import RichTextForm from '../../../../components_v1/Forms/RichTextForm';

const TimeReportForm = (props) => {
    const theme = useTheme();
    const { id, timereport, onUpdateData, onUpdateError, userID } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [file, setFile] = useState(null)
    const [businesses, setBusinesses] = useState([])
    const [services, setServices] = useState([])
    const [supervisors, setSupervisors] = useState([])
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const ismobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [supervisor, setSupervisor] = useState(false)

    //rich form
    const [description, setDescription] = useState('')
    const [meeting_notes, setMeetingNotes] = useState('')

    useEffect(() => {
        if (timereport) {
            //console.log(timereport)
            let _form = onInitForm(formData, timereport)
            _form.date.value = onGetDateFormat(_form.date.value)
            if (timereport.business_id) setBusinesses([{ value: timereport.business_id, label: timereport.business ? timereport.business : '' }])
            if (timereport.service_id) setServices([{ value: timereport.service_id, label: timereport.service ? timereport.service : '' }])
            if (timereport.supervisor_id) setSupervisors([{ value: timereport.supervisor_id, label: timereport.supervisor ? timereport.supervisor : '' }])
            if (timereport.supervisor_id) setSupervisor(true)
            setForm(_form)
            setDescription(timereport.task_description ? timereport.task_description : '')
            setMeetingNotes(timereport.meeting_notes ? timereport.meeting_notes : '')
            setError(''); setLoading(false);
        }
    }, [timereport])

    const onChange = (data) => {
        //console.log(`Form:${data.value}`)
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSearchBusiness = async (e) => {
        if (!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _businesses = await request_timereports_businesses(e.target.value)
            const _business_catalog = onGetCatalogs(_businesses, 'business_id', 'business')
            setBusinesses(_business_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSearchService = async (e) => {
        if (!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _services = await request_timereports_services(e.target.value)
            const _service_catalog = onGetCatalogs(_services, 'id_time_report_service', 'name')
            setServices(_service_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSearchSupervisor = async (e) => {
        if (!e || e.target.value === "" || e.target.value === 0) return
        try {
            const _supervisors = await request_timereports_supervisors(e.target.value)
            const _supervisor_catalog = onGetCatalogs(_supervisors, 'user_id', 'user')
            setSupervisors(_supervisor_catalog)
        } catch (error) {
            console.log(error)
        }
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if (errors && errors.length) {
            //console.log(errors)
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        //Check supervisor
        if (supervisor) {
            if (!form.supervisor_id.value) {
                setError('Es necesario seleccionar el supervisor')
                return
            }
        }

        //Extract data
        let data2send = onGetSelectedFormData(form, timereport)
        data2send.task_description = description
        data2send.meeting_notes = meeting_notes

        console.log(data2send)
        //const timeRegex = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]:?[0-5][0-9]+$))$/g   //HH:mm:ss
        const timeRegex_start = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g
        const timeRegex_end = /^(((([0-1][0-9])|(2[0-3])):?[0-5][0-9]))$/g

        const _aux = JSON.parse(JSON.stringify(data2send))
        delete _aux.date
        //Add extra data
        if (data2send.start_hour && timeRegex_start.test(data2send.start_hour)) data2send.start_hour = `${data2send.start_hour}:00`
        if (data2send.end_hour && timeRegex_end.test(data2send.end_hour)) data2send.end_hour = `${data2send.end_hour}:00`
        // Reset state to pending for approval because it is no the supervisor
        if (userID !== timereport.supervisor_id && JSON.stringify(_aux) !== "{}") {
            data2send.state_id = 2
        } else { }
        if (!supervisor) {
            data2send.supervisor_id = '@null!!'
            data2send.state_id = 1
        } else {
            //console.log('Requiere supervisor')
            //console.log(userID !== timereport.supervisor_id)
            if (userID !== timereport.supervisor_id && JSON.stringify(_aux) !== "{}") {
                //console.log('Dar reset')
                data2send.state_id = 2
            }
        }

        //////////////// Send data ///////////////
        setLoading(true)
        try {
            const _timereport = await request_update_timereport(id, data2send);
            onUpdateData(_timereport)

        } catch (error) {
            console.log(error)
            onUpdateError(onGetErrorMessage(error))
        }
        setLoading(false)

    }


    let business_form = { ...form.business_id }
    business_form.options = [...businesses]

    let service_form = { ...form.service_id }
    service_form.options = [...services]

    let supervisor_form = { ...form.supervisor_id }
    supervisor_form.options = [...supervisors]

    return (
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Grid container alignItems='center'>
                        <Grid item xs>
                            <DisplayText variant='h6'> Información detallada</DisplayText>
                        </Grid>
                        <Grid item>
                            <RoundedButton onClick={onSubmit} loading={loading}>Guardar</RoundedButton>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.date} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.start_hour} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.end_hour} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={business_form} onChange={onChange} onInputChange={onSearchBusiness} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={service_form} onChange={onChange} onInputChange={onSearchService} />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <InputText data={form.task_description} onChange={onChange} /> */}
                            <RichTextForm label='Descripción' value={description} onChange={(value)=>setDescription(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            {/* <InputText data={form.meeting_notes} onChange={onChange} /> */}
                            <RichTextForm label='Minuta' value={meeting_notes} onChange={(value)=>setMeetingNotes(value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container alignItems='center'>
                                <Grid item><DisplayText style={{ paddingRight: 16 }}>¿Requiere supervisión?</DisplayText></Grid>
                                <Grid item>
                                    <Grid container alignItems='center' >
                                        <Grid item><DisplayText variant='subtitle1'>No</DisplayText></Grid>
                                        <Grid item><Switch checked={supervisor} onChange={(e, v) => setSupervisor(v)} /></Grid>
                                        <Grid item><DisplayText variant='subtitle1'>Si</DisplayText></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        {supervisor ? (
                            <Grid item xs={12}>
                                <InputSelect data={supervisor_form} onChange={onChange} onInputChange={onSearchSupervisor} />
                            </Grid>
                        ) : null}
                        <Grid item xs={12} lg={6}>
                            <InputRating size={ismobile ? 'medium' : 'large'} data={form.complexity} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <InputRating size={ismobile ? 'medium' : 'large'} data={form.client_satisfaction} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.task_type_id} onChange={onChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container justify='center'>
                                <Grid item>
                                    <RoundedButton onClick={onSubmit} loading={loading}>Guardar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    );
}

export default TimeReportForm;


const formData = {
    date: {
        value: moment().format('YYYY-MM-DD'),
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: true,
        config: {
            id: 'date',
            type: 'date',
            fullWidth: true,
            label: 'Fecha *',
            helper: 'La fecha seleccionada solo puede ser de máximo 2 días anteriores y posteriores',
            minDate: moment().subtract(2, 'days').format('YYYY-MM-DD'),
            maxDate: moment().add(2, 'days').format('YYYY-MM-DD'),
            onKeyDown: (e) => e.preventDefault()
        },
        rules: { type: 'dateRange', min: moment().subtract(2, 'days').format('YYYY-MM-DD'), max: moment().add(2, 'days').format('YYYY-MM-DD') }
    },
    business_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'business_id',
            type: 'select',
            fullWidth: true,
            label: 'Negocio *',
            placeholder: 'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'select' }
    },
    service_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [],
        config: {
            id: 'service_id',
            type: 'select',
            fullWidth: true,
            label: 'Servicio',
            placeholder: 'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'select' }
    },
    /* task_description: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'task_description',
            type: 'text',
            fullWidth: true,
            label: 'Descripción de la tarea *',
            placeholder: 'Ingrese la descripción',
            helper: 'Este campo es requerido',
            multiline: true,
            rows: 3
        },
        rules: { type: 'distance', max: 1024 }
    }, */
    start_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'start_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de inicio *',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'time' }
    },
    end_hour: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'end_hour',
            type: 'time',
            fullWidth: true,
            label: 'Hora de final *',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'time' }
    },
    complexity: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'complexity',
            type: 'rating',
            fullWidth: true,
            label: 'Complejidad de la tarea *',
            helper: 'Debe seleccionar una opción'
        },
        rules: { type: 'numeric', min: 1, max: 10 }
    },
    client_satisfaction: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config: {
            id: 'client_satisfaction',
            type: 'rating',
            fullWidth: true,
            label: 'Satisfacción del cliente *',
            helper: 'Debe seleccionar una opción'
        },
        rules: { type: 'numeric', min: 1, max: 10 }
    },
    supervisor_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [],
        config: {
            id: 'supervisor_id',
            type: 'select',
            fullWidth: true,
            label: 'Supervisor',
            placeholder: 'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
    },
    task_type_id: {
        value: '',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        options: [...catalogs.timereport_tasks],
        config: {
            id: 'task_type_id',
            type: 'select',
            fullWidth: true,
            label: 'Tipo de tarea *',
            placeholder: 'Seleccione una opción',
            helper: 'Este campo es requerido'
        },
        rules: { type: 'select' }
    },
    /* meeting_notes: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config: {
            id: 'meeting_notes',
            type: 'text',
            fullWidth: true,
            label: 'Minuta de la reunión',
            placeholder: 'Ingrese un texto...',
            helper: 'Este campo es requerido',
            multiline: true,
            rows: 6
        },
        rules: { type: 'none' }
    }, */
}
