import { mainServer } from '../../../variables/config'
import axios from 'axios'
import FileSaver from 'file-saver';

export const request_evaluation = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const response = await mainServer.instance.get(`/employeevaluation/${id}`, { params: { language_id: 2 } });
  return response.data.data.employee_evaluation
}

export const request_update_evaluation = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/employeevaluation/${id}`, data);
  const response = await mainServer.instance.get(`/employeevaluation/${id}`, { params: { language_id: 2 } });
  return response.data.data.employee_evaluation
}

export const request_download_document = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/timereport/${id}/file`, { params: { name: 'evidence' } })
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, { responseType: 'blob' });
  FileSaver.saveAs(response.data, `evidence`);
}

export const request_employees = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response = await mainServer.instance.get(`/user/employee/list?${params}` )
  return response.data.data.employees
}