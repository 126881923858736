import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Redirect } from "react-router-dom"
import { Grid } from '@material-ui/core'
import styles from './styles'
import {contentData} from './content'
import SimpleTable from './components/SimpleTable'
import {request_users, request_delete_user} from './requests'
import {cleanData2Table} from './customFunctions'
import BasicView from '../../../../components_v1/Structure/Layouts/BasicView'
import queryString from 'query-string' 
import WarningModal from '../../../../components_v1/Structure/Modals/WarningModal'
import AddUserModal from './modals/AddUser/AddUserModal'

const Users = props => {

  const {history, catalogs, language, permissions, permissionsType} = props
  const [users, setUsers] = useState([])

  const [loading, setLoading] = useState(false)
  const [sending, setSending] = useState(false)
  const [success, setSuccess] = useState(null)
  const [error, setError] = useState(null)
  
  const [adduser, setAddUser] = useState(false)
  const [warningdeletemodal, setWarningDeleteModal] = useState(false)
  const [selected, setSelected] = useState(null)
    
  //Table states
  const [total, setTotal] = useState(0)
  const [tableFilter, setTableFilter] = useState({limit:10,offset:0, order_by:'id_user', order:0})
  const [search, setSearch] = useState(null)

  useEffect(() => {
    const initModule = async() => {
      try{
        setLoading(true)
        const {limit, offset, order_by, order} = tableFilter
        let filterArgs = [
            {"field":"username","operator":"LIKE","value":`%${search?search:''}%`},
        ]
        filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
        const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
        let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
        const _users = await request_users(params)
        setUsers([..._users.employees])
        setTotal(_users.count)
      }catch(e){
        console.log(e)
        setError('No fue posible cargar la lista de usuarios.')
      }
      setLoading(false)
    }
    initModule()
  },[tableFilter])

  const onChangeSearch = (val) => {
    setSearch(val)
    //setTableFilter({...tableFilter, offset:0})
  }

  const onUserselected = async(id) => {
    history.push('/users/details/'+id)
  }

  const onPreDeleteUser = (id) => {
    setSelected(id)
    setWarningDeleteModal(true)
  }

  const onDeleteUser = async() => {
    try {
      setSending(true)
      const {limit, offset, order_by, order} = tableFilter
      let filterArgs = [
          {"field":"username","operator":"LIKE","value":`%${search?search:''}%`},
      ]
      filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
      const param2send = {limit:limit, offset:offset*limit, order_by:order_by, order:order}
      let params = `?${queryString.stringify(param2send)}&filter=${filterArgs}` //&filter=${filterArgs}
      let _users = await request_delete_user(selected, params)
      setUsers(_users.employees)
      setTotal(_users.count)
      setWarningDeleteModal(false)
      setSending(false)
      setSuccess(true)
    } catch (error) {
      console.log(error)
      setError('Hubo un error al intentar eliminar el usuario')
    }
    setSending(false)
  }
  
  const content = contentData[language]
  let permission = permissions.find(el => el.id_permission_module === 1)
  //permission = permission ? permission.id_permission_type : 1
  let redirectContent = null
  if(permission && permission.id_permission_type === 1) redirectContent = <Redirect to='/profile'/> 
  
  return(
    <BasicView
      history={history}
      title={content.layout.title}
      success={success}
      error={error}
      sending={sending}
      onCloseSuccess={() => setSuccess(null)} 
      onCloseError={() => setError(null)}
    >
      {redirectContent}
      <WarningModal open={warningdeletemodal} message={content.modals.warning_modal} onClose={() => setWarningDeleteModal(false)} onDelete={onDeleteUser}/>
      <AddUserModal open={adduser} history={history} language={language} onClose={() => setAddUser(false)} catalogs={catalogs}/>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <SimpleTable
            search={search}
            onChangeSearch={onChangeSearch} 
            onAddRegister={() => setAddUser(true)}
            onSelectedItem={onUserselected}
            data={users}
            tableFilter={tableFilter}  
            onUpdateTableFilter={(data) => setTableFilter(data)}
            total={total} 
            loading={loading}
            onRemoveItem={onPreDeleteUser}
            /*
            title={content.tableTile}
            header={content.header}
            onAddItem={this.onAddUser}
            onDeleteUser={this.onPreDeleteUser}
            permission={permission ? permission.id_permission_type : 1}
            permissionsType={permissionsType}*/
          />
        </Grid>
      </Grid>
      
    </BasicView>
    
  )
}

export default withStyles(styles)(Users)