export const contentData = {
	spanish: {
		title: 'Crear nómina',
		nodata: 'Sin Registro',
		error: 'Sin registro',
		button: 'Guardar',
		cancelButton: 'Cancelar',
		image_button: 'Cambiar',
		form: {
			payroll_periodicity_id: {
				label: 'Periodicidad',
				placeholder: 'Periodicidad',
			},
			roster_type_id: {
				label: 'Tipo de nómina',
				placeholder: 'Tipo de nómina',
			},
			payroll_system_id: {
				label: 'Sistema',
				placeholder: 'Sistema',
			},
			employee_number: {
				label: 'No. de empleados',
				placeholder: 'No. de empleados',
			},
			note: {
				label: 'Notas',
				placeholder: 'Notas',
			},
		},
	},
};
