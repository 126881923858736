export const formData = {
  rfc:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'rfc',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  name:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'name',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  start:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'start',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'date',
    }
  },
  end:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'end',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'date',
    }
  },
  total_time:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'total_time',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  quoted_time:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'quoted_time',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  variation:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'variation',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  consulting_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'consulting_status_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  mod_type_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'mod_type_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  mod_cost:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'mod_cost',
      type:'number',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  consulting_progress_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    options:[],
    config:{
      id:'consulting_progress_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  witness:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'witness',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  note:{
    value: '',
    error: false,
    isVisited: false,
    isRequired: true,
    isValid: false,
    config:{
      id:'note',
      type:'text',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
}