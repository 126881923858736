import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  return response.data.data.tributary_mailbox_statuses;
}

export const request_audit = async (id,moduleID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/audit/"+moduleID.toString())
  let service = response.data.data.audit;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}  
  return service;
}

export const request_add_audits = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/audit",data);
  let response;
  response = await mainServer.instance.get("/audit/"+id.toString()+"/list")
  return response.data.data.audit
}

export const request_edit_audits = async (id, moduleID, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/audit/" + moduleID, data);
  await mainServer.instance.patch("/businessdocument/"+id.toString(), {rfc:data.rfc});
  let response;
  response = await mainServer.instance.get("/audit/"+moduleID.toString())
  let service = response.data.data.audit;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  service = {...service, 
            rfc:response.data.data.tributary_mailbox_statuses.rfc}  
  return service;
}

/**************************************** */
export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees
}

export const request_uploadFile = async(id,businessID,path,file, ext,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;

  let url = ''
  if(type)
    url= 'businessdocument/'+businessID+'/'+path
  else
    url = 'audit/' + id + '/' + path



  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/audit/"+id.toString())
  return response.data.data.audit
}

export const request_downloadFile = async(id,businessID,path,type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;

  let url = ''
  if(type)
    url= 'businessdocument/'+businessID+'/'+path
  else
    url = 'audit/' + id + '/' + path

  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data, path)
}

export const request_deleteFile = async(id, businessID,path, type) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  let url = ''
  if(type)
    url= 'businessdocument/'+businessID+'/'+path
  else
    url = 'audit/' + id + '/' + path

  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/audit/"+id.toString())
  return response.data.data.audit
}
