import React, {Component} from 'react'
import { Grid } from '@material-ui/core'
import ViewCardWrapper from '../../Cards/ViewCardWrapper'
import {DataItemWrapper} from '../../DisplayData/DisplayData'

import {updateForm} from './customFunctions'
import DocumentTable from '../../Tables/DocumentTable';

class ServiceForm extends Component {

  onEditData = () => {
    console.log('Editar')
  }

  state = {
    modals:{
      filemodal:{open:false,issending:false,error:null,extra:null},
      warning_delete_file:{open:false, issending:false, error:null, id:null}
    },
    success:{open:false,timeout:2000,type:'success',message:'Successful action!'},

  }



  render(){

    const {service, business_documents, staticview, onEditData, 
      onUploadFile, onDownloadFile, onDeleteFile, permission,
      permissionsType, children} = this.props

    let viewForm = {...staticview.data}
    viewForm = updateForm(viewForm, service, staticview.content)
    let staticContent = Object.keys(viewForm).map((item,key)=>{
      return(
        <Grid item xs={12} md={6}  key={key.toString()}>
          <DataItemWrapper 
            label={viewForm[item].label}
            data={viewForm[item].data}
            error={staticview.nodata}
            icon={viewForm[item].icon}
          />
        </Grid>
      )
    })

    let files = null
    if(staticview.documents.files){
      files = [...staticview.documents.files]
      if(service){
        files.forEach((item,key) => {
          
          if(item.businessDocument){
            if(business_documents){
              let temp = business_documents[item.key]
              files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
            }else{
              files[key].status = false
            }

          }else{
            let temp = service[item.key]
            files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
          }

          

        })
      }
    }

    return(
      <div>
        <Grid container spacing={4}>
          {staticview.data ? <Grid item xs={12}>
            <ViewCardWrapper
              title={staticview.title}
              button={staticview.button}
              onClick={onEditData}
              disabled={!(permission >= permissionsType.EDIT)}
            >
              <Grid container spacing={3}>
                {staticContent}
              </Grid>
            </ViewCardWrapper>
          </Grid>: null}
          {children ? <Grid item xs={12}>{children}</Grid> : null}
          {files ?
          <Grid item xs={12}>
            <ViewCardWrapper
              title={staticview.documents.title}
              button={null}
              level={2}
            >
              <DocumentTable 
                header={staticview.documents.header}
                files={files}
                onUpload={onUploadFile}
                onDownload={onDownloadFile}
                onDelete={onDeleteFile}
                permission={permission}
                permissionsType={permissionsType}
              />
            </ViewCardWrapper>
          </Grid>: null}

        </Grid>
      </div>
    )
  }
}

export default ServiceForm

