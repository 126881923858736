export const formData = {
    legal_representative_id:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options:[],
        config:{
            id:'legal_representative_id',
            type:'simple_select',
            fullWidth: true
        },
        rules:{
            type:'none',
        }
    },  
    antiquity:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        config:{
            id:'antiquity',
            type:'date',
            fullWidth: true
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
    rfc:{
        value:'',
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
        config:{
            id:'rfc',
            type:'text',
            fullWidth: true
        },
        rules:{
            type:'distance',
            min:1,
            max:100
        }
    },
}