import React from 'react'
import { Grid, Icon, IconButton} from '@material-ui/core'
import SimpleCard from '../Structure/Cards/SimpleCard'
import VerticalData from '../Structure/DisplayData/VerticalData'
import DocumentTable from '../Structure/Tables/DocumentTable';
import DisplayText from '../Texts/DisplayText'
import RoundedButton from '../Actions/RoundedButton';

export const updateForm = (data, user, content) => {
  let form = {}
  Object.keys(data).forEach((item)=> {
    let value = null
    if(user){
      value = user[item]
      if(value){
        // Put some conditions
        if(item === 'mobile'){
          let aux =  user.phone_code ? user.phone_code : ''
          value = aux + ' ' + value 
        }
        
      }
      
    }
    const aux = {...data[item], ...content[item], data:value}
    form = {...form, [item]:{...aux}}
  })
  return form
}

const ServiceCard = props => {

    const {service, button, business_documents, staticview, onEditData, onUploadFile, 
      onDownloadFile, onDeleteFile, onClose, permission, permissionsType} = props

    let viewForm = {...staticview.data}
    viewForm = updateForm(viewForm, service, staticview.content)
    let staticContent = Object.keys(viewForm).map((item,key)=>{
      return(
        <Grid item xs={12} md={6}  key={key.toString()}>
          <VerticalData 
            title={viewForm[item].label}
            data={viewForm[item].data}
            icon={viewForm[item].icon}
          />
        </Grid>
      )
    })

    let files = [...staticview.documents.files]
    if(service){
      files.forEach((item,key) => {
        if(item.businessDocument){
          if(business_documents){
            let temp = business_documents[item.key]
            files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
          }else{
            files[key].status = false
          }

        }else{
          let temp = service[item.key]
          files[key].status = temp && temp!=="0" && temp !==0 && temp !==""
        }

      })
    }

    const disabled=!(permission >= permissionsType.EDIT)

    return(
      <div>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SimpleCard>
              <Grid container spacing={6}>
                <Grid item xs>
                  <DisplayText color='primary' style={{fontWeight:500}}>{staticview.title}</DisplayText>
                </Grid>
                {onClose ? 
                  <Grid item>
                    <IconButton onClick={onClose}><Icon>close</Icon></IconButton>
                  </Grid>
                :null}
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    {staticContent}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={12}>
                      <DisplayText variant='h6'>Documentos</DisplayText>
                    </Grid>
                    <Grid item xs={12}>
                      <DocumentTable 
                        header={staticview.documents.header}
                        files={files}
                        onUpload={onUploadFile}
                        onDownload={onDownloadFile}
                        onDelete={onDeleteFile}
                        permission={permission}
                        permissionsType={permissionsType}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs></Grid>
                <Grid item>
                  <RoundedButton                    
                    size='small' 
                    color='secondary'
                    onClick={onEditData}
                  >{button}</RoundedButton>
                </Grid>
              </Grid>
            </SimpleCard>
          </Grid>
        </Grid>
      </div>
    )
}

export default ServiceCard