import { Grid, makeStyles} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import ActionModalBar from '../../../../components_v1/Actions/ActionModalBar';
import InputCheckbox from '../../../../components_v1/Forms/InputCheckbox';
import InputSelect from '../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../components_v1/Texts/DisplayText';
import { request_employees, request_update_evaluation } from '../requests';
import { onGetCatalogs, onGetErrorMessage, onGetSelectedFormData, onInitForm } from '../../../../shared/utility';
import { onGetEmployeesCatalogs } from '../../../../shared/utility';

const useStyles = makeStyles((theme) => ({
	form: {
		paddingTop: 32,
		paddingBottom: 32,
	},
}));

const EditEvaluatorModal = ({ open, onClose, content, origin, onSetEvaluation }) => {

	console.log(origin)

	const classes = useStyles();

	const [sending, setSending] = useState(false)
    const [error, setError] = useState(null)

	const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))

	useEffect(() => {
        if(open){
            initModal()
        }else{
            setError(null)
        }
    }, [open])

    const initModal = async () => {
        try {
            //setLoading(true)

            let filter = [{"field":"user_level_id","operator":"=","value":"10"}]
            filter = encodeURIComponent(JSON.stringify(filter))
            const params = `&filter=${filter}`
            const _employees = await request_employees()
            console.log(_employees)

            let _form = onInitForm(formData, origin)
            _form.evaluator_id.options = onGetEmployeesCatalogs(_employees)
            setForm(_form)
        } catch (error) {
            console.log(error)
            setError(onGetErrorMessage(error))
        }
        //setLoading(false)
    }

	const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id;
        temp[id] = { ...data };
        setForm(temp)
    }

	const onSubmit = async() => {
		setSending(true)
        const data2send = onGetSelectedFormData(form, origin)
        console.log(data2send)
        try{
            const new_evaluation = await request_update_evaluation(origin?.id_employee_evaluation, data2send)
            console.log(new_evaluation)
            onSetEvaluation(new_evaluation)
            onClose()
        }catch(e){
            console.log(e)
            setError(onGetErrorMessage(e))
        }
        setSending(false)
	}

	return (
		<SimpleModal open={open} onClose={onClose} maxWidth='sm'>
			<div>
				<DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>
					{content.title}
				</DisplayText>
				<div className={classes.form}>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<InputSelect data={form.evaluator_id} onChange={onChange} />
						</Grid>
						<Grid item xs={12}>
							<ActionModalBar btnLabel={content.button} loading={sending} error={error} onSubmit={onSubmit} />
						</Grid>
					</Grid>
				</div>
			</div>
		</SimpleModal>
	);
};

export default EditEvaluatorModal;

const formData = {
    evaluator_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: true,
        isValid: false,
		options:[],
        config: {
            id: 'evaluator_id',
            type: 'select',
            fullWidth: true,
            label: 'Evaluador',
            placeholder: 'Evaluador'
        },
        rules: {
            type: 'select',
            min: 1,
            max: 1024
        }
    },
    
}
