export const contentData = {
    spanish: {
        title: 'Evaluaciones',
        button: null,
        nodata: 'Sin Registro',
        header: [
            { id: 1, label: 'ID' },
            { id: 3, label: 'Usuario' },
            { id: 4, label: 'Estatus' },
            { id: 5, label: 'Puntaje' },
            { id: 6, label: 'Fecha de evaluación' },
            { id: 7, label: 'Documento' },
        ]
    }
}
