import React, {Component} from 'react'
import { Grid, Typography } from '@material-ui/core'
import InputText from '../../../../components/Inputs/InputText/InputText'
import BasicView from '../../../../components/Layouts/BasicView/BasicView'
import EditCardWrapper from '../../../../components/Cards/EditCardWrapper'
import {formData} from './data'
import {contentData} from './content'
import {grey} from '@material-ui/core/colors';

import {
    request_update_password,
    sleep
} from './requests'
//ocultar area

class PasswordProfile extends Component{

    state = {
        isloading:false,
        error:'',
        formData: JSON.parse(JSON.stringify(formData)),
        modals:{
            imagemodal:{open:false,issending:false,error:null},
            warning_delete_file:{open:false, issending:false, error:null, id:null}
        },
        success:{open:false,timeout:2000,type:'success',message:'Successful action!'},
    }

    componentDidMount(){
        const {language} = this.props;
        const {formData} = this.state;
        const content = contentData[language];
        let temp = {...formData};
        const isValidForm = this.validationForm(temp);
        this.setState({formData:{...temp}, isValid: isValidForm})
    }

    onCloseSnackbar = (id) => {
        let temp = this.state[id];
        temp.open = false;
        this.setState({[id]:{...temp}})
    }

    onActivateSuccessSnack = () => {
        const {success} = this.state;
        let success_temp = {...success};
        success_temp.open = true;
        this.setState({success:{...success_temp}})
    }

    onReturn = () => {
        const {history} = this.props
        history.push('/profile')
    }

    onCloseModal = (type) => {
        this.onEditModal(type, {open:false, issending:false, error:null})
    }

    onEditModal = (section, data) => {
        const {modals} = this.state
        let tempModals = {...modals}
        tempModals[section]={...tempModals[section],...data}
        this.setState({modals:{...tempModals}})
    }

    editPasswordProfile = async() => {
        try {
            const {formData} = this.state
            if(formData.password.isValid){
                let data2Send = {password:formData.password.value}
                let response = await request_update_password(data2Send)
                this.onActivateSuccessSnack()
                await sleep(600)
                this.onReturn();
            }else{
                this.setState({error:'Es necesario llenar el campo.'})
            }
        } catch (error) {
            console.log(error)
        }
    }

    onInputChange = (data) => {
        const {formData} = this.state;
        const id = data.config.id;
        let temp = {...formData};
        temp[id] = {...data};
        const isValidForm = this.validationForm(temp);
        this.setState({formData:{...temp}, isValid: isValidForm})
        if(isValidForm)
            this.setState({error:''})
      }

    validationForm = (data) => {
        let isValid = true;
        Object.keys(data).forEach((item) => {
          if(data[item].isRequired && !data[item].isValid){
            isValid = false;
          }
        })
        return isValid;
    }


    render(){

        const {history, language, catalogs} = this.props;
        const {isloading,error, success, modals} = this.state

        const content = contentData[language]
        const {password} = content.basicForm
        return(
            <BasicView
                history={history}
                content={content.layout}
                returnBtn={true}
                onReturn={this.onReturn}
                success={success}
                onCloseSnackbar={this.onCloseSnackbar}
                isloading={isloading}
            >
                <EditCardWrapper
                    button={content.save_button}
                    onSubmit={this.editPasswordProfile}
                    issending={isloading}
                    error={error}
                >
                    <Grid container alignItems='center' spacing={4}>
                        <Grid item>
                            <Typography style={{fontSize:'0.9em', fontWeight:'600', color: grey[700], padding:'8px 8px'}}>
                                {password.label + (formData.password.isRequired ? '*' : '')}
                            </Typography>
                        </Grid>
                        <Grid item >
                            <InputText
                              data={formData.password}
                              onChange={this.onInputChange}
                            />
                        </Grid>
                    </Grid>
                </EditCardWrapper>
            </BasicView>

        )
    }
}

export default PasswordProfile
