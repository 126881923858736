export const contentData = {
  spanish:{
    nodata:'Sin Registro',
    notable:'No existen socios registrados',
    tableTitle:'Socios',
    header:[
      {text:'ID',align:'inherit'},
      {text:'Nombre',align:'inherit'},
      {text:'RFC',align:'inherit'},
      {text:'Identificación Oficial',align:'center'},
      {text:'Acta de nacimiento',align:'center'},
      {text:'CURP',align:'center'},
      {text:'Forma Migratoria',align:'center'},
      {text:'Comprobante de domicilio',align:'center'},
      {text:'Situación fiscal',align:'center'},//passport
      {text:'Pasaporte',align:'center'},//passport
  
    ],
    modals:{
      warning_delete_item:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      cerl:{
        name:'Certificado eFirma',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
  },
  english:{

  }
}