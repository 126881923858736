import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import {
  Grid,
  Typography,
  Dialog,
  withWidth,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@material-ui/core'
import SimpleLoader from '../../Loaders/SimpleLoader'
import {Title, ErrorMessage} from '../../DisplayData/DisplayData'
import SimpleDropzone from '../../Dropzones/SimpleDropzone'
import  {RoundedButton} from '../../Buttons/GeneralButtons'



const contentData = {
  spanish:{
    button:'Subir',
    cancel:'Cancelar',
    fileDropMessage: 'Arrastre o de clic para subir un archivo',
    subtitle:'Nombre: '
  },
  english:{
    button:'Upload',
    cancel:'Cancel',
    fileDropMessage: 'Drag or clock here to upload a file',
    subtitle:'Filename: '
  }
}

class FileManagerModal extends Component {

  state = {
    current_file: [],
    msg_file: '',
    isValid: false,
  }

  onDropFile = (files) => {
    const msg = files[0].name
    this.setState({current_file: [...files],msg_file: msg,isValid: true})
  }

  onUpdateFile = () => {
    const {data, onSubmit} = this.props
    const {current_file} = this.state
    onSubmit(data.data.path,current_file, data.data)
  }

  render(){
    const {msg_file} = this.state
    const {open, data, onClose, language} = this.props
    const content = contentData[language]
    const {issending, error} = data

    let loaderContent = null;
    if(issending){
      loaderContent = <div><SimpleLoader/></div>
    }
    let errorContent = null;
    if(error){
      errorContent = <ErrorMessage message={error}/>
    }

    let contentFilename = null
    if(msg_file){
      contentFilename = (
        <Grid container spacing={1}>
          <Grid item>
            <Typography variant='subtitle2'>{content.subtitle}</Typography>
          </Grid>
          <Grid item xs>
            <Typography variant='body2'>{msg_file}</Typography>
          </Grid>
        </Grid>
      )
    }

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={'sm'}
      >
        <DialogTitle>
          <Title data={data.data.name}/>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <SimpleDropzone language={language} onDrop={this.onDropFile}/>
            </Grid>
            <Grid item xs={12}>
              {contentFilename}
            </Grid>
            <Grid item xs={12}>
              {errorContent}
            </Grid>
          </Grid>
          
        </DialogContent>
        <DialogActions>
          {loaderContent}
          <RoundedButton
            label={content.cancel}
            size='small'
            color='grey'
            onClick={onClose}
          />
          <RoundedButton
            label={content.button}
            size='small'
            color='secondary'
            onClick={this.onUpdateFile}
          />
        </DialogActions>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withWidth()(FileManagerModal));
