import React, { useState, useEffect } from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { request_bonus, request_employees, request_evaluations } from './requests'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import { onGetErrorMessage, onGetUserID } from '../../../../../shared/utility'
import EvaluationPDF from '../../../../../components_v1/Structure/EvaluationGenericView/components/EvaluationPDF'
import SimpleTable from './components/SimpleTable'
import FilterButton from './components/FilterButton'
import Statistics from './components/Statistics'
import { cleanData2Chart, cleanData2Table } from './customFunctions'
import { contentData } from './content'
import queryString from 'query-string'
import moment from 'moment'
import DateButton from '../../../../../components_v1/Forms/DateButton'
import { pdf } from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import InfoModal from '../../../../Bonus/modals/InfoModal'

const useStyles = makeStyles(theme => ({
    root: {},
    toolbar: {
        marginBottom: 16
    },
}))

const Bonus = (props) => {

    const classes = useStyles()
    const { history, language } = props
    const content = contentData[language]

    const [loading, setLoading] = useState()
    const [sending, setSending] = useState()
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    const [total, setTotal] = useState(0)
    const [tableFilter, setTableFilter] = useState({ limit: 10, offset: 0 })

    const [bonus, setBonus] = useState([])
    const [bonus_selected, setBonuSelected] = useState(null)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(moment(startDate).add(1, 'months'))

    const [info_modal, setInfoModal] = useState(false)

    useEffect(() => {
        const initModule = async () => {
            setLoading(true)
            try {
                const userID = onGetUserID()
                const { limit, offset } = tableFilter
                let filterArgs = [
                    { "field": 'user_id', "operator": "=", "value": userID },
                    { "field": 'bonus_status_id', "operator": "!=", "value": 1 },
                    { "field": 'bonus_status_id', "operator": "!=", "value": 2 },
                ]
                let param2send = {offset: offset * limit, order_by: 'id_bonus', order: 1, limit:limit}

                filterArgs = encodeURIComponent(JSON.stringify(filterArgs))
                const params = `?${queryString.stringify(param2send)}&filter=${filterArgs}`
                const _bonus = await request_bonus(params)
                //console.log(_bonus.boonuses)
                setTotal(_bonus.count)
                setBonus(_bonus.bonuses)
                
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        }

        initModule()
    }, [tableFilter])

    const onSelectedItem = (data) =>{
        setBonuSelected(data)
        setInfoModal(true)
    }


    return (
        <div>
            <InfoModal history={history} open={info_modal} onClose={()=>setInfoModal(false)} bonus={bonus_selected} content={content.modal} />
            <LoadingContainer
                error={error}
                onCloseError={() => setError(null)}
                loading={loading}
                sending={sending}
                success={success}
                onCloseSuccess={() => setSuccess(null)}
            >
                <div style={{ paddingTop: 32 }}>
                    <SimpleTable
                        header={content.header}
                        data={bonus}
                        tableFilter={tableFilter}
                        total={total}
                        loading={loading}
                        //origin_data={evaluations}
                        onSelectedItem={onSelectedItem}
                        onUpdateTableFilter={(data) => setTableFilter(data)}
                    />
                </div>
            </LoadingContainer>
        </div>
    );
}

export default Bonus