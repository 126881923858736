import React, { useState } from 'react'
import { Grid, Icon, IconButton, makeStyles, Popover } from '@material-ui/core'
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { grey } from '@material-ui/core/colors'
import cx from 'classnames'


const options = [
    { value: 10, label: 10 },
    { value: 25, label: 25 },
    { value: 50, label: 50 },
    { value: 100, label: 100 },
]

const useStyles = makeStyles(theme => ({
    iconButton: {
        background: 'white',
        padding: 6
    },
    button: {
        background: 'white',
        borderRadius: 8,
        padding: '0px 8px',
        paddingLeft: 16,
        '&:hover': {
            cursor: 'pointer'
        }
    },
    popper: {
        marginTop: 8,
        padding: '2px 0px',
        borderRadius: 12,
        boxSizing: 'border-box',
        overflow: 'hidden'
    },
    container: {
        maxHeight: 250,
        //background:'red',
        overflowX: 'hidden',
        overflowY: 'auto'
    },
    item: {
        fontFamily: theme.typography.fontFamily,
        padding: "4px 16px",
        borderTop: `1px solid ${grey[200]}`,
        '&:hover': {
            cursor: 'pointer',
            background: grey[100]
        }
    },
    selected: {
        fontWeight: 500
    }
}))

const ActionBar = props => {

    const classes = useStyles()
    const { total, tableFilter, onChange, onUpdateTableFilter } = props
    const { limit, offset } = tableFilter
    const [anchorEl, setAnchorEl] = useState(null)

    const onInnerChange = (id) => {
        setAnchorEl(null)
        let temp = { ...tableFilter }
        temp.offset = 0
        temp.limit = id
        onUpdateTableFilter(temp)

    }

    const onDecrement = () => {
        let temp = { ...tableFilter }
        if (offset > 0) {
            temp.offset = temp.offset - 1
            onUpdateTableFilter(temp)
        }
    }

    const onIncrement = () => {
        let temp = { ...tableFilter }
        if (offset < Math.ceil(total / limit) - 1) {
            temp.offset = temp.offset + 1
            onUpdateTableFilter(temp)
        }
    }


    return (
        <div>
            <Grid container spacing={2} alignItems='center' justify='flex-end'>
                <Grid item>
                    <DisplayText>No. de filas</DisplayText>
                </Grid>
                <Grid item>
                    <div>
                        <Popover classes={{ paper: classes.popper }} open={Boolean(anchorEl)} anchorEl={anchorEl} onClose={(e) => setAnchorEl(null)} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }} transformOrigin={{ vertical: 'top', horizontal: 'left' }} >
                            <div className={classes.container} >
                                {options.map((item, key) => {
                                    return (
                                        <div key={key.toString()} className={cx({
                                            [classes.item]: true,
                                            [classes.selected]: limit === item.value
                                        })} onClick={() => onInnerChange(item.value)}>
                                            {item.label}
                                        </div>
                                    )
                                })}
                            </div>
                        </Popover>
                        <div className={classes.button} onClick={(event) => setAnchorEl(event.currentTarget)}>
                            <Grid container spacing={1} alignItems='center'>
                                <Grid item>
                                    <DisplayText>{limit}</DisplayText>
                                </Grid>
                                <Grid item>
                                    <Icon>keyboard_arrow_down</Icon>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <DisplayText>{`${limit * offset + 1} - ${limit * offset + limit}  de ${total}`}</DisplayText>
                </Grid>
                <Grid item>
                    <IconButton className={classes.iconButton} size='small' onClick={onDecrement}><Icon>keyboard_arrow_left</Icon></IconButton>
                </Grid>
                <Grid item>
                    <DisplayText>{offset + 1}</DisplayText>
                </Grid>
                <Grid item>
                    <IconButton className={classes.iconButton} size='small' onClick={onIncrement}><Icon>keyboard_arrow_right</Icon></IconButton>
                </Grid>
            </Grid>
        </div>
    )
}

export default ActionBar