export const contentData = {
    spanish:{
      layout:{
        title:'Declaración de Riesgo Anual',
        navigation:[
          {name:'Clientes',path:'/clients'},
          {name:'Detalles',path:'/clients/details/'},
          {name:'Negocios',path:'/clients/details/:id/businesses'},
          {name:'Detalles',path:'/clients/details/:id/businesses/:idbusiness'},
          {name:'Servicio',path:'/clients/details/:id/businesses/:idbusiness/service/:idservice'},
        ],
        menu:[
          {id:0, label:'General'},
          {id:1, label:'Usuarios IDSE'},
          {id:2, label:'Usuarios Infonavit'},
          {id:3, label:'Registro Patronal'},
          {id:4, label:'Registro Estatal'},
          {id:5, label:'Logs'}
        ]
      },
      modals:{
        warning_delete_file:{
          title:'¿Estás seguro?',
          message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
          cancelButton:'Cancelar',
          deleteButton:'Eliminar'
        },
        edit_service:{
          title:'Editar servicio',
          button:'Guardar',
          cancelButton:'Cancelar'
        }
      },
      basicform:{
        title:'Información Básica',
        button:'Editar',
        nodata:'Sin Registro',
        data:{
          rfc:{
            label:'',
            data:''
          },
          responsible:{
              label:'',
              data:''
          }
        },
        content:{
          rfc:{
            label:'RFC'
          },
          responsible:{
              label:'Responsable'
          }
        },
        documents:{
          title:'Documentos',
          header:{title:'Archivos', actions:'Acciones'},
          files:null
        }
      }
    }
  }