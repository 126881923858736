import React, {Component} from 'react'
import { 
  withStyles,
} from '@material-ui/core/styles'
import {   
  Dialog, 
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle} from '@material-ui/core'
import {RoundedButton} from '../Buttons/GeneralButtons'
import {ErrorMessage, Title} from '../DisplayData/DisplayData'
import SimpleLoader from '../Loaders/SimpleLoader'
import {grey, red} from '@material-ui/core/colors'

const styles = (theme) => ({
  container:{
    padding:'0px 0px',
    maxWidth:'600px',
    boxSizing:'border-box'
  },
  icon:{
    color:red[700],
    fontSize:54
  },
  title:{
    color:grey[900],
    fontWeight:'500'
  },
  message:{
    color:grey[700],
    fontWeight:'400'
  }
})

class SimpleModal extends Component {
  render(){

    const {classes, onClose, content, onClick, maxWidth, width} = this.props

    const {open, issending, error} = this.props.data

    let messageContent = null
    if(issending){
      messageContent = <SimpleLoader />
    }else if(error){
      messageContent = <ErrorMessage message={error}/>
    }

    return(
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={maxWidth ? maxWidth : 'md'}
      >
        <DialogTitle>
          <Title data={content.title}/>
        </DialogTitle>
        <DialogContent>
          <div className={classes.container} style={{width : width ? width : null}}>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={12}>
                {this.props.children}
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='center'>
                  <Grid item>
                    {messageContent}
                  </Grid>
                </Grid>
                
              </Grid>
            </Grid>
          </div>
          
        </DialogContent>
        <DialogActions>
          <RoundedButton
            size='small'
            label={content.cancelButton}
            color='transparent'
            onClick={onClose}
          />
          <RoundedButton
            size='small'
            label={content.button}
            color='secondary'
            onClick={onClick}
          />
        </DialogActions>
        


      </Dialog>
    )
  }
}

export default withStyles(styles)(SimpleModal)

