import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onInitForm, onSetErrorsToForm } from '../../../../../../../shared/utility';
import ItemSelection from '../../../../../../../components/Inputs/ItemSelection/ItemSelection';
import {contentData} from './benefitsdata/content'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const BenefitsModal = props => {

    const classes = useStyles()
    const { open, sending, onClose, catalogs, origin_data, history, language } = props
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [benefits, setBenefits] = useState([])
    const content = contentData[language]

    useEffect(() => {
        if (!open) {
            setLoading(true)
            setBenefits([...origin_data])
            setError('')
            setLoading(false)
        }
    }, [open, origin_data])

    const onChange = (id, status) => {
        setLoading(true)
        let _benefits = [...benefits]
        let key = _benefits.findIndex(e => e.benefit_id === id)
        _benefits[key].status = status
        setBenefits([..._benefits])
        setLoading(false)
    }

    const onSubmit = async () => {
        setLoading(true)
        props.onSubmit({benefits:[...benefits]})        
        setLoading(false)
    }
    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        {benefits.map((item,key)=>{
                            return(
                                <Grid item xs={12} md={6} key={key.toString()}>
                                <ItemSelection
                                    label={item.benefit}
                                    status={item.status}
                                    id={item.benefit_id}
                                    onChange={onChange}
                                />
                                </Grid>
                            )
                        })}
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={sending} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default BenefitsModal;