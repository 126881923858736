export const contentData = {
    spanish: {
        button: 'Continuar',
        back: 'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        declarations: [
            {
                id: 'surcharges_and_updates',
                label: 'Recargos y Actualizaciones',
            },
            {
                id: 'payment_total',
                label: 'TOTAL A PAGAR',
            },
        ],
        declarations_date: {
            id: 'payment_limit_date',
            label: 'Fecha límite de Pago'
        }
    }
}
