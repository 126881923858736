export const updateForm = (data, user, content) => {
  let form = {}
  Object.keys(data).forEach((item)=> {
    let value = null
    if(user){
      value = user[item]
      if(value){
        // Put some conditions
        if(item === 'mobile'){
          let aux =  user.phone_code ? user.phone_code : ''
          value = aux + ' ' + value 
        }
        
      }
      
    }
    const aux = {...data[item], ...content[item], data:value}
    form = {...form, [item]:{...aux}}
  })
  return form
}