import {mainServer} from '../../../../../variables/config'
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  return response.data.data.tributary_mailbox_statuses;
}

export const request_business = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/business/listall");
  return response.data.data.businesses;
}

export const request_service = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  let service = response.data.data.tributary_mailbox_statuses;
  const {legal_representative_id} = service
  response = await mainServer.instance.get("/business/"+legal_representative_id);
  let legalRep = response.data.data.business
  Object.assign(service,legalRep)
  return service;
}

export const request_updateService = async(id,legalRepID,data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/businessdocument/"+id.toString(),
  {legal_representative_id:data.legal_representative_id});
  await mainServer.instance.patch("/business/"+legalRepID, data);

  let response;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  let service = response.data.data.tributary_mailbox_statuses;
  response = await mainServer.instance.get("/business/"+legalRepID);
  let legalRep = response.data.data.business
  Object.assign(service,legalRep)
  return service;
}

export const request_uploadFile = async(id, legalRepID, path,file, ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  const url = 'businessdocument/' + legalRepID + '/' + path
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  let service = response.data.data.tributary_mailbox_statuses;
  response = await mainServer.instance.get("/business/"+legalRepID);
  let legalRep = response.data.data.business
  Object.assign(service,legalRep)
  return service;
}

export const request_downloadFile = async(legalRepID,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'businessdocument/' + legalRepID + '/' + path
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data, path)
}

export const request_deleteFile = async(id,legalRepID,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response
  const url = 'businessdocument/' + legalRepID + '/' + path
  await mainServer.instance.delete(url)
  response = await mainServer.instance.get("/businessdocument/"+id.toString());
  let service = response.data.data.tributary_mailbox_statuses;
  response = await mainServer.instance.get("/business/"+legalRepID);
  let legalRep = response.data.data.business
  Object.assign(service,legalRep)
  return service;
}