export const formData = {
  presentation_limit_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'presentation_limit_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  work_sheet:{
    key:'work_sheet',
    config:{
      type:'file'
    }
  },
  process_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'process_status_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  isr:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'isr',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  isr_retentions:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'isr_retentions',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  iva:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'iva',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  iva_retentions:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'iva_retentions',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  other:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'other',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  surcharges_and_updates:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'surcharges_and_updates',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  total_to_pay:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'total_to_pay',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  payment_limit_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'payment_limit_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  receipt:{
    key:'receipt',
    config:{
      type:'file'
    }
  },
  capture_line:{
    key:'capture_line',
    config:{
      type:'file'
    }
  },
  client_response:{
    key:'client_response',
    config:{
      type:'file'
    }
  },
  client_comments:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'client_comments',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  diot_folio:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'diot_folio',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  diot_file:{
    key:'diot_file',
    config:{
      type:'file'
    }
  },
  diot_presentation_limit_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'diot_presentation_limit_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  diot_responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'diot_responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  diot_receipt:{
    key:'diot_receipt',
    config:{
      type:'file'
    }
  },
  tax_mailbox:{
    key:'tax_mailbox',
    config:{
      type:'file'
    }
  },
  compliance_opinion:{
    key:'compliance_opinion',
    config:{
      type:'file'
    }
  },
  compliance_opinion_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'compliance_opinion_status_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  other_documents:{
    key:'other_documents',
    config:{
      type:'file'
    }
  },
  payment_date_mailbox:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'payment_date_mailbox',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  mailbox_status_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'mailbox_status_id',
      type:'simple_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  mailbox_comment:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'mailbox_comment',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'none',
    }
  },
  mailbox_receipt:{
    key:'mailbox_receipt',
    config:{
      type:'file'
    }
  },
  responsible_id:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    options:[],
    config:{
      id:'responsible_id',
      type:'avatar_select',
      fullWidth: true,
    },
    rules:{
      type:'none',
    }
  },
  notes:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'notes',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  extra_hours:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_hours',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  hours_proof:{
    key:'hours_proof',
    config:{
      type:'file'
    }
  },
  extra_charges:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'extra_charges',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  charge_proof:{
    key:'charge_proof',
    config:{
      type:'file'
    }
  },
  collections_charge:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'collections_charge',
      type:'number',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  completition_date:{
    value: null,
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'completition_date',
      type:'date',
      fullWidth: true,
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
  ciec_key:{
    value:'',
    error: false,
    isVisited: false,
    isRequired: false,
    isValid: false,
    config:{
      id:'ciec_key',
      type:'text',
      fullWidth: true
    },
    rules:{
      type:'distance',
      min:1,
      max:100
    }
  },
}
