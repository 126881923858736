export const contentData = {
  spanish:{
    layout:{
      title:'SATIC',
      navigation:[
          {name:'Tablas de Control',path:'/controltables'},
          {name:'Detalles',path:'/controltables/details/:id'},
      ],
      menu:null
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      edit_service:{
        title:'Editar servicio',
        button:'Guardar',
        cancelButton:'Cancelar'
      }
    },
    basicform:{
      title:'Información',
      button:null,
      nodata:'Sin Registro',
      headers:{
        fixedHeaderA: ['Nombre','RFC','Estatus','Clasificación','Regimen Fiscal', 'Registro Patronal'],
        fixedHeaderB: null,
        varHeader: ['Acuse','Estatus del Proceso','Fecha de Entrega','Responsable','Notas',
        'Horas Extra', 'Comprobante Horas', 'Cargos Extra', 'Comprobante Cargo', 
        'Cargo a Cobranza', 'Fecha de Completado'],
        extra: 'Completado'
      },

    }
  }
}
