import {mainServer} from '../../../variables/config'

export const request_employees = async () => {
  const logData = JSON.parse(localStorage.getItem('data'))
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response;
    response = await mainServer.instance.get("/user/employee/list");
    return response.data.data.employees
}

export const request_evaluations = async(params) => {
    const logData = JSON.parse(localStorage.getItem('data'));
    mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
    let response;
    response = await mainServer.instance.get(`/employeevaluation/all${params}` );
    return response.data.data
}

export const request_evaluations_general = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/general${params}` );
  return response.data.data.employee_evaluations
}

export const request_evaluations_general_history = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/generalhistory${params}` );
  return response.data.data.general_statistics
}

export const request_evaluations_areas = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/area${params}` );
  return response.data.data.area_statistics
}

export const request_evaluations_areas_history = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/areahistory${params}` );
  return response.data.data.area_statistics
}

export const request_evaluations_level = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/level${params}` );
  return response.data.data.level_statistics
}

export const request_evaluations_level_history = async(params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/employeevaluation/statistics/levelhistory${params}` );
  return response.data.data.level_statistics
}