import { catalogs } from "../../../texts/esp/catalogs";

export const formData = {
    process_status_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_process_statuses], 
        formType:'select',
        config:{id:'process_status_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    declaration_period_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_declaration_period], 
        formType:'select',
        config:{id:'declaration_period_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    declaration_type_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[...catalogs.ctrl_declaration_type], 
        formType:'select',
        config:{id:'declaration_type_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    accumulated_income:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'accumulated_income', type:'number', placeholder:'Ingrese un valor', helperText:'Valor no válido'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    deductions:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'deductions', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    fiscal_utility:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'fiscal_utility', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_to_pay:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_to_pay', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    total_to_pay:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'total_to_pay', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    accumulated_loses:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'accumulated_loses', type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    exercise_loses:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'exercise_loses',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    isr_in_favor:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'isr_in_favor',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999}
    },
    operation_number:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'operation_number',type:'number', placeholder:'Ingrese un valor', helperText:'El mínimo valor aceptable es 0'},
        rules:{type:'numeric', min:0, max:999999999999}
    },
    presentation_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'presentation_date',type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    delivery_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'delivery_date',type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    responsible_id:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        options:[],
        formType:'select',
        config:{id:'responsible_id', type:'select', placeholder:'Seleccione una opcion', helperText:'El campo no es válido'},
        rules:{type:'none'}
    },
    notes:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'notes', type:'text', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completition_date:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'normal',
        config:{id:'completition_date', type:'date', placeholder:'Seleccione una fecha', helperText:'La fecha no es válida'},
        rules:{type:'none'}
    },
    completed:{
        value:null,
        isRequired:false,
        isValid:false,
        isVisited:false,
        formType:'checkbox',
        config:{id:'completed', type:'checkbox'},
        rules:{type:'none'}
    },

}