import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import BasicView from '../../../components_v1/Structure/Layouts/BasicView'
import ToolbarTable from './components/ToolbarTable'
import Records from './views/Records/Records'
import { contentData } from './content'
import General from './views/General/General'
import Area from './views/Area/Area'
import Level from './views/Level/Level'

const EvaluationsW = props => {

    const { history, language, catalogs, notifications, permissions, permissionsType, match } = props
    const [view, setView] = useState(1)
    const content = contentData[language]
    const pending = notifications && notifications.pending_personal_evaluations ? notifications.pending_personal_evaluations : 0
    let contentView = null

    const onChangeView = (id) => {
        const urlwithargs = `?view=${id}`
        window.history.replaceState(null, null, urlwithargs);
        setView(id)
    }

    switch (view) {
        case 1:
            contentView = <Records history={history} language={language} match={match} permissions={permissions} permissionsType={permissionsType} />
            break;
        case 2:
            contentView = <General history={history} language={language} match={match} permissions={permissions} permissionsType={permissionsType} />
            break;
        case 3:
            contentView = <Area history={history} language={language} match={match} permissions={permissions} permissionsType={permissionsType} />
            break;
        case 4:
            contentView = <Level history={history} language={language} match={match} permissions={permissions} permissionsType={permissionsType} />
            break;
        default:
            break;
    }

    const menuOptions = [
        { id: 1, label: 'Registros' },
        { id: 2, label: 'General' },
        { id: 3, label: 'Área' },
        { id: 4, label: 'Puesto' },
    ]

    return (
        <BasicView
            history={history}
            title={content.title}
            onChange={onChangeView}
            setReturn
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ToolbarTable selected={view} menuOptions={menuOptions} onChange={onChangeView} />
                </Grid>
                <Grid item xs={12}>
                    {contentView}
                </Grid>
            </Grid>
        </BasicView>
    )
}

export default EvaluationsW