import React from 'react'
import BasicView from '../../components_v1/Structure/Layouts/BasicView';
import { Avatar, Badge, Grid, Typography, makeStyles } from '@material-ui/core';
import { contentData } from './content'
import { AvatarGroup } from '@material-ui/lab';
import DisplayText from '../../components_v1/Texts/DisplayText';
import DisplayConsultor from './components/DisplayConsultor';
import DisplayBirthday from './components/DisplayBirthday';
import birthday_icon from '../../assets/icons/birthday_icon.svg'
import star_badge from '../../assets/icons/star_badge.svg'
import { pink } from '@material-ui/core/colors';
import Carrousel from './components/Carrousel';
import moment from 'moment';
import useHome from './useHome';
import AvatarPicture from '../../components_v1/Structure/DisplayData/AvatarPicture';
import { connect } from 'react-redux'
import AddBannerModal from './modals/AddBannerModal';
import EditBannerModal from './modals/EditBannerModal';
import Carrousel2 from './components/Carrousel2';

const styles = makeStyles(theme => ({
    right_section:{
        background:'white',
        position:'absolute',
        top:0,
        paddingTop:80,
        right:0,
        width:300,
        //height:'100%',
        padding:32,
        textAlign:'center',
        bottom:0,
        overflowY:'scroll'
    },
    birthday_container:{
        paddingTop:40,
        borderTop:'1px solid #CECECE',
        marginTop:40
    },
    hastag:{
        color:theme.palette.secondary.main
    },
    main_avatar:{
        height:58,
        width:58
    },
    avatar_group:{
        '& .MuiAvatarGroup-avatar':{
            border:'none'
        }
    },
    start_avatar:{
        height:22,
        width:22
    },
    badge:{
        position:'absolute',
        bottom:-6,
        left:'50%',
        transform:'translateX(-50%)',
        width:16,
        height:16
    },
    main_badge:{
        position:'absolute',
        bottom:-10,
        left:'50%',
        transform:'translateX(-50%)',
        width:22,
        height:22
    },
    left_side:{
        padding:'20px 40px',
        width:'calc(100% - 450px)',
        //background:'red'
    },
    date_container:{
        background:theme.palette.primary.main, 
        borderRadius:20,
        padding:'20px 40px',
        color:'white',
        maxWidth:700,
        margin:'auto',
        
        //display:'inline-block',
        
    },
    vertical_line:{

    }
}))

const Home = ({ history, language, permissions }) => {

    //console.log(permissions)

    const content = contentData[language]

    const classes = styles()

    const current_date = moment().format('ll')
    //const current_time = moment().format('LT a')
    
    const { view_data, modals, actions} = useHome({})

    return (
        <BasicView title={content.title}>
            <AddBannerModal open={modals.add_banner} onClose={()=>actions.onChangeModalStatus('add_banner', false)} onUpdateCompleted={actions.onUpdateBanners} />
            <EditBannerModal open={modals.edit_banner} onClose={()=>actions.onChangeModalStatus('edit_banner', false)} onUpdateCompleted={actions.onUpdateBanners} 
             origin={view_data.banner_selected} />
            <div>
                <div className={classes.left_side}>
                    <div className={classes.date_container}>
                        <Grid container justify='center' >
                            <Grid item>
                                <div style={{borderRight:'0.6px solid #767E89', paddingRight:40, marginRight:40}}>
                                    <Typography variant='h5'>{current_date}</Typography>
                                </div>
                            </Grid>
                            <Grid item><Typography variant='h5'>{view_data.current_time}</Typography></Grid>
                        </Grid>
                    </div>
                    <div style={{marginTop:40}} >
                        <Carrousel2 banners={view_data.banners} permissions={permissions} actions={actions} />
                    </div>
                </div>
                <div className={classes.right_section}>
                    {view_data.star_employees && view_data.star_employees.length > 0 ? 
                    <div>
                        <DisplayText variant='subtitle1' color='primary'style={{marginBottom:20}} >{content.best_evaluation}</DisplayText>
                        <AvatarGroup style={{alignItems:'end', justifyContent:'center'}} className={classes.avatar_group} >
                            {view_data.star_employees && view_data.star_employees.length > 2 ?
                            <div style={{position:'relative'}} >
                                <AvatarPicture size={40} imgSrc={view_data.star_employees[2].evaluated_image}  />
                                <div className={classes.badge}>
                                    <img src={star_badge} width='100%' height='100%' />
                                </div>
                            </div> : null}
                            <div style={{position:'relative', zIndex:3}} >
                                <AvatarPicture imgSrc={view_data.star_employees[0].evaluated_image} size={58}  />
                                <div className={classes.main_badge}>
                                    <img src={star_badge} width='100%' height='100%' />
                                </div>
                            </div>
                            {view_data.star_employees && view_data.star_employees.length > 1 ?
                            <div style={{position:'relative'}} >
                                <AvatarPicture size={40} imgSrc={view_data.star_employees[1].evaluated_image} />
                                <div className={classes.badge}>
                                    <img src={star_badge} width='100%' height='100%' />
                                </div>
                            </div> : null}
                        </AvatarGroup>
                        <Typography style={{margin:'24px 0px'}} variant='subtitle1' color='primary' >{content.congrats}</Typography>
                        <Grid container direction='column'  spacing={2}  >
                            {/* <Grid item><DisplayConsultor place='1' name='Kennet' /></Grid>
                            <Grid item><DisplayConsultor place='2' name='Kennet' /></Grid>
                            <Grid item><DisplayConsultor place='3' name='Kennet' /></Grid> */}
                            {view_data.star_employees && view_data.star_employees.length > 0 ? 
                                view_data.star_employees.map((item, ind) => {
                                    return(
                                        <Grid item xs={12}><DisplayConsultor place={ind+1} {...item} /></Grid>
                                    )
                                }) : null
                                }
                        </Grid>
                    </div> :  null}
                    <div className={classes.birthday_container}>
                        <Typography color='primary' variant='subtitle1' >La <span className={classes.hastag} >#FamiliaASCG</span> les desea un</Typography>
                        <Typography color='primary' variant='h6' style={{marginBottom:8}} >{content.happy_birthday}</Typography>
                        <img alt='' src={birthday_icon} />
                        <Grid container   spacing={2} style={{marginTop:20}} >
                            {view_data.users_bd && view_data.users_bd.length > 0 ? 
                            view_data.users_bd.map((item, ind) => {
                                return(
                                    <Grid item xs={12}><DisplayBirthday {...item} /></Grid>
                                )
                            }) : null
                            }
                        </Grid>
                        <Typography color='secondary' variant='subtitle1' style={{letterSpacing:'0.3rem', marginTop:32}}>{content.bless}</Typography>
                    </div>
                </div>
            </div>
        </BasicView>
    )
}

const mapStateToProps = (state) => {
    return {
        permissions: state.permissions
    }
}
//export default Home
export default connect(mapStateToProps)(Home)