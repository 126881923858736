import { catalogs } from "../../../texts/esp/catalogs";

export const content = {
    header:[
        {id:0,label:'',hidden:false, fixed:true, width:26, position:0, padding:'checkbox'},
        {id:1,key:'fixed_business_name',label:'Razón Social',hidden:false, width:300, fixed:true, add:{icon:'add'}, position:34},
        {id:2,key:'fixed_rfc',label:'RFC',hidden:false},
        {id:3,key:'fixed_client_classification',label:'Clasificación',hidden:false},
        {id:4,key:'fixed_client_status_id',label:'Estatus',hidden:false},
        {id:5,key:'fixed_client_responsible',label:'Asesor',hidden:false},
        {id:6,key:'fixed_workers_limit',label:'No. de Trabajores',hidden:false},
        {id:7,key:'fixed_payroll_responsible',label:'Responsable Nómina',hidden:false},
        {id:8,key:'employee_number',label:'No. de trabajadores',hidden:false},
        {id:9,key:'payment_date',label:'Fecha de pago',hidden:false},
        {id:10,key:'stamping_date',label:'Fecha de timbrado',hidden:false},
        {id:11,label:'Adjuntar documento',hidden:false},
        {id:12,key:'responsible',label:'Responsable',hidden:false},
        {id:13,key:'notes',label:'Notas',hidden:false},
        {id:14,key:'completition_date',label:'Fecha de completado',hidden:false},
        {id:15,label:'Completado',hidden:false},
    ],
    dataConfig:[
        {id:'fixed_business_name',type:'text',fixed:true, editBtn:true, position:34},
        {id:'fixed_rfc',type:'text'},
        {id:'fixed_client_classification',type:'text'},
        {id:'fixed_client_status_id',type:'status', options: [...catalogs.client_statuses] },
        {id:'fixed_client_responsible',type:'text'},
        {id:'fixed_workers_limit',type:'text'},
        {id:'fixed_payroll_responsible',type:'text'},
        {id:'employee_number', type:'text',editable:true},
        {id:'payment_date',type:'date',editable:true},
        {id:'stamping_date',type:'date',editable:true},
        {id:'document', value:null, type:'document', status:false},
        {id:'responsible', formId:'responsible_id',type:'text',editable:true},
        {id:'notes',type:'text',editable:true},
        {id:'completition_date',type:'date', variant:'date',editable:true},
        {id:'completed',type:'checkbox',editable:true},
    ]
}