export const contentData = {
  spanish:{
    layout:{
      title:'Información del prospecto',
      navigation:[
        {name:'Prospectos',path:'/prospects'},
        {name:'Detalles',path:'/prospects/details'},
      ]
    },
    modals:{
      warning_delete_file:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      },
      turn_client_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    }
  }
}