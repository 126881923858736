import { Grid, makeStyles, Switch } from '@material-ui/core';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import ActionModalBar from '../../../../../../components_v1/Actions/ActionModalBar';
import InputFile from '../../../../../../components_v1/Forms/InputFile';
import InputRating from '../../../../../../components_v1/Forms/InputRating';
import InputSelect from '../../../../../../components_v1/Forms/InputSelect';
import InputText from '../../../../../../components_v1/Forms/InputText';
import SimpleModal from '../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onGetFormData, onSetErrorsToForm } from '../../../../../../shared/utility';
import { request_add_user, request_employees } from '../../requests';
import {formData, permissions_benefits} from './userdata/data'
import {contentData} from './userdata/content'
import {catalog_matrix} from './userdata/catalog_matrix'
import {onInitEmptyForm} from '../../../../../../shared/utility'

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const AddUserModal = (props) => {

    const classes = useStyles()
    const { open, onClose, history, catalogs, language } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState('')
    const content = contentData[language]

    useEffect(() => {
        if (!open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitEmptyForm(_form, content.form)
            // Update catalogs
            catalog_matrix.forEach(item => {
                console.log(item.form_key)
                _form[item.form_key].options = catalogs[item.catalog_key]
            })
            setForm({..._form})
            setError(''); setLoading(false);
        }
    }, [open])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetFormData(form)
        delete data2send.passwordConfirm
        data2send = {...data2send, ...permissions_benefits}
        //Send data
        setLoading(true)
        
        try {
            let user_id = await request_add_user(data2send);
            onClose()
            history.push(`/users/details/${user_id}`)

        } catch (error) {
            console.log(error)
            setError('Hubo un error')
            setLoading(false)
        }

    }

    form.passwordConfirm.value2 = form.password.value

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <InputText data={form.username} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.password} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <InputText data={form.passwordConfirm} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.first_name} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.last_name} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.user_level_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.timezone} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputSelect data={form.employee_status_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.work_email} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <InputText data={form.entry_day} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default AddUserModal