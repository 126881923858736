export const formData = {
  first_name:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'first_name',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
  },
  last_name:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'last_name',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
  },
  phone_number:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'phone_number',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
  },
  work_email:{
      value:'',
      error: false,
      isVisited: false,
      isRequired: true,
      isValid: false,
      config:{
        id:'work_email',
        type:'text',
        fullWidth: true
      },
      rules:{
        type:'distance',
        min:1,
        max:100
      }
  }
}