import {green} from '@material-ui/core/colors';
const styles = theme => ({
  root:{
    padding:`${theme.spacing.unit}*2 0px`,
  },
  iconContainer:{
    background:theme.palette.primary.main,
    padding:theme.spacing.unit*2,
    borderRadius:theme.spacing.unit,
  },
  icon:{
    color:'white',
    fontSize:'32px'
  },
  cardContainer:{
    padding:theme.spacing.unit*2,
    borderLeft:'5px solid',
    borderRadius:'2px',
    borderColor: theme.palette.secondary.main,
  },
  dropzone:{
    width : '100%',
    border : '2px dashed black',
    borderRadius: '10px'
  },
  docTitle:{
    fontWeight:'600'
  },
  docContainer:{
    margin:'0px 0'
  },
  uploadButton:{
    backgroundColor:green[700],
    fontSize:'14px',
    borderRadius:'32px',
    color:'white',
    '&:hover':{
      backgroundColor:green[900],
    }
  },
  goodIcon:{
    color:'green'
  },
  wrongIcon:{
    color:'red'
  }

})

export default styles;
