import React, {Component} from 'react';
import { withStyles } from '@material-ui/core/styles';
import { grey, blue, red } from '@material-ui/core/colors';

import { Icon, Typography, Grid, IconButton} from '@material-ui/core';

const header_styles = (theme) => ({
  container:{
    padding:'8px'
  },
  text:{
    color: grey[500],
    fontWeight:'500'
  },
  textaction:{
    color: grey[500],
    fontWeight:'500',
  }
})

class HeaderTableW extends Component {

  render(){

    const {classes, data} = this.props;

    return(
      <div className={classes.container}>
        <Grid container>
          <Grid item xs><Typography variant='body2' className={classes.text}>{data.title}</Typography></Grid>
          <Grid item ><Typography variant='body2' className={classes.textaction}>{data.actions}</Typography></Grid>
        </Grid>
      </div>
    )
  }
}

const HeaderTable = withStyles(header_styles)(HeaderTableW);

const DocumentItem = (props) => {

  const {disabled, onDelete, data} = props;
  
  const styles = {
    card:{borderRadius:'16px'},
    container:{padding:'8px', paddingLeft:'8px', borderTop:`1px solid ${grey[200]}`},
    folder:{color:blue[700]},
    name:{color:grey[700], fontWeight:'500'}
  }

  const delete_color = disabled ? grey[400] : red[700]

  return(
      <div style={styles.container}>
        <Grid container wrap='nowrap' alignItems='center' spacing={1}>
          <Grid item>
            <Icon style={styles.folder}>business</Icon>
          </Grid>
          <Grid item xs>
            <Typography variant='body2' style={styles.name}>{data.name}</Typography>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton disabled={disabled} onClick={() => onDelete(data)}>
                  <Icon fontSize='small' style={{color:delete_color}}>close</Icon>
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
          
        </Grid>
      </div>
  )
}

const contentData = {
  spanish:{
    header:{
      title:'Negocios',
      actions:'Acciones'
    }
  },
  english:{
    header:{
      title:'Businesses',
      actions:'Actions'
    }
  }
}

class BusinessTable extends Component {
  render(){

    const {businesses, onDelete, language} = this.props
    let filesView = null;

    const content = contentData[language]

    if(businesses && businesses.length > 0){
      filesView = businesses.map((item,key) => {
        return(
          <Grid item xs={12} key={key.toString()}>
            <DocumentItem 
              data={item}
              onDelete={onDelete}
            />
          </Grid>
        )
      })
    }


    return(
      <div>
        <Grid container>
          <Grid item xs={12}>
            <HeaderTable data={content.header}/>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {filesView}
            </Grid>
          </Grid>
        </Grid>
        

      </div>
    )
  }
}

export default BusinessTable;