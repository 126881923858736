import React from 'react'
import { Card, Grid, Hidden, Icon, makeStyles } from '@material-ui/core'
import DisplayText from '../../../../../components_v1/Texts/DisplayText'
import { StepIcon } from '../../../../../components_v1/Texts/StepperStatus'
import InputSelectStatus from '../../../../../components_v1/Forms/InputSelectStatus'
import LoadingContainer from '../../../../../components_v1/Structure/Layouts/LoadingContainer'
import TopBanner from '../TopBanner'
import useStepperContainer from './useStepperContainer'
import moment from 'moment'
import { monthsName } from '../../../../../shared/utility'

const styles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
        borderRadius: 12,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
    },
    dateCard:{
        padding:'8px 16px',
        borderRadius:12,
        boxShadow: '0 10px 24px 0 rgba(82, 91, 115, .12)',
        fontWeight:500,
        fontFamily:theme.typography.fontFamily
    },
    topBanner: {
        marginBottom: 32
    },
    sidebar: {
        width: 'min-content',
        color: 'white',
        backgroundColor: theme.palette.primary.main,
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    connector: {
        width: 1,
        height: 24,
        display: 'block',
        margin: 'auto',
        borderLeft: 'solid 1px #bdbdbd'
    },
    content: {
        width: '100%',
    },
    hide: {
        display: 'none'
    },
    fixedTitle: {
        padding: 32,
        [theme.breakpoints.down('sm')]: {
            padding: 16,
        }
    },
    title: {
        color: theme.palette.primary.dark,
        fontWeight: 500
    },
    bannerHeader: {
        height: 33,
        display: 'flex',
        color: 'white',
        backgroundColor: theme.palette.primary.dark,
    },
    bannerBody: {
        //color: 'white',
        backgroundColor: '#EDF2F8',
    },
    selectStatus: {
        background: 'white'
    },
    contentComponent: {
        paddingTop: 32,
        paddingBottom: 64,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 16,
            paddingBottom: 32,
        }
    },
}))

const StepperContainer = ({ user, id, fixedID, url, content, steps, language, paths }) => {

    const classes = styles()
    const { system, view_data, actions } = useStepperContainer({ id, fixedID, url: `proc_${url}`, content, steps, paths })
    const { process, showCIEC, stepData, permission, ctrlID, form, stepID, step } = view_data

    const desktopView = (
        <Hidden smDown>
            <Grid container justify='center'>
                
                <Grid item xs={12}>
                    <div className={classes.bannerHeader}>
                        <Grid container justify='center' alignItems='center'>
                            <Grid item xs={3}>
                                <DisplayText align='center'>
                                    {content.progress}
                                </DisplayText>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <DisplayText align='center'>
                                    {content.priority}
                                </DisplayText>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bannerBody}>
                        <Grid container justify='center'>
                            <Grid item xs={3}>
                                <InputSelectStatus
                                    className={classes.selectStatus}
                                    color='primary'
                                    data={form.solution_progress_id}
                                    onChange={actions.onChange}
                                    disabled={!permission}
                                />
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <InputSelectStatus
                                    className={classes.selectStatus}
                                    color='primary'
                                    data={form.solution_priority_id}
                                    onChange={actions.onChange}
                                    disabled={!permission}
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </Hidden>
    )

    const mobileView = (
        <Hidden mdUp>
            <Grid container justify='center' direction='column'>
                <Grid item xs={12}>
                    <div className={classes.bannerHeader}>
                        <DisplayText align='center'>
                            {content.progress}
                        </DisplayText>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bannerBody}>
                        <InputSelectStatus
                            className={classes.selectStatus}
                            color='primary'
                            data={form.solution_progress_id}
                            onChange={actions.onChange}
                            disabled={!permission || step === content.steps.length}
                        />
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bannerHeader}>
                        <DisplayText align='center'>
                            {content.priority}
                        </DisplayText>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.bannerBody}>
                        <InputSelectStatus
                            className={classes.selectStatus}
                            color='primary'
                            data={form.solution_priority_id}
                            onChange={actions.onChange}
                            disabled={!permission || step === content.steps.length}
                        />
                    </div>
                </Grid>
            </Grid>
        </Hidden>
    )

    let date_label = ''
    if(process){
        const temp = moment(process.auto_date)
        if(temp.isValid()){
            date_label = `${monthsName [temp.month()]}, ${temp.year()}`
        }
    }

    return (
        <div>
            <div className={classes.topBanner}>
                <TopBanner data={process} path={url} content={content.top_banner} step={stepID} />
            </div>
            <Grid container style={{marginBottom:16}} justify='flex-end'>
                <Grid item >
                    <Card className={classes.dateCard} >{date_label}</Card>
                </Grid>
            </Grid>
            <Card className={classes.root}>
                <div className={classes.sidebar}>
                    <Grid container justify='center' direction='row'>
                        {content.steps.map((c, id) => {
                            return (
                                <Grid item xs={12}>
                                    <div>
                                        <StepIcon id={id + 1} color={step === id + 1 ? 'secondary' : (stepID >= id + 1 ? 'primary' : 'grey')} onClick={id < stepID && (() => actions.onUpdateStep(id + 1))} />
                                        {content.steps.length - 1 !== id && <div className={classes.connector}></div>}
                                    </div>
                                </Grid>
                            )
                        })}
                    </Grid>
                </div>
                <LoadingContainer loading={system.loading} sending={system.sending} success={system.success} error={system.error} onCloseError={actions.onCloseError} onCloseSuccess={actions.onCloseSuccess}>
                    <div className={classes.content}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <div className={classes.fixedTitle}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            <Grid container>
                                                <Grid item xs></Grid>
                                                <Grid item>
                                                    {permission ?
                                                        <Icon style={{ color: 'green' }}>lock_open</Icon>
                                                        :
                                                        <Icon style={{ color: 'red' }}>lock</Icon>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DisplayText align='center' variant='h5' className={classes.title}>
                                                {stepData && stepData.title}
                                            </DisplayText>
                                        </Grid>
                                    </Grid>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                                {step !== content.steps.length &&
                                    <div className={classes.banner}>
                                        {desktopView}
                                        {mobileView}
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                <div className={classes.contentComponent}>
                                    {stepData && React.cloneElement(stepData.component, { id, ctrlID, process, onUpdate: actions.onUpdate, onUpdateStep: actions.onUpdateStep, language, permission, onDownloadDocument: actions.onDownloadDocument, showCIEC })}
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </LoadingContainer>
            </Card >
        </div>
    )
}

export default StepperContainer
