import {mainServer} from '../../../../../variables/config.jsx'
import FileSaver from 'file-saver'
import axios from 'axios'

export const request_efirmaactions_list = async (params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get(`/certificateaction/list?language_id=2${params}`)
  return response.data.data;
}

export const request_update_business_documents = async(id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/businessdocument/${id}`, data);
}


/**************************************** */

export const request_service = async (id, params) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get(`/certificate/${id.toString()}/list`)
  return response.data.data
}

export const request_service_item = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/certificate/"+id.toString())
  return response.data.data.certificate;
}


export const request_add_service = async (data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/efirma",data);
}

export const request_edit_service = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch(`/efirma/${id}`, data);
}

export const request_delete_service = async (efirmaID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/efirma/" + efirmaID);
}

/**************************************** */

export const request_uploadFile = async(id,file,ext,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'efirma/' + id + '/'+ path
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.put(response.data.data.path, file, options);
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  response = await mainServer.instance.get("/efirma/"+id.toString())
  return response.data.data.efirmas
}

export const request_downloadFile = async(id, path) => {
  
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'efirma/' + id + '/'+path;
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'})
  FileSaver.saveAs(response.data)
}

export const request_upload_document = async(id, type, file, data, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  if(mode === 'SERVICE'){
    response = await mainServer.instance.post(`/efirma/${id}/${type}`, data)
  }else{
    response = await mainServer.instance.post(`/businessdocument/${id}/${type}`, data)
  }
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}


export const request_download_document = async(id, path, filename, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  if(mode === 'SERVICE'){
    response = await mainServer.instance.get(`/efirma/${id}/${path}`)
  }else{
    response = await mainServer.instance.get(`/businessdocument/${id}/${path}`)
  }
  //let response = await mainServer.instance.get(`/efirma/${id}/${path}`)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, `${filename}`);
} 


export const request_delete_document = async(id, type, mode) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  if(mode === 'SERVICE'){
    await mainServer.instance.delete(`/efirma/${id}/${type}`)
  }else{
    await mainServer.instance.delete(`/businessdocument/${id}/${type}`)
  }
}