import React, { useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import ActionModalBar from '../../../../../../../components_v1/Actions/ActionModalBar';
import InputText from '../../../../../../../components_v1/Forms/InputText';
import InputSelect from '../../../../../../../components_v1/Forms/InputSelect';
import SimpleModal from '../../../../../../../components_v1/Structure/Modals/SimpleModal';
import DisplayText from '../../../../../../../components_v1/Texts/DisplayText';
import { isFormValid, onCheckDateForm, onGetFormData, onInitForm, onSetErrorsToForm } from '../../../../../../../shared/utility';
import {catalog_matrix} from './servicedata/catalog_matrix'
import {formData} from './servicedata/data'
import {contentData} from './servicedata/content'

const prepareData = (users) => {
    let usersData = [];
  
    if(users){
      if(users[0].id_business){
        usersData = users.map((item) => {
          return{
            value: item.id_business,
            label: item.name,
            image: item.image
          }
        })
      }else{
        usersData = users.map((item) => {
          let firstname = item.first_name ? item.first_name : '';
          let lastname = item.last_name ? item.last_name : ''; 
          return{
            value: item.id_user,
            label: firstname + ' ' + lastname,
            image: item.image
          }
        })
      }
    }
  
  
    return usersData;
}

const useStyles = makeStyles(theme => ({
    form:{
        paddingTop:32,
        paddingBottom:32
    }
}))

const EditServiceModal = props => {

    const classes = useStyles()
    const { open, onClose, users, origin_data, language } = props
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const content = contentData[language]

    useEffect(() => {
        if (!open) {
            let _form = JSON.parse(JSON.stringify(formData))
            _form = onInitForm(_form, origin_data, content.form)
            if(catalog_matrix && users && users !== []){
                catalog_matrix.forEach(item => {
                    const aux_catalog = prepareData(users)
                    _form[item.form_key].options = aux_catalog
                })
            }
            _form.antiquity = onCheckDateForm(_form.antiquity)
            setForm({..._form})            
            setError('')
            setLoading(false)
        }
    }, [open, origin_data,users])

    const onChange = (data) => {
        let temp = { ...form }
        const id = data.config.id
        temp[id] = { ...data }
        setForm(temp)
    }

    const onSubmit = async () => {
        const errors = isFormValid(form)
        /// Check if the form is valid
        if(errors && errors.length){
            const new_form = onSetErrorsToForm(form, errors)
            setForm(new_form)
            return
        }
        
        //Extract data
        let data2send = onGetFormData(form)
        //Send data
        setLoading(true)
        props.onSubmit(data2send)
        setLoading(false)
    }

    return ( 
        <SimpleModal open={open} onClose={onClose} maxWidth='md'>
            <div>
                <DisplayText variant='h6' color='primary' style={{ fontWeight: 500 }}>{content.title}</DisplayText>
                <div className={classes.form}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} >
                            <InputSelect data={form.legal_representative_id} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.antiquity} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <InputText data={form.rfc} onChange={onChange}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ActionModalBar btnLabel='Guardar' loading={loading} error={error} onSubmit={onSubmit}/>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </SimpleModal>
     );
}
 
export default EditServiceModal