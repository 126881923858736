import React, { useEffect } from 'react'
import { useState } from 'react'
import { catalogs } from '../../../../../texts/esp/catalogs'
import { isFormValid, onGetErrorMessage, onGetFormDataProcess, onGetUserID, onInitFormProcess, onSetErrorsToForm } from '../../../../../shared/utility'
import { request_download_document, request_get_user_info, request_process, request_update_process, request_update_process_step } from '../../requests'
import moment from 'moment'

const useStepperContainer = ({ id, fixedID, url, content, steps, paths }) => {

    //PROCESS STATE
    const [loading, setLoading] = useState(false)
    const [sending, setSending] = useState(false)
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)

    //DATA
    const [showCIEC, setShowCIEC] = useState(null)
    const [process, setProcess] = useState(null)
    const [stepData, setStepData] = useState(null)
    const [permission, setPermission] = useState(false)
    const [ctrlID, setCtrlID] = useState(null)
    const [form, setForm] = useState(JSON.parse(JSON.stringify(formData)))
    const [stepID, setStepID] = useState(1) //Step from DB
    const [step, setStep] = useState(1) //Current step selected
    const [area, setArea] = useState(false)

    useEffect(() => {
        actions.initModule()
    }, [])

    const actions = {
        initModule: async () => {
            try {
                setLoading(true)
                let _process = await request_process(id, url, `?step_id=${stepID}`)
                setProcess(_process)
                let _step = _process[`fixed_${fixedID}`]
                setStep(_step)
                setStepID(_step)
                const userID = onGetUserID()
                setShowCIEC(userID === _process.fixed_responsible_id)

                setPermission(_process.process_semaphore_id === 1)
                if (url === 'proc_integral_solution')
                    _process = await request_process(id, url, `?step_id=${_step}`)
                setCtrlID(_process[paths.primary.id])
                if (paths && steps && steps.includes(_step))
                    setCtrlID(_process[paths.secondary.id])
                let _form = JSON.parse(JSON.stringify(formData))
                const _altStep = content.steps[_step - 1].altStep ? content.steps[_step - 1].altStep : _step
                _form = onInitFormProcess(_form, _process, _altStep)
                setForm(_form)
                const _stepData = content.steps[_step - 1]
                setStepData(_stepData)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onCloseError: () => setError(null),
        onCloseSuccess: () => setSuccess(false),
        onUpdateStep: async (newID, changeStep) => {
            try {
                let _process = { ...process }

                if (changeStep) {
                    console.log('CHANGE STEP BACK')
                    let data = {}
                    const maxStep = content.steps.length
                    let params = `?step_id=${newID}`
                    if(fixedID !== 'proc_stampings_step_id')
                        data[`fixed_proc_${fixedID}`] = newID > maxStep ? maxStep : newID
                    else
                        data[`fixed_${fixedID}`] = newID > maxStep ? maxStep : newID
                    await request_update_process_step(id, url, params, data)
                    setStepID(newID)
                }

                if (url === 'proc_integral_solution') {
                    _process = await request_process(id, url, `?step_id=${newID}`)
                    setProcess(_process)
                }

                let _form = JSON.parse(JSON.stringify(formData))
                const _altStep = content.steps[newID - 1].altStep ? content.steps[newID - 1].altStep : newID
                _form = onInitFormProcess(_form, _process, _altStep)
                setForm(_form)

                setCtrlID(_process[paths.primary.id])
                if (steps && steps.includes(newID))
                    setCtrlID(_process[paths.secondary.id])
                const _stepData = content.steps[newID - 1]
                setStep(newID)
                setStepData(_stepData)
                setPermission(_process.process_semaphore_id === 1 && (changeStep || newID === stepID))
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setLoading(false)
        },
        onUpdate: async (_data, changeStep, newStep, responsible_banner) => {
            try {
                const errors = isFormValid(form)
                /// Check if the form is valid
                if (errors && errors.length) {
                    const new_form = onSetErrorsToForm(form, errors)
                    setForm(new_form)
                    return
                }

                setSending(true)
                const maxStep = content.steps.length
                const _step = content.steps[step - 1].altStep ? content.steps[step - 1].altStep : step
                //Extract data
                let data2send = _data ? onGetFormDataProcess(_data, _step) : onGetFormDataProcess(form, _step)
                const { data, ctrlData } = data2send
                if (changeStep) {
                    const { responsible_id, completed_date, ctrlpath } = responsible_banner
                    if(fixedID !== 'proc_stampings_step_id')
                        data[`fixed_proc_${fixedID}`] = newStep > maxStep ? maxStep : newStep
                    else
                        data[`fixed_${fixedID}`] = newStep > maxStep ? maxStep : newStep
                    if (newStep > maxStep) data[`completed_${maxStep}`] = 1
                    if (ctrlpath) {
                        ctrlData[`${responsible_id}`] = onGetUserID()
                        ctrlData[`${completed_date}`] = moment().local().format()
                    } else {
                        data[`${responsible_id}_${_step}`] = onGetUserID()
                        data[`${completed_date}_${_step}`] = moment().local().format()
                    }
                }

                let params = `?step_id=${step}`
                let path = paths.primary.path

                if (steps && steps.includes(step)) path = paths.secondary.path
                const _process = await request_update_process(id, ctrlID, url, params, path, data, ctrlData);

                if (changeStep && newStep < maxStep + 1) {
                    setStep(newStep)
                    setStepID(newStep)
                    setCtrlID(_process[paths.primary.id])
                    if (steps && steps.includes(newStep))
                        setCtrlID(_process[paths.secondary.id])
                    const _stepData = content.steps[newStep - 1]
                    setStepData(_stepData)
                    
                    let _form = JSON.parse(JSON.stringify(formData))
                    const _altStep = _stepData.altStep ? _stepData.altStep : newStep
                    _form = onInitFormProcess(_form, _process, _altStep)
                    setForm(_form)
                }

                setProcess(_process)
                setPermission(_process.process_semaphore_id === 1)
                setSending(false)
                setSuccess(true)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
            setSending(false)
        },
        onChange: (data) => {
            let temp = { ...form }
            const id = data.config.id
            temp[id] = { ...data }
            setForm(temp)
            actions.onUpdate(temp)
        },
        onDownloadDocument: async (data, realStep) => {
            try {
                let _step = realStep ? realStep : step
                let _path = data.path.replaceAll('_', '')
                let _data = { params: { document: `${_step}_${data.path}` } }
                if (data.ctrlpath) _data = { params: { document: data.path, path: _path } }
                let path = paths.primary.path
                if (steps && steps.includes(_step)) path = paths.secondary.path
                await request_download_document(id, ctrlID, url, path, _data, data.ctrlpath)
            } catch (error) {
                console.log(error)
                setError(onGetErrorMessage(error))
            }
        },
    }

    const system = { loading, sending, success, error }
    const view_data = { process, showCIEC, stepData, permission, ctrlID, form, stepID, step }

    return { system, view_data, actions }
}

const formData = {
    solution_progress_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_progress_status],
        config: {
            id: 'solution_progress_id',
            type: 'simple_select',
            fullWidth: true,
            color: null
        },
        rules: {
            type: 'none',
        }
    },
    solution_priority_id: {
        value: null,
        error: false,
        isVisited: false,
        isRequired: false,
        isValid: false,
        options: [...catalogs.process_priority],
        config: {
            id: 'solution_priority_id',
            type: 'simple_select',
            fullWidth: true,
            color: null
        },
        rules: {
            type: 'none',
        }
    },
}

export default useStepperContainer
