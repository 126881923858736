import { Avatar, Grid } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton';
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard';
import VerticalData from '../../../../../../components_v1/Structure/DisplayData/VerticalData';
import { onGetTimeDuration } from '../../../../../../shared/utility';
import business_logo from '../../../../../../assets/business2.png';
import StatusData from '../../../../../../components_v1/Structure/DisplayData/StatusData';
import { nodata } from '../../../../../../texts/esp/general';
import { red } from '@material-ui/core/colors';
import AvatarPicture from '../../../../../../components_v1/Structure/DisplayData/AvatarPicture';
import { PERMISSION_TYPES } from '../../../../../../variables/config';
const BusinessInfoForm = ({data, legal_representative, onEdit, onChangePicture, permission}) => {


    let quotedHours = '0 h'
    let restricted
    if(data){
        quotedHours = onGetTimeDuration(data.monthly_quoted_time)
        restricted = data.restricted ? 'Negocio restringido' : null
    } 


    let can_edit = true
    let can_create = true
    let has_full_access = true
    if(permission){
        can_edit = permission.permission_type_id > PERMISSION_TYPES.READ
        can_create = permission.permission_type_id > PERMISSION_TYPES.EDIT
        has_full_access = permission.permission_type_id > PERMISSION_TYPES.CREATE
    }


    return ( 
        <SimpleCard>
            <Grid container>
                <Grid item xs={12} md={6}>
                    <Grid container alignItems='center' direction='column' spacing={2}>
                        <Grid item>
                            <AvatarPicture can_edit={can_edit} nopicture={business_logo} onChangePicture={onChangePicture} imgSrc={data ? data.image : null}/>
                        </Grid>
                        {restricted ? <Grid item><StatusData color={red[700]} data={restricted}/></Grid> : null}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Razón Social' data={data ? data.name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='RFC' data={data ? data.rfc : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Régimen fiscal' data={data ? data.business_fiscal_regime : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Horas cotizadas' data={quotedHours}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Representante legal' data={legal_representative ? legal_representative.name : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Actividades' data={data ? data.activity_text : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Subactividad' data={data ? data.subactivity_text : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Detalles de subactividad' data={data ? data.sub_activity : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Detalles de Sector de actividad económica' data={data ? data.economic_activity_sector : null}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <VerticalData title='Asesor' data={data ? data.advisor : null}/>
                        </Grid>
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}

                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default BusinessInfoForm;