import {mainServer} from '../../../../../variables/config.jsx';
import FileSaver from 'file-saver';
import axios from 'axios'

export const request_business = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/business/"+id)
  return response.data.data.business
}

export const request_businesses = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/business/listbyclient/"+id);
  return response.data.data.businesses;
}

export const request_business_documents = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token
  let response
  response = await mainServer.instance.get("/businessdocument/"+id)
  return response.data.data.tributary_mailbox_statuses;
}

export const request_employers = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/employerregistration/list/"+id.toString());
  return response.data.data.employer_registrations;
}

export const request_accounts = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/billingaccount/list/"+id.toString());
  return response.data.data.billing_accounts;
}

export const request_stamps = async (id) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/employerregistration/list/"+id.toString());
  return response.data.data.employer_registrations;
}

export const request_add_employer_registration = async (id, data) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.post("/employerregistration",data);
  let response;
  response = await mainServer.instance.get("/employerregistration/list/"+id.toString());
  return response.data.data.employer_registrations;
}

export const request_edit_employer = async (id, data, accountID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.patch("/employerregistration/" + accountID, data);
  let response;
  response = await mainServer.instance.get("/employerregistration/list/"+id.toString());
  return response.data.data.employer_registrations;
}

export const request_delete_billing_stamp = async (id, stampID) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  await mainServer.instance.delete("/employerregistration/" + stampID);
  let response;
  response = await mainServer.instance.get("/employerregistration/list/"+id.toString());
  return response.data.data.employer_registrations;
}

/**************************************** */
export const request_users = async () => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  response = await mainServer.instance.get("/user/employee/list/");
  return response.data.data.employees;
}

export const request_uploadFile = async(id, path,file, ext) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  const url = 'businessdocument/' + id + '/' + path
  let response = await mainServer.instance.post(url, ext)
  const options = {headers: {'Content-Type': file.type}}
  delete axios.defaults.headers.common["Authorization"]
  await axios.put(response.data.data.path, file, options);
}

export const request_downloadFile = async(id,path) => {
  const logData = JSON.parse(localStorage.getItem('data'));
  mainServer.instance.defaults.headers.common['Authorization'] = logData.token;
  let response;
  const url = 'businessdocument/' + id + '/' + path;
  response = await mainServer.instance.get(url)
  delete axios.defaults.headers.common["Authorization"]
  response = await axios.get(response.data.data.path, {responseType: 'blob'});
  FileSaver.saveAs(response.data, path);
}