import { useRef, useState } from "react"


const useSearchBar = ({ onUpdateTableFilter, tableFilter, onChange}) => {


    const inputRef = useRef()
    const timerRef = useRef()

    const actions = {
        onStartCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
            timerRef.current = setTimeout(actions.onRequestSearch, 300);
        },
        onStopCountDown: () => {
            if(timerRef.current) clearTimeout(timerRef.current)
        },
        onRequestSearch: async() => {       
            if(timerRef.current) clearTimeout(timerRef.current)
            let temp = {...tableFilter}
            temp.offset = 0
            onUpdateTableFilter(temp)            
        },
        onUpdateSearchValue: (val) => {
            onChange(val.currentTarget.value)
        },

    }

    return {inputRef, actions}
}

export default useSearchBar