import React, { useState, useEffect } from 'react';
import SimpleCard from '../../../../../components_v1/Structure/Cards/SimpleCard';
import logo from '../../../../../assets/timereport.png'
import { Grid, makeStyles } from '@material-ui/core';
import { nodata } from '../../../../../texts/esp/general';
import DisplayText from '../../../../../components_v1/Texts/DisplayText';
import HorizontalData from '../../../../../components_v1/Structure/DisplayData/HorizontalData';
import { onGetDateFormat, onGetUserID } from '../../../../../shared/utility';
import HTimeReportStateBtn from '../../../../../components_v1/Actions/HTimeReportStateBtn';


const useStyles = makeStyles(theme => ({
    container:{paddingTop:16}
}))

const OverviewTimeReport = (props) => {

    const classes = useStyles()
    const {data, onChangeState, isSupervisor} = props


    return ( 
        <SimpleCard>
            <Grid container justify='center' spacing={1}>
                <Grid item>
                    <img src={logo} alt='' width={100} />
                </Grid>
                <Grid item xs={12}>
                    <DisplayText variant='subtitle1' align='center'>{data ? data.service : nodata}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.container}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <HorizontalData title='Negocio' data={data ? data.business : nodata}/>
                            </Grid>
                            <Grid item xs={12}>
                                <HorizontalData title='Creado por' data={data ? data.user : nodata}/>
                            </Grid>
                            <Grid item xs={12}>
                                <HorizontalData title='Fecha' data={onGetDateFormat(data ? data.date : null, 'DD MMM YY')}/>
                            </Grid>
                            <Grid item xs={12}>
                                <HTimeReportStateBtn title='Estatus' statusID={data ? data.state_id : null} disabled={!isSupervisor}
                                onChange={onChangeState}/>
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </SimpleCard>
     );
}
 
export default OverviewTimeReport;