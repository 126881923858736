import React from 'react'
import { Grid } from '@material-ui/core'
import RoundedButton from '../../../../../../components_v1/Actions/RoundedButton'
import SimpleCard from '../../../../../../components_v1/Structure/Cards/SimpleCard'
import DisplayText from '../../../../../../components_v1/Texts/DisplayText'
import { PERMISSION_TYPES } from '../../../../../../variables/config';
import BenefitItem from '../../../../../../components_v1/Structure/DisplayData/BenefitItem'

const BenefitContent = ({title, benefits, onEdit, permission}) => {
    let can_edit = true
    let can_create = true
    let has_full_access = true
    
    if(permission){
        can_edit = permission > PERMISSION_TYPES.READ
        can_create = permission > PERMISSION_TYPES.EDIT
        has_full_access = permission > PERMISSION_TYPES.CREATE
    }

    let contentView = null 
    
    if(benefits)
        contentView = benefits.map((item,key)=> {
            return(
              <Grid item xs={12} key={key.toString()}>
                <BenefitItem data={item.benefit} status={item.status}/>
              </Grid>
            )
        })

    return(
        <SimpleCard>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <DisplayText color='primary' variant='subtitle1' >{title}</DisplayText>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={3}>
                        {contentView}
                        {can_edit ? <Grid item xs={12}>
                            <Grid container justify='flex-end'>
                                <Grid item>
                                    <RoundedButton onClick={onEdit}>Editar</RoundedButton>
                                </Grid>
                            </Grid>
                        </Grid> : null}
                    </Grid>
                </Grid>
            </Grid>
        </SimpleCard>
    )
}

export default BenefitContent