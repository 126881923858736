export const contentData = {
  spanish:{
    layout:{
      title:'Usuarios del cliente',
      navigation:[
        {name:'Clientes',path:'/clients'},
        {name:'Detalles',path:'/clients/details/:id'},
        {name:'Usuarios',path:'/clients/details/:id/clientusers'},
      ],
    },
    modals:{
      warning_modal:{
        title:'¿Estás seguro?',
        message:'¿Realmente quieres eliminar este registro? Este proceso no puede ser revertido',
        cancelButton:'Cancelar',
        deleteButton:'Eliminar'
      }
    },
    nodata:'Sin Registro',
    header:['ID','Nombre', 'Usuario','Correo','Nivel','Acciones'],
    tableTitle:'Lista de clientes'
  }
}