export const contentData = {
    spanish: {
        title: 'Declaraciones',
        button: 'Continuar',
        back:'Regresar',
        responsible_banner: {
            responsible: 'responsible',
            responsible_id: 'responsible_id',
            completed_date: 'completed_date'
        },
        declarations: [
            [
                {
                    id: 'isr_salaries_withholdings',
                    label: 'Ret. ISR Sueldos y Salarios'
                },
                {
                    id: 'isr_assimilated_fees_withholdings',
                    label: 'Ret. ISR Honorarios Asimilados'
                },
                {
                    id: 'isr_professional_services_withholdings',
                    label: 'Ret. ISR Servicios Profesionales'
                },
                {
                    id: 'isr_leasing_withholdings',
                    label: 'Ret. ISR Arrendamiento'
                },
                {
                    id: 'other_income_isr_withholdings',
                    label: 'Otras Retenciones de ISR'
                },
                {
                    id: 'total_to_pay',
                    label: 'TOTAL A PAGAR',
                    dark: true
                },
            ],
            [
                {
                    id: 'vat_collected',
                    label: 'IVA Cobrado'
                },
                {
                    id: 'creditable_vat',
                    label: 'IVA Acreditable'
                },
            ],
            [
                {
                    id: 'ieps_charged',
                    label: 'IEPS Cobrado'
                },
                {
                    id: 'ieps_creditable',
                    label: 'IEPS Acreditable'
                },
            ]
        ],
        declarations_date:{
            id:'payment_limit_date',
            label:'Fecha límite de Pago'
        }
    }
}
