import moment from 'moment'

export const cleanData2Table = (prevData, currentData, noData) =>{
    let temp = {
        id: currentData.id_ctrl_outsourcing,
        completed: currentData.completed ? true : false,
        files:[],
        data:[]
    }

    let fixedDataA = [
        currentData.businesses_name ? currentData.businesses_name : noData,
        currentData.rfc ? currentData.rfc : noData,
        currentData.client_status ? currentData.client_status : noData,
        currentData.client_classification ? currentData.client_classification : noData,
        currentData.fiscal_regime ? currentData.fiscal_regime : noData
    ];

    let fixedDataB = [
        currentData.outsourcing_name ? currentData.outsourcing_name : noData,
        currentData.outsourcing_responsible ? currentData.outsourcing_responsible : noData,
        currentData.outsourcing_periodicity ? currentData.outsourcing_periodicity : noData, 
        {key:'outsourcing_sdi_roster'},
        currentData.outsourcing_net_salary ? currentData.outsourcing_net_salary : noData,
        currentData.outsourcing_substitute_boss ? currentData.outsourcing_substitute_boss : noData,
        {key:'outsourcing_clabe'},
        {key:'outsourcing_bank'},
        currentData.beneficiary ? currentData.beneficiary : noData,
        currentData.other_benefits ? currentData.other_benefits : noData
    ]

    let varData = [
        {key:'bill'},
        currentData.payment_limit_date ? moment(currentData.payment_limit_date).format('DD-MM-YYYY') : noData,
        {key:'imss_bill'},
        currentData.payment_date ? moment(currentData.payment_date).format('DD-MM-YYYY') : noData,
        {key:'payment_receipt'},
        currentData.responsible ? currentData.responsible : noData,
        currentData.notes ? currentData.notes : noData,
        currentData.extra_hours ? currentData.extra_hours : noData,
        {key:'hours_proof'},
        currentData.extra_charges ? currentData.extra_charges : noData,
        {key:'charge_proof'},
        currentData.collections_charge ? currentData.collections_charge : noData,
        currentData.completition_date ? moment(currentData.completition_date).format('DD-MM-YYYY') : noData,
    ]

    let origin_data = {
        payment_limit_date: currentData.payment_limit_date,
        payment_date: currentData.payment_date,
        responsible_id: currentData.responsible_id,
        notes : currentData.notes,
        extra_hours : currentData.extra_hours,
        extra_charges : currentData.extra_charges,
        collections_charge : currentData.collections_charge,
        completition_date : currentData.completition_date
    }

    let tempA = {...temp}
    tempA.data = [...fixedDataA]
    prevData.fixedDataA.push(tempA);

    let tempB = {...temp}
    tempB.data = [...fixedDataB]
    tempB.files = [
        {id:currentData.id_ctrl_outsourcing,key:'outsourcing_sdi_roster',name:'Timbre de Nómina',path:'outsourcingsdiroster',
            status: checkStatus(currentData, 'outsourcing_sdi_roster')},
        {id:currentData.id_ctrl_outsourcing,key:'outsourcing_clabe',name:'CLABE Interbancaria',path:'outsourcingclabe',
            status:checkStatus(currentData, 'outsourcing_clabe')},
        {id:currentData.id_ctrl_outsourcing,key:'outsourcing_bank',name:'Banco',path:'outsourcingbank',
            status:checkStatus(currentData, 'outsourcing_bank')},
    ]
    prevData.fixedDataB.push(tempB);

    let tempVar = {...temp}
    tempVar.data = [...varData]
    tempVar.files = [
        {id:currentData.id_ctrl_outsourcing,key:'bill',name:'Factura',path:'bill',
            status: checkStatus(currentData, 'bill')},
        {id:currentData.id_ctrl_outsourcing,key:'imss_bill',name:'Factura IMSS', path:'imss_bill', 
            status:checkStatus(currentData, 'imss_bill')},
        {id:currentData.id_ctrl_outsourcing,key:'payment_receipt',name:'Recibo de Pago',path:'paymentreceipt',
            status: checkStatus(currentData, 'payment_receipt')},
        {id:currentData.id_ctrl_outsourcing,key:'hours_proof',name:'Comprobante de horas',path:'hoursproof',
            status: checkStatus(currentData, 'hours_proof')},
        {id:currentData.id_ctrl_outsourcing,key:'charge_proof',name:'Tabla SAT',path:'chargeproof',
            status: checkStatus(currentData, 'charge_proof')}
    ]
    tempVar.origin_data = {...origin_data}
    
    prevData.varData.push(tempVar);
    return prevData;
}

const checkStatus = (item, key) => {
    return item[key] !== "0" && item[key] !== 0 && item[key] !== ""
}
