export const contentData = {
    spanish:{
      nodata:'Sin Registro',
      error:'Sin registro',
      button:'Guardar',
      cancelButton:'Cancelar',
      image_button:'Cambiar',
      form:{
        process_status_id:{
          placeholder:'Estatus'
        },
        declaration_period_id:{
          placeholder:'Periodo'
        },
        declaration_type_id:{
          placeholder:'Tipo'
        },
        accumulated_income:{
          placeholder:'Ingresos Acumulables'
        },
        deductions:{
          placeholder:'Deducciones'
        },
        fiscal_utility:{
          placeholder:'Utilidad Físcal/Pérdida'
        },
        isr_to_pay:{
          placeholder:'ISR a Pagar'
        },
        total_to_pay:{
          placeholder:'Total a Pagar'
        },
        accumulated_loses:{
          placeholder:'Pérdidas Acumuladas'
        },
        exercise_loses:{
          placeholder:'Pérdida del Ejercicio'
        },
        isr_in_favor:{
          placeholder:'ISR a Favor'
        },
        operation_number:{
          placeholder:'Número de Operación'
        },
        presentation_date:{
          placeholder:'Fecha de Presentación'
        },
        delivery_date:{
          placeholder:'Fecha de Entrega'
        },
        responsible_id:{
          placeholder:'Responsable'
        },
        notes:{
          placeholder:'Notas'
        },
        extra_hours:{
          placeholder:'Horas Extra'
        },
        extra_charges:{
          placeholder:'Cargos Extra'
        },
        collections_charge:{
          placeholder:'Cargo a Cobranza'
        },
        return_date:{
          placeholder:'Fecha de Devolución'
        },
        return_comment:{
          placeholder:'Comentario de Devolución'
        },
        completition_date:{
          placeholder:'Fecha de Completado'
        }
      }
    }
  }
