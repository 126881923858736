import React, {Component} from 'react'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles.jsx'
import { Switch, Route, Redirect } from "react-router-dom"
import SideBar from '../../components/SideBar/SideBar.jsx'
import TopBar from '../../components/TopBar/TopBar.jsx'
import dashboardRoutes from '../../routes/dashboard'
import { withRouter } from 'react-router-dom'
import {IconButton, withWidth} from '@material-ui/core';
import cx from  'classnames'
import {catalogs as catalogContent}  from '../../variables/catalogs'
import {permissionsType}  from '../../variables/permissions'
import moment from 'moment-timezone'
import {
  request_reauth, 
  request_get_user_info, 
  request_notifications,
  request_permissions, request_areas} from './request'
import {contentData} from './content'
import { request_user_areas } from '../../views/Employee/Users/EditUser/requests.jsx'
import { actionTypes } from '../../redux/actions.jsx'
import { connect } from 'react-redux'
import AIChatButton from '../../components_v1/aichat/AIChatButton.js'

class Dashboard extends Component{

  state={
    isDrawerOpen: true,
    notifications:{},
    permissions:[],
    dashboard:[],
    sidebar_routes:[],
    isreauth:false,
    section: "HOME",
    user: null,
    language:'spanish',
    modal:{open:true, issending:false,error:null}
  }

  componentDidMount(){
    //catalog2Text()
    let temp = localStorage.getItem('data')
    if(temp){
      this.initModule()
    }else{
      this.props.history.push('/signin')
    }
  }

  initModule = async() => {
    const {onUpdateUser, onUpdatePermissions, onUpdateNotifications} = this.props
    let evaluator_permission = {
      permission_module_id: 0,
      permission_module:'Evaluaciones',
      permission_type_id : 1
    }
    try {
      this.setState({isloading:true})
      let _user = await request_get_user_info()
      this.setState({user:{..._user}})
      onUpdateUser(_user) // Update variable in redux
      evaluator_permission.permission_type_id = _user.evaluator === 1 ? 5 : 1
      let response = await request_permissions()
      
      onUpdatePermissions([evaluator_permission,...response]) // Update variable in redux
      const permissions = this.cleanPermissions([evaluator_permission, ...response])
      this.setState({permissions:[...permissions]})
      let _notifications = await request_notifications()
      onUpdateNotifications({..._notifications}) // Update variable in redux
      this.setState({notifications:{..._notifications}})
      this.updateDashboardRoutes()
      this.setState({isloading:false})
    } catch (error) {
      console.log(error)
      this.setState({isloading:false})
    }
  }

  updateDashboardRoutes = () => {
    const {permissions} = this.state
    const dashboard = {...dashboardRoutes}

    let _routes = []
    dashboard.sections.forEach(item => {
      if(item.sidebar){
        
        let _permission = permissions.find(el => el.id_permission_module ===item.permission_id)
        if(!_permission){
          _routes.push(item)
          return
        } 
        if(_permission.id_permission_type >= permissionsType.READ) _routes.push(item) 
      }
    })

    if(_routes.length === 0) _routes.push(dashboard.sections[0])

    this.setState({sidebar_routes:_routes})
  }

  mouseMoveEvent = async(e) => {
    try {
      const {isreauth} = this.state;
      const data = JSON.parse(localStorage.getItem('data'))
      if(data && !isreauth){
        const {history} = this.props;
        let expDate = new Date(data.exp*1000)
        let currentDate = new Date()
        if(currentDate.getTime() < expDate.getTime()){ // valid token
          if((currentDate.getTime() + 15*60*1000) > expDate.getTime()){ // expiration date soon
            this.setState({isreauth:true})
            await request_reauth()
            this.setState({isreauth:false})
          }
        }else{
          localStorage.clear();
          history.push("/signin");
        }
      }
    } catch (error) {
      console.log(error.response)
    }
  }

  onChangeView = (url) => {
    const {history} = this.props;
    history.push(url)
  }

  handlerExit = () => {
    localStorage.clear();
    this.props.history.push("/signin");
  }
  handlerHome = () => {
    this.props.history.push("/");
    this.setState({section: "HOME"})
    console.log("home")
  }

  onActionSelected = (path) => {
    if(path === '/signin'){
      this.handlerExit()
    }else{
      this.props.history.push(path)
    }
  }

  toggleDrawer = () => {
    this.setState((prevState, props) => ({isDrawerOpen: !prevState.isDrawerOpen }));
  }

  closeModal = () =>{
    this.setState({modal: {open:false, issending:false, error:null}})
  }

  cleanPermissions = (permissions) => {
    let cleanData = []
    permissions.map(item => {
      let array = {
        id_permission_module: item.permission_module_id,
        name: item.permission_module,
        id_permission_type: item.permission_type_id
      }
      cleanData.push(array)
    })

    return cleanData
  }

  updatePermissions = (permissions) => {
    this.setState({permissions:[...permissions]})
    this.updateDashboardRoutes()
    return permissions
  }

  render(){

    const { classes, history } = this.props;
    const {isDrawerOpen, section, user, language, notifications,
      permissions, dashboard, sidebar_routes} = this.state;
    let catalogs = catalogContent[language]
    const timezones = moment.tz.names().map(item => ({value:item, label:item}))
    catalogs.timezones = [...timezones]    

    let permission = null
    if(permissions.length > 0){
      permission = permissions[0].id_permission_type+permissions[1].id_permission_type+permissions[4].id_permission_type
    }
    const content = contentData[language]
     
    let contentRedirect = null



    return(

      <div className={classes.root} onMouseMove={this.mouseMoveEvent}>
        {contentRedirect}
      <TopBar
        open={isDrawerOpen}
        toggleDrawer={this.toggleDrawer}
        user={user}
        language={language}
        isdraweropen={this.isDrawerOpen}
        onActionSelected={this.onActionSelected}
        notifications={notifications}
        section={section}
      />
      <SideBar
        history={history}
        routes={sidebar_routes}
        notifications={notifications}
        onChange={this.onChangeView}
        toggleDrawer={this.toggleDrawer}
        open={isDrawerOpen}
      />
      <AIChatButton permissions={permissions} />
      {permissions && permissions.length ? 
        <main className={cx({
          [classes.content]:true,
          [classes.contentOpen]:isDrawerOpen
        })}>
          <Switch>
            {dashboardRoutes.sections.map((route,idx) => {
              if(route.redirect)
                return <Redirect to={route.to} key={idx.toString()}/>
              return <Route
                path={route.path}
                exact={route.exact}
                render={(props) => <route.component
                  {...props}
                  language={'spanish'}
                  parentuser={user}
                  catalogs={catalogs}
                  notifications={notifications}
                  permissions={permissions}
                  permissionsType={permissionsType}
                  updatePermissions={this.updatePermissions}
                />}
                key={idx.toString()}
              />
            })}
          </Switch>
        </main> 
      : null}
    </div>
    );
  }
};

const mapDispatchToProps = dispatch => {
  return{
    onUpdateUser: (user) => dispatch({type: actionTypes.AUTH_UPDATE_USER, user}),
    onUpdatePermissions: (permissions) => dispatch({type: actionTypes.AUTH_UPDATE_PERMISSIONS, permissions}),
    onUpdateNotifications: (notifications) => dispatch({type: actionTypes.AUTH_UPDATE_NOTIFICATIONS, notifications})

  }
}


export default connect(null, mapDispatchToProps)(withStyles(styles)(withWidth()(Dashboard)));
